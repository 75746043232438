import Vue from "vue";
import VueRouter from "vue-router";

import auth from "./middleware/auth";

import LoginView from "@views/auth/Login.vue";

import LoginFormView from  "@views/auth/LoginForm.vue";
import LoginMultipleAccountsView from "@views/auth/LoginMultipleAccounts.vue";
import LoginTwoFactorView from  "@views/auth/LoginTwoFactor.vue";
import WelcomeView from  "@views/auth/Welcome.vue";
import LogoutView from  "@views/auth/Logout.vue";
import PasswordForgotView from  "@views/auth/PasswordForgot.vue";
import PasswordResetView from  "@views/auth/PasswordReset.vue";

import DashboardView from  "@views/customer/Dashboard.vue";
import ProspectView from "@views/customer/prospect/Index.vue"
import NotificationsView from  "@views/notification/Index.vue";
import NotificationView from "@views/notification/Notification.vue"
import NotificationComposeView from "@views/notification/CustomerCompose.vue"
import ProfileView from "@views/customer/profile/Index.vue"
import PlanView from  "@views/customer/plan/Plan.vue";
import PortfolioView from "@views/customer/portfolio/Index.vue"
import QuarterReportView from  "@views/quarterReports/Index.vue";

import AdminDashboardView from  "@views/admin/Dashboard.vue";
import AdminCustomersView from  "@views/admin/customer/Index.vue";
import AdminCustomersContactView from  "@views/admin/customer/Contact.vue";
import AdminCustomerView from  "@views/admin/customer/Customer.vue";
import AdminPlansView from  "@views/admin/plan/Index.vue";
import AdminEtfsView from  "@views/admin/plan/Etf.vue";
import AdminProfileView from  "@views/admin/profile/Index.vue";
import AdminAlertsView from  "@views/admin/alerts/Index.vue";
import AdminTasksView from  "@views/admin/tasks/Index.vue";
import AdminComposeNotification from  "@views/notification/Compose.vue";
import AdminNotificationTemplatesIndex from "@views/notification/templates/Index.vue"
import AdminNotificationTemplatesCreate from "@views/notification/templates/Create.vue"
import AdminNotificationTemplatesEdit from "@views/notification/templates/Edit.vue"
import AdminQuarterReports from  "@views/quarterReports/Upload.vue";
import AdminAdvisorsView from  "@views/admin/advisors/Index.vue";
import AdminCreateAdvisorView from  "@views/admin/advisors/Create.vue";
import AdminAdvisorView from  "@views/admin/advisors/Advisor.vue";

import AdminCustomerProfileView from "@views/admin/customer/pages/Profile.vue"
import AdminCustomerPortfolioView from "@views/admin/customer/pages/Portfolio.vue"
import AdminCustomerProspectView from "@views/admin/customer/pages/Prospect.vue"
import AdminCustomerProspectNoteView from "@views/admin/customer/pages/ProspectNote.vue"
import AdminCustomerPlanView from "@views/admin/customer/pages/Plan.vue"
import AdminCustomerNotesView from "@views/admin/customer/pages/Notes.vue"
import AdminAllNotificationsView from "@views/notification/AllNotifications.vue"
import AdminCustomerNotificationsView from "@views/admin/customer/pages/Notifications/Index.vue"
import AdminCustomerNotificationInboxView from "@views/admin/customer/pages/Notifications/Inbox.vue"
import AdminCustomerNotificationSentView from "@views/admin/customer/pages/Notifications/Sent.vue"
import AdminCustomerNotificationsComposeView from "@views/admin/customer/pages/Notifications/Compose.vue"
import AdminCustomerNotesPrintableView from "@views/admin/customer/pages/NotesPrintable.vue"
import AdminCustomerTasksView from "@views/admin/customer/pages/Tasks.vue"
import AdminCustomerQuarterReportsView from "@views/admin/customer/pages/QuarterReports.vue"
import AdminCustomerAdvisorsView from "@views/admin/customer/pages/Advisors.vue"
import AdminCustomerAccountView from "@views/admin/customer/pages/Account.vue"
import AdminCustomerHistoricalView from "@views/admin/customer/pages/Historical.vue"

Vue.use(VueRouter);

const routes = [
    {
        component: DashboardView,
        name: "dashboard",
        path: "/",
        meta: {
            middleware: auth,
        },
    },
    {
        component: LogoutView,
        name: "auto-logout",
        path: "/auth/logout/auto",
    },
    {
        component: LogoutView,
        name: "logout",
        path: "/auth/logout",
    },
    {
        component: LoginView,
        path: "/auth/login",
        children: [
            {
                component: LoginFormView,
                name: "login",
                path: "",
            },
            {
                component: LoginMultipleAccountsView,
                name: "login/multpleAccounts",
                path: "multiple-accounts",
            },
            {
                component: LoginTwoFactorView,
                name: "login/twoFactor",
                path: "two-factor",
            },
        ],
    },
    {
        component: PasswordForgotView,
        name: "password-forgot",
        path: "/auth/password-forgot",
    },
    {
        component: WelcomeView,
        name: "welcome",
        path: "/auth/welcome/:email/:token",
    },
    {
        component: PasswordResetView,
        name: "password-reset",
        path: "/auth/password-reset/:token",
    },
    {
        component: ProfileView,
        name: "profile",
        path: "/profile",
        meta: {
            middleware: auth,
        },
    },
    {
        component: ProfileView,
        name: "profile/password",
        path: "/profile/:page",
        meta: {
            middleware: auth,
        },
    },
    {
        component: ProfileView,
        name: "profile/two-factor",
        path: "/profile/:page",
        meta: {
            middleware: auth,
        },
    },
    {
        component: ProspectView,
        name: "prospect",
        path: "/prospect/:page?",
        meta: {
            middleware: auth,
        },
    },

    {
        component: NotificationsView,
        name: "notifications",
        path: "/notifications/",
        meta: {
            middleware: auth,
        },
    },
    {
        component: NotificationComposeView,
        name: "notification/compose",
        path: "/notifications/compose/:receiver?",
        meta: {
            middleware: auth,
        },
    },
    {
        component: NotificationView,
        name: "notification",
        path: "/notifications/:id",
        meta: {
            middleware: auth,
        },
    },
    {
        component: PlanView,
        name: "plan",
        path: "/plan/",
        meta: {
            middleware: auth,
        },
    },
    {
        component: PortfolioView,
        name: "portfolio",
        path: "/portfolio/",
        meta: {
            middleware: auth,
        },
    },
    {
        component: QuarterReportView,
        name: "quarter-reports",
        path: "/quarter-reports/",
        meta: {
            middleware: auth,
        },
    },
    {
        component: AdminAllNotificationsView,
        name: "admin/notifications/all",
        path: "/admin/notifications/all",
        meta: {
            middleware: auth,
        },
    },
    {
        component: AdminComposeNotification,
        name: "admin/notifications/compose",
        path: "/notifications/compose",
        meta: {
            middleware: auth,
        },
    },
    {
        component: AdminNotificationTemplatesIndex,
        name: "admin/notifications/templates",
        path: "/notifications/templates",
        meta: {
            middleware: auth,
        },
    },
    {
        component: AdminNotificationTemplatesCreate,
        name: "admin/notifications/templates/create",
        path: "/notifications/templates/create",
        meta: {
            middleware: auth,
        },
    },
    {
        component: AdminNotificationTemplatesEdit,
        name: "admin/notifications/templates/edit",
        path: "/notifications/templates/:id/",
        meta: {
            middleware: auth,
        },
    },
    {
        component: AdminDashboardView,
        name: "admin/dashboard",
        path: "/admin/dashboard",
        meta: {
            middleware: auth,
        },
    },
    {
        component: AdminCustomersView,
        name: "admin/customers",
        path: "/admin/customers",
        meta: {
            middleware: auth,
        },
    },
    {
        component: AdminCustomersContactView,
        name: "admin/customers/contact",
        path: "/admin/customers/contact",
        meta: {
            middleware: auth,
        },
    },
    {
        component: AdminCustomersView,
        name: "admin/customers/prospects",
        path: "/admin/customers/prospects",
        meta: {
            middleware: auth,
        },
    },
    {
        component: AdminCustomerView,
        path: "/admin/customers/:id/",
        meta: {
            middleware: auth,
        },
        children: [
            {
                path: "",
                name: "admin/customer",
                component: AdminCustomerProfileView,
            },
            {
                path: "portfolio",
                name: "admin/customer/portfolio",
                component: AdminCustomerPortfolioView,
            },
            {
                path: "prospect/note/:option",
                name: "admin/customer/prospect/note",
                component: AdminCustomerProspectNoteView,
            },
            {
                path: "prospect/:index?/:section?",
                name: "admin/customer/prospect",
                component: AdminCustomerProspectView,
            },
            {
                path: "plan",
                name: "admin/customer/plan",
                component: AdminCustomerPlanView,
            },
            {
                path: "notes",
                name: "admin/customer/notes",
                component: AdminCustomerNotesView,
            },
            {
                path: "notifications",
                component: AdminCustomerNotificationsView,
                children: [
                    {
                        path: "",
                        name: "admin/customer/notifications",
                        component: AdminCustomerNotificationInboxView,
                    },
                    {
                        path: "sent",
                        name: "admin/customer/notifications/sent",
                        component: AdminCustomerNotificationSentView,
                    },
                    {
                        path: "compose",
                        name: "admin/customer/notifications/compose",
                        component: AdminCustomerNotificationsComposeView,
                    },
                ],
            },
            {
                path: "notes/printable",
                name: "admin/customer/notes/printable",
                component: AdminCustomerNotesPrintableView,
            },
            {
                path: "tasks",
                name: "admin/customer/tasks",
                component: AdminCustomerTasksView,
            },
            {
                path: "quarter-reports",
                name: "admin/customer/quarter-reports",
                component: AdminCustomerQuarterReportsView,
            },
            {
                path: "advisors",
                name: "admin/customer/advisors",
                component: AdminCustomerAdvisorsView,
            },
            {
                path: "account",
                name: "admin/customer/account",
                component: AdminCustomerAccountView,
            },
            {
                path: "historical",
                name: "admin/customer/historical",
                component: AdminCustomerHistoricalView,
            },
        ],
    },
    {
        component: AdminAdvisorsView,
        name: "admin/advisors",
        path: "/admin/advisors",
        meta: {
            middleware: auth,
        },
    },
    {
        component: AdminCreateAdvisorView,
        name: "admin/advisors/create",
        path: "/admin/advisors/create",
        meta: {
            middleware: auth,
        },
    },
    {
        component: AdminAdvisorView,
        name: "admin/advisor",
        path: "/admin/advisors/:id",
        meta: {
            middleware: auth,
        },
    },
    {
        component: AdminPlansView,
        name: "admin/plans",
        path: "/admin/plans",
        meta: {
            middleware: auth,
        },
    },
    {
        component: AdminEtfsView,
        name: "admin/plans/etfs",
        path: "/admin/plans/etfs",
        meta: {
            middleware: auth,
        },
    },
    {
        component: AdminProfileView,
        name: "admin/profile",
        path: "/admin/profile",
        meta: {
            middleware: auth,
        },
    },
    {
        component: AdminAlertsView,
        name: "admin/alerts",
        path: "/admin/alerts",
        meta: {
            middleware: auth,
        },
    },
    {
        component: AdminTasksView,
        name: "admin/tasks",
        path: "/admin/tasks",
        meta: {
            middleware: auth,
        },
    },
    {
        component: AdminQuarterReports,
        name: "admin/quarter-reports",
        path: "/admin/quarter-reports",
        meta: {
            middleware: auth,
        },
    },
    {
        component: AdminProfileView,
        name: "advisors/profile",
        path: "/advisors/profile",
        meta: {
            middleware: auth,
        },
    },
];

const router = new VueRouter({
    linkActiveClass: "is-active",
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (
            savedPosition &&
            (to.name === "admin/tasks" || to.name === "admin/customers")
        ) {
            return new Promise((resolve, reject) => {
                window.bus.$once("dataLoaded", () => {
                    resolve(savedPosition);
                });
            });
        } else {
            return {x: 0, y: 0};
        }
    },
});

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware});
    };
}

router.beforeEach((to, from, next) => {
    window.bus.$emit("startLoading");

    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];
        const context = {from, next, router, to};
        const nextMiddleware = nextFactory(context, middleware, 1);
        return middleware[0]({...context, next: nextMiddleware});
    }
    return next();
});

router.afterEach((to, from) => {
    window.bus.$emit("doneLoading");
});

export default router;
