<template>
  <div>
    <section class="section content no-header">
      <div class="container">
        <div class="columns">
          <div class="column is-6">
            <configure-two-factor />

            <div class="block">
              <h4 class="title is-4">Profiel</h4>
              <div class="columns">
                <div class="column is-narrow">
                  <figure class="image is-64x64" v-if="user.profile_picture">
                    <img class="is-rounded" :src="user.profile_picture">
                  </figure>
                  <span v-if="!user.profile_picture">
                    Geen foto geüpload
                  </span>
                </div>
                <div class="column">
                  <form @submit.prevent="submitProfile">
                    <form-errors :errors="profile.errors"></form-errors>
                    <div class="field file has-name">
                      <label class="file-label">
                        <input
                          class="file-input"
                          type="file"
                          name="profile_picture"
                          @change="onFileChange($event)"
                          accept="image/jpg"
                        >
                        <span class="file-cta">
                          <span class="file-icon">
                            <i class="fa fa-upload"></i>
                          </span>
                          <span class="file-label">Kies afbeelding</span>
                        </span>
                        <span class="file-name">{{ profile.picture ? profile.picture.name : 'Geen bestand geselecteerd' }}</span>
                      </label>
                    </div>
                    <div class="field">
                      <div class="control">
                        <button type="submit" class="button is-primary">Profielfoto uploaden</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-6">
            <change-password></change-password>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ConfigureTwoFactor from "../../../components/ConfigureTwoFactor.vue";
import ChangePassword from "../../auth/ChangePassword.vue";

export default {
  components: {
    "configure-two-factor": ConfigureTwoFactor,
    "change-password": ChangePassword,
  },

  data() {
    return {
      form: new Laravel.Form({
        secret: "",
        token: ""
      }),
      turnOffForm: new Laravel.Form({
        password: ""
      }),
      QRcode: "",
      turnOffShowPassword: false,
      profile: new Laravel.Form({
        picture: null
      })
    };
  },

  computed: {
    user() {
      return this.$root.user;
    }
  },

  methods: {
    getQRCode() {
      axios
        .get("/api/profile/2fa")
        .then(({ data }) => {
          this.form.secret = data.secret;
          this.QRcode = data.QRcode;
        })
        .catch(() => {
          alert("Kon geen code gereren");
        });
    },

    verifyToken() {
      this.form
        .post("/api/profile/2fa")
        .then(() => {
          this.$root.user["2fa"] = true;
        })
        .catch(() => {});
    },

    turnOff() {
      this.turnOffForm
        .put("/api/profile/2fa")
        .then(() => {
          this.$root.user["2fa"] = false;
        })
        .catch(() => {});
    },

    onFileChange(event) {
      this.profile.picture = event.target.files[0];
    },

    submitProfile() {
      this.profile
        .post("api/profile/picture", true, true)
        .then(({ profile_picture }) => {
          this.$root.user.profile_picture = profile_picture;
          this.$buefy.snackbar.open(`Foto succesvol gewijzigd.`);
        })
        .catch(() => {
          this.$buefy.snackbar.open(`Kon foto niet wijzigen.`);
        });
    }
  }
};
</script>
