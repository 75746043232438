<template>
  <div v-if="show">
    <b-tabs :animated="false">
      <b-tab-item label="Plan">
        <div class="columns">
          <div class="column is-6">
            <div class="field">
              <label class="label">Risicoprofiel</label>
              <p class="control">{{ proposal.plan.name }}</p>
            </div>

            <div class="field">
              <label class="label">Bedrag</label>
              <p class="control" v-if="proposal.amount">&euro; {{ proposal.amount | currency }}</p>
            </div>

            <div class="field">
              <label class="label">Beleggingsdoel</label>
              <p class="control" v-if="proposal.objective == 'retention'">Instandhouding vermogen</p>
              <p class="control" v-if="proposal.objective == 'grow'">Vermogensgroei</p>
              <p
                class="control"
                v-if="proposal.objective == 'not_necessary_addition'"
              >Niet noodzakelijke aanvulling op het inkomen</p>
              <p
                class="control"
                v-if="proposal.objective == 'necessary_addition'"
              >Noodzakelijke aanvulling op het inkomen</p>
              <p
                class="control"
                v-if="proposal.objective == 'repayment_mortgage'"
              >Aflossing hypotheek</p>
              <p class="control" v-if="proposal.objective == 'children_study'">Financiering studie</p>
              <p
                class="control"
                v-if="proposal.objective == 'specific_spend_feature'"
              >Specifieke uitgave in de toekomst</p>
              <p class="control" v-if="proposal.objective == 'other'">Anders</p>
            </div>

            <div class="field">
              <label class="label">Startdatum</label>
              <p class="control">{{ proposal.start_date | formatDate }}</p>
            </div>

            <div class="field">
              <label class="label">Einddatum</label>
              <p class="control">{{ proposal.end_date | formatDate }}</p>
            </div>

            <div class="field">
              <label class="label">Gewenst rendement over looptijd</label>
              <p class="control">{{ proposal.rate_of_return }}%</p>
            </div>

            <div class="field">
              <label class="label">Doelbedrag</label>
              <p class="control">€{{ proposal.target_amount | currency }}</p>
            </div>

            <div class="field">
              <label class="label">Beschrijving grafiek</label>
              <p class="control" v-if="proposal.description_short" v-html="proposal.description_short"></p>
              <p class="control" v-else>
                <em>Geen beschrijving opgegeven</em>
              </p>
            </div>

            <div class="field">
              <label class="label">Beschrijving</label>
              <p class="control" v-if="proposal.description" v-html="proposal.description"></p>
              <p class="control" v-else>
                <em>Geen beschrijving opgegeven</em>
              </p>
            </div>

            <div
              class="control"
              v-if="!data.profile.current_plan || proposal.id !== data.profile.current_plan.id"
            >
              <hr>
              <h4 class="title is-4">Plan starten</h4>
              <form @submit.prevent="startPlan()">
                <form-errors :errors="startPlanForm.errors"></form-errors>
                <div class="field">
                  <label class="label">Ingangsdatum plan</label>
                  <b-datepicker
                    name="start_date"
                    placeholder="01-01-2000"
                    v-model="startPlanForm.start_date"
                  ></b-datepicker>
                </div>
                <div class="field">
                  <button
                    type="submit"
                    class="button is-primary"
                    :class="{'is-loading': startPlanForm.loading}"
                  >Plan starten</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </b-tab-item>

      <b-tab-item label="Verwachte kosten">
        <div class="columns">
          <div class="column is-12">
            <ExpectedCosts :data="proposal.expectedCosts"></ExpectedCosts>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import ExpectedCosts from "@customer/plan/ExpectedCosts.vue";
import NL from "flatpickr/dist/l10n/nl.js";

export default {
  components: {
    ExpectedCosts: ExpectedCosts
  },

  data() {
    return {
      show: false,
      proposal: {},
      startPlanForm: new Laravel.Form({
        id: "",
        start_date: new Date()
      }),
      showExpectedCosts: false
    };
  },

  props: ["index"],

  computed: {
    nl() {
      return NL;
    },

    data() {
      return this.$parent.data;
    }
  },

  methods: {
    startPlan() {
      this.startPlanForm.id = this.proposal.id;
      this.$buefy.dialog.confirm({
        message: "Weet u zeker dat u dit traject wilt starten?",
        cancelText: "Nee",
        confirmText: "Ja, start dit traject",
        onConfirm: () => {
          this.startPlanForm
            .put(
              `/api/admin/customers/${this.proposal.user_id}/proposal/${
                this.proposal.id
              }/start`
            )
            .then(response => {
              Object.entries(response).forEach(([key, value]) => {
                this.$set(this.$parent.data, key, value);
              })
              this.$buefy.snackbar.open(`Plan succesvol gestart`);
            }).catch(() => {this.$buefy.snackbar.open('Kon niet bijwerken.')});
        }
      });
    }
  },

  watch: {
    index: function(index) {
      this.show = true;
      this.proposal = this.$parent.data.proposals[index];
    }
  }
};
</script>
