<style scoped>
    i.fa.is-white {
        color: #fff;
    }
</style>

<template>
  <div>
    <h4 class="title is-4">Documenten</h4>

    <table class="table">
      <thead>
        <th>Titel document</th>
        <th>Uploaddatum/
          <br>Verloopdatum
        </th>
        <th>Zichtbaarheid</th>
        <th>Download</th>
      </thead>

      <tbody>
        <tr v-for="(file, index) in data.files" v-bind:key="file.id">
          <td>{{ file.name }}</td>
          <td>
            {{ file.created_at | formatDate }}
            <span v-if="file.expires_at">
              <br>
              ({{ file.expires_at | formatDate }})
            </span>
          </td>
          <td>
            <span class="tag is-success" v-if="file.visible_for_user">
              <i class="fa fa-check is-white"></i>
            </span>
            <span class="tag is-danger" v-if="!file.visible_for_user">
              <i class="fa fa-times is-white"></i>
            </span>
          </td>
          <td>
            <span class="button is-primary" @click="downloadFile(file.downloadURL)">Download</span>
            <span class="button is-danger" @click="deleteFile(file.id, index)">
              <i class="fa fa-times"></i>
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <button
      class="button is-primary"
      @click="toggleUploadForm()"
      v-show="!showUploadForm"
    >Nieuw bestand uploaden</button>

    <div v-if="showUploadForm">
      <h4 class="title is-4">Bestand uploaden</h4>
      <form-errors :errors="uploadForm.errors"></form-errors>
      <form @submit.prevent="submitForm()">
        <div class="field">
          <label class="label">Titel document</label>
          <input
            name="name"
            type="text"
            class="input"
            placeholder="Titel document"
            v-model="uploadForm.name"
          >
        </div>

        <div class="field">
          <label class="label">Verloopdatum (optioneel)</label>
          <b-datepicker name="start_date" v-model="uploadForm.expires_at"></b-datepicker>
        </div>

        <div class="field">
          <label class="label">Bestand</label>
          <div class="field file has-name">
            <label class="file-label">
              <input class="file-input" type="file" name="file" @change="onFileChange($event)">
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fa fa-upload"></i>
                </span>
                <span class="file-label">Kies bestand</span>
              </span>
              <span
                class="file-name"
              >{{ uploadForm.file ? uploadForm.file.name : 'Geen bestand geselecteerd' }}</span>
            </label>
          </div>
          <p
            class="content is-small"
          >Toegestaande bestandstypes: jpeg, png, jpg, doc, docx, pdf. Maximale grootte: 10MB</p>
        </div>

        <div class="field">
          <label class="label">Zichtbaarheid</label>
          <div class="field">
            <p class="control">
              <label class="checkbox">
                <input
                  name="visible_for_user"
                  type="checkbox"
                  v-model="uploadForm.visible_for_user"
                >
                Dit document is zichtbaar voor de klant
              </label>
            </p>
          </div>
        </div>

        <div class="field">
          <label class="label">Watermerk toevoegen</label>
          <label class="checkbox">
            <input type="checkbox" v-model="uploadForm.watermark">
            Voeg watermerk toe aan de afbeelding.
          </label>
          <br>
          <small>Deze functionaliteit werkt alleen bij afbeeldingen (jpeg, jpg, png). Gebruik deze functie als u bijvoorbeeld een identificatiedocument upload.</small>
        </div>

        <div class="field">
          <button
            type="submit"
            class="button is-primary"
            :class="{'is-loading': uploadForm.loading}"
          >Uploaden</button>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      showUploadForm: false,
      uploadForm: new Laravel.Form({
        user_id: "",
        name: "",
        expires_at: new Date(),
        file: null,
        visible_for_user: false,
        watermark: false
      })
    };
  },

  computed: {
    data() {
      return this.$parent.data;
    },

    token() {
      return localStorage.getItem("token");
    }
  },

  methods: {
    toggleUploadForm() {
      return (this.showUploadForm = !this.showUploadForm);
    },

    onFileChange(event) {
      this.uploadForm.file = event.target.files[0];
    },

    submitForm() {
      this.uploadForm.user_id = this.$parent.data.profile.id;

      let date = this.uploadForm.expires_at;

      this.uploadForm.expires_at =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

      this.uploadForm.post("api/documents", true, true).then(data => {
        this.data.files.push(data);
        this.$buefy.snackbar.open("Bestand succesvol geüpload");
      }).catch(() => {this.$buefy.snackbar.open('Kon niet bijwerken.')});
    },

    downloadFile(url) {
      window.open(`${url}?token=${this.token}`);
    },

    deleteFile(id, index) {
      this.$buefy.dialog.confirm({
        message: `Het bestand met de titel "${
          this.data.files[index].name
        }" verwijderen?`,
        cancelText: "Nee",
        confirmText: "Ja",
        onConfirm: () => {
          axios
            .delete(`/api/documents/${id}`)
            .then(() => {
              this.$parent.data.files.splice(index, 1);
              this.$buefy.snackbar.open("Bestand succesvol verwijderd.");
            })
            .catch(() => {
              this.$buefy.snackbar.open("Kon bestand niet verwijderen.");
            });
        }
      });
    }
  }
};
</script>
