<template>
  <div>
    <div class="section light">
      <div class="container">
        <h3 class="title is-3" v-if="!autoLogout" align>Uitgelogd</h3>
        <h3 class="title is-3" v-if="autoLogout">Automatisch uitgelogd</h3>
      </div>
    </div>

    <section class="section no-background has-header">
      <div class="container content">
        <div class="block">
          <p v-if="!autoLogout">U bent succesvol uitgelogd.
            <br>U kunt terugkeren naar het inlogformulier of naar onze website.
          </p>
          <p v-if="autoLogout">U bent om veiligheidsredenen automatisch uitgelogd.
            <br>U kunt terugkeren naar het inlogformulier of naar onze website.
          </p>

          <router-link :to="{ name: 'login' }" class="button is-primary">Ga naar inloggen</router-link>
          <a href="https://besmartib.nl" class="button is-primary">Ga naar onze website</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      autoLogout: false
    };
  },

  mounted() {
    axios.post("api/auth/logout").then(() => {
     this.clearApplication()
    }).catch(() => {
      this.clearApplication()
    })
  },

  methods: {
    clearApplication() {
      this.$root.destroyUser();
      if (window.location.hash == "#/auth/logout/auto") {
        this.autoLogout = true;
      }
    }
  }
};
</script>
