<style>
.triangle {
  vertical-align: middle;
  color: #ee8e00;
}
</style>

<template>
  <div v-if="data">
    <table
      v-for="row in data"
      v-bind:key="row.id"
      class="table is-striped"
      :class="row[0].etf.asset_class.slug"
    >
      <thead>
        <th width="30%">
          <span v-if="row[0].etf.asset_class.slug === 'aandelen'"
            >Aandelen</span
          >
          <span v-if="row[0].etf.asset_class.slug === 'obligaties'"
            >Obligaties</span
          >
          <span v-if="row[0].etf.asset_class.slug === 'grondstoffen'"
            >Grondstoffen</span
          >
          <span v-if="row[0].etf.asset_class.slug === 'liquiditeiten'"
            >Liquiditeiten</span
          >
        </th>
        <th width="10%">ISIN code</th>
        <th width="5%">Aantal</th>
        <th width="10%">Koers</th>
        <th width="15%">% v/h vermogen</th>
        <th width="15%">Huidige waarde</th>
        <th class="has-text-right" width="15%">Resultaat in %</th>
      </thead>
      <tbody>
        <!-- <template > -->
        <tr v-for="etf in row" v-bind:key="etf.id">
          <td>
            <i
              class="fa fa-exclamation-triangle triangle"
              v-if="etf.deviates && user.role === 'manager'"
              title="Dit fonds wijkt af van de modelportefeuille"
            ></i>
            {{ etf.etf.name }}
          </td>
          <td>{{ etf.etf.isin }}</td>
          <td>
            <span v-if="etf.amount > 0">{{ etf.amount }}</span>
          </td>
          <td nowrap="nowrap">
            <span v-if="etf.etf.current_rate > 0"
              >&euro; {{ etf.etf.current_rate | currency }}</span
            >
          </td>
          <td>{{ ((etf.value / totalValue) * 100) | currency }}%</td>
          <td nowrap="nowrap">&euro; {{ etf.value | currency }}</td>
          <td class="has-text-right">{{ etf.result | currency }}%</td>
        </tr>
        <!-- </template> -->
      </tbody>
      <tfoot v-if="row.length">
        <tr>
          <th colspan="4">
            <strong>Subtotaal {{ row[0].etf.asset_class.slug }}</strong>
          </th>
          <th>
            {{
              ((percentageOfCapital[row[0].etf.asset_class.slug] / totalValue) *
                100)
                | currency
            }}%
          </th>
          <th>
            &euro;
            {{ percentageOfCapital[row[0].etf.asset_class.slug] | currency }}
          </th>
          <th></th>
        </tr>
      </tfoot>
    </table>

    <table class="table dark">
      <thead>
        <tr>
          <td width="72%">
            <strong>Totaal</strong>
          </td>
          <td width="15%">
            <strong>&euro; {{ totalValue | currency }}</strong>
          </td>
          <td width="13%">
            <strong></strong>
          </td>
        </tr>
      </thead>
    </table>
    <p
      class="has-text-right"
      v-if="Object.keys(data).length && data['1'] && data['1'][0]"
    >
      <small>
        <em
          >Laatst bijgewerkt op
          {{ data["1"][0]["created_at"] | formatDate("D-M-Y HH:mm:ss") }}</em
        >
      </small>
    </p>
  </div>
</template>

<script>
export default {
  props: ["data"],

  data() {
    return {
      totalValue: 0,
      percentageOfCapital: {
        aandelen: 0,
        obligaties: 0,
        grondstoffen: 0,
        liquiditeiten: 0,
      },
    };
  },

  mounted() {
    if (this.data) {
      for (let assetClass in this.data) {
        assetClass = this.data[assetClass];
        for (let item of assetClass) {
          if (
            this.percentageOfCapital.hasOwnProperty(item.etf.asset_class.slug)
          ) {
            this.percentageOfCapital[item.etf.asset_class.slug] += item.value;
          } else {
            this.percentageOfCapital[item.etf.asset_class.slug] = item.value;
          }
          this.totalValue += item.value;
        }
      }
    }
  },

  watch: {
    data: function (val) {
      if (this.data) {
        this.totalValue = 0;
        this.percentageOfCapital = {};
        for (let assetClass in this.data) {
          assetClass = this.data[assetClass];
          for (let item of assetClass) {
            if (
              this.percentageOfCapital.hasOwnProperty(item.etf.asset_class.slug)
            ) {
              this.percentageOfCapital[item.etf.asset_class.slug] += item.value;
            } else {
              this.percentageOfCapital[item.etf.asset_class.slug] = item.value;
            }
            this.totalValue += item.value;
          }
        }
      }
    },
  },

  computed: {
    user() {
      return this.$root.user;
    },
  },
};
</script>
