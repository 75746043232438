<template>
  <div class="content">
    <section class="section light no-padding-bottom">
      <div class="container">
        <h3 class="title is-3">
          <strong>Inventarisatie</strong>
          <span class="is-primary">Risicohouding</span>
        </h3>

        <form-errors :errors="form.errors"></form-errors>
      </div>
    </section>

    <section class="section no-background has-header">
      <div class="container">
        <div class="columns">
          <div class="column is-6">
            <div class="block">
              <h4 class="title is-4">Uw risicohouding</h4>

              <div class="field">
                <label
                  class="label"
                >Wat is de acceptatie van het risico van uw gewenste portefeuille?</label>

                <div class="field">
                  <label class="radio">
                    <input
                      name="acceptance"
                      type="radio"
                      value="Zeer defensief"
                      v-model="form.acceptance"
                    >
                    Zeer defensief
                  </label>
                </div>

                <div class="field">
                  <label class="radio">
                    <input
                      name="acceptance"
                      type="radio"
                      value="Defensief"
                      v-model="form.acceptance"
                    >
                    Defensief
                  </label>
                </div>

                <div class="field">
                  <label class="radio">
                    <input
                      name="acceptance"
                      type="radio"
                      value="Gematigd defensief"
                      v-model="form.acceptance"
                    >
                    Gematigd defensief
                  </label>
                </div>

                <div class="field">
                  <label class="radio">
                    <input
                      name="acceptance"
                      type="radio"
                      value="Neutraal"
                      v-model="form.acceptance"
                    >
                    Neutraal
                  </label>
                </div>

                <div class="field">
                  <label class="radio">
                    <input
                      name="acceptance"
                      type="radio"
                      value="Offensief"
                      v-model="form.acceptance"
                    >
                    Offensief
                  </label>
                </div>

                <div class="field">
                  <label class="radio">
                    <input
                      name="acceptance"
                      type="radio"
                      value="Zeer offensief"
                      v-model="form.acceptance"
                    >
                    Zeer offensief
                  </label>
                </div>
              </div>
              <form-field-errors :errors="form.errors" field="acceptance"></form-field-errors>
              <br />
              <div class="field">
                <label
                  class="label"
                >Wat doet u wanneer de waarde van uw beleggingen in het eerste jaar daalt met 20%?</label>

                <div class="field">
                  <label class="radio">
                    <input
                      name="risk_tolerance"
                      type="radio"
                      value="Ik verkoop al mijn beleggingen om verdere verliezen te voorkomen."
                      v-model="form.risk_tolerance"
                    >
                    Ik verkoop al mijn beleggingen om verdere verliezen te voorkomen.
                  </label>
                </div>

                <div class="field">
                  <label class="radio">
                    <input
                      name="risk_tolerance"
                      type="radio"
                      value="Ik laat mijn beleggingen staan en wacht tot de markt weer herstelt."
                      v-model="form.risk_tolerance"
                    >
                    Ik laat mijn beleggingen staan en wacht tot de markt weer herstelt.
                  </label>
                </div>

                <div class="field">
                  <label class="radio">
                    <input
                      name="risk_tolerance"
                      type="radio"
                      value="Ik overweeg om deze kans te benutten en extra beleggingen te kopen tegen lagere prijzen."
                      v-model="form.risk_tolerance"
                    >
                    Ik overweeg om deze kans te benutten en extra beleggingen te kopen tegen lagere prijzen.
                  </label>
                </div>
              </div>
              <form-field-errors :errors="form.errors" field="risk_tolerance"></form-field-errors>
              <br />
              <div class="field">
                <label
                  class="label"
                >Welke waardeontwikkeling van € 100.000,- in &eacute;&eacute;n jaar heeft uw voorkeur?</label>

                <div class="field">
                  <label class="radio">
                    <input
                      name="value_development"
                      type="radio"
                      value="95000 - 115000"
                      v-model="form.value_development"
                    >
                    Tussen de € 95.000,- en € 115.000,-
                  </label>
                </div>
                <div class="field">
                  <label class="radio">
                    <input
                      name="value_development"
                      type="radio"
                      value="90000 - 125000"
                      v-model="form.value_development"
                    >
                    Tussen de € 90.000,- en € 125.000,-
                  </label>
                </div>

                <div class="field">
                  <label class="radio">
                    <input
                      name="value_development"
                      type="radio"
                      value="80000 - 135000"
                      v-model="form.value_development"
                    >
                    Tussen de € 80.000,- en € 135.000,-
                  </label>
                </div>
              </div>
              <form-field-errors :errors="form.errors" field="risk_tolerance"></form-field-errors>
            </div>
          </div>

          <div class="column is-6">
            <div class="block">
              <h4 class="title is-4">&nbsp;</h4>

              <div class="field">
                <label class="label">Welke stelling past het beste bij u?</label>

                <div class="field">
                  <label class="radio">
                    <div class="columns">
                      <div class="column is-narrow">
                        <input name="endeavor" type="radio" value="1" v-model="form.endeavor">
                      </div>
                      <div class="column">
                        <p>
                          U streeft met uw beleggingen naar een rendement van
                          <strong>circa 3 á 4%</strong>. Met de beoogde portefeuille inrichting zal het rendement zich waarschijnlijk bewegen tussen een
                          <strong>negatief resultaat van -5% en een positief resultaat van 12%</strong>. Ook grotere uitslagen in positieve en negatieve zin zijn mogelijk.
                        </p>
                      </div>
                    </div>
                  </label>
                </div>

                <div class="field">
                  <label class="radio">
                    <div class="columns">
                      <div class="column is-narrow">
                        <input name="endeavor" type="radio" value="2" v-model="form.endeavor">
                      </div>
                      <div class="column">
                        <p>
                          U streeft met uw beleggingen naar een rendement van
                          <strong>4 á 5%</strong>. Met de beoogde portefeuille inrichting zal het rendement zich waarschijnlijk bewegen tussen een
                          <strong>negatief resultaat van -6% en een positief resultaat van 15%</strong>. Ook grotere uitslagen in positieve en negatieve zin zijn mogelijk.
                        </p>
                      </div>
                    </div>
                  </label>
                </div>

                <div class="field">
                  <label class="radio">
                    <div class="columns">
                      <div class="column is-narrow">
                        <input name="endeavor" type="radio" value="3" v-model="form.endeavor">
                      </div>
                      <div class="column">
                        <p>
                          U streeft met uw beleggingen naar een rendement van
                          <strong>5 á 6%</strong>. Met de beoogde portefeuille inrichting zal het rendement zich waarschijnlijk bewegen tussen een
                          <strong>negatief resultaat van -9% en een positief resultaat van 20%</strong>. Ook grotere uitslagen in positieve en negatieve zin zijn mogelijk.
                        </p>
                      </div>
                    </div>
                  </label>
                </div>

                <div class="field">
                  <label class="radio">
                    <div class="columns">
                      <div class="column is-narrow">
                        <input name="endeavor" type="radio" value="4" v-model="form.endeavor">
                      </div>
                      <div class="column">
                        <p>
                          U streeft met uw beleggingen naar een rendement van
                          <strong>6 á 7%</strong>. Met de beoogde portefeuille inrichting zal het rendement zich waarschijnlijk bewegen tussen een
                          <strong>negatief resultaat van -12,5% en een positief resultaat van 25,5%</strong>. Ook grotere uitslagen in positieve en negatieve zin zijn mogelijk.
                        </p>
                      </div>
                    </div>
                  </label>
                </div>

                <div class="field">
                  <label class="radio">
                    <div class="columns">
                      <div class="column is-narrow">
                        <input name="endeavor" type="radio" value="5" v-model="form.endeavor">
                      </div>
                      <div class="column">
                        <p>
                          U streeft met uw beleggingen naar een rendement van
                          <strong>7 á 8%</strong>. Met de beoogde portefeuille inrichting zal het rendement zich waarschijnlijk bewegen tussen een
                          <strong>negatief resultaat van -17,5% en een positief resultaat van 32,5%</strong>. Ook grotere uitslagen in positieve en negatieve zin zijn mogelijk.
                        </p>
                      </div>
                    </div>
                  </label>
                </div>

                <div class="field">
                  <label class="radio">
                    <div class="columns">
                      <div class="column is-narrow">
                        <input name="endeavor" type="radio" value="6" v-model="form.endeavor">
                      </div>
                      <div class="column">
                        <p>
                          U streeft met uw beleggingen naar een rendement van
                          <strong>8 á 9%</strong>. Met de beoogde portefeuille inrichting zal het rendement zich waarschijnlijk bewegen tussen een
                          <strong>negatief resultaat van -19,5% en een positief resultaat van 36,5%</strong>. Ook grotere uitslagen in positieve en negatieve zin zijn mogelijk.
                        </p>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <form-field-errors :errors="form.errors" field="endeavor"></form-field-errors>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <button
              @click="save()"
              class="button is-primary"
              :class="{'is-loading': form.loading}"
            >Tussentijds opslaan</button>
            <button
              @click="next()"
              class="button is-primary is-pulled-right"
              :class="{'is-loading': form.loading}"
            >Opslaan en verder</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: this.$parent.form.risk_attitude
    };
  },

  beforeMount() {
    if (!this.form.acceptance) {
      this.form = {
        acceptance: "",
        endeavor: "",
        risk_tolerance: "",
        value_development: "",
      };
      this.form.next = false;

      this.form = new Laravel.Form(this.form);
    }
  },

  methods: {
    save() {
      this.$parent.save(this.form);
    },

    next() {
      this.$parent.save(this.form, true);
    }
  }
};
</script>
