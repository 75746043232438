<template>

    <div>

        <div class="section light">

            <div class="container">

                <h3 class="title is-3">Wachtwoord vergeten</h3>

                <form-errors :errors="passwordForgotForm.errors"></form-errors>

            </div>

        </div>

        <section class="section content has-title">
            <div class="container">
                <div class="block">

                    <div v-if="!success">

                        <p>Als u het e-mailadres invult waarmee u bij ons bekeend staat en daarna op 'bevestigen' klikt, ontvangt u binnen enkele ogenblikken een e-mail met daarin een link waarmee u een nieuw wachtwoord aan kunt maken.</p>

                        <form method="GET" @submit.prevent="submitForm()">
                            <div class="columns">

                                <div class="column">
                                    <label>E-mailadres</label>
                                </div>
                                <div class="column">
                                    <input name="email" type="email" class="input" placeholder="E-mailadres" v-model="passwordForgotForm.email">
                                </div>
                            </div>

                            <div class="columns">

                                <div class="column"></div>
                                <div class="column">
                                    <button type="submit" class="button is-primary" :class="{'is-loading': passwordForgotForm.loading}">Bevestigen</button>
                                </div>

                            </div>
                        </form>
                    </div>

                    <div v-if="success">
                        <p>Er is een e-mail verstuurd naar uw e-mailadres. Wanneer u na enige tijd geen e-mail ontvangen heeft, neemt u dan alstublieft contact met ons op.</p>
                    </div>
                </div>
            </div>
        </section>

    </div>

</template>

<script>

    export default {
        data() {
            return {
                passwordForgotForm: new Laravel.Form ({
                    email: ''
                }),
                success: false
            }
        },

        methods: {
            submitForm() {
                this.passwordForgotForm.post('/api/auth/password/forgot')
                    .then(data => {
                        this.success = true
                    })
                    .catch(error => {
                    })
            }
        },

    }

</script>
