<template>

    <div>

        <section class="section content no-header">
            <div class="container">
                <div class="block">
                    <p>De onderstaande klanten staan klaar om geherbalanceerd te worden.</p>
                    <br /><br />
                    <h4 class="title is-4">Afwijking plan én herbalanceren</h4>
                    <table class="table is-striped">
                        <thead>
                            <th width="15%">Naam klant</th>
                            <th width="10%">Huidig plan</th>
                            <th width="20%">Benodigd rendement per jaar</th>
                            <th width="45%">Beschrijving</th>
                            <th  width="10%" nowrap>Actie ondernemen</th>
                        </thead>
                        <tbody>
                            <tr v-for="(plan, index) in bothData">
                                <td class="has-pointer">{{ plan.user.fullName }}</td>
                                <td class="has-pointer">{{ plan.plan.name }}</td>
                                <td class="has-pointer">{{ plan.required_efficiency_per_year | currency}}% / {{ plan.plan.intended_yearly_return | currency }}%</td>
                                <td class="has-pointer"><span v-if="plan.rebalance_substantiation" v-html="plan.rebalance_substantiation"></span></td>
                                <td nowrap>
                                    <router-link
                                        tag="button"
                                        class="button is-primary"
                                        :class="{'is-loading': plan.loading }"
                                        :to="{ name: 'admin/customer', params: {id: plan.user.id } }"
                                        >Open profiel</router-link>
                                    <button class="button is-primary"
                                        :class="{ 'is-loading': plan.loading }"
                                        @click="done('both', index, plan.id)"><i class="fa fa-check"></i>&nbsp;afgewerkt</button>
                                    <button class="button is-danger"
                                        :class="{ 'is-loading': plan.loading }"
                                        title="Negeer voor nu"
                                        @click="ignore(index, plan.id)"><i class="fa fa-times"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <h4 class="title is-4">Afwijking plan</h4>
                    <table class="table is-striped">
                        <thead>
                            <th width="15%">Naam klant</th>
                            <th width="10%">Huidig plan</th>
                            <th width="20%">Benodigd rendement per jaar</th>
                            <th width="45%">Beschrijving</th>
                            <th  width="10%" nowrap>Actie ondernemen</th>
                        </thead>
                        <tbody>
                            <tr v-for="(plan, index) in planData">
                                <td class="has-pointer">{{ plan.user.fullName }}</td>
                                <td class="has-pointer">{{ plan.plan.name }}</td>
                                <td class="has-pointer">{{ plan.required_efficiency_per_year | currency}}% / {{ plan.plan.intended_yearly_return | currency }}%</td>
                                <td class="has-pointer"><span v-if="plan.rebalance_substantiation" v-html="plan.rebalance_substantiation"></span></td>
                                <td nowrap>
                                    <router-link
                                        tag="button"
                                        class="button is-primary"
                                        :class="{'is-loading': plan.loading }"
                                        :to="{ name: 'admin/customer', params: {id: plan.user.id } }"
                                        >Open profiel</router-link>
                                    <button class="button is-primary"
                                        :class="{ 'is-loading': plan.loading }"
                                        @click="done('plan', index, plan.id)"><i class="fa fa-check"></i>&nbsp;afgewerkt</button>
                                    <button class="button is-danger"
                                        :class="{ 'is-loading': plan.loading }"
                                        title="Negeer voor nu"
                                        @click="ignore(index, plan.id)"><i class="fa fa-times"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <h4 class="title is-4">Herbalanceren</h4>
                    <table class="table is-striped">
                        <thead>
                            <th width="15%">Naam klant</th>
                            <th width="10%">Huidig plan</th>
                            <th width="20%">Benodigd rendement per jaar</th>
                            <th width="45%">Beschrijving</th>
                            <th  width="10%" nowrap>Actie ondernemen</th>
                        </thead>
                        <tbody>
                            <tr v-for="(plan, index) in rebalanceData">
                                <td class="has-pointer">{{ plan.user.fullName }}</td>
                                <td class="has-pointer">{{ plan.plan.name }}</td>
                                <td class="has-pointer">{{ plan.required_efficiency_per_year | currency}}% / {{ plan.plan.intended_yearly_return | currency }}%</td>
                                <td class="has-pointer"><span v-if="plan.rebalance_substantiation" v-html="plan.rebalance_substantiation"></span></td>
                                <td nowrap>
                                    <router-link
                                        tag="button"
                                        class="button is-primary"
                                        :to="{ name: 'admin/customer', params: {id: plan.user.id } }"
                                        >Open profiel</router-link>
                                    <button class="button is-primary"
                                        :class="{ 'is-loading': plan.loading }"
                                        @click="done('rebalance', index, plan.id)"><i class="fa fa-check"></i>&nbsp;afgewerkt</button>
                                    <button class="button is-danger"
                                        title="Negeer voor nu"
                                        @click="ignore(index, plan.id)"><i class="fa fa-times"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    </div>

</template>

<script>
    import IgnoreAlert from './IgnoreAlert.vue';

    export default {
        components: {
            IgnoreAlert,
        },

        data() {
            return {
                rebalanceData: [],
                planData: [],
                bothData: [],
            }
        },

        mounted() {
            this.loadOverview();

            bus.$on('ignored', () => {
                this.loadOverview();
            });
        },

        methods: {
            done(section, index, id) {
                this[section+'Data'][index].loading = true;

                axios.put(`/api/admin/rebalancing/${id}/done`, {type: section})
                    .then(() => {
                        if (section === 'both') {
                            this.bothData.splice(index, 1);
                        }

                        if (section === 'plan') {
                            this.planData.splice(index, 1);
                        }

                        if (section === 'rebalance') {
                            this.rebalanceData.splice(index, 1);
                        }

                        this.$buefy.snackbar.open('Succesvol afgewerkt.');
                    })
                    .catch(() => {
                        this.$buefy.snackbar.open('Kon niet worden afgewerkt.');
                    })
            },

            ignore(index, id) {
                this.$buefy.modal.open({
                    width: 640,
                    component: IgnoreAlert,
                    props: { id }
                });
            },

            loadOverview() {
                axios.get('/api/admin/rebalancing')
                    .then(({data}) => {
                        this.rebalanceData = data.rebalance
                        this.planData = data.plan
                        this.bothData = data.both
                    })
                    .catch(() => {
                        alert("Kon overzicht niet laden.")
                    })
            }
        }
    }

</script>
