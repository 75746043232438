<style>
    html.printable body,
    html.printable .section.light,
    html.printable .section.content {
        background-color: #fff;
    }

    html.printable #header,
    html.printable #navigation,
    html.printable .submenu,
    html.printable .section.light.no-padding-bottom,
    html.printable .footer {
        display: none;
    }
</style>

<template>

    <div>
        <h4 class="title is-4">Notities van {{ data.profile.fullName }}</h4>
        <div v-for="note in data.notes">
            <div class="card">
                <header class="card-header">
                    <p class="card-header-title">
                        {{ note.author }}<span class="is-pulled-right" v-if="note.duration !== '0:00'">&nbsp;&bull; Tijdsduur: {{ note.duration }} uur</span>
                    </p>
                </header>
                <div class="card-content">
                    <p v-html="note.note"></p>
                    <small>{{ note.created_at | formatDate('D-M-Y HH:mm:ss') }}</small>
                </div>
            </div>
            <br />
        </div>
    </div>

</template>

<script>
    export default {

        computed: {
            data() {
                return this.$parent.data
            },
        },

        beforeCreate() {
            let html = document.getElementsByTagName("html")[0];
            html.className += "printable";
        },

        mounted() {
            setTimeout(() => {
                window.print();
                this.$router.go(-1);
                let html = document.getElementsByTagName("html")[0];
                html.className = '';
            }, 500)
        }

    }
</script>
