<template>
  <div>
    <portfolio :data="data.portfolio"></portfolio>
  </div>
</template>

<script>
  export default {
    computed: {
      data() {
        return this.$parent.data
      }
    }
  }
</script>
