import router from "../router";

export default function auth({ next, router }) {
  if (!localStorage.getItem('login')) {
    let path = window.location.hash.substring(1);
    if (path.indexOf("/auth") === -1) {
      localStorage.setItem("redirect", path);
    }

    if (localStorage.getItem('login') === 'false') {
      router.push({name: 'auto-logout'}).catch(() => false)
    } else {
      router.push({ name: "login" }).catch(() => false);
    }
  }

  return next();
}
