<template>
  <div>
    <section class="section content no-header">
      <div class="container">
        <h2 title="title is-2">Welkom, {{ data.full_name }}</h2>
        <p v-if="!data.customers || data.customers.length === 0">
          <em>Er zijn geen profielen aan dit account gekoppeld.</em>
        </p>
        <p v-else>Klik op een profiel om meer gegevens te bekijken.</p>
        <div class="columns" v-if="data.customers && data.customers.length > 0">
          <div
            class="column is-4"
            v-for="customer in data.customers"
            v-bind:key="customer.id"
            @click="openCustomer(customer)"
          >
            <div class="block has-pointer">
              <h5 class="title is-5">{{ customer.fullName }}</h5>
              <p class="">
                {{ customer.securities_account_account_name }}
                &bull;
                {{ customer.portfolio_number }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <b-modal :active.sync="modalOpen" :width="960" scroll="keep" :canCancel="false">
      <customer :customer="customer"></customer>
    </b-modal>
  </div>
</template>

<script>
import Customer from "../../advisor/Customer.vue";

export default {
  components: {
    "customer": Customer
  },

  data() {
    return {
      data: {},
      customer: {},
      modalOpen: false
    };
  },

  mounted() {
    this.get();
  },

  methods: {
    get() {
      axios
        .get("/api/advisor")
        .then(({ data }) => (this.data = data))
        .catch(() => this.$buefy.snackbar.open("Kon overzicht niet laden"));
    },

    openCustomer(customer) {
      this.modalOpen = true;
      this.customer = customer
    }
  },
};
</script>
