<template>
  <div>
    <h3 class="title is-4">Verzonden</h3>
    <table class="table is-striped">
      <thead>
        <th>Afzender</th>
        <th>Onderwerp</th>
        <th>Verzenddatum</th>
      </thead>
      <tbody>
        <tr v-if="!data.sentNotifications || !data.sentNotifications.length">
          <td colspan="3">
            <center>Nog geen berichten verzonden.</center>
          </td>
        </tr>
        <tr
          v-for="notification in data.sentNotifications"
          v-bind:key="notification.id"
          @click="$parent.openModal(notification)"
          :class="{
            'has-pointer':
              notification.type === 'App\\Notifications\\PlainNotification',
          }"
        >
        <td>
          <span v-if="typeof notification.data.message === 'object'">{{ notification.data.message.sender.fullName }}</span>
          <span v-else>Onbekend</span>
        </td>
          <td>
            <i
              class="fa fa-external-link"
              v-if="
                notification.type === 'App\\Notifications\\PlainNotification'
              "
            >
            </i>
            {{ notification.data.subject }}
            <span class="tag is-danger is-small" v-if="!notification.read_at"
              >Nog niet geopend</span
            >
          </td>
          <td>{{ notification.created_at | formatDate }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {

  computed: {
    data() {
      return this.$parent.data;
    },
    user() {
      return this.$root.user;
    },
  },

  beforeMount() {

  },

  methods: {

  },
};
</script>
