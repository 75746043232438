<template>
  <div>
    <div class="submenu">
      <div class="container">
        <div class="tabs is-top">
          <ul>
            <li class="is-active">
              <router-link :to="{ name: 'admin/customers' }">Klantenoverzicht</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'admin/quarter-reports' }">Kwartaalrapportages</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'admin/customers/contact' }">Hebben contact nodig</router-link>
            </li>
            <li>
              <span class="has-pointer" @click="createCustomer()">Nieuwe klant</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <section class="section no-background no-header">
      <div class="container">
        <div
          class="notification is-warning has-pointer"
          v-if="appliedSavedSearch"
          @click="clearSearch()"
        >
          <h4 class="title is-4">Laatste zoekopdracht hersteld</h4>
          <p>De laatste zoekopdracht is hersteld. Klik op deze melding om de zoekopdracht te verwijderen.</p>
        </div>

        <div class="block">
          <form @submit.prevent="get()">
            <div class="columns">
              <div class="column">
                <input
                  type="text"
                  name="name"
                  class="input is-medium"
                  placeholder="Zoeken op voor-, achternaam, e-mail, woonplaats, telefoonnummer of postcode"
                  v-model="searchCriteria.name"
                >

                <div class="columns">
                  <div class="column is-narrow">
                    <label class="checkbox">
                      <input type="radio" name="role_id" v-model="searchCriteria.role_id" value="4">
                      Prospects
                    </label>
                  </div>

                  <div class="column is-narrow">
                    <label class="checkbox">
                      <input type="radio" name="role_id" v-model="searchCriteria.role_id" value="3">
                      Klanten
                    </label>
                  </div>

                  <div class="column is-narrow">
                    <label class="checkbox">
                      <input type="radio" name="role_id" v-model="searchCriteria.role_id" value="5">
                      Ex-klanten
                    </label>
                  </div>

                  <div class="column is-narrow">
                    <label class="checkbox">
                      <input type="checkbox" v-model="searchCriteria.from_current_manager">
                      Gebruikers gekoppeld aan mij
                    </label>
                  </div>
                </div>

                <div class="columns">
                  <div class="column is-narrow">
                    <span class="select">
                      <select name="remark_field" v-model="searchCriteria.remark_field">
                        <option value disabled>Zoeken op kenmerken</option>
                        <option value="prospect_tag">Kenmerk prospect</option>
                        <option value="lead_origin">Herkomst lead</option>
                        <option value="lead_type">Type lead</option>
                        <option value="total_capital">Totaal vermogen</option>
                        <option value="current_financial_manager">Huidige beheerder</option>
                        <option value="advisor">Relatiebeheerder</option>
                        <option value="manager">Vermogensbeheerder</option>
                      </select>
                    </span>
                  </div>

                  <div class="column is-narrow">
                    <div v-if="searchCriteria.remark_field === 'prospect_tag'">
                      <span class="select">
                        <select name="remark_value" v-model="searchCriteria.remark_value">
                          <option value disabled>Selecteer waarde</option>
                          <option value="1">Niet geinteresseerd</option>
                          <option value="2">Geinteresseerd</option>
                          <option value="3">Niet geschikt</option>
                          <option value="4">Geschikt</option>
                          <option value="5">Overleden</option>
                          <option value="6">Ex-client</option>
                        </select>
                      </span>
                    </div>

                    <div v-if="searchCriteria.remark_field === 'total_capital'">
                      <div class="columns">
                        <div class="column">
                          <input
                            type="text"
                            class="input"
                            v-model="searchCriteria.remark_value_min"
                            placeholder="Minimaal bedrag"
                          >
                        </div>
                        <div class="column">
                          <input
                            type="text"
                            class="input"
                            v-model="searchCriteria.remark_value_max"
                            placeholder="Maximaal bedrag"
                          >
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="searchCriteria.remark_field === 'lead_origin' ||
                             searchCriteria.remark_field === 'lead_type' ||
                             searchCriteria.remark_field === 'current_financial_manager'"
                    >
                      <span class="select">
                        <select v-model="searchCriteria.remark_value" @change="get()">
                          <option value disabled>Selecteer waarde</option>
                          <option
                            v-for="item in remarks[searchCriteria.remark_field]"
                            :value="item"
                            v-bind:key="item"
                          >{{ item }}</option>
                        </select>
                      </span>
                    </div>
                    <div v-if="searchCriteria.remark_field === 'advisor'">
                      <span class="select">
                        <select name="remark_value" v-model="searchCriteria.remark_value" @change="get()">
                          <option value disabled>Selecteer waarde</option>
                          <option value="null">(niet opgegeven)</option>
                          <option v-for="collegue in collegues" v-bind:key="collegue.id" :value="collegue.id">
                            {{ collegue.fullName }}
                          </option>
                        </select>
                      </span>
                    </div>
                    <div v-if="searchCriteria.remark_field === 'manager'">
                      <span class="select">
                        <select name="remark_value" v-model="searchCriteria.remark_value" @change="get()">
                          <option value disabled>Selecteer waarde</option>
                          <option value="null">(niet opgegeven)</option>
                          <option v-for="collegue in collegues" v-bind:key="collegue.id" :value="collegue.id">
                            {{ collegue.fullName }}
                          </option>
                        </select>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-narrow" style="justify-content: space-between; flex-direction: column; display: flex">
                <button
                  class="button is-primary is-medium"
                  :class="{'is-loading': searchCriteria.loading}"
                >Zoeken</button>
                <button
                  type="button"
                  class="button"
                  :class="{'is-loading': exporting}"
                  @click="generateExport()"
                >Exporteer</button>
              </div>
            </div>
          </form>
        </div>

        <div class="block">
          <table class="table is-striped" v-if="searchCriteria.role_id == 3 || searchCriteria.role_id == 5">
            <thead>
              <th>Naam</th>
              <th>Vermogen</th>
              <th>Benodigd rendement per jaar</th>
              <th>Risicoprofiel</th>
              <th>Actueel YTD-rendement</th>
            </thead>
            <tbody>
              <tr
                class="has-pointer"
                v-for="customer in data.data"
                v-bind:key="customer.id"
                @click="openCustomer(customer.id)"
              >
                <td>{{ customer.fullName }}</td>
                <td>
                  <span v-if="customer.current_plan">
                    <span
                      v-if="customer.current_plan.portfolio_value"
                    >&euro;&nbsp;{{ customer.current_plan.portfolio_value | currency }}</span>
                    <span v-else>&euro; {{ customer.current_plan.amount | currency }}</span>
                  </span>
                </td>
                <td>
                  <span
                    v-if="customer.current_plan && customer.current_plan.required_efficiency_per_year"
                  >{{ customer.current_plan.required_efficiency_per_year | currency}}% / {{ customer.current_plan.plan.intended_yearly_return | currency }}%</span>
                </td>
                <td>
                  <span v-if="customer.current_plan">{{ customer.current_plan.plan.name }}</span>
                </td>
                <td>
                  <span
                    v-if="customer.current_plan && customer.current_plan.efficiency_ytd"
                  >{{ customer.current_plan.efficiency_ytd | currency }}%</span>
                </td>
              </tr>
              <tr v-if="data.data.length == 0">
                <td colspan="6">
                  <center>Geen resultaten gevonden</center>
                </td>
              </tr>
            </tbody>
          </table>

          <table class="table is-striped" v-if="searchCriteria.role_id == 4">
            <thead>
              <th>Naam</th>
              <th>Kenmerk prospect</th>
              <th>Totaal vermogen</th>
              <th>Type lead</th>
              <th>Herkomst lead</th>
              <th>Relatiebeheerder</th>
              <th>Vermogensbeheerder</th>
            </thead>
            <tbody>
              <tr
                class="has-pointer"
                v-for="customer in data.data"
                v-bind:key="customer.id"
                @click="openCustomer(customer.id)"
              >
                <td>{{ customer.fullName }}</td>
                <td>
                  <span v-if="customer.prospect_tag == 1">Niet geinteresseerd</span>
                  <span v-if="customer.prospect_tag == 2">Geinteresseerd</span>
                  <span v-if="customer.prospect_tag == 3">Niet geschikt</span>
                  <span v-if="customer.prospect_tag == 4">Geschikt</span>
                  <span v-if="customer.prospect_tag == 5">Overleden</span>
                  <span v-if="customer.prospect_tag == 6">Ex-client</span>
                </td>
                <td>
                  <span v-if="customer.total_capital">&euro;{{ customer.total_capital | currency }}</span>
                </td>
                <td>{{ customer.lead_type }}</td>
                <td>{{ customer.lead_origin }}</td>
                <td>
                  <span v-if="customer.advisor && customer.advisor in collegues">
                    {{ collegues[customer.advisor].fullName }}
                  </span>
                </td>
                <td>
                  <span v-if="customer.manager && customer.manager in collegues">
                    {{ collegues[customer.manager].fullName }}
                  </span>
                </td>
              </tr>
              <tr v-if="data.data.length == 0">
                <td colspan="7">
                  <center>Geen resultaten gevonden</center>
                </td>
              </tr>
            </tbody>
          </table>

          <nav class="pagination is-centered">
            <button
              class="button pagination-previous"
              @click="searchCriteria.page--"
              :disabled="!data.prev_page_url"
            >Vorige pagina</button>
            <ul class="pagination-list">
              <li>Pagina {{ data.current_page }} van {{ data.last_page }}</li>
            </ul>
            <button
              class="button pagination-next"
              @click="searchCriteria.page++"
              :disabled="data.current_page == data.last_page"
            >Volgende pagina</button>
          </nav>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AdminCreateCustomer from "./CreateCustomer.vue";

const cleanSearch = {
  page: 1,
  name: "",
  role_id: 3,
  from_current_manager: false,
  remark_field: "",
  remark_value: "",
  remark_value_min: "",
  remark_value_max: ""
};

export default {
  data() {
    return {
      data: {
        data: []
      },
      searchCriteria: new Laravel.Form(cleanSearch),
      remarks: {},
      collegues: {},
      appliedSavedSearch: false,
      loaded: false,
      exporting: false,
    };
  },

  beforeMount() {
    this.getRemarks()
    this.getCollegues();

    if (this.$route.name === "admin/customers/prospects") {
      this.searchCriteria.role_id = 4;
    } else {
      let saveCustomerSearch = localStorage.getItem("saveCustomerSearch")

      if (saveCustomerSearch && JSON.stringify(cleanSearch) != saveCustomerSearch) {
        this.appliedSavedSearch = true;
        this.searchCriteria = new Laravel.Form(
          JSON.parse(saveCustomerSearch)
        );
      }
    }

    this.get();
  },

  methods: {
    get(page = 1, clear = false) {
      this.searchCriteria.loading = true;

      // Set to local storage to save search
      localStorage.setItem(
        "saveCustomerSearch",
        JSON.stringify(this.searchCriteria.data())
      );

      axios
        .get("/api/admin/customers", {
          params: this.searchCriteria.data()
        })
        .then(({ data }) => {
          this.searchCriteria.loading = false;
          this.data = data;
          this.loaded = true;
          window.bus.$emit('dataLoaded');
        })
        .catch(() => {
          alert("Kon het overzicht niet inladen");
        });
    },

    openCustomer(id) {
      this.$router.push({ name: "admin/customer", params: { id: id } });
    },

    clearSearch() {
      this.searchCriteria = new Laravel.Form(cleanSearch);
      this.appliedSavedSearch = false;
      localStorage.removeItem("saveCustomerSearch");
      this.get();
    },

    createCustomer() {
      this.$buefy.modal.open({
        component: AdminCreateCustomer,
        props: {
          router: this.$router
        }
      });
    },

    getRemarks() {
      axios
        .get(`/api/admin/remarks`)
        .then(({ data }) => {
          this.remarks = data;
        })
        .catch(() => {
          this.$buefy.snackbar.open("Kon kenmerken niet laden.");
        });
    },

    getCollegues() {
      axios
        .get(`/api/admin/collegues`)
        .then(({ data }) => {
          data.forEach((item) => {
            this.collegues[item.id] = item;
          });
        })
        .catch(() => {
          this.$buefy.snackbar.open("Kon collega's niet laden.");
        });
    },

    generateExport() {
      this.exporting = true;
      axios.get('/api/admin/customers/export', {
        params: this.searchCriteria.data(),
        responseType: 'blob',
      })
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Overzicht MyBeSmart.xlsx');
          document.body.appendChild(link);
          link.click();

          this.exporting = false;
        })
    }
  },

  watch: {
    "searchCriteria.remark_field": function(val) {
      if (this.loaded) {
        this.searchCriteria.remark_value = "";
        this.searchCriteria.remark_value_min = "";
        this.searchCriteria.remark_value_max = "";
      }
    },

    "searchCriteria.from_current_manager": function(val) {
      if (this.loaded) {
        this.get();
      }
    },

    "searchCriteria.role_id": function(val, oldVal) {
      if (this.loaded) {
        this.get();
      }
    },

    "searchCriteria.page": function() {
      if (this.loaded) {
        this.get();
      }
    }
  }
};
</script>
