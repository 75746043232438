<template>
  <div>
    <h4 class="title is-4">Berichten</h4>
    <table class="table is-striped">
      <thead>
        <th colspan="2">Onderwerp</th>
        <th>Verzenddatum</th>
      </thead>
      <tbody>
        <tr v-if="!notifications || !notifications.length">
          <td colspan="3">
            <center>U heeft nog geen berichten ontvangen.</center>
          </td>
        </tr>
        <tr
          class="has-pointer"
          :class="{'strong': !notification.read_at}"
          v-for="notification in notifications"
          v-bind:key="notification.id"
          @click="openNotification(notification.id)"
        >
          <td>
            <i class="fa fa-envelope is-primary" v-if="!notification.read_at"></i>
          </td>
          <td>{{ notification.data.subject }}</td>
          <td>{{ notification.created_at | formatDate }}</td>
        </tr>
      </tbody>
    </table>
    <p class="has-text-right">
      <router-link :to="{ name: 'notifications' }" class="button is-primary">Meer berichten</router-link>
    </p>
  </div>
</template>

<script>
export default {
  props: ["notifications"],

  methods: {
    openNotification(id) {
      this.$router.push({ name: "notification", params: { id: id } });
    }
  }
};
</script>
