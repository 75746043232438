<template>
  <div>
    <section class="section content no-header">
      <div class="container">
        <div class="block">
          <portfolio :data="portfolio"></portfolio>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      last_proposal_id: null
    };
  },

  mounted() {
    if (!this.portfolio.length) {
      this.getCustomerDashboard();
    }
  },
};
</script>
