<template>
  <div>
    <div class="submenu">
      <div class="container">
        <div class="tabs is-top">
          <ul>
            <li :class="{ 'is-active': !page }">
              <router-link :to="{ name: 'profile' }">Profiel</router-link>
            </li>
            <li :class="{ 'is-active': page == 'password' }">
              <router-link
                :to="{ name: 'profile/password', params: { page: 'password' } }"
              >Wachtwoord wijzigen</router-link>
            </li>
            <li :class="{ 'is-active': page == 'two-factor' }">
              <router-link
                :to="{ name: 'profile/two-factor', params: { page: 'two-factor' } }"
              >Tweefactorauthenticatie</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <section class="section content no-header">
      <div class="container">
        <profile v-if="!page"></profile>
        <password v-if="page == 'password'"></password>
        <two-factor v-if="page == 'two-factor'"></two-factor>
      </div>
    </section>
  </div>
</template>

<script>
import Profile from "./Profile.vue";
import Password from "../../auth/ChangePassword.vue";
import TwoFactor from "../../../components/ConfigureTwoFactor.vue";

export default {
  components: {
    profile: Profile,
    password: Password,
    "two-factor": TwoFactor,
  },

  computed: {
    page() {
      return this.$route.params.page;
    }
  }
};
</script>
