<template>
  <div>
    <div class="columns">
      <div class="column is-6" v-for="(accountholder, key) in bank_accounts" v-bind:key="key">
        <div class="control">
          <div v-if="accountholder && (key == 'accountholder_1' || key == 'accountholder_2')">
            <h4
              class="title is-4"
            >Opleiding en beroep rekeninghouder {{ accountholder.accountholder }}</h4>

            <div class="field">
              <label class="label">Hoogstgenoten opleiding</label>
              <p>{{ accountholder.highest_education }}</p>
            </div>

            <div class="field">
              <label class="label">Beroep</label>
              <p>{{ accountholder.profession }}</p>
            </div>

            <div class="field">
              <label class="label">Naam (voormalig) werkgever</label>
              <p>{{ accountholder.name_employer }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr>

    <h4 class="title is-4">Ervaring dienstverlening</h4>
    <p class="control">Hoeveel ervaring heeft u met onderstaande effectendienstverlening?</p>

    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">Vermogensbeheer</label>
          <div v-if="data.concept">
            <p v-if="data.concept.wealth_management == 1">Minder dan 2 jaar</p>
            <p v-if="data.concept.wealth_management == 2">2 tot 10 jaar</p>
            <p v-if="data.concept.wealth_management == 3">Meer dan 10 jaar</p>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label class="label">Advies</label>
          <div v-if="data.concept">
            <p v-if="data.concept.advice == 1">Minder dan 2 jaar</p>
            <p v-if="data.concept.advice == 2">2 tot 10 jaar</p>
            <p v-if="data.concept.advice == 3">Meer dan 10 jaar</p>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label class="label">Zelf beleggen</label>
          <div v-if="data.concept">
            <p v-if="data.concept.self_invest == 1">Minder dan 2 jaar</p>
            <p v-if="data.concept.self_invest == 2">2 tot 10 jaar</p>
            <p v-if="data.concept.self_invest == 3">Meer dan 10 jaar</p>
          </div>
        </div>
      </div>
    </div>

    <hr>

    <div class="columns">
      <div class="column is-12">
        <h4 class="title is-4">Kennis & ervaring financiële instrumenten</h4>
        <p>Hoeveel ervaring heeft u in de onderstaande financiële instrumenten, hoeveel transacties deed u of uw adviseur/vermogensbeheerder gemiddeld per kwartaal en wat was de gemiddelde omvang van deze transacties?</p>
        <table class="table" width="100%">
          <thead>
            <tr>
              <th></th>
              <th>Ervaring</th>
              <th>Kennis</th>
              <th>Aantal transacties per kwartaal</th>
              <th>Gemiddelde omvang</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in rows" v-bind:key="row.key">
              <th>{{ row.name }}</th>
              <td>
                <span v-if="data.concept && data.concept[row.key] == 1">Minder dan 2 jaar</span>
                <span v-if="data.concept && data.concept[row.key] == 2">2 - 10 jaar</span>
                <span v-if="data.concept && data.concept[row.key] == 3">Meer dan 10 jaar</span>
              </td>
              <td>
                <span v-if="data.knowledge && data.knowledge[row.key] == 1">Minder dan 2 jaar</span>
                <span v-if="data.knowledge && data.knowledge[row.key] == 2">2 - 10 jaar</span>
                <span v-if="data.knowledge && data.knowledge[row.key] == 3">Meer dan 10 jaar</span>
              </td>
              <td>
                <span v-if="data.transactions && data.transactions[row.key] == 1">0-3 transacties</span>
                <span v-if="data.transactions && data.transactions[row.key] == 2">3-5 transacties</span>
                <span v-if="data.transactions && data.transactions[row.key] == 3">Meer dan 5 transacties</span>
              </td>
              <td>
                <span v-if="data.amount && data.amount[row.key] == 1">0 - €2500,-</span>
                <span v-if="data.amount && data.amount[row.key] == 2">€2500,- - &lt; €7500,-</span>
                <span v-if="data.amount && data.amount[row.key] == 3">> €7500,-</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data", "bank_accounts"],

  data() {
    return {
      rows: [
        {
          key: "shares",
          name: "Aandelen"
        },
        {
          key: "investment_funds",
          name: "Beleggingsfondsen"
        },
        {
          key: "exchange_funds",
          name: "Indexfondsen en ETF's"
        },
        {
          key: "debentures",
          name: "Obligaties"
        },
        {
          key: "derivatives",
          name: "Derivaten"
        },
        {
          key: "alternative",
          name: "Alternatieve beleggingen"
        }
      ]
    };
  }
};
</script>
