<template>

    <div class="content">

        <section class="section light no-padding-bottom">
            <div class="container">
                <h3 class="title is-3"><strong>Inventarisatie</strong> <span class="is-primary">Clientprofiel: Financiële positie</span></h3>

                <form-errors :errors="form.errors"></form-errors>
            </div>
        </section>

        <section class="section no-background has-header">
            <div class="container">
                <div class="columns">

                    <div class="column is-6">
                        <div class="block">
                            <h4 class="title is-4">Financiële positie</h4>

                            <div class="field">
                                <label class="label">Wat is de omvang van uw vrij beschikbare vermogen?</label>
                                <p class="control">Onder vrij beschikbaar vermogen verstaan wij: Liquiditeiten en alle vermogensbestandsdelen die snel liquide kunnen worden gemaakt zoals uw beleggingen minus alle financiële verplichtingen (excl. woninghypotheek).</p>
                                <div class="field">
                                    <label class="radio">
                                        <input name="financial_position.available_captial" type="radio" value="100000 - 250000" v-model="form.financial_position.available_captial">
                                        €100.000 - €250.000
                                    </label>
                                </div>

                                <div class="field">
                                    <label class="radio">
                                        <input name="financial_position.available_captial" type="radio" value="250000 - 500000" v-model="form.financial_position.available_captial">
                                        €250.000 - €500.000
                                    </label>
                                </div>

                                <div class="field">
                                    <label class="radio">
                                        <input name="financial_position.available_captial" type="radio" value="500000 - 1000000" v-model="form.financial_position.available_captial">
                                        €500.000 - €1.000.000
                                    </label>
                                </div>

                                <div class="field">
                                    <label class="radio">
                                        <input name="financial_position.available_captial" type="radio" value="1000000" v-model="form.financial_position.available_captial">
                                        Meer dan €1.000.000
                                    </label>
                                </div>
                                <form-field-errors :errors="form.errors" field="financial_position.available_captial"></form-field-errors>
                            </div>

                            <div class="field">
                                <label class="label">Hoeveel van dit vermogen wilt u beleggen via BeSmart?</label>
                                <div class="field has-addons">
                                    <p class="control">
                                        <a class="button">€</a>
                                    </p>
                                    <p class="control is-expanded">
                                        <money name="financial_position.to_invest" class="input" placeholder="Bedrag" v-model="form.financial_position.to_invest"></money>
                                        <form-field-errors :errors="form.errors" field="financial_position.to_invest"></form-field-errors>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="column is-6">
                        <div class="block">
                            <h4 class="title is-4">Herkomst belegbaar vermogen</h4>

                            <div class="field">
                                <label class="label">Wat is hoofdzakelijk de herkomst van het belegbare vermogen?</label>
                                <p class="control">
                                   <label class="checkbox">
                                        <input name="enterprise.checked" type="checkbox" v-model="form.enterprise.checked">
                                        Verkoop onderneming
                                    </label>
                                </p>

                                <form-field-errors :errors="form.errors" field="enterprise.checked"></form-field-errors>

                                <div class="field" v-if="form.enterprise.checked">
                                    <label class="label">Naam onderneming</label>
                                    <p class="control">
                                        <input name="enterprise.name_enterprise" class="input" type="text" placeholder="Naam onderneming" v-model="form.enterprise.name_enterprise">
                                        <form-field-errors :errors="form.errors" field="enterprise.name_enterprise"></form-field-errors>
                                    </p>

                                    <label class="label">Datum verkoop</label>
                                    <p class="control">
                                        <b-datepicker name="date" v-model="form.enterprise.sold_date"></b-datepicker>
                                        <form-field-errors :errors="form.errors" field="enterprise.sold_date"></form-field-errors>
                                    </p>

                                    <label class="label">Totale verkoopsom</label>
                                    <div class="field has-addons">
                                        <p class="control">
                                            <a class="button">€</a>
                                        </p>
                                        <p class="control is-expanded">
                                            <money name="enterprise.total_selling_price" class="input" type="text" placeholder="Totale verkoopsom" v-model="form.enterprise.total_selling_price"></money>
                                            <form-field-errors :errors="form.errors" field="enterprise.total_selling_price"></form-field-errors>
                                        </p>
                                    </div>
                                </div>

                                <p class="control">
                                   <label class="checkbox">
                                        <input name="property.checked" type="checkbox" v-model="form.property.checked">
                                        Verkoop onroerend goed
                                    </label>
                                </p>

                                <form-field-errors :errors="form.errors" field="property.checked"></form-field-errors>

                                <div class="field" v-if="form.property.checked">
                                    <label class="label">Type woning</label>
                                    <p class="control">
                                        <input name="property.type" class="input" type="text" placeholder="Type woning" v-model="form.property.type">
                                        <form-field-errors :errors="form.errors" field="property.type"></form-field-errors>
                                    </p>

                                    <label class="label">Datum verkoop</label>
                                    <p class="control">
                                        <b-datepicker name="date" v-model="form.property.sold_date"></b-datepicker>
                                        <form-field-errors :errors="form.errors" field="property.sold_date"></form-field-errors>
                                    </p>

                                    <label class="label">Totale verkoopsom</label>
                                    <div class="field has-addons">
                                        <p class="control">
                                            <a class="button">€</a>
                                        </p>
                                        <p class="control is-expanded">
                                            <money name="property.total_selling_price" class="input" type="text" placeholder="Totale verkoopsom" v-model="form.property.total_selling_price"></money>
                                            <form-field-errors :errors="form.errors" field="property.total_selling_price"></form-field-errors>
                                        </p>
                                    </div>
                                </div>

                                <p class="control">
                                   <label class="checkbox">
                                        <input name="heritage.checked" type="checkbox" v-model="form.heritage.checked">
                                        Erfenis
                                    </label>
                                </p>

                                <form-field-errors :errors="form.errors" field="heritage.checked"></form-field-errors>

                                <div class="field" v-if="form.heritage.checked">
                                    <label class="label">Relatie tot erflater</label>
                                    <p class="control">
                                        <input name="heritage.relationship" class="input" type="text" placeholder="Relatie tot erflater" v-model="form.heritage.relationship">
                                        <form-field-errors :errors="form.errors" field="heritage.relationship"></form-field-errors>
                                    </p>

                                    <label class="label">Jaar erfenis</label>
                                    <p class="control">
                                        <input name="heritage.year" class="input" type="text" placeholder="vb: 2000" v-model="form.heritage.year">
                                        <form-field-errors :errors="form.errors" field="heritage.year"></form-field-errors>
                                    </p>

                                    <label class="label">Bedrag</label>
                                    <div class="field has-addons">
                                        <p class="control">
                                            <a class="button">€</a>
                                        </p>
                                        <p class="control is-expanded">
                                            <money name="heritage.amount" class="input" type="text" placeholder="Bedrag" v-model="form.heritage.amount"></money>
                                            <form-field-errors :errors="form.errors" field="heritage.amount"></form-field-errors>
                                        </p>
                                    </div>
                                </div>


                                <p class="control">
                                   <label class="checkbox">
                                        <input name="severance.checked" type="checkbox" v-model="form.severance.checked">
                                        Gouden handdruk
                                    </label>
                                </p>

                                <form-field-errors :errors="form.errors" field="severance.checked"></form-field-errors>

                                <div class="field" v-if="form.severance.checked">
                                    <label class="label">Naam bedrijf</label>
                                    <p class="control">
                                        <input name="severance.company_name" class="input" type="text" placeholder="Naam bedrijf" v-model="form.severance.company_name">
                                        <form-field-errors :errors="form.errors" field="severance.company_name"></form-field-errors>
                                    </p>

                                    <label class="label">Datum ontvangst</label>
                                    <p class="control">
                                        <b-datepicker name="date" v-model="form.severance.received_at"></b-datepicker>
                                        <form-field-errors :errors="form.errors" field="severance.received_at"></form-field-errors>
                                    </p>

                                    <label class="label">Bedrag</label>
                                    <div class="field has-addons">
                                        <p class="control">
                                            <a class="button">€</a>
                                        </p>
                                        <p class="control is-expanded">
                                            <money name="severance.amount" class="input" type="text" placeholder="Bedrag" v-model="form.severance.amount"></money>
                                            <form-field-errors :errors="form.errors" field="severance.amount"></form-field-errors>
                                        </p>
                                    </div>
                                </div>

                                <p class="control">
                                   <label class="checkbox">
                                        <input name="savings.checked" type="checkbox" v-model="form.savings.checked">
                                        Gespaarde inkomsten uit arbeid en beleggen
                                    </label>
                                </p>
                                <form-field-errors :errors="form.errors" field="savings"></form-field-errors>

                                <p class="control">
                                   <label class="checkbox">
                                        <input name="other.checked" type="checkbox" v-model="form.other.checked">
                                        Anders
                                    </label>
                                </p>

                                <form-field-errors :errors="form.errors" field="other.checked"></form-field-errors>

                                <div class="field" v-if="form.other.checked">
                                    <label class="label">Namelijk</label>
                                    <p class="control">
                                        <input name="other.namely" class="input" type="text" placeholder="Namelijk" v-model="form.other.namely">
                                        <form-field-errors :errors="form.errors" field="other.namely"></form-field-errors>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="columns">
                    <div class="column">
                        <button @click="save()" class="button is-primary" :class="{'is-loading': form.loading}">Tussentijds opslaan</button>
                        <button @click="next()" class="button is-primary is-pulled-right" :class="{'is-loading': form.loading}">Opslaan en verder</button>
                    </div>
                </div>

            </div>
        </section>

    </div>

</template>

<script>

    export default {

        data() {
            return {
                form: this.$parent.form.financial_position,
            }
        },

        beforeMount() {
            if (!this.form.financial_position) {
                this.form = {}
                this.form.next = false;
                this.form.financial_position = {
                    available_captial: 0,
                    to_invest: 0,
                }
                this.form.enterprise = {
                    checked: false,
                }
                this.form.property = {
                    checked: false,
                }
                this.form.heritage = {
                    checked: false,
                }
                this.form.severance = {
                    checked: false,
                }
                this.form.savings = {
                    checked: false,
                }
                this.form.other = {
                    checked: false,
                }
                this.form = new Laravel.Form(this.form)
            }
        },

        methods: {
            save() {
                this.$parent.save(this.form);
            },

            next() {
                this.$parent.save(this.form, true);
            }
        },
    }

</script>
