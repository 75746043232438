<template>
  <div>
    <p>Voor dit account is een Google Authenticator code benodigd. Geef de code in.</p>

    <form @submit.prevent="submit">
      <div class="columns" style="margin-top: 0">
        <div class="column">
          <label>Google Authenticator code</label>
        </div>

        <div class="column">
          <input
            name="gAuthToken"
            type="text"
            id="gAuthToken"
            class="input"
            placeholder="Google Authenticator code"
            v-model="form.gAuthToken"
          >
          <form-field-errors :errors="form.errors" field="gAuthToken"></form-field-errors>
        </div>
      </div>

      <div class="columns">
        <div class="column"></div>

        <div class="column">
          <button
            type="submit"
            class="button is-primary"
            :class="{'is-loading': form.loading}"
          >Inloggen</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: new Laravel.Form({
        id: this.$parent.id,
        token: this.$parent.token,
        gAuthToken: ""
      })
    };
  },

  mounted() {
    document.getElementById("gAuthToken").focus();
  },

  methods: {
    submit() {
      this.form
        .post("/api/auth/login/two-factor")
        .then(data => {
          this.$parent.login(data);
        })
        .catch(e => {
          if (e.hasOwnProperty("errors") && e.errors.hasOwnProperty("email")) {
            this.$router.replace({ name: "login" });
          }

          this.$parent.form = this.form;
        });
    }
  }
};
</script>
