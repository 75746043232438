<template>
  <div>
    <section class="section content no-header">
      <div class="container">
        <div
          class="notification is-warning has-pointer"
          v-if="appliedSavedFilter"
          @click="clearFilter()"
        >
          <h4 class="title is-4">Laatste filter hersteld</h4>
          <p>Het laatste filter is hersteld. Klik op deze melding om het filter te verwijderen.</p>
        </div>
        <div class="columns">
          <div class="column is-4">
            <div class="block">
              <h4 class="title is-4">Statistieken</h4>
              <table class="table">
                <tr>
                  <th width="50%">Achterstallig</th>
                  <td>{{ stats.overdue }}</td>
                </tr>
                <tr>
                  <th>Vandaag</th>
                  <td>{{ stats.today }}</td>
                </tr>
                <tr>
                  <th>Toekomstig</th>
                  <td>{{ stats.future }}</td>
                </tr>
              </table>
            </div>
          </div>

          <div class="column is-4">
            <div class="block">
              <h4 class="title is-4">Filter op datum</h4>
              <div class="columns">
                <div class="column is-narrow">
                  <div class="select">
                    <select name="date_comparison" v-model="filter.date_comparison">
                      <option value="<">Voor</option>
                      <option value="=">Op</option>
                      <option value=">">Na</option>
                    </select>
                  </div>
                </div>
                <div class="column">
                  <div class="control">
                    <b-datepicker
                      name="date"
                      placeholder="01-01-2000"
                      v-model="date"
                      id="datepicker"
                      indicators="bars"
                      :events="events"
                    ></b-datepicker>
                  </div>
                </div>
              </div>
              <button class="button is-primary is-fullwidth" @click="get()">Filteren</button>
            </div>
          </div>

          <div class="column is-4">
            <div class="block">
              <h4 class="title is-4">Filter op beheerder</h4>
              <div class="columns is-multiline is-gapless">
                <div class="column is-6" v-for="manager in managers" v-bind:key="manager.id">
                  <label class="checkbox">
                    <input type="checkbox" :value="manager.id" v-model="filter.assignees">
                    &nbsp;{{ manager.first_name }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="block">
          <div class="level">
            <div class="level-left">
              <div class="level-item">
                <h4 class="title is-4">Taken</h4>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <button class="button" @click="findProspectMatch">Prospect koppelen</button>
              </div>
              <div class="level-item">
                <span class="select">
                  <select v-model="filter.role">
                    <option value>Alle</option>
                    <option value="3">Alleen klanten</option>
                    <option value="4">Alleen prospect</option>
                  </select>
                </span>
              </div>
              <div class="level-item">
                <span class="tag is-success">1-4 taken</span>
              </div>
              <div class="level-item">
                <span class="tag is-warning">5-9 taken</span>
              </div>
              <div class="level-item">
                <span class="tag is-danger">> 9 taken</span>
              </div>
            </div>
          </div>
          <table class="table is-striped">
            <thead>
              <th width="15%">Datum</th>
              <th width="50%">Omschrijving</th>
              <th width="10%">Uitvoerder</th>
              <th nowrap="nowrap">Acties</th>
            </thead>
            <tbody>
              <tr v-for="(task, index) in tasks" v-bind:key="task.id">
                <td class="has-pointer v-align-middle" @click="setEditRow(index)">
                  <span v-if="editMode !== index">{{ task.date | formatDate }}</span>
                  <span v-if="editMode === index">
                    <b-datepicker
                      name="editRow.date"
                      v-model="editRow.date"
                      indicators="bars"
                      :events="events"
                    ></b-datepicker>
                  </span>
                </td>
                <td class="has-pointer v-align-middle" @click="setEditRow(index)">
                  <span v-if="editMode !== index">
                    <span v-if="task.customer">
                      <strong>{{ task.customer.fullName}}</strong>:
                    </span>
                    {{ task.description }}
                  </span>
                  <span v-if="editMode === index">
                    <input
                      type="text"
                      class="input"
                      name="editRow.description"
                      v-model="editRow.description"
                    >
                  </span>
                </td>
                <td class="v-align-middle" v-if="task.assignee">{{ task.assignee.first_name }}</td>
                <td class="v-align-middle">
                  <span v-if="editMode !== index">
                    <router-link
                      class="button is-primary"
                      :to="{ name: 'admin/customer', params: { id: task.customer_id } }"
                    >
                      <i class="fa fa-user"></i>&nbsp;Profiel
                    </router-link>
                    <button class="button is-success" @click="taskDone(task.id, index)">
                      <i class="fa fa-check"></i>&nbsp;Afgerond
                    </button>
                  </span>
                  <span v-if="editMode === index">
                    <button class="button is-primary" @click="submitRow()">Opslaan</button>
                  </span>
                </td>
              </tr>
              <tr v-if="tasks.length === 0">
                <td colspan="4">
                  <center>Geen taken voor deze dag gevonden.</center>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <b-modal :active.sync="showProspectMatchModal" :width="640" :canCancel="false" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <div class="has-text-centered" v-if="!prospectMatch">
              <h2 class="title is-4">Bezig om een match te vinden..</h2>
              <progress class="progress is-primary" max="100"></progress>
            </div>
            <div class="has-text-centered" v-if="prospectMatch">
              <h2 class="title is-4">Er is een match gevonden!</h2>
              <p class="title is-3">{{ prospectMatch.name }}</p>
              <button @click="showProspectMatchModal = false" class="button">Dankjewel</button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
const d = new Date();

const cleanFilter = {
  assignees: [],
  date_comparison: "=",
  date: [
    d.getFullYear(),
    (d.getMonth() + 1).toString().padStart(2, "0"),
    d
      .getDate()
      .toString()
      .padStart(2, "0")
  ].join("-"),
  role: ""
};

export default {
  data() {
    return {
      tasks: [],
      stats: {},
      managers: [],
      events: [],
      appliedSavedFilter: false,
      loaded: false,
      editMode: null,
      date: new Date(),
      editRow: new Laravel.Form({
        date: null,
        description: null
      }),
      filter: new Laravel.Form(cleanFilter),
      showProspectMatchModal: false,
      prospectMatch: null,
    };
  },

  mounted() {
    if (this.user.hasOwnProperty("id")) {
      this.applySavedFilter();
    }
  },

  computed: {
    user() {
      return this.$root.user;
    }
  },

  methods: {
    applySavedFilter() {
      let savedFilter = localStorage.getItem("savedTaskFilter");

      if (savedFilter && JSON.stringify(cleanFilter) != savedFilter) {
        this.appliedSavedFilter = true;
        this.filter = new Laravel.Form(JSON.parse(savedFilter));
      }

      this.get();
    },

    get() {
      this.editMode = null;

      this.date = moment(this.filter.date).toDate();

      // Set to local storage to save search
      localStorage.setItem(
        "savedTaskFilter",
        JSON.stringify(this.filter.data())
      );

      axios
        .get("/api/admin/tasks", {
          params: this.filter.data()
        })
        .then(({ data }) => {
          this.tasks = data.tasks;
          this.managers = data.managers;
          this.stats = data.stats;
          this.filter.assignees = data.selected_assignees;
          this.parseEvents(data.events);
          this.loaded = true;
          window.bus.$emit("dataLoaded");
        })
        .catch(() => {
          this.$buefy.snackbar.open("Kon taken niet laden.");
        });
    },

    setEditRow(index) {
      if (index !== this.editMode) {
        this.editMode = index;
        this.editRow = new Laravel.Form(this.tasks[index]);
      }
    },

    submitRow() {
      this.editRow
        .put(`/api/admin/tasks/${this.editRow.id}`)
        .then(data => {
          this.editMode = null;
          this.$buefy.snackbar.open("Taak succesvol bijgewerkt.");
          this.get();
        })
        .catch(() => {
          this.$buefy.snackbar.open(`Kon taak niet bewerken.`);
        });
    },

    taskDone(id, index) {
      this.$buefy.dialog.confirm({
        message: "Deze taak voltooid en verwijderen?",
        cancelText: "Nee",
        confirmText: "Ja",
        onConfirm: () => {
          axios
            .delete(`/api/admin/tasks/${id}`)
            .then(() => {
              this.tasks.splice(index, 1);
              this.$root.user.tasksTodayCount--;
              this.$buefy.snackbar.open(`Taak succesvol verwijderd.`);
            })
            .catch(() => {
              this.$buefy.snackbar.open(
                `Kon taak niet verwijderd, waarschijnlijk omdat het al verwijderd is.`
              );
            });
        }
      });
    },

    parseEvents(data) {
      this.events = [];
      data.forEach(item => {
        let type =
          item.amount >= 10
            ? "is-danger"
            : item.amount > 4
            ? "is-warning"
            : "is-success";

        this.events.push({
          date: moment(item.date).toDate(),
          type
        });
      });
    },

    clearFilter() {
      this.filter = new Laravel.Form(cleanFilter);
      this.appliedSavedFilter = false;
      localStorage.removeItem("savedTaskFilter");
      this.get();
    },

    findProspectMatch() {
      this.prospectMatch = null;
      this.showProspectMatchModal = true;

      let timeout = (Math.random() * (5 - 2) + 2) * 1000;

      axios.get(`/api/admin/tasks/findProspect`)
        .then(({ data }) => {
          setTimeout(() => {
            this.prospectMatch = data;
            this.get();
          }, timeout);
        })
        .catch(() => {
          alert('Kon data niet laden.');
        });
    }
  },

  watch: {
    user: function(val) {
      if (val && cleanFilter.assignees.length === 0) {
        cleanFilter.assignees = [val.id];
        this.applySavedFilter();
      }
    },
    date: function(val) {
      if (typeof val === "object") {
        this.filter.date = [
          val.getFullYear(),
          (val.getMonth() + 1).toString().padStart(2, "0"),
          val
            .getDate()
            .toString()
            .padStart(2, "0")
        ].join("-");
      }
    },
    "filter.assignees": function(val, oldVal) {
      if (this.loaded && val.length !== oldVal.length) {
        this.get();
      }
    },
    "filter.role": function(val, oldVal) {
      if (this.loaded) {
        this.get();
      }
    }
  }
};
</script>
