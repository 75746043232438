<template>
  <section class="section content no-header">
    <div class="container">
      <div class="content">
        <div class="block">
          <h4 class="title is-4">Bericht opstellen</h4>
          <form @submit.prevent="submit()" v-if="user.manager || user.advisor">
            <form-errors :errors="form.errors"></form-errors>
            <div class="field">
              <label class="label">Geadresseerde</label>
              <span class="select" v-if="user.manager || user.advisor">
                <select v-model="form.receiver">
                  <option value="manager" v-if="user.manager">Vermogensbeheerder</option>
                  <option value="advisor" v-if="user.advisor">Beleggingsadviseur</option>
                </select>
              </span>
              <form-field-errors :errors="form.errors" field="receiver"></form-field-errors>
            </div>

            <div class="field">
              <label class="label">Bericht</label>
              <textarea
                name="message"
                class="textarea"
                placeholder="Typ hier het bericht"
                v-model="form.message"
              ></textarea>
              <form-field-errors :errors="form.errors" field="message"></form-field-errors>
            </div>

            <div class="field">
              <button
                type="submit"
                class="button is-primary"
                :class="{'is-loading': form.loading}"
              >Verzenden</button>
            </div>
          </form>
          <p v-else>Er zijn nog geen contactpersonen aan u toegewezen.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      form: new Laravel.Form({
        receiver: "manager",
        message: ""
      })
    };
  },

  computed: {
    user() {
      return this.$root.user;
    }
  },

  mounted() {
    if (this.$route.params.receiver) {
      this.form.receiver = this.$route.params.receiver;
    }
  },

  methods: {
    submit() {
      this.form.post("/api/notifications").then(() => {
        this.$buefy.snackbar.open("Bericht succesvol verstuurd");
      });
    }
  }
};
</script>
