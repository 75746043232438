<template>

    <div>
        <div v-if="data && Object.keys(data).length > 0">
            <div class="columns">
                <div class="column is-6">
                    <h2 class="title is-4"><em>Verwachte</em> kosten komende 12 maanden</h2>
                    <table class="table">
                        <tbody>
                            <tr>
                                <td width="60%">Beheerfee BeSmart</td>
                                <td width="20%">&euro; {{ data.management_fee_in_eur | currency}}</td>
                                <td width="20%">{{ data.management_fee_in_perc | currency}}%</td>
                            </tr>
                            <tr>
                                <td>Transactiekosten Bank/Broker</td>
                                <td>&euro; {{ data.transaction_costs_fee_in_eur | currency}}</td>
                                <td>{{ data.transaction_costs_fee_in_perc | currency}}%</td>
                            </tr>
                            <tr>
                                <td>Spreadkosten transacties</td>
                                <td>&euro; {{ data.spread_costs_in_eur | currency}}</td>
                                <td>{{ data.spread_costs_in_perc | currency}}%</td>
                            </tr>
                            <tr>
                                <td>Bewaarloon/servicefee Bank/Broker</td>
                                <td>&euro; {{ data.custody_fee_in_eur | currency}}</td>
                                <td>{{ data.custody_fee_in_perc | currency}}%</td>
                            </tr>
                        </tbody>
                        <tfoot>
                                <tr>
                                    <th>Totaal dienstverlening</th>
                                    <th>&euro; {{ data.services_in_eur | currency}}</th>
                                    <th>{{ data.services_in_perc | currency}}%</th>
                                </tr>
                        </tfoot>
                    </table>
                    <table class="table">
                        <tbody>
                            <tr>
                                <th width="60%">Kosten financiële instrumenten</th>
                                <th width="20%">&euro; {{ data.instruments_in_eur | currency}}</th>
                                <th width="20%">{{ data.instruments_in_perc | currency}}%</th>
                            </tr>
                        </tbody>
                    </table>

                    <table class="table">
                        <tfoot>
                            <tr>
                                <th width="60%">Totaal</th>
                                <th width="20%">&euro; {{ (data.services_in_eur + data.instruments_in_eur) | currency}}</th>
                                <th width="20%">{{ (data.services_in_perc + data.instruments_in_perc) | currency }}%</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <div class="column is-6">

                    <h2 class="title is-4">Illustratie impact kosten op rendement</h2>
                    <highcharts :options="line" ref="highcharts"></highcharts>

                    <div class="legend columns is-multiline">
                        <div class="column is-12"><span class="line intended"></span> Waardeontwikkeling zonder kosten</div>
                        <div class="column is-12"><span class="line achieved"></span> Waardeontwikkeling na kosten</div>
                    </div>
                </div>
            </div>

            <p>DISCLAIMER: De door BeSmart berekende rendementsontwikkeling betreft slechts een verwachting op basis van het langjarig gemiddeld resultaat per risicoprofiel.De weergegeven prognoses en/of verwachtingen vormen geen betrouwbare indicator voor toekomstige resultaten De waarde van uw belegging kan fluctueren. In het verleden behaalde resultaten bieden geen garantie voor de toekomst. U kunt aan de berekende rendementsontwikkeling dan ook geen rechten ontlenen. </p>
        </div>

        <div v-if="!data || data.length == 0">
            <h2 class="title is-4">Overzicht van uw verwachte kosten</h2>
            <p><em>Er kan nog geen calculatie van uw verwachte kosten worden berekend.</em></p>
        </div>
    </div>

</template>

<script>
    import Highcharts from 'highcharts/highcharts';

    export default {

        props: ['data'],

        data() {
            return {
                line: {
                    title: {
                        text: null
                    },

                    tooltip: {
                        xDateFormat: '%m-%Y',
                        backgroundColor: '#dee0d5',
                        borderColor: '#c6c7c1',
                        borderRadius: 0,
                        borderWidth: 1,
                    },

                    yAxis: {
                        title: {
                            text: 'Rendement'
                        }
                    },

                    xAxis: {
                        gridLineWidth: 1,
                        title: {
                            text: 'Resterende looptijd',
                        }
                    },

                    plotOptions: {
                          series: {
                            fillOpacity: 0.1
                          }
                    },

                    legend: {
                        enabled: false
                    },

                    credits: false,

                    series: [
                        // Plan
                        {
                            name: 'Waardeontwikkeling zonder kosten',
                            zIndex: 1,
                            data: [],
                            color: '#0D8A84',
                            lineWidth: 3,
                            marker: {
                                enabled: false,
                            },
                            dashStyle: 'Dash',
                            enableMouseTracking: false
                        },
                        // Positive
                        {
                            name: 'Waardeontwikkeling na kosten',
                            zIndex: 1,
                            data: [],
                            lineWidth: 3,
                            color: '#333333',
                            marker: {
                                enabled: false,
                            },
                            data: [],
                            zIndex: 0,
                            enableMouseTracking: false
                        },
                    ]
                }
            }
        },

        mounted() {
            this.fillLineChart();
        },

        methods: {
            fillLineChart() {
                if (this.data && Object.keys(this.data).length > 0) {
                    this.line.series[0].data = [];
                    this.line.series[1].data = [];

                    this.line.series[0].data.push([1, this.data.start_amount])
                    this.line.series[0].data.push([this.data.horizon, this.data.bruto_target_amount])

                    this.line.series[1].data.push([1, this.data.start_amount])
                    this.line.series[1].data.push([this.data.horizon, this.data.netto_target_amount])
                }
            }
        },

        watch: {
            data: function(value) {
                this.fillLineChart();
            }
        }


    }

</script>
