<template>
  <div>
    <div class="block">
      <h4 class="title is-4">Historische rendementsontwikkeling</h4>
      <div v-if="!show">
        <div class="notification is-danger">
          <strong>U staat op het punt om de rendementsontwikkeling in te zien of te bewerken</strong>
          <p>Voer de tweefactor code in als u zeker bent om door te gaan.</p>
        </div>
        <div class="field has-addons">
          <div class="control">
            <input class="input" type="number" placeholder="Tweefactor code" v-model="twoFactor">
          </div>
          <div class="control">
            <a
              class="button is-primary"
              @click="checkTwoFactorCode"
            >Doorgaan</a>
          </div>
        </div>
        <div class="notification is-danger" v-if="error">
          <p>De opgegeven code is onjuist. Probeer het opnieuw.</p>
        </div>
      </div>

      <div v-if="show">
        <div class="notification is-warning">
          <strong>With great power comes great responsibility</strong>
          <ul>
            <li>Vul de datum in zoals in de voorbeelden, dus: YYYY-MM-DD</li>
            <li>Vul de waarden in met een punt (.) voor decimale aanduideingen</li>
            <li>Elke laatste STD-waarde van de maand wordt gebruikt voor de rendementsontwikkeling (grafiek)</li>
            <li>Elke laatste YTD-waarde van het jaar wordt gebruikt voor 'rendement per jaar' (tabel)</li>
            <li>De STD-waarde van de allerlaatste waarde is het cumulatief rendement</li>
          </ul>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th width="20%">Datum</th>
              <th width="20%">YTD-rendement</th>
              <th width="20%">STD-rendement</th>
              <th width="20%">Portefeuillewaarde</th>
              <th width="20%"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              height="47px"
              v-for="(row, index) in rows"
              v-bind:key="row.id"
              :class="{'is-danger': toDelete === index}"
            >
              <td>
                <span v-if="editRow !== index">{{ row.date }}</span>
                <span v-if="editRow === index">
                  <input type="text" class="input" v-model="form.date">
                  <form-field-errors :errors="form.errors" field="date"></form-field-errors>
                </span>
              </td>
              <td>
                <span v-if="editRow !== index">{{ row.efficiency_ytd }}</span>
                <span v-if="editRow === index">
                  <input type="text" class="input" v-model="form.efficiency_ytd">
                  <form-field-errors :errors="form.errors" field="efficiency_ytd"></form-field-errors>
                </span>
              </td>
              <td>
                <span v-if="editRow !== index">{{ row.efficiency_std }}</span>
                <span v-if="editRow === index">
                  <input type="text" class="input" v-model="form.efficiency_std">
                  <form-field-errors :errors="form.errors" field="efficiency_std"></form-field-errors>
                </span>
              </td>
              <td>
                <span v-if="editRow !== index">{{ row.portfolio_value }}</span>
                <span v-if="editRow === index">
                  <input type="text" class="input" v-model="form.portfolio_value">
                  <form-field-errors :errors="form.errors" field="portfolio_value"></form-field-errors>
                </span>
              </td>
              <td>
                <span v-if="editRow === null">
                  <button class="button" @click="edit(index, row)">
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button class="button" @click="destroy(index, row)">
                    <i class="fa fa-trash"></i>
                  </button>
                  <button class="button" @click="add(index)">
                    <i class="fa fa-caret-up"></i>
                  </button>
                  <button class="button" @click="add(index, 1)">
                    <i class="fa fa-caret-down"></i>
                  </button>
                </span>
                <span v-if="editRow === index">
                  <button class="button" @click="save()">Opslaan</button>
                  <button class="button" @click="cancel()">Annuleren</button>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      twoFactor: "",
      show: false,
      error: false,
      rows: [],
      form: null,
      editRow: null,
      toDelete: null
    };
  },

  computed: {
    data() {
      return this.$parent.data;
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.loadData();
    });
  },

  beforeDestroy() {
    bus.$emit("reloadCustomer");
  },

  methods: {
    checkTwoFactorCode() {
      axios.post('api/admin/two-factor/validate', {code: this.twoFactor})
        .then(() => {
          this.show = true
          this.error = false
        })
        .catch(() => {
          this.error = true
        })
    },

    loadData() {
      if (this.data.profile.id) {
        axios
          .get(`/api/admin/customers/${this.data.profile.id}/historical`)
          .then(({ data }) => {
            this.rows = data;
          });
      }
    },

    edit(index, row) {
      this.editRow = index;
      this.form = new Laravel.Form(row);
    },

    destroy(index, row) {
      let userAction = null;
      this.toDelete = index;

      setTimeout(() => {
        userAction = window.confirm(
          "Weet u zeker dat u de geselecteerde rij wil verwijderen?"
        );

        if (userAction) {
          this.toDelete = null;

          axios
            .delete(
              `/api/admin/customers/${this.data.profile.id}/historical/${
                row.id
              }`
            )
            .then(({ data }) => {
              this.rows = data;
              this.toDelete = null;
              this.$buefy.snackbar.open("Waarde verwijderd");
            })
            .catch(() => {
              this.$buefy.snackbar.open("Waarde NIET verwijderd");
            });
        } else {
          this.toDelete = null;
        }
      }, 250);
    },

    add(index, next = 0) {
      let pos = index + next;
      let date = moment(this.rows[index].date)[next ? "add" : "subtract"](
        1,
        "day"
      );
      let obj = {
        date: date.format("YYYY-MM-DD"),
        efficiency_ytd: "",
        efficiency_std: "",
        portfolio_value: ""
      };
      this.rows.splice(pos, 0, obj);
      this.edit(pos, obj);
    },

    save() {
      if (this.form.id) {
        this.form
          .put(
            `/api/admin/customers/${this.data.profile.id}/historical/${
              this.form.id
            }`
          )
          .then(data => {
            this.rows = data;
            this.editRow = null;
            this.$buefy.snackbar.open("Waarde opgeslagen");
          })
          .catch(() => {
            this.$buefy.snackbar.open("Waarde NIET opgeslagen");
          });
      } else {
        this.form
          .post(`/api/admin/customers/${this.data.profile.id}/historical`)
          .then(data => {
            this.rows = data;
            this.editRow = null;
            this.$buefy.snackbar.open("Waarde opgeslagen");
          })
          .catch(() => {
            this.$buefy.snackbar.open("Waarde NIET opgeslagen");
          });
      }
    },

    cancel() {
      this.editRow = null;
      this.loadData();
    }
  },

  watch: {
    data: function(val) {
      if (val.profile.id) {
        this.loadData();
      }
    }
  }
};
</script>

<style scoped>
tr.is-danger {
  background-color: hsl(348, 100%, 61%);
}
</style>
