<template>
  <div>
    <section class="section content no-header">
      <div class="container">
        <div class="columns">
          <div class="column is-6">
            <div class="block">
              <h4 class="title is-4">Inventarisatieformulier</h4>
              <p>Door op onderstaande knop te klikken start u met het invullen van het inventarisatieformulier. U kunt alle stappen tussentijds opslaan en eventueel op een later moment de inventarisatie vervolgen. Bedragen kunt u afronden op hele getallen. Bedragen die voor u niet van toepassing zijn kunt u op 0 laten staan.</p>
              <router-link
                :to="{ name: 'prospect' }"
                class="button is-primary"
              >Ga naar het inventarisatieformulier</router-link>
            </div>
          </div>
          <div class="column is-6">
            <div class="block">
              <notification-widget :notifications="data.notifications"></notification-widget>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$root.user;
    },

    data() {
      return this.$parent.data;
    }
  }
};
</script>
