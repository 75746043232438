<template>
  <div>
    <div class="submenu" v-if="user.role === 'manager'">
      <div class="container">
        <div class="tabs is-top">
          <ul>
            <li :class="{ 'is-active': false }">
              <router-link :to="{ name: 'admin/notifications' }"
                >Mijn berichten</router-link
              >
            </li>
            <li :class="{ 'is-active': true }">
              <router-link :to="{ name: 'admin/notifications/all' }"
                >Alle berichten</router-link
              >
            </li>
            <li :class="{ 'is-active': false }">
              <router-link :to="{ name: 'admin/notifications/compose' }"
                >Bericht opstellen</router-link
              >
            </li>
            <li :class="{ 'is-active': false }">
              <router-link :to="{ name: 'admin/notifications/templates' }"
                >Templates</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>

    <section class="section content no-header">
      <div class="container">
        <div class="block">
          <table class="table">
            <thead>
              <th>Geadresseerde</th>
              <th>Afzender</th>
              <th>Onderwerp</th>
              <th>Verzonden op</th>
              <th>Gelezen op</th>
            </thead>

            <tbody>
              <tr v-if="data.length === 0">
                <td colspan="4">
                  <center>Er zijn nog geen berichten verstuurd.</center>
                </td>
              </tr>
              <tr
                class="has-pointer"
                v-for="notification in data"
                v-bind:key="notification.id"
                @click="openNotification(notification.id)"
              >
                <td>{{ notification.notifiable.fullName }}</td>
                <td>{{ notification.data.message?.sender?.fullName }}</td>
                <td>{{ notification.data.subject }}</td>
                <td>
                  {{ notification.created_at | formatDate("D-M-Y HH:mm") }}
                </td>
                <td v-if="notification.read_at">
                  {{ notification.read_at | formatDate("D-M-Y HH:mm") }}
                </td>
                <td v-if="!notification.read_at">
                  <span
                    class="tag is-danger is-small"
                    v-if="!notification.read_at"
                    >Nieuw</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <div class="buttons is-centered">
            <button class="button" @click="loadMore">
              Meer berichten laden
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [],
      nextPage: 1,
    };
  },

  computed: {
    user() {
      return this.$root.user;
    },
  },

  beforeMount() {
    this.load();
  },

  methods: {
    openNotification(id) {
      this.$router.push({ name: "notification", params: { id: id } });
    },

    load() {
      axios
        .get(`/api/admin/notifications/all?page=${this.nextPage}`)
        .then(({ data }) => {
          this.data = [...this.data, ...data.data];
          this.nextPage = this.nextPage + 1;
        })
        .catch(() => {
          this.$buefy.snackbar.open("Kon berichten niet laden.");
        });
    },

    loadMore() {
      this.load();
    },
  },
};
</script>
