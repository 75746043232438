<template>

    <div v-if="hasErrors">
        <div class="notification is-primary">
            <h2 class="title is-4">Er zijn validatiefouten opgetreden</h2>

            <ul v-for="(error, key) in errors.errors.errors" v-bind:key="key">
                <li v-for="(err, key) in error" v-bind:key="key">{{ err }}</li>
            </ul>
        </div>

        <br>
    </div>

</template>

<script>

    export default {
        props: ['errors'],

        computed: {
            hasErrors() {
                return Object.keys(this.errors.errors).length;
            }
        }
    }

</script>
