<template>
  <div>
    <div class="submenu" v-if="user.role === 'manager'">
      <div class="container">
        <div class="tabs is-top">
          <ul>
            <li :class="{ 'is-active': true }">
              <router-link :to="{ name: 'admin/notifications' }">Mijn berichten</router-link>
            </li>
            <li :class="{ 'is-active': false }">
              <router-link :to="{ name: 'admin/notifications/all' }">Alle berichten</router-link>
            </li>
            <li :class="{ 'is-active': false }">
              <router-link :to="{ name: 'admin/notifications/compose' }">Bericht opstellen</router-link>
            </li>
            <li :class="{ 'is-active': false }">
              <router-link :to="{ name: 'admin/notifications/templates' }">Templates</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <section class="section content no-header">
      <div class="container">
        <div class="block">
          <table class="table">
            <thead>
              <th>Onderwerp</th>
              <th>Ontvangen op</th>
              <th>Gelezen op</th>
            </thead>

            <tbody>
              <tr v-if="!data.length">
                <td colspan="3">
                  <center>U heeft nog geen berichten ontvangen.</center>
                </td>
              </tr>
              <tr
                class="has-pointer"
                v-for="notification in data"
                v-bind:key="notification.id"
                @click="openNotification(notification.id)"
              >
                <td>{{ notification.data.subject }}</td>
                <td>{{ notification.created_at | formatDate('D-M-Y HH:mm') }}</td>
                <td
                  v-if="notification.read_at"
                >{{ notification.read_at | formatDate('D-M-Y HH:mm') }}</td>
                <td v-if="!notification.read_at">
                  <span class="tag is-danger is-small" v-if="!notification.read_at">Nieuw</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {}
    };
  },

  computed: {
    user() {
      return this.$root.user;
    }
  },

  beforeMount() {
    axios
      .get("/api/notifications")
      .then(({ data }) => {
        this.data = data;
      })
      .catch(() => {
        this.$buefy.snackbar.open("Kon berichten niet laden.");
      });
  },

  methods: {
    openNotification(id) {
      this.$router.push({ name: "notification", params: { id: id } });
    }
  }
};
</script>
