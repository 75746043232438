<template>
  <div>
    <section class="section content no-header">
      <div class="columns is-centered">
        <div class="column is-6 push-3">
          <div class="block">
            <h4 class="title is-4">Adviseur uitnodigen</h4>

            <form-errors :errors="form.errors"></form-errors>

            <form @submit.prevent="create()">
              <div class="field">
                <label class="label">Voornaam</label>
                <div class="control">
                  <input
                    name="first_name"
                    type="text"
                    class="input"
                    v-model="form.first_name"
                  />
                </div>
              </div>

              <div class="field">
                <label class="label">Achternaam</label>
                <div class="control">
                  <input
                    name="last_name"
                    type="text"
                    class="input"
                    v-model="form.last_name"
                  />
                </div>
              </div>

              <div class="field">
                <label class="label">E-mailadres</label>
                <div class="control">
                  <input
                    name="email"
                    type="text"
                    class="input"
                    v-model="form.email"
                  />
                </div>
              </div>

              <div class="field">
                <button
                  class="button is-primary"
                  :class="{ 'is-loading': loading }"
                >
                  Adviseur uitnodigen
                </button>
              </div>

              <p>
                <em
                  >De adviseur ontvangt een e-mailbericht om een wachtwoord aan
                  te maken.</em
                >
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: new Laravel.Form({
        first_name: "",
        last_name: "",
        email: "",
      }),
    };
  },

  methods: {
    create() {
      this.loading = true;
      this.form
        .post("/api/admin/advisors")
        .then((data) => {
          this.loading = false;
          this.$buefy.snackbar.open("Adviseur aangemaakt.");
          this.$router.push({
            name: "admin/advisor",
            params: { id: data.id },
          });
        })
        .catch((error) => {
          this.$buefy.snackbar.open("Kon adviseur niet aanmaken.");
          this.loading = false;
        });
    },
  },
};
</script>
