<template>
  <div>
    <div class="submenu">
      <div class="container">
        <div class="tabs is-top">
          <ul>
            <li>
              <router-link :to="{ name: 'admin/customers' }">Klantenoverzicht</router-link>
            </li>
            <li class="is-active">
              <router-link :to="{ name: 'admin/quarter-reports' }">Kwartaalrapportages</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'admin/customers/contact' }">Hebben contact nodig</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <section class="section content no-header">
      <div class="container">
        <div class="block" v-if="form.loading">
          <div v-if="filesToUpload.length">
            <h4 class="title is-4">Bestanden uploaden..</h4>
            <p>De bestanden worden geüpload. Dit scherm
              <strong>niet</strong> sluiten tijdens het uploaden.
            </p>
            <progress class="progress is-primary" :value="currentFileUploadIndex" :max="totalFiles"></progress>
            <p>Bezig met uploaden van bestand {{ currentFileUploadIndex }} van {{ totalFiles }}.</p>
          </div>
          <div v-else>
            <h4 class="title is-4">Bestanden geüpload</h4>
            <p>Het uploaden is voltooid. Eventuele foutmeldingen worden hieronder weergegeven.</p>
          </div>

          <div v-if="errors">
            <ul v-for="(value, key) in Object.values(errors)" v-bind:key="key">
              <div v-for="(error, key) in Object.values(value)" v-bind:key="key">
                <li v-for="(message, key) in error" v-bind:key="key">{{ message }}</li>
              </div>
            </ul>
          </div>
        </div>

        <form @submit.prevent="submitForm" v-if="!form.loading">
          <div class="block">
            <div class="field">
              <label class="label">Kwartaal</label>
              <span class="select">
                <select name="quarter" v-model="form.quarter">
                  <option value="0">Jaarrapportage</option>
                  <option value="1">1e kwartaal</option>
                  <option value="2">2e kwartaal</option>
                  <option value="3">3e kwartaal</option>
                  <option value="4">4e kwartaal</option>
                </select>
              </span>
            </div>

            <div class="field">
              <label class="label">Jaar</label>
              <span class="select">
                <select name="year" v-model="form.year">
                  <option v-for="i in years" v-bind:key="i" :value="i">{{ i }}</option>
                </select>
              </span>
            </div>

            <div class="columns">
              <div class="column is-6">
                <div class="field">
                  <label class="label">Kwartaalrapportages</label>
                  <p>Selecteer alle PDF-bestanden. De bestandsnamen dienen te beginnen met het portefeuillenummer of met 'Year_', direct gevolgd door een '_'-teken.</p>
                  <div class="file has-name">
                    <label class="file-label">
                      <input
                        class="file-input"
                        type="file"
                        name="quarter_reports"
                        @change="onFileChange('quarter_reports', $event)"
                        accept="application/pdf"
                        multiple
                      >
                      <span class="file-cta">
                        <span class="file-icon">
                          <i class="fa fa-upload"></i>
                        </span>
                        <span class="file-label">Selecteer kwartaalrapportages</span>
                      </span>
                      <span
                        class="file-name"
                      >{{ form.quarter_reports.length ? form.quarter_reports.length : 'Geen' }} bestanden geselecteerd</span>
                    </label>
                  </div>
                  <table class="table" v-if="files.quarter_reports.length">
                    <thead>
                      <tr>
                        <th>Bestandsnaam</th>
                        <th>Match</th>
                      </tr>
                    </thead>
                    <tr v-for="item in files.quarter_reports" v-bind:key="item.fileName">
                      <td>{{ item.fileName }}</td>
                      <td>
                        <span class="icon has-text-success" v-if="item.match">
                          <i class="fa fa-check-circle"></i>
                        </span>
                        <span class="icon has-text-danger" v-if="item.match === false">
                          <i class="fa fa-times-circle"></i>
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="column is-6">
                <div class="field">
                  <label class="label">Nota's</label>
                  <p>Selecteer alle PDF-bestanden. De bestandsnamen dienen te beginnen met het woord 'factuur', gevolgd door een '_'-teken, direct gevolg door het portefeuillenummer.</p>
                  <div class="file has-name">
                    <label class="file-label">
                      <input
                        class="file-input"
                        type="file"
                        name="invoices"
                        accept="application/pdf"
                        @change="onFileChange('invoices', $event)"
                        multiple
                      >
                      <span class="file-cta">
                        <span class="file-icon">
                          <i class="fa fa-upload"></i>
                        </span>
                        <span class="file-label">Selecteer nota's</span>
                      </span>
                      <span
                        class="file-name"
                      >{{ form.invoices.length ? form.invoices.length : 'Geen' }} bestanden geselecteerd</span>
                    </label>
                  </div>
                  <table class="table" v-if="files.invoices.length">
                    <thead>
                      <tr>
                        <th>Bestandsnaam</th>
                        <th>Match</th>
                      </tr>
                    </thead>
                    <tr v-for="item in files.invoices" v-bind:key="item.fileName">
                      <td>{{ item.fileName }}</td>
                      <td>
                        <span class="icon has-text-success" v-if="item.match">
                          <i class="fa fa-check-circle"></i>
                        </span>
                        <span class="icon has-text-danger" v-if="item.match === false">
                          <i class="fa fa-times-circle"></i>
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div class="field">
              <label class="label">Mededeling voor e-mail</label>
              <input
                name="announcement_email"
                v-model="form.announcement_email"
                class="input"
                placeholder="Geef een mededeling op voor het bericht in de e-mail."
              >
            </div>

            <div class="field">
              <label class="label">Mededeling voor MyBeSmart</label>
              <textarea
                name="announcement_portal"
                v-model="form.announcement_portal"
                class="textarea"
                placeholder="Geef een mededeling op voor het bericht in MyBeSmart."
              ></textarea>
            </div>

          <hr />

          <p>Het is mogelijk om optioneel een extra knop toe te voegen naar een
            nieuwsbrief of blogpost die gaat over de resulaten van deze periode.</p>
            <div class="columns">
              <div class="column is-6">
                <div class="field">
                  <label class="label">Link titel</label>
                  <p>Deze tekst komt in de knop te staan. Houd het kort, liefst 1 woord.</p>
                  <input
                    name="link_title"
                    v-model="form.link_title"
                    class="input"
                    placeholder="Geef optioneel een link titel op"
                  >
                </div>
              </div>
              <div class="column is-6">
                <div class="field">
                  <label class="label">Link URL</label>
                  <p>De pagina die opent als je op de knop klikt. Dit moet een internetadres zijn.</p>
                  <input
                    name="link_title"
                    v-model="form.link_url"
                    class="input"
                    placeholder="Geef optioneel een link URL op (verplicht i.c.m. link titel)"
                  >
                </div>
              </div>
            </div>

            <div class="field">
              <button class="button is-primary">Verzenden</button>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: new Laravel.Form({
        quarter: 1,
        year: 2015,
        quarter_reports: [],
        invoices: [],
        announcement_email: "",
        announcement_portal: "",
        link_title: "",
        link_url: "",
      }),
      currentFileUploadIndex: 0,
      totalFiles: 0,
      errors: [],
      files: {
        quarter_reports: [],
        invoices: []
      }
    };
  },

  computed: {
    years() {
      return Array.from({ length: 20 }, (_, i) => i + 2015);
    }
  },

  methods: {
    submitForm() {
      this.form.loading = true;

      let files = [...this.files.quarter_reports, ...this.files.invoices];

      this.errors = [];
      this.currentFileUploadIndex = 0;
      this.totalFiles = files.length;
      this.filesToUpload = files;
      this.prepareFileUpload();
    },

    prepareFileUpload() {
      if (this.filesToUpload.length) {
        let file = this.filesToUpload[0];
        let data = new FormData();
        data.append("year", this.form.year);
        data.append("quarter", this.form.quarter);
        data.append("announcement_email", this.form.announcement_email);
        data.append("announcement_portal", this.form.announcement_portal);
        data.append("link_title", this.form.link_title);
        data.append("link_url", this.form.link_url);
        data.append("file", file.file);
        data.append("type", file.type);
        data.append("match", file.match);
        this.uploadFile(data);
      }
    },

    uploadFile(data) {
      return axios
        .post("/api/admin/quarter-reports", data)
        .then(({ data }) => {
          this.currentFileUploadIndex++;
          this.filesToUpload.shift();
          this.prepareFileUpload();
        })
        .catch(error => {
          this.currentFileUploadIndex++;
          this.filesToUpload.shift();
          this.prepareFileUpload();
          this.errors.push(error.response.data.errors);
        });
    },

    onFileChange(name, e) {
      this.form[name] = e.target.files;

      this.files[name] = [];
      Array.from(e.target.files).forEach(f => {
        this.files[name].push({
          file: f,
          type: name,
          fileName: f.name,
          match: null
        });
      });
    }
  },

  watch: {
    files: {
      deep: true,
      handler: function(val) {
        if (val.invoices.length && val.quarter_reports.length) {
          let quarter_report_ids = [];
          let invoice_ids = [];

          val.quarter_reports.forEach(item => {
            let id = item.fileName.match(/^(?:Year_)?[0-9]+/)[0].replace('Year_', '');
            quarter_report_ids.push(id);
            item.id = id;
          });

          val.invoices.forEach(item => {
            let id = item.fileName.match(/^factuur_([0-9]+)/)[1];
            invoice_ids.push(id);
            item.match = quarter_report_ids.includes(id);
          });

          val.quarter_reports.forEach(item => {
            item.match = invoice_ids.includes(item.id);
          });
        }
      }
    }
  }
};
</script>
