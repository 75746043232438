/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Bootstrap from './bootstrap';
Bootstrap();

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import Vue from "vue";
import router from "./router";
import Buefy from "buefy";
import VueFilters from "./filters";
import VueHighcharts from "vue-highcharts";
import Highcharts from "highcharts/highcharts";
import money from "v-money";
import NProgress from "nprogress";
import * as Sentry from "@sentry/vue";

import Header from "./components/header.vue";
import FormErrors from "./components/form-errors.vue";
import FormFieldErrors from "./components/form-field-errors.vue";
import NotificationWidget from "./views/notification/Widget.vue";
import Portfolio from "./components/Portfolio.vue";
import Datepicker from "./components/Datepicker.vue";
import Timepicker from "./components/Timepicker.vue";

const HighchartsMore = require("highcharts/highcharts-more")(Highcharts);

Vue.component("app-header", Header);
Vue.component("form-errors", FormErrors);
Vue.component("form-field-errors", FormFieldErrors);
Vue.component("notification-widget", NotificationWidget);
Vue.component("portfolio", Portfolio);
Vue.use(Buefy, { defaultIconPack: "fa" });
Vue.component("b-datepicker", Datepicker);
Vue.component("b-timepicker", Timepicker);
Vue.use(VueHighcharts, { Highcharts, HighchartsMore });

Vue.use(money, {
  decimal: ",",
  thousands: ".",
  prefix: "",
  suffix: "",
  precision: 0,
  masked: false,
});

Object.entries(VueFilters).forEach((filter) => {
  Vue.filter(filter[0], filter[1]);
});

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: "https://753911a49ca24671b3a22b3eff3868c4@sentry.io/126031",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
  });
}

window.bus = new Vue();
const app = new Vue({
  el: "#app",
  router,
  NProgress,

  data: {
    openHTTPConnections: 0,
    unreadNotifications: 0,
    tasksTodayCount: 0,
    user: {},
    accounts: [],
  },

  watch: {
    openHTTPConnections: function (val, oldVal) {
      if (val <= 0) {
        this.openHTTPConnections = 0;
        NProgress.done();
      } else if (oldVal == 0 && val > 0) {
        NProgress.start();
      } else {
        NProgress.set(oldVal < val ? oldVal / val : val / oldVal);
      }
    },
  },

  created() {
    this.tokenVerify();

    // Interceptor on request
    window.axios.interceptors.request.use(
      (config) => {
        // Loading indicator
        this.openHTTPConnections++;

        return config;
      },
      (error) => {
        // Do something with request error
        this.openHTTPConnections--;
        return Promise.reject(error);
      }
    );

    // Interceptor on response
    window.axios.interceptors.response.use(
      (res) => {
        this.openHTTPConnections--;
        return Promise.resolve(res);
      },
      (err) => {
        this.openHTTPConnections--;
        let res = err.response;
        if (res.status === 401 && res.config && !res.config.__isRetryRequest) {
          return new Promise((resolve, reject) => {
            if (!localStorage.getItem("login")) {
              if (router.history.current.fullPath.indexOf("/auth") === -1) {
                localStorage.setItem(
                  "redirect",
                  router.history.current.fullPath
                );
              }
              router.push({ name: "login" }).catch(() => false);
            } else {
              router.push({ name: "auto-logout" }).catch(() => false);
            }
          });
        } else {
          return Promise.reject(err);
        }
      }
    );

    setInterval(() => {
      this.tokenVerify();
    }, 600000);
    // }, 6000)
  },

  mounted() {
    window.bus.$on("startLoading", () => {
      this.openHTTPConnections++;
    });

    window.bus.$on("doneLoading", () => {
      this.openHTTPConnections--;
    });
  },

  methods: {
    setUser(data) {
      // Set user id for Sentry
      Sentry.setUser({
        id: data.user.id,
      });

      localStorage.setItem("login", "true");

      // Set user for application
      this.user = data.user;
      this.accounts = data.accounts;
      // redirect to dashboard

        if (this.$route.name === "login") {
            this.$router.push({ name: "dashboard" });
        }
    },

    destroyUser() {
      window.axios.defaults.headers.common.Authorization = null;
      localStorage.removeItem("login");
      localStorage.removeItem("savedTaskFilter");
      localStorage.removeItem("saveCustomerSearch");
      this.user = {};
      this.accounts = [];
    },

    tokenVerify() {
      axios
        .get("api/auth/user")
        .then(({ data }) => {
          this.setUser(data);
        })
        .catch((e) => {
          this.destroyUser();
        });
    },
  },
});
