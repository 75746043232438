<template>
  <div>
    <section class="section content no-header">
      <div class="container">
        <div class="column">
          <div class="block full-height">
            <div class="columns">
              <div class="column">
                <h4 class="title is-4">Adviseurs</h4>
              </div>

              <div class="column">
                <button
                  @click="create()"
                  class="button is-primary is-pulled-right"
                >
                  Adviseur uitnodigen
                </button>
              </div>
            </div>
            <table class="table is-striped">
              <thead>
                <th>Naam</th>
                <th>Actief</th>
                <th>Two factor</th>
              </thead>
              <tbody>
                <tr
                  class="has-pointer"
                  v-for="advisor in advisors"
                  @click="open(advisor.id)"
                  v-bind:key="advisor.id"
                >
                  <td>{{ advisor.full_name }}</td>
                  <td>
                    <span class="tag is-success" v-if="advisor.is_activated">
                      <i class="fa fa-check is-white"></i>
                    </span>
                    <span class="tag is-danger" v-if="!advisor.is_activated">
                      <i class="fa fa-times is-white"></i>
                    </span>
                  </td>
                  <td>
                    <span class="tag is-success" v-if="advisor.enabled_2fa">
                      <i class="fa fa-check is-white"></i>
                    </span>
                    <span class="tag is-danger" v-if="!advisor.enabled_2fa">
                      <i class="fa fa-times is-white"></i>
                    </span>
                  </td>
                  <!-- <td>{{ prospect.created_at | formatDate('D-M-Y HH:mm:ss') }}</td>
                    <td>{{ prospect.user.lead_type }}</td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      advisors: [],
    };
  },

  beforeMount() {
    this.getAdvisors();
  },

  computed: {},

  methods: {
    getAdvisors() {
      axios
        .get("/api/admin/advisors")
        .then(({ data }) => {
          this.advisors = data;
        })
        .catch(() => {
          this.$buefy.snackbar.open("Kon niet laden.");
        });
    },

    create() {
      this.$router.push("advisors/create");
    },

    open(id) {
      this.$router.push(`advisors/${id}`);
    },
  },
};
</script>
