<template>
  <div>
    <div class="block">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h4 class="title is-4">Inventarisatieformulier</h4>
          </div>
        </div>

        <div class="level-right">
          <div class="level-item">
            <button class="button is-primary" @click="create">Nieuw formulier</button>
          </div>
        </div>
      </div>

      <table class="table">
        <thead>
          <th>Datum van starten</th>
          <th>Afgerond</th>
          <th>Formulier inzien</th>
        </thead>
        <tbody>
          <tr v-if="!data.prospects.length">
            <td colspan="3">
              <center>Nog geen inventarisatieformulieren.</center>
            </td>
          </tr>
          <tr v-for="(prospect, index) in data.prospects" v-bind:key="prospect.prospect.id">
            <td>{{ prospect.prospect.created_at | formatDate('D-M-Y HH:mm') }}</td>
            <td>{{ prospect.prospect.completed_step_6 ? 'Ja' : 'Nee' }}</td>
            <td>
              <button class="button is-primary" @click="openForm(index, prospect.prospect.id)">Openen</button>
              <button class="button" @click="printForm(prospect.prospect.id)">Printen</button>
              <button class="button" @click="prospectNote(index)">Prospect briefje</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="block">
      <prospect-form :index="openedForm"></prospect-form>
    </div>
  </div>
</template>

<script>
import ProspectForm from "./components/ProspectForm.vue";

export default {
  components: {
    "prospect-form": ProspectForm
  },

  data() {
    return {
      openedForm: ""
    };
  },

  computed: {
    data() {
      return this.$parent.data;
    },
    token() {
      return localStorage.getItem("token");
    }
  },

  methods: {
    openForm(index) {
      if (this.$route.params.index !== index) {
        this.$router.push({ name: 'admin/customer/prospect', params: {
          id: this.$parent.data.profile.id,
          index: index
        }});
      }

      this.openedForm = index;
    },

    create() {
      axios
        .post("/api/admin/prospects", { user_id: this.$parent.data.profile.id })
        .then(({ data }) => {
          this.$parent.data.prospects = data;
        })
        .catch(() => {
          this.$buefy.snackbar.open(`Kon formulier niet aanmaken.`);
        });
    },

    printForm(id) {
      window.open(`/api/admin/prospects/${id}/pdf?token=${this.token}`);
    },

    prospectNote(id) {
      this.$router.push({
        name: "admin/customer/prospect/note",
        params: { id: this.data.profile.id, option: id }
      });
    }
  },

  watch: {
    data: function(val) {
      if (this.$route.params.index) {
        this.openForm(this.$route.params.index)
      }
    }
  }
};
</script>
