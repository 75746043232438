<template>

    <div class="content">

        <section class="section light no-padding-bottom">
            <div class="container">
                <h3 class="title is-3"><strong>Inventarisatie</strong> <span class="is-primary">Clientprofiel: Horizon en beleggingsdoel</span></h3>

                <form-errors :errors="form.errors"></form-errors>
            </div>
        </section>

        <section class="section no-background has-header">
            <div class="container">
                <div class="columns">

                    <div class="column is-6">
                        <div class="block">
                            <h4 class="title is-4">Beleggingshorizon</h4>

                            <div class="field">
                                <label class="label">Wat is de horizon voor uw beleggingen?</label>

                                <div class="field">
                                    <span class="select">
                                        <select name="horizon.horizon" v-model="form.horizon.horizon">
                                            <option :value="i" v-for="i in years" v-bind:key="i">{{ i }} jaar</option>
                                        </select>
                                    </span>
                                </div>

                                <form-field-errors :errors="form.errors" field="horizon.horizon"></form-field-errors>
                            </div>

                            <div class="field">
                                <label class="label">In hoeverre bent u, nu of in de toekomst, afhankelijk van de beleggingsresultaten uit uw vermogen?</label>

                                <div class="field">
                                    <label class="radio">
                                        <input name="horizon.dependence" type="radio" value="niet" v-model="form.horizon.dependence">
                                        niet afhankelijk
                                    </label>
                                </div>

                                <div class="field">
                                    <label class="radio">
                                        <input name="horizon.dependence" type="radio" value="deels" v-model="form.horizon.dependence">
                                        deels afhankelijk
                                    </label>
                                </div>

                                <div class="field">
                                    <label class="radio">
                                        <input name="horizon.dependence" type="radio" value="geheel" v-model="form.horizon.dependence">
                                        geheel afhankelijk
                                    </label>
                                </div>
                                <form-field-errors :errors="form.errors" field="horizon.dependence"></form-field-errors>
                            </div>
                        </div>
                    </div>

                    <div class="column is-6">
                        <div class="block">
                            <h4 class="title is-4">Beleggingsdoelstelling</h4>

                            <div class="field">
                                <label class="label">Wat is het doel van uw beleggingen?</label>
                                <p class="control">
                                   <label class="checkbox">
                                        <input name="objective.retention.checked" type="checkbox" v-model="form.objective.retention.checked">
                                        Instandhouding vermogen (bijhouden inflatie + belasting)
                                    </label>
                                </p>

                                <form-field-errors :errors="form.errors" field="objective.retention"></form-field-errors>

                                <p class="control">
                                   <label class="checkbox">
                                        <input name="objective.grow.checked" type="checkbox" v-model="form.objective.grow.checked">
                                        Vermogensgroei
                                    </label>
                                </p>

                                <form-field-errors :errors="form.errors" field="objective.grow"></form-field-errors>

                                <p class="control">
                                   <label class="checkbox">
                                        <input name="objective.not_necessary_addition.checked" type="checkbox" v-model="form.objective.not_necessary_addition.checked">
                                        Niet noodzakelijke aanvulling op het inkomen
                                    </label>
                                </p>

                                <form-field-errors :errors="form.errors" field="objective.not_necessary_addition.checked"></form-field-errors>

                                <div class="field" v-if="form.objective.not_necessary_addition.checked">
                                    <label class="label">Bedrag</label>
                                    <div class="field has-addons">
                                        <p class="control">
                                            <a class="button">€</a>
                                        </p>
                                        <p class="control is-expanded">
                                            <money name="objective.not_necessary_addition.amount" class="input is-expanded" type="text" placeholder="Bedrag" v-model="form.objective.not_necessary_addition.amount"></money>
                                            <form-field-errors :errors="form.errors" field="objective.not_necessary_addition.amount"></form-field-errors>
                                        </p>
                                    </div>

                                    <label class="label">Periodiciteit</label>
                                    <div class="field">
                                        <label class="radio">
                                            <input name="objective.not_necessary_addition.periodicity" type="radio" value="per maand" v-model="form.objective.not_necessary_addition.periodicity">
                                            per maand
                                        </label>
                                    </div>
                                    <div class="field">
                                        <label class="radio">
                                            <input name="objective.not_necessary_addition.periodicity" type="radio" value="per kwartaal" v-model="form.objective.not_necessary_addition.periodicity">
                                            per kwartaal
                                        </label>
                                    </div>
                                    <div class="field">
                                        <label class="radio">
                                            <input name="objective.not_necessary_addition.periodicity" type="radio" value="per jaar" v-model="form.objective.not_necessary_addition.periodicity">
                                            per jaar
                                        </label>
                                    </div>
                                    <form-field-errors :errors="form.errors" field="objective.not_necessary_addition.periodicity"></form-field-errors>

                                    <label class="label">Vanaf (jaartal)</label>
                                    <div class="field">
                                        <p class="control">
                                            <input name="objective.not_necessary_addition.starting_from" class="input" placeholder="2000" v-model="form.objective.not_necessary_addition.starting_from"></input>
                                            <form-field-errors :errors="form.errors" field="objective.not_necessary_addition.starting_from"></form-field-errors>
                                        </p>
                                    </div>
                                    <hr />
                                </div>

                                <p class="control">
                                   <label class="checkbox">
                                        <input name="objective.necessary_addition.checked" type="checkbox" v-model="form.objective.necessary_addition.checked">
                                        Noodzakelijke aanvulling op het inkomen
                                    </label>
                                </p>

                                <form-field-errors :errors="form.errors" field="objective.necessary_addition.checked"></form-field-errors>

                                <div class="field" v-if="form.objective.necessary_addition.checked">
                                    <label class="label">Bedrag</label>
                                    <div class="field has-addons">
                                        <p class="control">
                                            <a class="button">€</a>
                                        </p>
                                        <p class="control is-expanded">
                                            <money name="objective.necessary_addition.amount" class="input" type="text" placeholder="Bedrag" v-model="form.objective.necessary_addition.amount"></money>
                                            <form-field-errors :errors="form.errors" field="objective.necessary_addition.amount"></form-field-errors>
                                        </p>
                                    </div>

                                    <label class="label">Periodiciteit</label>
                                    <div class="field">
                                        <label class="radio">
                                            <input name="objective.necessary_addition.periodicity" type="radio" value="per maand" v-model="form.objective.necessary_addition.periodicity">
                                            per maand
                                        </label>
                                    </div>
                                    <div class="field">
                                        <label class="radio">
                                            <input name="objective.necessary_addition.periodicity" type="radio" value="per kwartaal" v-model="form.objective.necessary_addition.periodicity">
                                            per kwartaal
                                        </label>
                                    </div>
                                    <div class="field">
                                        <label class="radio">
                                            <input name="objective.necessary_addition.periodicity" type="radio" value="per jaar" v-model="form.objective.necessary_addition.periodicity">
                                            per jaar
                                        </label>
                                    </div>
                                    <form-field-errors :errors="form.errors" field="objective.necessary_addition.periodicity"></form-field-errors>

                                    <label class="label">Vanaf (jaartal)</label>
                                    <div class="field">
                                        <p class="control">
                                            <input name="objective.necessary_addition.starting_from" class="input" placeholder="2000" v-model="form.objective.necessary_addition.starting_from"></input>
                                            <form-field-errors :errors="form.errors" field="objective.necessary_addition.starting_from"></form-field-errors>
                                        </p>
                                    </div>
                                    <hr />
                                </div>

                                <p class="control">
                                   <label class="checkbox">
                                        <input name="objective.repayment_mortgage.checked" type="checkbox" v-model="form.objective.repayment_mortgage.checked">
                                        Aflossing hypotheek
                                    </label>
                                </p>

                                <form-field-errors :errors="form.errors" field="objective.repayment_mortgage"></form-field-errors>

                                <div class="field" v-if="form.objective.repayment_mortgage.checked">
                                    <label class="label">Vanaf (jaartal)</label>
                                    <div class="field">
                                        <p class="control">
                                            <input name="objective.repayment_mortgage.starting_from" class="input" placeholder="2000" v-model="form.objective.repayment_mortgage.starting_from"></input>
                                            <form-field-errors :errors="form.errors" field="objective.repayment_mortgage.starting_from"></form-field-errors>
                                        </p>
                                    </div>

                                    <hr />
                                </div>

                                <p class="control">
                                   <label class="checkbox">
                                        <input name="objective.children_study.checked" type="checkbox" v-model="form.objective.children_study.checked">
                                        Financiering studie kinderen
                                    </label>
                                </p>

                                <form-field-errors :errors="form.errors" field="objective.children_study"></form-field-errors>

                                <div class="field" v-if="form.objective.children_study.checked">
                                    <label class="label">Vanaf (jaartal)</label>
                                    <div class="field">
                                        <p class="control">
                                            <input name="objective.children_study.starting_from" class="input" placeholder="2000" v-model="form.objective.children_study.starting_from"></input>
                                            <form-field-errors :errors="form.errors" field="objective.children_study.starting_from"></form-field-errors>
                                        </p>
                                    </div>

                                    <hr />
                                </div>

                                <p class="control">
                                   <label class="checkbox">
                                        <input name="objective.specific_spend_feature.checked" type="checkbox" v-model="form.objective.specific_spend_feature.checked">
                                        Specifieke uitgave in de toekomst
                                    </label>
                                </p>

                                <form-field-errors :errors="form.errors" field="objective.specific_spend_feature"></form-field-errors>

                                <div class="field" v-if="form.objective.specific_spend_feature.checked">
                                    <label class="label">Vanaf (jaartal)</label>
                                    <div class="field">
                                        <p class="control">
                                            <input name="objective.specific_spend_feature.starting_from" class="input" placeholder="2000" v-model="form.objective.specific_spend_feature.starting_from"></input>
                                            <form-field-errors :errors="form.errors" field="objective.specific_spend_feature.starting_from"></form-field-errors>
                                        </p>
                                    </div>

                                    <hr />
                                </div>

                                <p class="control">
                                   <label class="checkbox">
                                        <input name="objective.other.checked" type="checkbox" v-model="form.objective.other.checked">
                                        Anders
                                    </label>
                                </p>

                                <form-field-errors :errors="form.errors" field="objective.other.checked"></form-field-errors>

                                <div class="field" v-if="form.objective.other.checked">
                                    <label class="label">Namelijk</label>
                                    <p class="control">
                                        <input name="objective.other.namely" class="input" type="text" placeholder="Namelijk" v-model="form.objective.other.namely">
                                        <form-field-errors :errors="form.errors" field="objective.other.namely"></form-field-errors>
                                    </p>

                                    <label class="label">Vanaf (jaartal)</label>
                                    <div class="field">
                                        <p class="control">
                                            <input name="objective.other.starting_from" class="input" placeholder="2000" v-model="form.objective.other.starting_from">
                                            <form-field-errors :errors="form.errors" field="objective.other.starting_from"></form-field-errors>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="columns">
                    <div class="column">
                        <button @click="save()" class="button is-primary" :class="{'is-loading': form.loading}">Tussentijds opslaan</button>
                        <button @click="next()" class="button is-primary is-pulled-right" :class="{'is-loading': form.loading}">Opslaan en verder</button>
                    </div>
                </div>

            </div>
        </section>

    </div>

</template>

<script>

    export default {

        data() {
            return {
                form: this.$parent.form.horizon_and_objective,
                years: [],
            }
        },

        beforeMount() {
            for (let i = 1; i < 31; i++) {
                this.years.push(i)
            }

            if (!this.form.horizon) {
                this.form = {}
                this.form.next = false;
                this.form.horizon = {
                    horizon: 1,
                };
                this.form.objective = {
                    retention: {checked: false},
                    grow: {checked: false},
                    not_necessary_addition: {checked: false},
                    necessary_addition: {checked: false},
                    repayment_mortgage: {checked: false},
                    children_study: {checked: false},
                    specific_spend_feature: {checked: false},
                    other: {checked: false}
                };

                this.form = new Laravel.Form(this.form)
            }
        },

        methods: {
            save() {
                this.$parent.save(this.form);
            },

            next() {
                this.$parent.save(this.form, true);
            }
        },

    }

</script>
