<template>
  <div>
    <div class="section light" :class="{ 'no-padding-bottom': form.errors.any() }">
      <div class="container">
        <h3 class="title is-3">Welkom!</h3>

        <form-errors :errors="form.errors"></form-errors>
      </div>
    </div>

    <section class="section content has-header">
      <div class="container">
        <div class="block">
          <div v-if="!success">
            <p>Welkom in MyBeSmart.</p>
            <p>Om uw account te activeren vult u hieronder een persoonlijk wachtwoord in. Hiermee kunt u de volgende keer ook weer inloggen, bewaar dit dus zorgvuldig. Het wachtwoord dient minimaal uit 8 karakters te bestaan.</p>

            <form method="GET" @submit.prevent="submitForm()">
              <div>
                <div class="columns">
                  <div class="column">
                    <label>Uw gewenste wachtwoord</label>
                  </div>

                  <div class="column">
                    <input
                      name="password"
                      type="password"
                      class="input"
                      placeholder="Uw gewenste wachtwoord"
                      v-model="form.password"
                      autofocus
                    >
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <label>Uw gewenste wachtwoord (controle)</label>
                  </div>

                  <div class="column">
                    <input
                      name="password_confirmation"
                      type="password"
                      class="input"
                      placeholder="Uw gewenste wachtwoord (controle)"
                      v-model="form.password_confirmation"
                    >
                  </div>
                </div>

                <div class="columns">
                  <div class="column"></div>
                  <div class="column">
                    <button
                      type="submit"
                      class="button is-primary"
                      :class="{'is-loading': form.loading}"
                    >Account activeren</button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div v-if="success">
            <p>Uw wachtwoord is succesvol aan uw account gekoppeld. U kunt doorgaan door op de onderstaande knop te klikken.</p>

            <router-link
              tag="button"
              :to="{ name: 'dashboard' }"
              class="button is-primary"
              exact
            >Ga naar het dashboard</router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      success: false,
      form: new Laravel.Form({
        email: "",
        token: "",
        password: "",
        password_confirmation: ""
      })
    };
  },

  mounted() {
    this.form.email = this.$route.params.email;
    this.form.token = this.$route.params.token;
  },

  methods: {
    submitForm() {
      this.form
        .post("/api/auth/activate-account")
        .then(data => {
          this.$root.setUser(data);
          this.$router.replace({ name: "dashboard" });
          this.success = true;
        })
        .catch(error => {});
    }
  }
};
</script>
