<template>
    <form action="" @submit.prevent="submit">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Melding negeren</p>
            </header>
            <section class="modal-card-body">
                <p>Negeer de meldingen voor een afwijking aan het plan voor een op te geven periode.</p>
                <br />
                <p class="control">
                    <label class="label">Negeer plan alerts tot:</label>
                    <datepicker name="property.sold_date" placeholder="01-01-2000" :config="{ dateFormat: 'd-m-Y', static: false, locale: nl }" v-model="form.ignore_plan_rebalance_until"></datepicker>
                </p>
            </section>
            <footer class="modal-card-foot">
                <button class="button" type="button" @click="$parent.close()">Annuleren</button>
                <button type="submit" class="button is-primary">Verzenden</button>
            </footer>
        </div>
    </form>
</template>

<script>

    export default {

        props: ['id'],

        data() {
            return {
                form: new Laravel.Form({
                    id: this.id,
                    ignore_plan_rebalance_until: ''
                })
            }
        },

        computed: {
            nl() {
                return require('flatpickr/dist/l10n/nl.js').nl
            }
        },

        methods: {
            submit() {
                this.form.put('/api/admin/rebalancing/ignore')
                    .then(() => {
                        this.$buefy.snackbar.open('Succesvol genegeerd.');
                        bus.$emit('ignored');
                        this.$emit('close');
                    })
                    .catch((err) => {
                        this.$buefy.snackbar.open('Negeren niet gelukt.');
                        this.$emit('close');
                    })
            }
        }
    }

</script>
