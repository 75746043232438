<template>
  <div>
    <form method="POST" @submit.prevent="submit()">
      <div class="columns">
        <div class="column">
          <label>E-mailadres</label>
        </div>

        <div class="column">
          <input
            name="email"
            type="email"
            class="input"
            placeholder="E-mailadres"
            v-model="form.email"
            autofocus
          />
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <label>Wachtwoord</label>
        </div>

        <div class="column">
          <input
            name="password"
            type="password"
            id="input_password"
            class="input"
            placeholder="Wachtwoord"
            v-model="form.password"
          />
        </div>
      </div>

      <div class="columns">
        <div class="column"></div>
        <div class="column">
          <button
            type="submit"
            class="button is-primary"
            :class="{ 'is-loading': form.loading }"
          >
            Inloggen
          </button>
        </div>
      </div>
    </form>
    <div>
      <hr />

      <p>Of log direct in op uw depotrekening:</p>
      <br />
      <div class="columns">
        <div class="column is-2">
          <a
            href="https://www.saxoportfolio.com"
            target="_blank"
            rel="noreferrer noopener"
            class="external-service"
          >
            <img src="img/saxobank.svg" width="68" height="54" />
          </a>
        </div>

        <div class="column is-2">
          <a
            href="https://portal.fondsenplatform.nl"
            target="_blank"
            rel="noreferrer noopener"
            class="external-service"
          >
            <img
              src="img/fondsenplatform.jpg"
              width="161"
              height="40"
              style="vertical-align: bottom;"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: new Laravel.Form({
        email: "",
        password: "",
      }),
    };
  },

  methods: {
    submit() {
      axios.get("/sanctum/csrf-cookie").then((response) => {
        this.form
          .post("/api/auth/login")
          .then((data) => {
            if (data.hasOwnProperty("multipleAccountsAvailable")) {
              this.$parent.token = data.token;
              this.$parent.accounts = data.accounts;
              this.$router.replace({ name: "login/multpleAccounts" });
            } else if (data.hasOwnProperty("twoFactor")) {
              this.$parent.id = data.id;
              this.$parent.token = data.token;
              this.$router.replace({ name: "login/twoFactor" });
            } else {
              this.$parent.login(data);
            }
          })
          .catch(() => {
            this.$parent.form = this.form;
            this.form.password = "";
            document.getElementById("input_password").focus();
          });
      });
    },
  },
};
</script>

<style scoped>
.external-service {
  border: 1px solid #e7e7e5;
  padding: 5px;
  height: 100%;
  margin: auto;

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
