<template>
  <div class="content">
    <section class="section light no-padding-bottom">
      <div class="container">
        <h3 class="title is-3">
          <strong>Inventarisatie</strong>
          <span class="is-primary">Identificatie</span>
        </h3>

        <form-errors :errors="form.errors"></form-errors>
      </div>
    </section>

    <section class="section no-background has-header">
      <div class="container">
        <div class="columns">
          <div class="column">
            <div class="block">
              <h4 class="title is-4">Identificatie</h4>
              <p>Upload in dit formulier uw identificatie. Geldige identificatiebewijzen zijn:</p>
              <ul>
                <li>Identiteitskaart (voor- en achterzijde)</li>
                <li>Paspoort</li>
              </ul>
              <div class="columns is-multiline">
                <div class="column is-narrow">
                  <div class="field">
                    <div class="file is-primary has-name">
                      <label class="file-label">
                        <input
                          id="path_identification"
                          class="file-input"
                          type="file"
                          name="path_identification"
                          @change="onFileChange('path_identification', $event)"
                        >
                        <span class="file-cta">
                          <span class="file-icon">
                            <i class="fa fa-upload"></i>
                          </span>
                          <span class="file-label">Kies een bestand..</span>
                        </span>
                        <span
                          class="file-name"
                          v-if="form.name_identification"
                        >{{ form.name_identification }}</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="column is-narrow">
                <div class="field">
                    <div class="file is-primary has-name">
                      <label class="file-label">
                        <input
                          id="path_identification"
                          class="file-input"
                          type="file"
                          name="path_identification"
                          @change="onFileChange('path_identification2', $event)"
                        >
                        <span class="file-cta">
                          <span class="file-icon">
                            <i class="fa fa-upload"></i>
                          </span>
                          <span class="file-label">Kies optioneel een tweede bestand..</span>
                        </span>
                        <span
                          class="file-name"
                          v-if="form.name_identification2"
                        >{{ form.name_identification2 }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <p
                class="content is-small"
              >Toegestaande bestandstypes: jpeg, png, jpg, pdf, docx. Een watermerk wordt automatisch toegevoegd bij jpeg, png en jpg. Maximale grootte: 10MB</p>
              <form-field-errors :errors="form.errors" field="path_identification"></form-field-errors>

              <div class="notification">Tip: Het is ook mogelijk een foto van uw ID te uploaden.</div>
            </div>
          </div>
          <div class="column is-6" v-if="bank_accounts.account_type === 'business'">
            <div class="block">
              <h4 class="title is-4">Statuten of oprichtingsakte</h4>
              <p>U heeft in een eerdere stap gekozen voor een zakelijke rekening. Upload voor uw zakelijke rekening de statuten of oprichtingsakte ter identificatie.</p>
              <div class="field">
                <div class="file is-primary has-name">
                  <label class="file-label">
                    <input
                      id="path_statutes_or_founding_deed"
                      class="file-input"
                      type="file"
                      name="path_statutes_or_founding_deed"
                      @change="onFileChange('path_statutes_or_founding_deed', $event)"
                    >
                    <span class="file-cta">
                      <span class="file-icon">
                        <i class="fa fa-upload"></i>
                      </span>
                      <span class="file-label">Kies een bestand..</span>
                    </span>
                    <span
                      class="file-name"
                      v-if="form.name_statutes_or_founding_deed"
                    >{{ form.name_statutes_or_founding_deed }}</span>
                  </label>
                </div>
                <p
                  class="content is-small"
                >Toegestaande bestandstypes: jpeg, png, jpg, pdf, doc, docx. Maximale grootte: 10MB</p>
                <form-field-errors :errors="form.errors" field="path_statutes_or_founding_deed"></form-field-errors>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <button
              @click="save()"
              class="button is-primary"
              :class="{'is-loading': form.loading}"
            >Tussentijds opslaan</button>
            <button
              @click="next()"
              class="button is-primary is-pulled-right"
              :class="{'is-loading': form.loading}"
            >Opslaan en verder</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bank_accounts: this.$parent.form.bank_accounts.accountholder_1,
      form: this.$parent.form.identification
    };
  },

  beforeMount() {
    if (!this.form.path_identification) {
      this.form = {
        next: false,
        path_identification: null,
        name_identification: null,
        path_identification2: null,
        name_identification2: null,
        path_statutes_or_founding_deed: null,
        name_statutes_or_founding_deed: null
      };

      this.form = new Laravel.Form(this.form);
    }
  },

  methods: {
    onFileChange(name, e) {
      this.form[name] = e.target.files[0];
      this.form[name.replace("path_", "name_")] = e.target.files[0].name;
    },

    save() {
      this.$parent.save(this.form, false, true);
    },

    next() {
      this.$parent.save(this.form, true, true);
    }
  }
};
</script>
