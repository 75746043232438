<template>
  <div class="block">
    <div v-if="user['2fa']">
      <h4 class="title is-4">Tweefactorauthenticatie</h4>
      <div class="notification is-success">
        <i class="fa fa-check"></i> Tweefactorauthenticatie is ingeschakeld voor
        dit account.
      </div>
      <button
        class="button is-primary"
        @click="turnOffShowPassword = true"
        v-if="!turnOffShowPassword"
      >
        Tweefactorauthenticatie uitschakelen
      </button>

      <div v-if="turnOffShowPassword">
        <div class="control">
          <label class="label">Geef uw wachtwoord op ter bevestiging</label>
          <input
            type="password"
            class="input"
            placeholder="Wachtwoord"
            v-model="turnOffForm.password"
          />
        </div>
        <div class="control">
          <button
            class="button is-primary"
            @click="turnOff()"
            :class="{ 'is-loading': turnOffForm.loading }"
          >
            Tweefactorauthenticatie uitschakelen
          </button>
        </div>
      </div>
    </div>

    <div v-if="!user['2fa']">
      <h4 class="title is-4">Tweefactorauthenticatie configureren</h4>

      <div v-if="!QRcode">
        <ul>
          <li>
            <strong>Stap 1</strong> Download de Google Authenticator app uit de
            <a
              href="https://itunes.apple.com/nl/app/google-authenticator/id388497605?mt=8"
              target="_blank"
              >Apple AppStore</a
            >
            of de
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=nl"
              target="_blank"
              >Google Play Store</a
            >
          </li>
          <li><strong>Stap 2</strong> Open de Google Authenticator app</li>
          <li>
            <strong>Stap 3</strong> Voeg een nieuwe code toe door op het
            '+'-symbool te drukken. Kies vervolgens voor 'QR-code scannen'.
          </li>
          <li>
            <strong>Stap 4</strong> Klik op de onderstaande knop om een unieke
            code voor uw account te genereren
          </li>
        </ul>

        <button class="button is-primary" @click="getQRCode()">
          Genereer een unieke code voor mijn account
        </button>
      </div>

      <div v-if="QRcode">
        <img
          :src="`data:image/png;base64, ${QRcode}`"
          width="250"
          height="250"
        />
        <ul>
          <li>
            <strong>Stap 5</strong> Scan de bovenstaande QR-code met uw telefoon
          </li>
          <li>
            <strong>Stap 6</strong> Voer in het onderstaande tekstvak de code
            die in uw scherm verschijnt om de koppeling te verifiëren
          </li>
        </ul>
        <form-errors :errors="form.errors"></form-errors>
        <div class="control">
          <input
            type="text"
            class="input"
            placeholder="Voer hier de code in"
            v-model="form.token"
          />
        </div>
        <div class="control">
          <button
            class="button is-primary"
            :class="{ 'is-loading': form.loading }"
            @click="verifyToken()"
          >
            Code verifiëren en tweefactorauthenticatie inschakelen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: new Laravel.Form({
        secret: "",
        token: "",
      }),
      turnOffForm: new Laravel.Form({
        password: "",
      }),
      QRcode: "",
      turnOffShowPassword: false,
      profile: new Laravel.Form({
        picture: null,
      }),
    };
  },

  computed: {
    user() {
      return this.$root.user;
    },
  },

  methods: {
    getQRCode() {
      axios
        .get("/api/profile/2fa")
        .then(({ data }) => {
          this.form.secret = data.secret;
          this.QRcode = data.QRcode;
        })
        .catch(() => {
          alert("Kon geen code gereren");
        });
    },

    verifyToken() {
      this.form
        .post("/api/profile/2fa")
        .then(() => {
          this.$root.user["2fa"] = true;
        })
        .catch(() => {});
    },

    turnOff() {
      this.turnOffForm
        .put("/api/profile/2fa")
        .then(() => {
          this.$root.user["2fa"] = false;
        })
        .catch(() => {});
    },
  },
};
</script>
