<template>
  <div class="content">
    <div class="submenu">
      <div class="container">
        <div class="tabs is-top" v-if="prospect">
          <ul>
            <li :class="{ 'is-active': activePage == '' }">
              <router-link :to="{ name: 'prospect', params: { } }">Introductie</router-link>
            </li>
            <li
              v-for="(step, index) in steps"
              v-bind:key="step"
              :class="{
                'is-active': activePage === step,
              }"
            >
              <router-link :to="{ name: 'prospect', params: { page: step } }">
                Stap {{ index + 1 }}
                <span
                  class="icon is-small has-text-success"
                  v-if="prospect['completed_' + steps[index]]"
                >
                  <i class="fa fa-check-square"></i>
                </span>
                <span class="icon has-yellow-background" v-if="!prospect['completed_' + steps[index]]">
                  <i class="fa fa-hourglass-start"></i>
                </span>
              </router-link>
            </li>
            <li class="is-disabled">
              <a>Voltooid</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["activePage", "isBusiness"],

  computed: {
    prospect() {
      return this.$parent.prospect;
    },

    steps() {
      return this.$parent.steps;
    }
  }
};
</script>
