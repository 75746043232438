<template>
  <div>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">Privacyverklaring</p>
      </header>
      <div class="card-content">
        <div class="content">
          <p>Voor het gebruik van MyBeSmart gaat u akkoord gaan met de <a href="/pdf/privacy-statement.pdf" target="_blank">privacyverklaring</a> van BeSmart.</p>
        </div>
      </div>
      <div class="card-content">
        <div class="content has-text-centered">
          <button class="button is-primary" @click="accept()">Ja, ik ga akkoord</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    accept() {
      axios.post('api/legal/general_terms')
        .then(() => {
          this.$root.user.accepted_general_terms = true
          this.$parent.close();
        })
    }
  }
};
</script>
