<template>
  <div>
    <dashboard-prospect v-if="user.role === 'prospect'"></dashboard-prospect>
    <dashboard-customer v-if="user.role === 'customer'"></dashboard-customer>
    <dashboard-advisor v-if="user.role === 'advisor'"></dashboard-advisor>

    <b-modal :active.sync="showGeneralTermsModal" :width="650" scroll="keep" :canCancel="false">
      <general-terms></general-terms>
    </b-modal>
  </div>
</template>

<script>

import DashboardProspect from "./dashboard/Prospect.vue";
import DashboardCustomer from "./dashboard/Customer.vue";
import DashboardAdvisor from "./dashboard/Advisor.vue";
import GeneralTerms from "./GeneralTerms.vue";

export default {
  components: {
    "dashboard-prospect": DashboardProspect,
    "dashboard-customer": DashboardCustomer,
    "dashboard-advisor": DashboardAdvisor,
    "general-terms": GeneralTerms,
  },

  data() {
    return {
      showGeneralTermsModal: false,
      data: {
        chart: {},
        composition: {}
      }
    };
  },

  beforeMount() {
    window.bus.$once("loggedIn", () => {
      this.getData();
    });
    this.getData();
  },

  computed: {
    user() {
      return this.$root.user ? this.$root.user : {};
    }
  },

  methods: {
    getData() {
      if (this.user.role === "manager" || this.user.role === "admin") {
        this.$router.replace({ name: "admin/dashboard" });
      }

      if (this.user.role === "prospect" || this.user.role === "customer") {
        this.checkShowGeneralTermsModal();
        axios
          .get("/api/dashboard")
          .then(({ data }) => {
            this.data = data;
          })
          .catch(() => {
            this.$buefy.snackbar.open("Kon overzicht niet laden.");
          });
      }
    },

    checkShowGeneralTermsModal() {
      if (
        this.user &&
        (this.user.role_id === 3 || this.user.role_id === 4) &&
        this.user.hasOwnProperty("accepted_general_terms") &&
        this.user.accepted_general_terms === false
      ) {
        this.showGeneralTermsModal = true;
      }
    }
  },

  watch: {
    user: function() {
      this.getData();
    }
  }
};
</script>
