"use strict";

export class FormErrors {
  /**
   * Create a new Errors instance.
   */
  constructor() {
    this.errors = {};
  }

  /**
   * Determine if an errors exists for the given field.
   *
   * @param {string} field
   */
  has(field) {
    if (this.errors.hasOwnProperty("errors")) {
      return this.errors.errors.hasOwnProperty(field);
    }
    return false;
  }

  /**
   * Determine if we have any errors.
   */
  any() {
    return Object.keys(this.errors).length > 0;
  }

  /**
   * Retrieve the error message for a field.
   *
   * @param {string} field
   */
  get(field) {
    if (this.errors.errors && this.errors.errors[field]) {
      return this.errors.errors[field][0];
    }
  }

  /**
   * Record the new errors.
   *
   * @param {object} errors
   */
  record(errors) {
    this.errors = errors;
  }

  /**
   * Clear one or all error fields.
   *
   * @param {string|null} field
   */
  clear(field) {
    if (field) {
      delete this.errors[field];

      return;
    }

    this.errors = {};
  }
}

export class Form {
  /**
   * Create a new Form instance.
   *
   * @param {object} data
   */
  constructor(data) {
    this.originalData = data;

    for (let field in data) {
      this[field] = data[field];
    }

    this.errors = new FormErrors();
    this.loading = false;
    this.formData = false;
  }

  /**
   * Fetch all relevant data for the form.
   */
  data() {
    let data = this.formData ? new FormData() : {};

    for (let property in this.originalData) {
      this.formData
        ? data.append(property, this[property])
        : (data[property] = this[property]);
    }

    return data;
  }

  /**
   * Reset the form fields.
   */
  reset() {
    // for (let field in this.originalData) {
    //     this[field] = this.originalData[field];

    //     if(this.originalData[field] instanceof Array) {
    //         this[field] = []
    //     } else if(this.originalData[field] instanceof Object) {
    //         this[field] = {}
    //     }
    // }

    for (let field in this.originalData) {
      this[field] = "";
    }

    this.errors.clear();
  }

  /**
   * Send a POST request to the given URL.
   * .
   * @param {string} url
   */
  post(url, clear, formData = false) {
    this.formData = formData;
    return this.submit("post", url, clear);
  }

  /**
   * Send a PUT request to the given URL.
   * .
   * @param {string} url
   */
  put(url) {
    return this.submit("put", url);
  }

  /**
   * Send a PATCH request to the given URL.
   * .
   * @param {string} url
   */
  patch(url) {
    return this.submit("patch", url);
  }

  /**
   * Send a DELETE request to the given URL.
   * .
   * @param {string} url
   */
  delete(url) {
    return this.submit("delete", url);
  }

  /**
   * Submit the form.
   *
   * @param {string} requestType
   * @param {string} url
   */
  submit(requestType, url, clear = true) {
    this.errors.clear();
    this.loading = true;
    return new Promise((resolve, reject) => {
      axios[requestType](url, this.data())
        .then(response => {
          if (clear) {
            this.onSuccess(response.data);
          }
          this.loading = false;

          resolve(response.data);
        })
        .catch(error => {
          this.onFail(error.response.data);
          this.loading = false;

          reject(error.response.data);
        });
    });
  }

  /**
   * Handle a successful form submission.
   *
   * @param {object} data
   */
  onSuccess(data) {
    this.reset();
  }

  /**
   * Handle a failed form submission.
   *
   * @param {object} errors
   */
  onFail(errors) {
    this.errors.record(errors);
  }
}
