<template>
  <div>
    <h3 class="title is-4">Inkomend</h3>
    <table class="table is-striped">
      <thead>
        <th>Aan</th>
        <th>Onderwerp</th>
        <th>Verzenddatum</th>
      </thead>
      <tbody>
        <tr
          v-if="
            !data.receivedNotifications ||
            !Object.keys(data.receivedNotifications).length
          "
        >
          <td colspan="3">
            <center>Nog geen berichten ontvangen.</center>
          </td>
        </tr>
        <tr
          @click="$parent.openModal(notification)"
          :class="{ 'has-pointer': notification.notifiable_id === user.id }"
          v-for="notification in data.receivedNotifications"
          v-bind:key="notification.id"
        >
        <td>
          {{  notification.notifiable.fullName }}
        </td>
          <td>
            <i
              class="fa fa-external-link"
              v-if="
                notification.type === 'App\\Notifications\\PlainNotification'
              "
            >
            </i>
            {{ notification.data.subject }}
            <span class="tag is-danger is-small" v-if="!notification.read_at"
              >Nieuw</span
            >
          </td>
          <td>{{ notification.created_at | formatDate }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      templates: [],
      selected_template: undefined,
      variableFields: [],
      form: new Laravel.Form({
        subject: "",
        message: "",
        introduction: "",
        button_label: "",
        button_url: "",
        closing: "",
        email: false,
      }),
    };
  },

  computed: {
    data() {
      return this.$parent.data;
    },
    user() {
      return this.$root.user;
    },
    replacedSubject() {
      return this.form.subject.replace(
        /\[(.*?)\]/g,
        (match) =>
          this.variableFields.find(
            (field) => field.location === "subject" && field.match == match
          ).value || match
      );
    },
    replacedIntroduction() {
      return this.form.introduction.replace(
        /\[(.*?)\]/g,
        (match) =>
          this.variableFields.find(
            (field) => field.location === "introduction" && field.match == match
          ).value || match
      );
    },
    replacedClosing() {
      return this.form.closing.replace(
        /\[(.*?)\]/g,
        (match) =>
          this.variableFields.find(
            (field) => field.location === "closing" && field.match == match
          ).value || match
      );
    },
  },

  beforeMount() {
    axios
      .get("/api/admin/notification-templates")
      .then(({ data }) => {
        this.templates = data;
      })
      .catch(() => {
        this.$buefy.snackbar.open("Kon templates niet laden.");
      });
  },

  methods: {
    submitForm() {
      this.form
        .post(`/admin/customers/${this.data.id}/messages`)
        .then((response) => {
          this.$parent.$emit("messagesent", response.data);
          this.form.reset();
        });
    },
  },

  watch: {
    selected_template(val) {
      const template = this.templates.find((template) => template.id == val);

      if (!template) {
        this.form = new Laravel.Form({
          subject: "",
          message: "",
          introduction: "",
          button_label: "",
          button_url: "",
          closing: "",
          email: false,
        });

        return;
      }

      this.form = new Laravel.Form({
        subject: template.subject,
        message: "",
        introduction: template.introduction,
        button_label: template.button_label,
        button_url: template.button_url,
        closing: template.closing,
        email: false,
      });

      this.variableFields = [];
      const regex = /\[(.*?)\]/g;
      const fieldsToMatch = ["subject", "introduction", "closing"];

      fieldsToMatch.forEach((field) => {
        template[field].replace(regex, (match) => {
          this.variableFields.push({
            match,
            label: match.replace("[", "").replace("]", ""),
            location: field,
            value: "",
          });
        });
      });
    },
  },
};
</script>
