<template>
  <div>
    <div class="submenu" v-if="user.role === 'manager'">
      <div class="container">
        <div class="tabs is-top">
          <ul>
            <li :class="{ 'is-active': false }">
              <router-link :to="{ name: 'notifications' }"
                >Berichten</router-link
              >
            </li>
            <li :class="{ 'is-active': false }">
              <router-link :to="{ name: 'admin/notifications/all' }">Alle berichten</router-link>
            </li>
            <li :class="{ 'is-active': false }">
              <router-link :to="{ name: 'admin/notifications/compose' }"
                >Bericht opstellen</router-link
              >
            </li>
            <li :class="{ 'is-active': true }">
              <router-link :to="{ name: 'admin/notifications/templates' }"
                >Templates</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>

    <section class="section content no-header">
      <div class="container">
        <div class="columns">
          <div class="column">
            <h4 class="title is-3">Templates</h4>
          </div>
          <div class="column has-text-right">
            <router-link :to="{ name: 'admin/notifications/templates/create' }">
              <button class="button is-primary">Template aanmaken</button>
            </router-link>
          </div>
        </div>

        <div class="block">
          <table class="table">
            <thead>
              <th>Titel</th>
              <th>Bewerken</th>
              <th>Verwijderen</th>
            </thead>

            <tbody>
              <tr v-if="!data.length">
                <td colspan="3">
                  <center>Nog geen templates aangemaakt.</center>
                </td>
              </tr>
              <tr
                v-for="template in data"
                v-bind:key="template.id"
              >
                <td width="60%">{{ template.name }}</td>
                <td>
                  <button class="button" @click="openTemplate(template.id)">
                    Bewerken
                  </button>
                </td>
                <td>
                  <button
                    class="button is-danger"
                    @click="deleteTemplate(template.id)"
                  >
                    Verwijder template
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
    };
  },

  computed: {
    user() {
      return this.$root.user;
    },
  },

  beforeMount() {
    axios
      .get("/api/admin/notification-templates")
      .then(({ data }) => {
        this.data = data;
      })
      .catch(() => {
        this.$buefy.snackbar.open("Kon templates niet laden.");
      });
  },

  methods: {
    openTemplate(id) {
      this.$router.push({
        name: "admin/notifications/templates/edit",
        params: { id: id },
      });
    },
    deleteTemplate(id) {
      axios.delete(`/api/admin/notification-templates/${id}`).then(() => {
        this.$buefy.snackbar.open("Template verwijderd.");
        this.data = this.data.filter((template) => template.id !== id);
      });
    },
  },
};
</script>
