<template>
  <div class="columns">
    <div class="column is-6">
      <div class="block">
        <h4 class="title is-4" v-if="!editMode">Taak toevoegen</h4>
        <h4 class="title is-4" v-if="editMode">Taak bewerken</h4>

        <form-errors :errors="reminder.errors"></form-errors>
        <form @submit.prevent="editMode ? editReminder() : saveReminder()">
          <label class="label">Datum</label>
          <div class="field">
            <p class="control">
              <b-datepicker
                name="date"
                placeholder="01-01-2000"
                v-model="reminder.date"
                indicators="bars"
                :events="events"
              ></b-datepicker>
            </p>
          </div>
          <label class="label">Uitvoerder</label>
          <div class="field">
            <p class="control">
              <span class="select">
                <select v-model="reminder.assignee_id">
                  <option
                    v-for="collegue in collegues"
                    v-bind:key="collegue.id"
                    :value="collegue.id"
                  >{{ collegue.fullName }}</option>
                </select>
              </span>
            </p>
          </div>
          <label class="label">Taak</label>
          <div class="field has-addons">
            <p class="control">
              <button class="button is-disabled">{{ data.profile.fullName }}:</button>
            </p>
            <p class="control is-expanded">
              <input
                name="description"
                class="input"
                type="text"
                placeholder="Taak"
                v-model="reminder.description"
              >
            </p>
          </div>
          <div class="field">
            <button
              type="submit"
              class="button is-primary"
              :class="{'is-loading': reminder.loading}"
            >
              <span v-if="!editMode">Taak toevoegen</span>
              <span v-if="editMode">Taak bewerken</span>
            </button>

            <button
              type="button"
              v-if="editMode"
              class="button"
              @click="cancelEditing()"
            >Bewerken annuleren</button>
          </div>
        </form>
      </div>
    </div>

    <div class="column is-6">
      <div class="block">
        <h4 class="title is-4">Geplande taken</h4>
        <table class="table">
          <thead>
            <th>Datum</th>
            <th>Uitvoerder</th>
            <th>Taak</th>
            <th></th>
          </thead>
          <tbody>
            <tr v-if="!data.upcomingTasks.length">
              <td colspan="4">
                <center>Er zijn nog geen taken aangemaakt</center>
              </td>
            </tr>
            <tr v-for="(task, index) in data.upcomingTasks" v-bind:key="task.id">
              <td>{{ task.date | formatDate }}</td>
              <td>{{ task.assignee.first_name }}</td>
              <td>{{ task.description }}</td>
              <td>
                <button class="button" @click="editTask(task, index)">
                  <i class="fa fa-pencil"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      events: [],
      reminder: new Laravel.Form({
        date: new Date(),
        description: "",
        assignee_id: this.$root.user.id
      }),
      editMode: false
    };
  },

  mounted() {
    if (
      this.$parent.data &&
      this.$parent.data.profile &&
      this.$parent.data.profile.manager
    ) {
      this.reminder.assignee_id = this.$parent.data.profile.manager;
    }

    axios.get("/api/admin/tasks").then(({ data }) => {
      this.parseEvents(data.events);
    });
  },

  computed: {
    data() {
      return this.$parent.data;
    },

    collegues() {
      return this.$parent.collegues;
    }
  },

  methods: {
    saveReminder() {
      this.reminder
        .submit(
          "put",
          `/api/admin/customers/${this.$parent.data.profile.id}/reminders`,
          false
        )
        .then(data => {
          this.$parent.data.upcomingTasks.push(data);
          this.$buefy.snackbar.open(`Taak succesvol toegevoegd.`);
          this.reminder.description = "";
        })
        .catch(e => {
          this.$buefy.snackbar.open(`Kon taak niet toevoegen.`);
        });
    },

    editReminder() {
      this.reminder
        .submit("put", `/api/admin/tasks/${this.reminder.id}`, false)
        .then(data => {
          this.$parent.data.upcomingTasks[this.editMode - 1] = data;

          this.$buefy.snackbar.open(`Taak succesvol bewerkt.`);
          this.reminder.description = "";
          this.editMode = false;
        })
        .catch(e => {
          this.$buefy.snackbar.open(`Kon taak niet bewerken.`);
        });
    },

    editTask(task, index) {
      task = { ...task };

      this.editMode = index + 1;
      // task.date = moment(task.date, "YYYY-MM-DD").format("DD-MM-YYYY");
      this.reminder = new Laravel.Form(task);
    },

    cancelEditing() {
      this.reminder = new Laravel.Form({
        description: "",
        assignee_id: ""
      });

      this.editMode = false;
    },

    parseEvents(data) {
      this.events = [];
      data.forEach(item => {
        let type =
          item.amount >= 10
            ? "is-danger"
            : item.amount > 4
            ? "is-warning"
            : "is-success";

        this.events.push({
          date: moment(item.date).toDate(),
          type
        });
      });
    }
  },

  watch: {
    data: function(data) {
      if (data.profile.manager) {
        this.reminder.assignee_id = data.profile.manager;
      }
    }
  }
};
</script>
