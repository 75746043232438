<template>
  <div>
    <section class="section no-background no-header">
      <div class="container">
        <div class="columns">
          <div class="column is-3">
            <div class="block">
              <aside class="menu">
                <p class="menu-label">Bericht ontvangen op</p>
                <ul class="menu-list">
                  <li>
                    {{ notification.created_at | formatDate("D-M-Y HH:mm") }}
                  </li>
                </ul>
              </aside>
              <hr />
              <aside class="menu">
                <p class="menu-label">Bericht geopend op</p>
                <ul class="menu-list">
                  <li>
                    {{ notification.read_at | formatDate("D-M-Y HH:mm") }}
                  </li>
                </ul>
              </aside>
            </div>
          </div>

          <div class="column content">
            <div class="block">
              <p v-if="notification.data.subject">
                <strong v-if="notification.data.message">{{
                  notification.data.subject
                }}</strong>
                <span v-if="!notification.data.message">{{
                  notification.data.subject
                }}</span>
              </p>
              <div
                class="content"
                v-if="typeof notification.data.message === 'object'"
              >
                <div class="mb-4">
                  <pre class="content">{{
                    notification.data.message.greeting
                  }}</pre>
                  <pre class="content">{{
                    notification.data.message.introduction
                  }}</pre>
                </div>
                <p
                  v-if="
                    notification.data.message.button_url &&
                    notification.data.message.button_label
                  "
                >
                  <a
                    :href="notification.data.message.button_url"
                    class="button is-primary"
                    target="_blank"
                    noreferrer
                    noopener
                    >{{ notification.data.message.button_label }}</a
                  >
                </p>

                <div class="mb-4" v-if="notification.data.message.closing">
                  <pre class="content">{{
                    notification.data.message.closing
                  }}</pre>
                </div>

                <div class="mb-4" v-if="notification.data.message.salutation">
                  <pre class="content">{{
                    notification.data.message.salutation
                  }}</pre>
                </div>
              </div>
              <pre class="content" v-else>{{ notification.data.message }}</pre>
              <p v-if="notification.data.id">
                <router-link
                  :to="{
                    name: 'admin/customer',
                    params: { id: notification.data.id },
                  }"
                  class="button is-primary"
                  >Ga naar het profiel van
                  {{ notification.data.fullName }}</router-link
                >
              </p>
              <p v-if="notification.data.attachment">
                <a
                  href="#"
                  @click="downloadFile(notification.attachment_url)"
                  target="_blank"
                >
                  <i class="fa fa-download"></i> Download bijlage
                </a>
              </p>
              <router-link
                v-if="
                  (user.role_id === 3 || user.role_id === 4) &&
                  (user.manager || user.advisor)
                "
                :to="{ name: 'notification/compose' }"
                class="button is-primary"
                >Nieuw bericht opstellen</router-link
              >
            </div>

            <div
              class="block"
              v-if="
                typeof notification.data.message === 'object' &&
                notification.data.message.sender
              "
            >
              <p>
                <strong
                  >Direct antwoorden aan
                  {{ notification.data.message.sender.fullName }}</strong
                >
              </p>
              <form @submit.prevent="submitReply()">
                <form-errors :errors="reply.errors"></form-errors>
                <div class="field">
                  <textarea
                    name="message"
                    class="textarea"
                    placeholder="Typ hier het bericht"
                    v-model="reply.message"
                  ></textarea>
                </div>

                <p>
                  <em
                    >Als u een document wilt bijvoegen, dan kunt u dat beveiligd
                    doen via
                    <a href="/#/profile" target="_blank" noreferrer noopener
                      >documenten in uw profiel</a
                    >.</em
                  >
                </p>

                <div class="field">
                  <button
                    type="submit"
                    class="button is-primary"
                    :class="{ 'is-loading': reply.loading }"
                  >
                    Verzenden
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notification: {
        data: {},
      },
      reply: new Laravel.Form({
        message: "",
      }),
    };
  },

  computed: {
    token() {
      return localStorage.getItem("token");
    },

    user() {
      return this.$root.user;
    },
  },

  beforeMount() {
    let id = this.$route.params.id;

    axios
      .get(`/api/notifications/${id}`)
      .then(({ data }) => {
        this.notification = data;
      })
      .catch(() => {
        this.$buefy.snackbar.open("Kon bericht niet openen.");
      });
  },

  methods: {
    downloadFile(url) {
      window.open(`${url}?token=${this.token}`);
    },

    submitReply() {
      this.reply
        .post(`/api/notifications/${this.notification.id}/reply`)
        .then(() => {
          this.$buefy.snackbar.open("Bericht verzonden.");
        })
        .catch(() => {
          this.$buefy.snackbar.open("Kon bericht niet verzenden.");
        });
    },
  },
};
</script>
