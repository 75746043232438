<template>
  <div class="columns">
    <div class="column" v-for="(accountholder, key) in data" v-bind:key="key">
      <div
        v-if="accountholder && Object.keys(accountholder) && (key == 'accountholder_1' || key == 'accountholder_2')"
      >
        <div class="field">
          <label class="label">Rekeninghouder</label>
          <p>{{ accountholder.accountholder }}</p>
        </div>

        <div class="field" v-if="accountholder.account_type">
          <label class="label">Type rekening / account</label>
          <p v-if="accountholder.account_type == 'private'">Privérekening</p>
          <p v-if="accountholder.account_type == 'business'">Zakelijke rekening</p>
        </div>

        <div class="field" v-if="accountholder.securities_account_account_name">
          <label class="label">Tenaamstelling rekening</label>
          <p>{{ accountholder.securities_account_account_name }}</p>
        </div>

        <div class="field" v-if="accountholder.pep != null">
          <label class="label">Bent u of bent u directe familie van een politiek prominent persoon ook wel een 'Politically Exposed Person' of PEP genoemd?</label>
          <p>
            <span v-if="accountholder.pep == 1">Ja</span>
            <span v-if="accountholder.pep == 0">Nee</span>
          </p>
        </div>

        <hr v-if="key == 'accountholder_1'" />

        <div class="field">
          <label class="label">Aanhef</label>
          <p>{{ accountholder.salutation }}</p>
        </div>

        <div class="field">
          <label class="label">Voornaam</label>
          <p>{{ accountholder.first_name }}</p>
        </div>

        <div class="field">
          <label class="label">Achternaam</label>
          <p>{{ accountholder.last_name }}</p>
        </div>

        <div class="field">
          <label class="label">BSN</label>
          <p>{{ accountholder.BSN }}</p>
        </div>

        <div class="field">
          <label class="label">Geboortedatum</label>
          <p>{{ accountholder.date_of_birth_day }}-{{ accountholder.date_of_birth_month }}-{{ accountholder.date_of_birth_year }}</p>
        </div>

        <div class="field">
          <label class="label">Geboorteplaats</label>
          <p>{{ accountholder.place_of_birth }}</p>
        </div>

        <div class="field">
          <label class="label">Geboorteland</label>
          <p>{{ accountholder.country_of_birth }}</p>
        </div>

        <div class="field">
          <label class="label">Nationaliteit</label>
          <p>{{ accountholder.nationality }}</p>
        </div>

        <div class="field">
          <label class="label">Adres</label>
          <p>{{ accountholder.address }}</p>
        </div>

        <div class="field">
          <label class="label">Postcode</label>
          <p>{{ accountholder.postal_code }}</p>
        </div>

        <div class="field">
          <label class="label">Woonplaats</label>
          <p>{{ accountholder.place_of_residence }}</p>
        </div>

        <div class="field">
          <label class="label">Land</label>
          <p>{{ accountholder.country }}</p>
        </div>

        <div class="field">
          <label class="label">Telefoonnummer</label>
          <p>{{ accountholder.phone_number }}</p>
        </div>

        <div class="field">
          <label class="label">E-mailadres</label>
          <p>{{ accountholder.email_address }}</p>
        </div>

        <div class="field">
          <label class="label">Tegenrekeningnummer</label>
          <p>{{ accountholder.bank_account_number }}</p>
        </div>

        <div class="field">
          <label class="label">Tenaamstelling tegenrekening</label>
          <p>{{ accountholder.bank_account_ascription }}</p>
        </div>

        <div class="field">
          <label class="label">Naam bank tegenrekening</label>
          <p>{{ accountholder.bank_account_bank_name }}</p>
        </div>

        <div class="field">
          <label class="label">Afwijkend postadres (indien van toepassing)</label>
          <p>{{ accountholder.mailing_address }}</p>
          <p>{{ accountholder.mailing_postal_code }}</p>
          <p>{{ accountholder.mailing_place }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"]
};
</script>
