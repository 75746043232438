<template>
  <div>
    <div class="submenu" v-if="user.role === 'manager'">
      <div class="container">
        <div class="tabs is-top">
          <ul>
            <li :class="{ 'is-active': false }">
              <router-link :to="{ name: 'notifications' }"
                >Berichten</router-link
              >
            </li>
            <li :class="{ 'is-active': false }">
              <router-link :to="{ name: 'admin/notifications/all' }">Alle berichten</router-link>
            </li>
            <li :class="{ 'is-active': false }">
              <router-link :to="{ name: 'admin/notifications/compose' }"
                >Bericht opstellen</router-link
              >
            </li>
            <li :class="{ 'is-active': true }">
              <router-link :to="{ name: 'admin/notifications/templates' }"
                >Templates</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>

    <section class="section content no-header">
      <div class="container">
        <h4 class="title is-3">Template aanmaken</h4>
        <div class="block">
          <form @submit.prevent="submitForm">
            <form-errors :errors="form.errors"></form-errors>
            <div class="field">
              <label class="label">Naam van deze template (intern)</label>
              <input
                type="text"
                class="input"
                placeholder="Naam"
                v-model="form.name"
              />
            </div>

            <hr />

            <div class="field">
              <label class="label">Onderwerp van het bericht</label>
              <input
                type="text"
                class="input"
                placeholder="Onderwerp"
                v-model="form.subject"
              />
            </div>

            <div class="field">
              <label class="label">Aanhef</label>
              <label class="radio">
                <input
                  name="greeting"
                  type="radio"
                  value="formal"
                  v-model="form.greeting"
                />
                Formeel
              </label>

              <label class="radio">
                <input
                  name="greeting"
                  type="radio"
                  value="informal"
                  v-model="form.greeting"
                />
                Informeel
              </label>
            </div>

            <div class="field">
              <label class="label">Eerste regel van het bericht</label>
              <textarea
                class="textarea"
                placeholder="Introductie"
                v-model="form.introduction"
              ></textarea>
            </div>

            <hr />

            <div class="field">
              <label class="label">Knoptekst</label>
              <input
                type="text"
                class="input"
                placeholder="Onderwerp"
                v-model="form.button_label"
              />
            </div>

            <div class="field">
              <label class="label">Knoplink (URL)</label>
              <input
                type="text"
                class="input"
                placeholder="Onderwerp"
                v-model="form.button_url"
              />
            </div>

            <hr />

            <div class="field">
              <label class="label">Afsluiting van het bericht</label>
              <textarea
                class="textarea"
                placeholder="Afsluiting"
                v-model="form.closing"
              ></textarea>
            </div>

            <div class="notification is-warning">
              <p>
                <strong>Let op!</strong> Alleen de velden "Onderwerp",
                "Introductie" en "Afsluiting" hebben ondersteuning voor
                variabelen. Zet een gedeelte tussen [blokhaken] om dit gedeelte
                bij het opstellen van een bericht aan te kunnen passen.
                Bijvoorbeeld: "De afspraak is op [Datum en tijd]."
              </p>
            </div>

            <button type="submit" class="button is-primary">
              Template opslaan
            </button>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: new Laravel.Form({
        name: "",
        subject: "",
        greeting: "formal",
        introduction: "",
        button_label: "",
        button_url: "",
        closing: "",
      }),
    };
  },

  computed: {
    user() {
      return this.$root.user;
    },
  },

  methods: {
    submitForm() {
      this.form
        .post("/api/admin/notification-templates")
        .then(() => {
          this.$buefy.snackbar.open("Template opgeslagen.");
          this.$router.push({ name: "admin/notifications/templates" });
        })
        .catch(() => {
          this.$buefy.snackbar.open("Kon template niet opslaan.");
        });
    },
  },
};
</script>
