export default function(value, format = "DD-MM-Y") {
  // Alternative: D-M-Y HH:mm:ss
  if (value && value.length == 10) {
    return moment(String(value), "YYYY-MM-DD").format(format);
  } else if (value && value.length == 19) {
    return moment(String(value), "YYYY-MM-DD HH:mm:ss").format(format);
  } else if (value) {
    if (typeof value === "object" && typeof value.getFullYear === "function") {
      return moment(
        [
          value.getFullYear(),
          (value.getMonth() + 1).toString().padStart(2, "0"),
          value.getDate()
        ].join("-")
      ).format(format);
    }

    if (typeof value === "object" && value.hasOwnProperty("date")) {
      return moment(value.date).format(format);
    }

    return moment(String(value)).format(format);
  } else {
    return "";
  }
}
