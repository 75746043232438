<template>
  <div>
    <p>Er zijn meerdere accounts gevonden met dit e-mailadres. Selecteer het account waarmee u wenst in te loggen.</p>

    <div class="columns" style="margin-top: 0">
      <div class="column" v-for="(account, index) in accounts" v-bind:key="index">
        <div class="box has-pointer" :dusk="`account-${account.id}`" :class="{'is-disabled': loading}" @click="login(account)">
          <article class="media">
            <div class="media-left">
              <i class="fa fa-user-o" style="font-size: 24px; color: #333334"></i>
            </div>
            <div class="media-content">
              <div class="content">
                <table class="table is-narrow no-borders">
                  <tr v-if="account.securities_account_account_name">
                    <th width="30%">Tenaamstelling</th>
                    <td>{{ account.securities_account_account_name }}</td>
                  </tr>
                  <tr v-if="account.portfolio_number">
                    <th width="30%">Depotnummer</th>
                    <td>{{ account.portfolio_number }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      accounts: this.$parent.accounts
    };
  },

  methods: {
    login(account) {
      this.loading = true;
      if (account["2fa"]) {
        this.$parent.id = account.id;
        this.$router.replace({ name: "login/twoFactor" });
      } else {
        let data = {
          id: account.id,
          token: this.$parent.token
        };

        axios
          .post("/api/auth/login/select-account", data)
          .then(({ data }) => {
            this.$parent.login(data);
          })
          .catch((e) => {
            this.$buefy.snackbar.open("Kon niet inloggen met dit account.");
            this.$router.replace({ name: "login" });
          });
      }
    }
  }
};
</script>

<style>
.box.is-disabled {
  pointer-events: none;
  opacity: 0.6;
}
</style>
