<template>
  <div class="block">
    <h4 class="title is-4">Plan aanmaken</h4>

    <form-errors :errors="form.errors"></form-errors>

    <form @submit.prevent="doProposal()">
      <div class="field" v-if="data.profile.current_plan">
        <label class="label">Gebaseerd op</label>
        <p>Wanneer u hier een waarde opgeeft, wordt voortgezet op een vorig plan. Dit is een gebeurtenis in de rendementsgrafiek.</p>
        <span class="select">
          <select name="based_on" v-model="based_on">
            <option value disabled>Niet gebaseerd op een vorig plan</option>
            <option
              :value="proposal.id"
              v-for="proposal of data.proposals"
              v-bind:key="proposal.id"
            >
              {{ proposal.plan.name }} ({{ proposal.created_at | formatDate }})
              <span
                v-if="proposal.id === data.profile.current_plan.id"
              >(HUIDIG)</span>
            </option>
          </select>
        </span>
        <form-field-errors :errors="form.errors" field="based_on"></form-field-errors>
      </div>

      <div class="field">
        <label class="label">Risicoprofiel / Plan</label>
        <span class="select">
          <select name="plan_id" v-model="form.plan_id">
            <option value disabled>Maak een keuze</option>
            <option value="1">Zeer defensief</option>
            <option value="2">Defensief</option>
            <option value="3">Gematigd defensief</option>
            <option value="4">Neutraal</option>
            <option value="5">Offensief</option>
            <option value="6">Zeer offensief</option>
            <option value="7">Duurzaam defensief</option>
            <option value="11">Duurzaam gematigd defensief</option>
            <option value="8">Duurzaam neutraal</option>
            <option value="9">Duurzaam offensief</option>
            <option value="10">Duurzaam zeer offensief</option>
          </select>
        </span>
        <form-field-errors :errors="form.errors" field="plan_id"></form-field-errors>
      </div>

      <div class="field">
        <label class="label">Bedrag</label>

        <div class="field has-addons">
          <p class="control">
            <button type="button" class="button is-disabled">&euro;</button>
          </p>
          <p class="control">
            <money
              name="amount"
              class="input"
              type="text"
              placeholder="Bedrag"
              v-model="form.amount"
            ></money>
          </p>
        </div>
        <form-field-errors :errors="form.errors" field="amount"></form-field-errors>
      </div>

      <div class="field">
        <label class="label">Beleggingsdoel</label>
        <span class="select">
          <select name="objective" v-model="form.objective">
            <option value disabled>Maak een keuze</option>
            <option value="retention">Instandhouding vermogen</option>
            <option value="grow">Vermogensgroei</option>
            <option value="not_necessary_addition">Niet noodzakelijke aanvulling op het inkomen</option>
            <option value="necessary_addition">Noodzakelijke aanvulling op het inkomen</option>
            <option value="repayment_mortgage">Aflossing hypotheek</option>
            <option value="children_study">Financiering studie</option>
            <option value="specific_spend_feature">Specifieke uitgave in de toekomst</option>
            <option value="other">Anders</option>
          </select>
        </span>
        <form-field-errors :errors="form.errors" field="objective"></form-field-errors>
      </div>

      <div class="field">
        <label class="label">Begindatum</label>
        <div class="columns">
          <div class="column is-narrow">
            <b-datepicker name="start_date" v-model="form.start_date"></b-datepicker>
          </div>
        </div>
      </div>

      <div class="field">
        <div class="columns">
          <div class="column is-narrow">
            <label class="label">Einddatum</label>
            <b-datepicker name="end_date" v-model="form.end_date" :min-date="new Date()"></b-datepicker>
          </div>
          <div class="column is-narrow">
            <label class="label">Of calculeer a.d.h.v horizon</label>
            <div class="field has-addons">
              <p class="control">
                <input
                  name="horizon"
                  class="input"
                  type="text"
                  placeholder="Horizon"
                  v-model="horizon"
                >
              </p>
              <p class="control">
                <button type="button" class="button is-disabled">jaar</button>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label">Gewenst rendement over looptijd</label>
        <p>Excl. stortingen / onttrekkingen</p>

        <div class="field has-addons">
          <p class="control">
            <button
              class="input"
              type="button"
              placeholder="Gewenst rendement over looptijd"
            >{{ form.rate_of_return }}</button>
          </p>
          <p class="control">
            <button type="button" class="button">%</button>
          </p>
        </div>
        <form-field-errors :errors="form.errors" field="rate_of_return"></form-field-errors>
      </div>

      <div class="field">
        <label class="label">Doelbedrag</label>
        <div class="field has-addons">
          <p class="control">
            <button type="button" class="button">€</button>
          </p>
          <p class="control">
            <button
              class="input"
              type="button"
              placeholder="Doelbedrag"
            >{{ form.target_amount | currency }}</button>
          </p>
        </div>
      </div>

      <div class="field">
        <label class="label">Beschrijving grafiek (optioneel)</label>
        <input
          type="text"
          name="form.description_short"
          class="input"
          v-model="form.description_short"
          placeholder="Korte beschrijving voor de gele stip in de grafiek"
        >
        <form-field-errors :errors="form.errors" field="description_short"></form-field-errors>
      </div>

      <div class="field">
        <label class="label">Beschrijving (beleggings)plan (optioneel)</label>
        <div class="field">
          <textarea
            class="textarea"
            placeholder="Geef een optionele toelichting op"
            v-model="form.description"
          ></textarea>
          <form-field-errors :errors="form.errors" field="description"></form-field-errors>
        </div>
      </div>

      <div class="field">
        <label class="checkbox">
          <input type="checkbox" v-model="form.show_event_in_graph">
          Toon als gebeurtenis in de grafiek voor rendementsontwikkeling
        </label>
      </div>

      <br>
      <button
        type="submit"
        class="button is-primary"
        :class="{'is-loading': form.loading}"
      >Plan aanmaken</button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      horizon: null,
      based_on: "",
      form: new Laravel.Form({
        based_on: "",
        plan_id: "",
        amount: "",
        objective: "",
        start_date: new Date(),
        end_date: new Date(),
        rate_of_return: "",
        target_amount: "",
        description: "",
        force: false,
        show_event_in_graph: true
      })
    };
  },

  computed: {
    data() {
      return this.$parent.data;
    }
  },

  methods: {
    doProposal() {
      this.form
        .post(`/api/admin/customers/${this.$parent.data.profile.id}/proposal`)
        .then(data => {
          this.$parent.data.proposals.push(data);
          this.$buefy.snackbar.open(`Voorstel succesvol verstuurd.`);
        })
        .catch(data => {
          this.$buefy.snackbar.open(`Kon voorstel niet versturen.`);
        });
    },

    calculateRateOfReturn() {
      if (this.form.end_date && this.form.plan_id && this.form.amount) {
        let years =
          moment(this.form.end_date).diff(
            this.form.start_date ? this.form.start_date : new Date()
          ) / 31556952000;

        let result = 0;
        switch (parseInt(this.form.plan_id)) {
          case 1:
            result = this.FV(3 / 100, years, 0, this.form.amount * -1, 0);
            break;
          case 2:
            result = this.FV(4 / 100, years, 0, this.form.amount * -1, 0);
            break;
          case 3:
            result = this.FV(5 / 100, years, 0, this.form.amount * -1, 0);
            break;
          case 4:
            result = this.FV(6 / 100, years, 0, this.form.amount * -1, 0);
            break;
          case 5:
            result = this.FV(7 / 100, years, 0, this.form.amount * -1, 0);
            break;
          case 6:
            result = this.FV(8 / 100, years, 0, this.form.amount * -1, 0);
            break;
          case 7:
            result = this.FV(4 / 100, years, 0, this.form.amount * -1, 0);
            break;
          case 8:
            result = this.FV(6 / 100, years, 0, this.form.amount * -1, 0);
            break;
          case 9:
            result = this.FV(7 / 100, years, 0, this.form.amount * -1, 0);
            break;
          case 10:
            result = this.FV(7 / 100, years, 0, this.form.amount * -1, 0);
            break;
          case 11:
            result = this.FV(4 / 100, years, 0, this.form.amount * -1, 0);
            break;
        }

        this.form.target_amount = result;
        this.form.rate_of_return = (
          ((result - this.form.amount) / this.form.amount) *
          100
        ).toFixed(2);
      }
    },

    FV(rate, nper, pmt, pv, type) {
      var pow = Math.pow(1 + rate, nper),
        fv;
      if (rate) {
        fv = (pmt * (1 + rate * type) * (1 - pow)) / rate - pv * pow;
      } else {
        fv = -1 * (pv + pmt * nper);
      }
      return fv.toFixed(2);
    }
  },

  watch: {
    form: {
      deep: true,
      handler: function() {
        this.calculateRateOfReturn();
      }
    },

    based_on: function(value, oldVal) {
      if (value) {
        let plan = {
          ...this.data.proposals.filter(({ id }) => value === id)[0]
        };
        delete plan.id;

        this.form = new Laravel.Form({
          ...plan,
          based_on: value
        });
      }
    },
    horizon: function(value) {
      if (value) {
        let currentDate;
        if (this.form.start_date) {
          currentDate = this.form.start_date
        } else {
          currentDate = new Date();
        }

        this.form.end_date = new Date(
          currentDate.getFullYear() + parseInt(value),
          currentDate.getMonth(),
          currentDate.getDate()
        );
      }
    }
  }
};
</script>
