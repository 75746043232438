export default function (value, n = 2, x = 3, s = '.', c = ',') {
  if(!value) {
      value = 0;
  }

  value = parseFloat(value);

  var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
      num = value.toFixed(Math.max(0, ~~n));

  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};
