<template>
  <div>
    <nav id="navigation">
      <div class="container">
        <div>
          <ul>
            <li>
              <a
                @click="setView('efficiency')"
                :class="{ 'is-active': view === 'efficiency' }"
                >Rendementsontwikkeling</a
              >
            </li>
            <li>
              <a
                @click="setView('portfolio')"
                :class="{ 'is-active': view === 'portfolio' }"
                >Portefeuille</a
              >
            </li>
            <li>
              <a
                @click="setView('profile')"
                :class="{ 'is-active': view === 'profile' }"
                >NAW-gegevens</a
              >
            </li>
            <li style="float: right"><a @click="close()">Sluiten</a></li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="block">
      <portfolio v-if="view === 'portfolio'"></portfolio>
      <profile v-if="view === 'profile'"></profile>
      <efficiency v-if="view === 'efficiency'"></efficiency>
    </div>
  </div>
</template>

<script>
import Portfolio from "./components/Portfolio.vue";
import Profile from "./components/Profile.vue";
import Efficiency from "./components/Efficiency.vue";

export default {
  props: ["customer"],

  components: {
    portfolio: Portfolio,
    profile: Profile,
    efficiency: Efficiency,
  },

  mounted() {
    this.get();
  },

  data() {
    return {
      view: "efficiency",
      data: {},
    };
  },

  methods: {
    get() {
      axios.get(`/api/advisor/${this.customer.id}`).then((response) => {
        this.data = response.data;
      }).catch(()=> this.$buefy.snackbar("Kon gebruiker niet laden."))
    },

    close() {
      this.$parent.close();
    },

    setView(view) {
      this.view = view;
    },
  },
};
</script>
