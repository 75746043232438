<template>
  <div>
    <div class="submenu">
      <div class="container">
        <div class="tabs is-top">
          <ul>
            <router-link tag="li" :to="{ name: 'admin/customer', params: { id } }" exact>
              <a>Profiel</a>
            </router-link>

            <router-link
              tag="li"
              :to="{ name: 'admin/customer/portfolio', params: { id } }"
              v-if="data.portfolio"
            >
              <a>Portefeuille</a>
            </router-link>

            <router-link tag="li" :to="{ name: 'admin/customer/prospect', params: { id } }">
              <a>Inventarisatieformulier</a>
            </router-link>

            <router-link tag="li" :to="{ name: 'admin/customer/plan', params: { id } }">
              <a>(Beleggings)Plan</a>
            </router-link>

            <router-link tag="li" :to="{ name: 'admin/customer/notes', params: { id } }">
              <a>Notities</a>
            </router-link>

            <router-link tag="li" :to="{ name: 'admin/customer/notifications', params: { id } }">
              <a>Berichten</a>
            </router-link>

            <router-link tag="li" :to="{ name: 'admin/customer/tasks', params: { id } }">
              <a>Taken</a>
            </router-link>

            <router-link tag="li" :to="{ name: 'admin/customer/quarter-reports', params: { id } }">
              <a>Kwartaalrapportages</a>
            </router-link>

            <router-link tag="li" :to="{ name: 'admin/customer/advisors', params: { id } }">
              <a>Adviseurs</a>
            </router-link>

            <router-link tag="li" :to="{ name: 'admin/customer/account', params: { id } }">
              <a>Accountinstellingen</a>
            </router-link>
          </ul>
        </div>
      </div>
    </div>

    <section class="section light no-padding-bottom">
      <div class="container">
        <h3 class="title is-3">
          {{ data.profile.salutation }} {{ data.profile.last_name }}
          <span
            class="tag is-danger"
            v-if="data && Object.keys(data.profile).length && data.profile.role == 'prospect'"
          >Prospect</span>
          <span class="tag is-danger" v-if="data.profile.locked">Account geblokkeerd</span>
          <span v-if="data.profile.role_id === 3">
            <span
                class="tag"
                :class="{'is-success': data.next_review < 999, 'is-warning': data.next_review < 60, 'is-danger': data.next_review < 20}"
                v-if="data.next_review"
            >KYC-review in {{ data.next_review }} dagen</span>
            <span class="tag is-light" v-if="!data.next_review">Nog niet gereviewd</span>
          </span>
        </h3>
        <p
          class="sub-title"
          v-if="data.profile.securities_account_account_name || data.profile.portfolio_number"
        >
          <span
            v-if="data.profile && data.profile.securities_account_account_name"
          >{{ data.profile.securities_account_account_name }}</span>
          <span
            v-if="data.profile && data.profile.securities_account_account_name && data.profile.portfolio_number"
          >&nbsp;&bull;&nbsp;</span>
          <span
            v-if="data.profile && data.profile.portfolio_number"
          >{{ data.profile.portfolio_number }}</span>
        </p>
      </div>
    </section>

    <section class="section content no-header">
      <div class="container">
        <router-view></router-view>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        profile: {},
        files: [],
        proposals: [],
        prospects: [],
        receivedNotifications: [],
        sentNotifications: [],
        upcomingTasks: [],
        notes: [],
        chart: {}
      },
      collegues: []
    };
  },

  beforeMount() {
    this.load();

    bus.$on("reloadCustomer", () => {
      this.load();
    });
  },

  methods: {
    load() {
      this.getCollegues();
      axios
        .get(`api/admin/customers/${this.$route.params.id}`)
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {
          window.alert("Kon klant niet laden.");
        });
    },

    getCollegues() {
      axios
        .get(`/api/admin/collegues`)
        .then(({ data }) => {
          this.collegues = data;
        })
        .catch(() => {
          this.$buefy.snackbar.open("Kon collega's niet laden.");
        });
    }
  },

  computed: {
    id() {
      return this.$route.params.id;
    }
  }
};
</script>

<style scoped>
.sub-title {
  margin-top: -15px;
}
</style>
