<template>
  <div>
    <h4 class="title is-4">Rendement per jaar</h4>
    <table class="table is-striped">
      <thead>
        <th width="50%">Jaar</th>
        <th>Rendement</th>
      </thead>
      <tbody>
        <tr
          v-for="(efficiency, year) in data.efficiency_per_year"
          v-bind:key="year"
        >
          <template v-if="year != 'cumulative'">
            <td>
              {{ year }}
              <span v-if="year == new Date().getFullYear()">(YTD)</span>
            </td>
            <td>{{ efficiency | currency }}%</td>
          </template>
        </tr>
      </tbody>
    </table>

    <div v-if="data && data.efficiency_per_year">
      <hr />

      <table class="table is-striped">
        <tbody>
          <tr>
            <td width="50%">Cumulatief</td>
            <td>{{ data.efficiency_per_year.cumulative | currency }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
};
</script>
