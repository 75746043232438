<template>
  <div class="columns">
    <div class="column is-3">
      <div class="block">
        <aside class="menu">
          <p class="menu-label">Berichten</p>
          <ul class="menu-list">
            <li>
              <router-link
                :to="{ name: 'admin/customer/notifications/compose' }"
                exact
              >
                Bericht opstellen
              </router-link>
            </li>
          </ul>
          <p class="menu-label">Mappen</p>
          <ul class="menu-list">
            <li>
              <router-link :to="{ name: 'admin/customer/notifications' }" exact>
                Inkomend
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'admin/customer/notifications/sent' }"
                exact
              >
                Verzonden
              </router-link>
            </li>
          </ul>
        </aside>
      </div>
    </div>

    <div class="column is-9">
      <div class="block">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationModal from "../components/NotificationModal.vue";

export default {
  computed: {
    data() {
      return this.$parent.data;
    },

    user() {
      return this.$root.user;
    },
  },

  methods: {
    openModal(notification) {
        this.$buefy.modal.open({
          parent: this,
          component: NotificationModal,
          hasModalCard: true,
          props: { notification },
        });
    },
  },
};
</script>
