<template>

    <div>
        <div class="block">

            <table class="table is-striped">
                <thead>
                    <tr>
                        <th width="20%">Jaar</th>
                        <th width="20%">Kwartaal</th>
                        <th width="40%">Kwartaalrapportage</th>
                        <!-- <th width="33%">Nota</th> -->
                        <th width="20%">Verwijderen</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="row in data.quarter_reports" v-bind:key="row.id">
                        <td style="vertical-align: middle">{{ row.year }}</td>
                        <td style="vertical-align: middle">
                            <span v-if="row.quarter === 0">
                              Jaarrapportage
                            </span>
                            <span v-else>
                                {{ row.quarter }}<sup>e</sup>
                                kwartaal
                            </span>
                        <td>
                            <button class="button is-primary" v-if="row.quarterReportDownloadURL" @click="download(row.quarterReportDownloadURL)">Download rapportage</button>
                            <span v-else>Niet beschikbaar</span>

                            <a v-if="row.link_url && row.link_title" :href="row.link_url" class="button is-primary is-outlined" target="_blank" rel="noopener noreferrer">{{row.link_title}}</a>
                        </td>
                        <td>
                            <button class="button is-danger" @click="remove(row.id)">Verwijderen</button>
                        </td>
                    </tr>
                    <tr v-if="data && data.quarter_reports && data.quarter_reports.length === 0">
                        <td colspan="4" class="has-text-centered">Er staan nog geen kwartaalrapportages klaar.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</template>

<script>

    export default {

        computed: {
            token() {
                return localStorage.getItem('token')
            },

            data() {
                return this.$parent.data;
            },
        },

        methods: {
            download(url) {
                window.open(`${url}?token=${this.token}`)
            },

            remove(id) {
                this.$buefy.dialog.confirm({
                    message: 'Weet u zeker dat u deze kwartaalrapportage wilt verwijderen??',
                    cancelText: 'Nee',
                    confirmText: 'Ja, verwijder rapportage',
                    onConfirm: () => {
                        axios.delete(`/api/admin/quarter-reports/${id}`)
                            .then(({ data }) => {
                                this.$buefy.snackbar.open(`Rapportage succesvol verwijderd.`);
                                this.$parent.data.quarter_reports = data;
                            })
                            .catch(data => {
                                this.$buefy.snackbar.open(`Kon rapportage niet verwijderen.`)
                            })
                    }
                });
            }
        }

    }

</script>
