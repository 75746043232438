<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <h4 class="title is-4">Bezittingen</h4>

        <div class="field">
          <label class="label">Beleggingen</label>
          <p
            v-if="Object.keys(data).length > 0"
          >&euro; {{ data.assets.investments }}</p>
        </div>

        <div class="field">
          <label class="label">Onroerend goed</label>
          <p
            v-if="Object.keys(data).length > 0"
          >&euro; {{ data.assets.property }}</p>
        </div>

        <div class="field">
          <label class="label">Uitgesteld vermogen (lijfrentes, koopsompolissen etc.)</label>
          <p
            v-if="Object.keys(data).length > 0"
          >&euro; {{ data.assets.delayed_assets }}</p>
        </div>

        <div class="field">
          <label class="label">Liquiditeiten</label>
          <p
            v-if="Object.keys(data).length > 0"
          >&euro; {{ data.assets.liquidity }}</p>
        </div>

        <div class="field">
          <label class="label">Overige bezittingen</label>
          <p
            v-if="Object.keys(data).length > 0"
          >&euro; {{ data.assets.other_assets }}</p>
        </div>
      </div>

      <div class="column is-6">
        <h4 class="title is-4">Schulden</h4>

        <div class="field">
          <label class="label">Hypothecaire schuld</label>
          <p
            v-if="Object.keys(data).length > 0"
          >&euro; {{ data.debts.mortgage }}</p>
        </div>

        <div class="field">
          <label
            class="label"
          >Pensioenverplichting (indien van toepassing bij een zakelijke rekening)'</label>
          <p
            v-if="Object.keys(data).length > 0"
          >&euro; {{ data.debts.retirement }}</p>
        </div>

        <div class="field">
          <label class="label">Overige schulden</label>
          <p
            v-if="Object.keys(data).length > 0"
          >&euro; {{ data.debts.other_debts }}</p>
        </div>

        <div class="field">
          <label class="label">Overige verplichtingen</label>
          <p
            v-if="Object.keys(data).length > 0"
          >&euro; {{ data.debts.other_obligations }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"]
};
</script>
