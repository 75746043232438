<script src="https://jquery.com/jquery.js">
</script>
import jquery as $ from "jquery";

<style scoped>
.tag {
  margin-top: 8px;
  margin-left: 4px;
}
</style>

<template>
  <div>
    <div class="columns">
      <div class="column">
        <div class="block">
          <h4 class="title is-4">Accountinstellingen</h4>

          <div class="field">
            <label class="label">Account blokkeren / deblokkeren</label>
            <button
              class="button is-danger"
              @click="lockAccount()"
              v-if="!data.profile.locked"
              :class="{ 'is-loading': form.loading }"
            >
              Blokkeren
            </button>
            <button
              class="button is-primary"
              @click="unlockAccount()"
              v-if="data.profile.locked"
              :class="{ 'is-loading': form.loading }"
            >
              Deblokkeren
            </button>
          </div>

          <div class="field">
            <label class="label">Account verwijderen</label>
            <button class="button is-danger" @click="deleteAccount()">
              Account verwijderen
            </button>
          </div>

          <div class="field">
            <label class="label">Tweefactorauthenticatie uitschakelen</label>
            <button class="button is-danger" @click="disableTwoFactor()">
              Tweefactorauthenticatie uitschakelen
            </button>
          </div>

          <br />
          <h4 class="title is-4">Handmatige mails & uitnodigingen</h4>

          <div class="field">
            <label class="label">Verstuur uitnodiging voor MyBeSmart</label>
            <button
              class="button is-danger"
              dusk="invite"
              @click="inviteUser()"
            >
              Verstuur
            </button>
            <span
              v-if="mailActivity['App\\Events\\InvitationMailSent']"
              class="tag is-normal"
            >
              {{ parseDate(mailActivity["App\\Events\\InvitationMailSent"]) }}
            </span>
            <p v-if="data.profile.role_id === 4">
              <em>
                Als je de inventarisatie via Blanco wil laten verlopen, klik dan
                <strong>NIET</strong> op deze knop. Zet de status van deze gebruiker dan op
                'Klant' en verstuur dan een 'Welkom bij BeSmart' mail.
              </em>
            </p>
          </div>

          <div class="field">
            <label class="label">Verstuur 'Welkom bij BeSmart' mail.</label>
            <button
              class="button is-danger"
              dusk="welcome"
              @click="becameCustomer()"
            >
              Verstuur
            </button>
            <span
              v-if="mailActivity['App\\Events\\WelcomeMailSent']"
              class="tag is-normal"
            >
              {{ parseDate(mailActivity["App\\Events\\WelcomeMailSent"]) }}
            </span>
          </div>

          <div class="field">
            <label class="label">Verstuur 'Wachtwoord resetten' mail.</label>
            <button
              class="button is-danger"
              dusk="welcome"
              @click="resetPassword()"
            >
              Verstuur
            </button>
          </div>

          <br />
          <h4 class="title is-4">Onboarding via Fillout</h4>

          <div class="field">
            <label class="label">Fillout onboarding versturen</label>
            <button
                class="button is-danger"
                dusk="welcome"
                @click="filloutOnboarding()"
            >
              Verstuur
            </button>
            <span
                v-if="mailActivity['App\\Events\\FilloutMailSent']"
                class="tag is-normal"
            >
              {{
                parseDate(mailActivity["App\\Events\\FilloutMailSent"])
              }}
            </span>
          </div>

          <br />
          <h4 class="title is-4">Blanco onboarding</h4>

          <div class="field">
            <label class="label">Privérekening (enkele naam)</label>
            <button
              class="button is-danger"
              dusk="welcome"
              @click="
                blancoOnboarding('individual', 'Privérekening (enkele naam)')
              "
            >
              Verstuur
            </button>
            <span
              v-if="mailActivity['App\\Events\\BlancoIndividualMailSent']"
              class="tag is-normal"
            >
              {{
                parseDate(mailActivity["App\\Events\\BlancoIndividualMailSent"])
              }}
            </span>
          </div>

          <div class="field">
            <label class="label">Privérekening (en/of)</label>
            <button
              class="button is-danger"
              dusk="welcome"
              @click="blancoOnboarding('joint', 'Privérekening (en/of)')"
            >
              Verstuur
            </button>
            <span
              v-if="mailActivity['App\\Events\\BlancoJointMailSent']"
              class="tag is-normal"
            >
              {{ parseDate(mailActivity["App\\Events\\BlancoJointMailSent"]) }}
            </span>
          </div>

          <div class="field">
            <label class="label">BV</label>
            <button
              class="button is-danger"
              dusk="welcome"
              @click="blancoOnboarding('business', 'BV')"
            >
              Verstuur
            </button>
            <span
              v-if="mailActivity['App\\Events\\BlancoBusinessMailSent']"
              class="tag is-normal"
            >
              {{
                parseDate(mailActivity["App\\Events\\BlancoBusinessMailSent"])
              }}
            </span>
          </div>

          <div class="field">
            <label class="label">Pensioenrekening</label>
            <button
              class="button is-danger"
              dusk="welcome"
              @click="blancoOnboarding('retirement', 'Pensioenrekening')"
            >
              Verstuur
            </button>
            <span
              v-if="mailActivity['App\\Events\\BlancoRetirementMailSent']"
              class="tag is-normal"
            >
              {{
                parseDate(mailActivity["App\\Events\\BlancoRetirementMailSent"])
              }}
            </span>
          </div>

          <div class="field">
            <label class="label">Kindrekening</label>
            <button
              class="button is-danger"
              dusk="welcome"
              @click="blancoOnboarding('child', 'Kindrekening')"
            >
              Verstuur
            </button>
            <span
              v-if="mailActivity['App\\Events\\BlancoChildMailSent']"
              class="tag is-normal"
            >
              {{ parseDate(mailActivity["App\\Events\\BlancoChildMailSent"]) }}
            </span>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="block">
          <h4 class="title is-4">Activiteitenlogboek</h4>

          <table class="table">
            <thead>
              <tr>
                <th>Datum</th>
                <th>Activiteit</th>
              </tr>
            </thead>

            <tbody>
              <tr v-if="!activities.current_page">
                <td colspan="2">
                  <center>Activiteiten laden...</center>
                </td>
              </tr>
              <tr v-if="activities.current_page && !activities.data.length">
                <td colspan="2">
                  <center>Deze gebruiker heeft nog geen activiteiten</center>
                </td>
              </tr>
              <tr v-for="row in activities.data" v-bind:key="row.id">
                <td>{{ row.created_at | formatDate("DD-MM-Y H:m") }}</td>
                <td>
                  <span v-if="row.type === 'App\\Events\\LoggedIn'"
                    >Succesvolle inlogpoging</span
                  >
                  <span v-if="row.type === 'App\\Events\\FilloutMailSent'"
                    >Fillout onboarding verzonden</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <div class="level">
            <div class="level-left">
              <div class="level-item">
                <button
                  class="button"
                  @click="getActivities(activities.current_page - 1)"
                  :disabled="!activities.prev_page_url"
                >
                  Vorige pagina
                </button>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <button
                  class="button"
                  @click="getActivities(activities.current_page + 1)"
                  :disabled="!activities.next_page_url"
                >
                  Volgende pagina
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: new Laravel.Form({
        locked: this.$parent.data.profile.locked,
      }),
      activities: {
        data: [],
      },
      mailActivity: [],
    };
  },

  computed: {
    data() {
      return this.$parent.data;
    },
  },

  mounted() {
    this.getActivities();
    this.getMailActivity();
  },

  methods: {
    parseDate(date) {
      return moment(date).locale("nl").format("D MMM YYYY [om] HH:mm");
    },

    lockAccount() {
      this.form.locked = true;
      this.submitForm();
    },

    unlockAccount() {
      this.form.locked = false;
      this.submitForm();
    },

    submitForm() {
      this.form
        .put(
          `/api/admin/customers/${this.$parent.data.profile.id}/manageAccess`
        )
        .then((data) => {
          this.$parent.data.profile = data;
        });
    },

    deleteAccount() {
      this.$buefy.dialog.confirm({
        message: "Weet u zeker dat u deze gebruiker wil verwijderen?",
        cancelText: "Nee",
        confirmText: "Ja, verwijder account",
        onConfirm: () => {
          axios
            .delete(`/api/admin/customers/${this.$parent.data.profile.id}`)
            .then((data) => {
              this.$buefy.snackbar.open(`Gebruiker succesvol verwijderd.`);
              this.$router.replace({ name: "admin/customers" });
            })
            .catch((data) => {
              this.$buefy.snackbar.open(`Kon gebruiker niet verwijderen.`);
            });
        },
      });
    },

    disableTwoFactor() {
      this.$buefy.dialog.confirm({
        message:
          "Weet u zeker dat u te maken heeft met deze klant? Oplichters kunnen zich voordoen als een klant om zo toegang te verschaffen tot een account. Stel desnoods een aantal verificatievragen als je niet 100% zeker bent.",
        cancelText: "Nee",
        confirmText: "Ja, schakel tweetrapsauthenticatie uit",
        onConfirm: () => {
          axios
            .put(
              `/api/admin/customers/${this.$parent.data.profile.id}/disable-two-factor`
            )
            .then((data) => {
              this.$buefy.snackbar.open(`Tweetrapsauthenticatie uitgeschakeld`);
            })
            .catch((data) => {
              this.$buefy.snackbar.open(
                `Kon tweetrapsauthenticatie niet uitschakelen`
              );
            });
        },
      });
    },

    inviteUser() {
      this.$buefy.dialog.confirm({
        message: "Weet u zeker dat u de uitnodiging wilt versturen?",
        cancelText: "Nee",
        confirmText: "Ja, versturen",
        onConfirm: () => {
          axios
            .put(`/api/admin/customers/${this.$parent.data.profile.id}/invite`)
            .then((data) => {
              this.$buefy.snackbar.open(`Gebruiker succesvol uitgenodigd.`);
            })
            .catch((data) => {
              this.$buefy.snackbar.open(`Kon gebruiker niet uitnodigen.`);
            });
        },
      });
    },

    becameCustomer() {
      this.$buefy.dialog.confirm({
        message:
          "Weet u zeker dat u de mail 'Welkom bij BeSmart' wilt versturen?",
        cancelText: "Nee",
        confirmText: "Ja, versturen",
        onConfirm: () => {
          axios
            .put(
              `/api/admin/customers/${this.$parent.data.profile.id}/becameCustomerMail`
            )
            .then((data) => {
              this.$buefy.snackbar.open(`Mail succesvol verstuurd.`);
            })
            .catch((data) => {
              this.$buefy.snackbar.open(`Kon mail niet versturen.`);
            });
        },
      });
    },

    resetPassword() {
      this.$buefy.dialog.confirm({
        message:
          "Weet u zeker dat u een 'Wachtwoord resetten' mail wilt versturen?",
        cancelText: "Nee",
        confirmText: "Ja, versturen",
        onConfirm: () => {
          axios
            .put(
              `/api/admin/customers/${this.$parent.data.profile.id}/resetPassword`
            )
            .then((data) => {
              this.$buefy.snackbar.open(`Mail succesvol verstuurd.`);
            })
            .catch((data) => {
              this.$buefy.snackbar.open(`Kon mail niet versturen.`);
            });
        },
      });
    },

    getMailActivity() {
      if (this.$parent.data.profile.id) {
        axios
          .get(
            `/api/admin/customers/${this.$parent.data.profile.id}/activities/mails`
          )
          .then(({ data }) => {
            this.mailActivity = data;
          })
          .catch(() => {
            this.$buefy.snackbar.open("Kon activiteiten niet laden.");
          });
      }
    },

    getActivities(page = 1) {
      if (this.$parent.data.profile.id) {
        axios
          .get(
            `/api/admin/customers/${this.$parent.data.profile.id}/activities?page=${page}`
          )
          .then(({ data }) => {
            this.activities = data;
          })
          .catch(() => {
            this.$buefy.snackbar.open("Kon activiteiten niet laden.");
          });
      }
    },

    blancoOnboarding(type, readableType) {
      this.$buefy.dialog.confirm({
        message: `Weet u zeker dat u de Blanco onboarding van het type '${readableType}' wilt verzenden?`,
        cancelText: "Nee",
        confirmText: "Ja, verstuur mail",
        onConfirm: () => {
          axios
            .post(
              `/api/admin/customers/${this.$parent.data.profile.id}/blanco-onboarding/${type}`
            )
            .then(() => {
              this.$buefy.snackbar.open(`Mail succesvol verstuurd.`);
            })
            .catch((data) => {
              this.$buefy.snackbar.open(`Kon mail niet versturen.`);
            });
        },
      });
    },

    filloutOnboarding() {
      this.$buefy.dialog.confirm({
        message: `Weet u zeker dat u de Fillout onboarding wilt verzenden?`,
        cancelText: "Nee",
        confirmText: "Ja, verstuur mail",
        onConfirm: () => {
          axios
              .post(
                  `/api/admin/customers/${this.$parent.data.profile.id}/fillout-onboarding`
              )
              .then(() => {
                this.$buefy.snackbar.open(`Mail succesvol verstuurd.`);
              })
              .catch((data) => {
                this.$buefy.snackbar.open(`Kon mail niet versturen.`);
              });
        },
      });
    },
  },

  watch: {
    "$parent.data.profile": function (newVal, oldVal) {
      if (!oldVal.profile) {
        this.getActivities(1);
        this.getMailActivity();
      }
    },
  },
};
</script>
