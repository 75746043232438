<template>
  <div>
    <h4 class="title is-4">Documenten</h4>

    <table class="table">
      <thead>
        <th>Titel document</th>
        <th>Uploaddatum</th>
        <th>Download</th>
      </thead>

      <tbody>
        <tr v-for="file in files">
          <td>{{ file.name }}</td>
          <td>{{ file.created_at | formatDate }}</td>
          <td>
            <span class="button is-primary" @click="downloadFile(file.downloadURL)">Download</span>
          </td>
        </tr>
      </tbody>
    </table>

    <button
      class="button is-primary"
      @click="toggleUploadForm()"
      v-show="!showUploadForm"
    >Nieuw bestand uploaden</button>

    <div v-if="showUploadForm">
      <h4 class="title is-4">Bestand uploaden</h4>
      <form-errors :errors="uploadForm.errors"></form-errors>
      <form @submit.prevent="submitForm()">
        <div class="field">
          <label class="label">Titel document</label>
          <input
            name="name"
            type="text"
            class="input"
            placeholder="Titel document"
            v-model="uploadForm.name"
          >
        </div>

        <div class="field">
          <label class="label">Verloopdatum (optioneel)</label>
          <b-datepicker v-model="uploadForm.expires_at"></b-datepicker>
        </div>

        <div class="field">
          <label class="label">Bestand</label>
          <!-- <input name="file" id="file" type="file"> -->
          <div class="field file has-name">
            <label class="file-label">
              <input class="file-input" type="file" name="file" @change="onFileChange($event)">
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fa fa-upload"></i>
                </span>
                <span class="file-label">Kies bestand</span>
              </span>
              <span
                class="file-name"
              >{{ uploadForm.file ? uploadForm.file.name : 'Geen bestand geselecteerd' }}</span>
            </label>
          </div>
          <p
            class="content is-small"
          >Toegestaande bestandstypes: jpeg, png, jpg, doc, docx, pdf. Maximale grootte: 10MB</p>
        </div>

        <div class="field">
          <label class="label">Watermerk toevoegen</label>
          <label class="checkbox">
            <input type="checkbox" v-model="uploadForm.watermark">
            Voeg watermerk toe aan de afbeelding.
          </label>
          <br>
          <small>Deze functionaliteit werkt alleen bij afbeeldingen (jpeg, jpg, png). Gebruik deze functie als u bijvoorbeeld een identificatiedocument upload.</small>
        </div>

        <div class="field">
          <button
            type="submit"
            class="button is-primary"
            :class="{'is-loading': uploadForm.loading}"
          >Uploaden</button>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      showUploadForm: false,
      uploadForm: new Laravel.Form({
        user_id: "",
        name: "",
        expires_at: new Date(),
        watermark: false,
        file: null
      }),
      files: []
    };
  },

  beforeMount() {
    axios.get("/api/documents").then(({ data }) => {
      this.files = data;
    });
  },

  computed: {
    token() {
      return localStorage.getItem("token");
    },

    user() {
      return this.$root.user;
    }
  },

  methods: {
    toggleUploadForm() {
      return (this.showUploadForm = !this.showUploadForm);
    },

    onFileChange(event) {
      this.uploadForm.file = event.target.files[0];
    },

    submitForm() {
      this.uploadForm.user_id = this.user.id;

      this.uploadForm
        .post("/api/documents", true, true)
        .then(data => {
          this.files.push(data);
          this.$buefy.snackbar.open('Bestand succesvol geüpload');
        })
        .catch(() => {
          this.$buefy.snackbar.open("Kon bestand niet uploaden");
        });
    },

    downloadFile(url) {
      window.open(`${url}?token=${this.token}`);
    }
  }
};
</script>
