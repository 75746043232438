<template>
  <div>
    <div class="columns">
      <div class="column is-3">
        <div class="block">
          <efficiency-per-year :data="data"></efficiency-per-year>
          <hr />
          <p class="control">
            <label class="checkbox">
              <input
                type="checkbox"
                v-model="data.profile.deviating_efficiency_calculation"
                @click="toggleDeviatingEfficiencyCalculation()"
              />
              Bereken rendement volgens eigen formule
            </label>
          </p>
        </div>
      </div>

      <div class="column is-9">
        <div class="block" v-if="data.chart">
          <div class="level">
            <div class="level-left">
              <div class="level-item">
                <h4 class="title is-4">Rendementsontwikkeling</h4>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <router-link
                  class="button"
                  :to="{
                    name: 'admin/customer/historical',
                    params: { customer: data.profile.id },
                  }"
                >
                  <i class="fa fa-pencil"></i>
                </router-link>
              </div>
            </div>
          </div>
          <DevelopmentChart
            :data="data.chart"
            :targetAmount="
              data.profile.current_plan
                ? data.profile.current_plan.target_amount
                : 0
            "
          ></DevelopmentChart>
        </div>
      </div>
    </div>

    <div class="block" v-if="data.portfolio">
      <div class="tags has-addons">
        <span class="tag is-medium">YTD-rendement</span>
        <span class="tag is-medium is-primary"
          >{{ data.profile.current_plan.efficiency_ytd | currency }}%</span
        >
      </div>
      <portfolio :data="data.portfolio"></portfolio>
    </div>
  </div>
</template>

<script>
import DevelopmentChart from "@customer/dashboard/DevelopmentChart.vue";
import EfficiencyPerYear from "@components/EfficiencyPerYear.vue";

export default {
  components: {
    DevelopmentChart: DevelopmentChart,
    EfficiencyPerYear: EfficiencyPerYear
  },

  computed: {
    data() {
      return this.$parent.data;
    },
  },

  methods: {
    toggleDeviatingEfficiencyCalculation() {
      axios
        .post(
          `/api/admin/customers/${this.data.profile.id}/toggle-deviating-efficiency-calculation`
        )
        .then(({ data }) => {
          this.$parent.data = data
        }).catch(() => {
          this.$buefy.snackbar.open(`Kon niet opslaan.`);
        })
    },
  },
};
</script>
