<template>

    <div>
        <section class="section no-background no-header">
            <div class="container">
                <div class="columns has-content-centered">
                    <div class="column is-8 content">
                        <div class="block">
                            <h3 class="title is-3">Inventarisatie afgerond</h3>

                            <p>Bedankt voor het vertrouwen! Binnenkort zal uw adviseur contact met u opnemen om de inventarisatie met u door te nemen. De adviseur kan eventuele vragen beantwoorden en de vervolgstappen met u doornemen. In uw persoonlijke MyBeSmart account treft u binnenkort ook de benodigde documentatie voor de verdere samenwerking.</p>

                            <hr />

                            <div class="has-text-centered">
                                <router-link tag="button" :to="{ name: 'profile'}" class="button is-primary">Ga naar profiel</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

</template>

<script>

    export default {

    }

</script>
