<template>
  <div>
    <section class="section content no-header">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-6">
            <div class="block">
              <h4 class="title is-4">Accountgegevens</h4>

              <div class="field">
                <label class="label">Voornaam</label>
                <span>{{ advisor.first_name }}</span>
              </div>

              <div class="field">
                <label class="label">Achternaam</label>
                <span>{{ advisor.last_name }}</span>
              </div>

              <div class="field">
                <label class="label">E-mailadres</label>
                <span>{{ advisor.email }}</span>
              </div>

              <div class="field">
                <label class="label">Is actief</label>
                <span
                  ><span class="tag is-success" v-if="advisor.is_activated">
                    <i class="fa fa-check is-white"></i>
                  </span>
                  <span class="tag is-danger" v-if="!advisor.is_activated">
                    <i class="fa fa-times is-white"></i> </span
                ></span>
              </div>

              <div class="field">
                <label class="label"
                  >Heeft two factor authentication ingeschakeld</label
                >
                <span
                  ><span class="tag is-success" v-if="advisor.enabled_2fa">
                    <i class="fa fa-check is-white"></i>
                  </span>
                  <span class="tag is-danger" v-if="!advisor.enabled_2fa">
                    <i class="fa fa-times is-white"></i>
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="column is-6">
            <div class="block">
              <h4 class="title is-4">Gekoppelde klanten</h4>
              <table class="table is-striped">
                <thead>
                  <th>Naam</th>
                </thead>
                <tbody>
                  <tr
                    class="has-pointer"
                    v-for="customer in advisor.customers"
                    @click="open(customer.id)"
                    v-bind:key="customer.id"
                  >
                    <td>{{ customer.fullName }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      advisor: {},
    };
  },

  mounted() {
    this.get();
  },

  methods: {
    get() {
      const id = this.$route.params.id;
      axios
        .get(`/api/admin/advisors/${id}`)
        .then(({ data }) => {
          this.advisor = data;
        })
        .catch(() => this.$buefy.snackbar.open("Kon niet laden."));
    },

    open(id) {
      this.$router.push(`/admin/customers/${id}`);
    },
  },
};
</script>
