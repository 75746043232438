<template>
  <div>
    <div class="submenu">
      <div class="container">
        <div class="tabs is-top">
          <ul>
            <li>
              <router-link :to="{ name: 'admin/customers' }">Klantenoverzicht</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'admin/quarter-reports' }">Kwartaalrapportages</router-link>
            </li>
            <li class="is-active">
              <router-link :to="{ name: 'admin/customers/contact' }">Hebben contact nodig</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <section class="section no-background no-header">
      <div class="container">
        <div class="block">
          <div class="level">
            <div class="level-left">
              <div class="level-item">
                <h4 class="title is-4">Contact</h4>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <span class="select">
                  <select v-model="filterManager">
                    <option value="all">Alle weergeven</option>
                    <option
                      v-for="manager in managers"
                      v-bind:key="manager"
                      :value="manager"
                    >{{ manager ? manager : 'Nog niet toegewezen' }}</option>
                  </select>
                </span>
              </div>
            </div>
          </div>

          <table class="table is-striped">
            <thead>
              <th width="60%">Naam</th>
              <th width="15%">Relatiebeheerder</th>
              <th width="15%">Vermogensbeheerder</th>
              <th width="10%">Laatst gecontacteerd</th>
            </thead>
            <tbody>
              <tr
                class="has-pointer"
                v-for="customer in data.contact"
                v-bind:key="customer.id"
                @click="openCustomer(customer.id)"
              >
                <td>{{ customer.fullName }}</td>
                <td>{{ customer.advisor }}</td>
                <td>{{ customer.manager }}</td>
                <td>
                  <span v-if="customer.latestContactNote">
                    {{ customer.latestContactNote.date_time | formatDate }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          <h4 class="title is-4">KYC-review</h4>

          <table class="table is-striped">
            <thead>
              <th width="60%">Naam</th>
              <th width="15%">Relatiebeheerder</th>
              <th width="15%">Vermogensbeheerder</th>
              <th width="10%">Laatst gereviewd</th>
            </thead>
            <tbody>
              <tr
                class="has-pointer"
                v-for="customer in data.review"
                v-bind:key="customer.id"
                @click="openCustomer(customer.id)"
              >
                <td>{{ customer.fullName }}</td>
                <td>{{ customer.advisor }}</td>
                <td>{{ customer.manager }}</td>
                <td>{{ customer.latestReviewNote.date_time | formatDate }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [],
      shadowData: [],
      managers: [],
      filterManager: "all"
    };
  },

  mounted() {
    axios
      .get("/api/admin/customers/contact")
      .then(({ data }) => {
        this.data = data;
        this.shadowData = {...data};

        data["contact"].forEach(row => {
          if (!this.managers.includes(row.manager)) {
            this.managers.push(row.manager);
          }
        });

        data["review"].forEach(row => {
          if (!this.managers.includes(row.manager)) {
            this.managers.push(row.manager);
          }
        });

        this.loaded = true;
      })
      .catch(() => {
        alert("Kon het overzicht niet inladen");
      });
  },

  methods: {
    openCustomer(id) {
      this.$router.push({ name: "admin/customer", params: { id: id } });
    },

    filterData(val) {
      this.data = {...this.shadowData};
      if (val !== "all") {
        const contact = [... this.shadowData["contact"]]
        const review = [... this.shadowData["review"]]

        this.data["contact"] = contact.filter(
          row => row.manager === val
        );
        this.data["review"] = review.filter(
          row => row.manager === val
        );
      }
    }
  },

  watch: {
    filterManager: function(val) {
      this.filterData(val);
    }
  }
};
</script>
