<template>
  <div>
    <header id="header">
      <div class="container">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <router-link :to="{ name: 'dashboard' }">
                <img src="img/logo.svg" width="259" height="60" alt="BeSmart" />
              </router-link>
            </div>
          </div>

          <div class="level-item">
            <div class="user-welcome has-text-right">
              <div class="information">
                <strong>Hulp nodig?</strong> Bel:
                <a href="tel:0031207608232">020 760 82 32</a>
              </div>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item has-text-right">
              <span
                class="user-welcome"
                v-if="user && Object.keys(user).length"
              >
                Welkom,
                <span v-if="accounts && accounts.length > 1">
                  &nbsp;
                  <span
                    class="select"
                    :class="{ 'is-loading': switching }"
                    style="vertical-align: middle"
                  >
                    <select
                      v-model="selected_account"
                      @change="switchAccount($event)"
                    >
                      <option
                        v-for="account in accounts"
                        v-bind:key="account.id"
                        :value="account.id"
                        selected
                      >
                        {{
                          account.securities_account_account_name
                            ? account.securities_account_account_name
                            : account.full_name
                        }}
                      </option>
                    </select>
                  </span>
                  &nbsp;&nbsp;
                </span>
                <span v-if="!accounts || accounts.length < 2">
                  <span v-if="user.securities_account_account_name">
                    <strong>{{ user.securities_account_account_name }}</strong>
                  </span>
                  <span v-if="!user.securities_account_account_name">
                    <strong>{{ user.first_name }} {{ user.last_name }}</strong>
                  </span>
                  &nbsp; &nbsp;
                </span>
                <router-link :to="{ name: 'logout' }">
                  <i class="fa fa-lock"></i> Uitloggen
                </router-link>
              </span>

              <span
                class="user-welcome"
                v-if="!user || !Object.keys(user).length"
              >
                <router-link :to="{ name: 'login' }">
                  <i class="fa fa-lock"></i> Inloggen
                </router-link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </header>

    <nav id="navigation">
      <div class="container">
        <div>
          <ul
            v-if="user && (user.role == 'prospect' || user.role == 'customer')"
          >
            <li>
              <router-link :to="{ name: 'dashboard' }" exact
                >Dashboard</router-link
              >
            </li>
            <li v-if="user.role == 'prospect'">
              <router-link :to="{ name: 'prospect' }"
                >Inventarisatieformulier</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'plan' }">(Beleggings)Plan</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'notifications' }">
                Berichten
                <span class="tag is-danger" v-if="unreadNotifications">{{
                  unreadNotifications
                }}</span>
              </router-link>
            </li>
            <li v-if="user.role == 'customer'">
              <router-link :to="{ name: 'quarter-reports' }"
                >Kwartaalrapportages</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'profile' }">Profiel</router-link>
            </li>
            <template v-if="user.role == 'customer' && user.current_plan">
              <li class="menu-right efficiency_ytd">
                Rendement {{ new Date().getFullYear() }} (YTD)
                <strong
                  >{{ user.current_plan.efficiency_ytd | currency }}%</strong
                >
              </li>
              <li class="menu-right">
                Waarde portefeuille
                <strong
                  >&euro;
                  {{ user.current_plan.portfolio_value | currency }}</strong
                >
              </li>
            </template>
          </ul>
          <ul v-if="user && Object.keys(user).length && user.role == 'manager'">
            <li>
              <router-link :to="{ name: 'admin/dashboard' }"
                >Dashboard</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'admin/customers' }"
                >Klanten</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'admin/advisors' }"
                >Adviseurs</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'admin/tasks' }">
                Taken
                <span class="tag is-danger" v-if="tasksTodayCount">{{
                  tasksTodayCount
                }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'notifications' }">
                Berichten
                <span class="tag is-danger" v-if="unreadNotifications">{{
                  unreadNotifications
                }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'admin/plans' }"
                >Modelportefeuilles</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'admin/profile' }">Profiel</router-link>
            </li>
          </ul>
          <ul v-if="user && user.role == 'advisor'">
            <li>
              <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'advisors/profile' }"
                >Profiel</router-link
              >
            </li>
          </ul>
        </div>
        <div v-if="!user || !Object.keys(user).length">
          <ul>
            <li>
              <a href="https://besmartib.nl">&#8249; Terug naar de website</a>
            </li>
            <li>
              <router-link :to="{ name: 'login' }">Inloggen</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'password-forgot' }"
                >Wachtwoord vergeten</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      switching: false,
      selected_account: null,
    };
  },

  computed: {
    unreadNotifications() {
      return this.$root.user ? this.$root.user.unreadNotificationCount : 0;
    },

    tasksTodayCount() {
      return this.$root.user ? this.$root.user.tasksTodayCount : 0;
    },

    user() {
      return this.$root.user;
    },

    accounts() {
      return this.$root.accounts;
    },
  },

  methods: {
    switchAccount(event) {
      const id = event.target.value;
      this.switching = true;

      axios
        .post("api/auth/switch-account", { id })
        .then(({ data }) => {
          this.$root.setUser(data);
          this.switching = false;
          if (this.$router.currentRoute.name !== "dashboard") {
            this.$router.push({ name: "dashboard" });
          }
          window.location.reload();
        })
        .catch((e) => {
          this.$buefy.snackbar.open("Kon niet inloggen met dit account.");
          this.switching = false;
        });
    },
  },

  watch: {
    user: function (val, oldVal) {
      if (Object.keys(val).length) {
        this.selected_account = val.id;
      } else {
        this.selected_account = null;
      }
    },
  },
};
</script>

<style scoped>
.tag {
  vertical-align: top;
}
</style>
