<style scoped>
.card-header-title {
  font-weight: 400;
}
</style>
<template>
  <div>
    <form-errors :errors="note.errors"></form-errors>
    <div class="card" v-if="note">
      <header class="card-header">
        <div class="card-header-title" style="margin-bottom: 0">
          <div class="level">
            <div class="level-item">
              <strong>{{ note.author }}</strong>
              &nbsp;op&nbsp;
              <span
                v-if="!edit"
              >{{ note.date_time | formatDate('D-M-Y HH:mm') }}</span>
            </div>
            <div class="level-item" style="max-width: 200px" v-if="edit">
              <div class="field has-addons">
                <div class="control">
                  <b-datepicker v-model="note.date_time"></b-datepicker>
                </div>
                <div class="control">
                  <b-timepicker placeholder="00:00" v-model="note.date_time"></b-timepicker>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-header-icon" aria-label="more options">
          <button class="button" @click="toggleEdit()" v-if="!edit">Bewerken</button>
          <button class="button is-primary" @click="submit()" v-if="edit">Opslaan</button>
        </div>
      </header>
      <div class="card-content">
        <p v-html="note.note_formatted" v-if="!edit"></p>
        <div v-if="edit">
          <textarea class="textarea" v-model="note.note"></textarea>
          <br>
        </div>
      </div>
      <footer class="card-footer">
        <div class="card-footer-item">
          <span v-if="!edit">
            <span v-if="note.type === 'note'">Notitie</span>
            <span v-if="note.type === 'review'">Reviewmoment</span>
            <span v-if="note.type === 'contact'">Contactmoment</span>
          </span>
          <span v-if="edit" class="select">
            <select v-model="note.type">
              <option value="contact">Contactmoment</option>
              <option value="review">Reviewmoment</option>
              <option value="note">Notitie</option>
            </select>
          </span>
        </div>
        <div class="card-footer-item" v-if="note.type == 'contact'">
          <span v-if="!edit">{{ note.contact_type === 'person' ? 'In persoon' : 'Telefonisch' }}</span>
          <span v-if="edit " class="select">
            <select v-model="note.contact_type">
              <option value="telephone">Telefonisch</option>
              <option value="person">In persoon</option>
            </select>
          </span>
        </div>
        <div class="card-footer-item" v-if="note.duration !== '0:00' && !edit">
          <span class="is-pulled-right">{{ note.duration }} uur</span>
        </div>
        <div class="card-footer-item" v-if="edit">
          <div class="field has-addons">
            <div class="control">
              <span class="select">
                <select name="plan" v-model="note.duration_hours">
                  <option value="0">0</option>
                  <option v-for="n in 12" v-bind:key="n" :value="n">{{ n }}</option>
                </select>
              </span>
            </div>
            <div class="control">
              <span class="select">
                <select name="plan" v-model="note.duration_minutes">
                  <option value="00">00</option>
                  <option
                    v-for="n in 59"
                    v-bind:key="n"
                    :value="n < 10 ? '0'+n : n"
                  >{{ n < 10 ? '0'+n : n }}</option>
                </select>
              </span>
            </div>
          </div>
        </div>
      </footer>
    </div>
    <br>
  </div>
</template>

<script>
export default {
  props: ["data", "customer"],

  data() {
    return {
      note: new Laravel.Form(),
      edit: false
    };
  },

  mounted() {
    this.parseData(this.data);
  },

  methods: {
    toggleEdit() {
      this.edit = !this.edit;
    },

    submit() {
      this.note
        .put(`/api/admin/customers/${this.customer}/notes/${this.note.id}`)
        .then(data => {
          this.$parent.$parent.load()
          this.toggleEdit();
          this.parseData(data);
        }).catch(() => {this.$buefy.snackbar.open('Kon niet bijwerken.')});
    },

    parseData(data) {
      this.note = new Laravel.Form(data);

      // Parse time
      if (this.note.duration) {
        let time = this.note.duration.split(":");
        this.note.duration_hours = time[0];
        this.note.duration_minutes = time[1];
        this.note.originalData.duration_hours = time[0];
        this.note.originalData.duration_minutes = time[1];
      }
    }
  }
};
</script>
