<template>
  <div>
    <div class="field">
      <label class="label">Identificatie</label>
      <p v-if="data && data.path_identification">
        <a
          :href="downloadLink(data.link_identification)"
          class="button"
          target="_blank"
        >Download {{ data.name_identification }}</a>
      </p>
      <p v-else>Geen bestand geupload</p>
    </div>

    <div class="field">
      <label class="label">Identificatie (2, optioneel)</label>
      <p v-if="data && data.path_identification2">
        <a
          :href="downloadLink(data.link_identification2)"
          class="button"
          target="_blank"
        >Download {{ data.name_identification2 }}</a>
      </p>
      <p v-else>Geen bestand geupload</p>
    </div>

    <div class="field">
      <label class="label">Statuten of oprichtingsakte</label>
      <p v-if="data && data.path_statutes_or_founding_deed">
        <a
          :href="downloadLink(data.link_statutes_or_founding_deed)"
          class="button"
          target="_blank"
        >Download {{ data.name_statutes_or_founding_deed }}</a>
      </p>
      <p v-else>Geen bestand geupload</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],

  computed: {
    token() {
      return localStorage.getItem("token");
    }
  },

  methods: {
    downloadLink(link) {
      return link + "/?token=" + this.token;
    }
  }
};
</script>
