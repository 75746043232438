<style>
    html.printable body,
    html.printable .section.light,
    html.printable .section.content {
        background-color: #fff;
    }

    html.printable #header,
    html.printable #navigation,
    html.printable .submenu,
    html.printable .section.light.no-padding-bottom,
    html.printable .footer {
        display: none;
    }
</style>
<template>

    <div>
        <div class="card">
            <div class="card-content">
                <div class="columns is-multiline">
                    <div class="column is-12">
                        <h2 class="title is-4">Profiel</h2>
                        <table>
                            <tr v-if="data.profile.salutation">
                                <th width="25%">Aanhef</th>
                                <td>{{ data.profile.salutation }}</td>
                            </tr>

                            <tr v-if="data.profile.first_name">
                                <th>Voornaam</th>
                                <td>{{ data.profile.first_name }}</td>
                            </tr>

                            <tr v-if="data.profile.last_name">
                                <th>Achternaam</th>
                                <td>{{ data.profile.last_name }}</td>
                            </tr>

                            <tr v-if="data.profile.email">
                                <th>E-mailadres</th>
                                <td>{{ data.profile.email }}</td>
                            </tr>

                            <tr v-if="data.profile.phone_number">
                                <th>Telefoonnummer</th>
                                <td>{{ data.profile.phone_number }}</td>
                            </tr>

                            <tr v-if="data.profile.lead_type">
                                <th>Type lead</th>
                                <td>{{ data.profile.lead_type }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <br /><br />
        <div class="card">
            <div class="card-content">
                <div class="columns is-multiline">
                    <div class="column is-12">
                        <h2 class="title is-4">Beleggingsdoelstelling</h2>
                        <table v-if="prospect">
                            <tr>
                                <th  width="25%">Doel</th>
                                <td>
                                    <p v-if="prospect.horizon_and_objective.objective.retention?.checked">Instandhouding vermogen / Financieële buffer</p>
                                    <p v-if="prospect.horizon_and_objective.objective.not_necessary_addition?.checked">Niet-noodzakelijke aanvulling / Aanvulling pensioen</p>
                                    <p v-if="prospect.horizon_and_objective.objective.grow?.checked">Groei / Eerder stoppen met werken</p>
                                    <p v-if="prospect.horizon_and_objective.objective.specific_spend_feature?.checked">Specifieke uitgave in de toekomst / Grote aankoop</p>
                                    <p v-if="prospect.horizon_and_objective.objective.repayment_mortgage?.checked">Aflossen hypotheek</p>
                                </td>
                            </tr>
                            <tr v-if="prospect.risk_attitude?.acceptance">
                                <th>Risicoprofiel</th>
                                <td>{{ prospect.risk_attitude.acceptance }}</td>
                            </tr>
                            <tr v-if="prospect.financial_position && prospect.financial_position.financial_position">
                                <th>Startbedrag (beleggingsvoorstel)</th>
                                <td>€ {{ prospect.financial_position.financial_position.initial_to_invest | currency }}</td>
                            </tr>
                            <tr v-if="prospect.financial_position && prospect.financial_position.financial_position">
                                <th>Startbedrag (inventarisatieformulier)</th>
                                <td>€ {{ prospect.financial_position.financial_position.to_invest | currency }}</td>
                            </tr>
                            <tr v-if="prospect.horizon_and_objective.horizon">
                                <th>Horizon</th>
                                <td>{{ prospect.horizon_and_objective.horizon.horizon }} jaar</td>
                            </tr>
                            <tr v-if="prospect.risk_attitude?.risk_tolerance">
                                <th>Risicotolerantie</th>
                                <td>{{ prospect.risk_attitude.risk_tolerance }}</td>
                            </tr>
                            <tr v-if="prospect.risk_attitude?.endeavor">
                                <th>Stelling</th>
                                <td v-if="prospect.risk_attitude.endeavor == 1">
                                    <p>U streeft met uw beleggingen naar een rendement van <strong>circa 3 á 4%</strong>. Met de beoogde portefeuille inrichting zal het rendement zich waarschijnlijk bewegen tussen een <strong>negatief resultaat van -5% en een positief resultaat van 12%</strong>. Ook grotere uitslagen in positieve en negatieve zin zijn mogelijk.</p>
                                </td>

                                <td v-if="prospect.risk_attitude.endeavor == 2">
                                     <p>U streeft met uw beleggingen naar een rendement van <strong>4 á 5%</strong>. Met de beoogde portefeuille inrichting zal het rendement zich waarschijnlijk bewegen tussen een <strong>negatief resultaat van -6% en een positief resultaat van 15%</strong>. Ook grotere uitslagen in positieve en negatieve zin zijn mogelijk.</p>
                                </td>

                                <td v-if="prospect.risk_attitude.endeavor == 3">
                                    <p>U streeft met uw beleggingen naar een rendement van <strong>5 á 6%</strong>. Met de beoogde portefeuille inrichting zal het rendement zich waarschijnlijk bewegen tussen een <strong>negatief resultaat van -9% en een positief resultaat van 20%</strong>. Ook grotere uitslagen in positieve en negatieve zin zijn mogelijk.</p>
                                </td>

                                <td v-if="prospect.risk_attitude.endeavor == 4">
                                    <p>U streeft met uw beleggingen naar een rendement van <strong>6 á 7%</strong>. Met de beoogde portefeuille inrichting zal het rendement zich waarschijnlijk bewegen tussen een <strong>negatief resultaat van -12,5% en een positief resultaat van 25,5%</strong>. Ook grotere uitslagen in positieve en negatieve zin zijn mogelijk.</p>
                                </td>

                                <td v-if="prospect.risk_attitude.endeavor == 5">
                                    <p>U streeft met uw beleggingen naar een rendement van <strong>7 á 8%</strong>. Met de beoogde portefeuille inrichting zal het rendement zich waarschijnlijk bewegen tussen een <strong>negatief resultaat van -17,5% en een positief resultaat van 32,5%</strong>. Ook grotere uitslagen in positieve en negatieve zin zijn mogelijk.</p>
                                </td>

                                <td v-if="prospect.risk_attitude.endeavor == 6">
                                    <p>U streeft met uw beleggingen naar een rendement van <strong>8 á 9%</strong>. Met de beoogde portefeuille inrichting zal het rendement zich waarschijnlijk bewegen tussen een <strong>negatief resultaat van -19,5% en een positief resultaat van 36,5%</strong>. Ook grotere uitslagen in positieve en negatieve zin zijn mogelijk.</p>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {

    props: ['option'],

    data() {
        return {
            prospect: null
        }
    },

    beforeCreate() {
        let html = document.getElementsByTagName("html")[0];
        html.className += "printable";

    },

    mounted() {
        let index = this.$route.params.option
        if (this.$parent.data && this.$parent.data.prospects[index]) {
            this.prospect = this.$parent.data.prospects[index]
        }

        setTimeout(() => {
            window.print();
            this.$router.go(-1);
            let html = document.getElementsByTagName("html")[0];
            html.className = '';
        }, 500)
    },

    computed: {
        data() {
            return this.$parent.data
        },

        index() {
            return this.$route.params.option
        }
     },

    watch: {
        '$parent.data': function(data) {
            this.prospect = data.prospects[this.index]
        }
    }
}
</script>
