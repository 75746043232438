<template>
  <div>
    <div class="block">
      <h4 class="title is-4">Externe adviseurs</h4>
      <table class="table is-striped">
        <thead>
          <th>Naam</th>
          <th>Ontkoppelen</th>
        </thead>
        <tbody>
          <tr
            class="has-pointer"
            v-for="advisor in data.external_advisors"
            @click="open(advisor.id)"
            v-bind:key="advisor.id"
          >
            <td style="vertical-align: middle">{{ advisor.full_name }}</td>
            <td>
              <button
                class="button is-danger"
                @click="detach(advisor.id)"
                :class="{ 'is-loading': loading }"
              >
                Ontkoppel
              </button>
            </td>
          </tr>
          <tr
            v-if="
              !data.external_advisors || data.external_advisors.length === 0
            "
          >
            <td colspan="2">
              <center>
                Er zijn nog geen externe adviseurs gekoppeld aan deze gebruiker.
              </center>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="block">
      <h4 class="title is-4">Externe adviseur toevoegen</h4>

      <div class="field">
        <p class="control">
          <span class="select">
            <select v-model="selectedAdvisor">
              <option :value="undefined">
                Selecteer een adviseur om toe te voegen
              </option>
              <option
                v-for="advisor in advisors"
                :key="advisor.id"
                :value="advisor.id"
              >
                {{ advisor.full_name }}
              </option>
            </select>
          </span>
        </p>
      </div>

      <div class="field">
        <button
          class="button is-primary"
          @click="add()"
          :class="{ 'is-loading': loading }"
        >
          Adviseur toevoegen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      advisors: [],
      selectedAdvisor: undefined,
      loading: false,
    };
  },

  computed: {
    data() {
      return this.$parent.data;
    },
  },

  mounted() {
    this.getAdvisors();
  },

  methods: {
    getAdvisors() {
      axios
        .get("/api/admin/advisors")
        .then(({ data }) => (this.advisors = data))
        .catch(() => this.$buefy.snackbar.open("Kon adviseurs niet laden"));
    },

    add() {
      this.loading = true
      const id = this.selectedAdvisor;

      axios
        .post(`/api/admin/advisors/${id}/customers`, {
          customer_id: this.data.profile.id,
        })
        .then(({ data }) => {
          bus.$emit("reloadCustomer");
          this.$buefy.snackbar.open("Adviseur toegevoegd");
        })
        .catch(() => this.$buefy.snackbar.open("Kon adviseur niet toevoegen"))
        .finally(() => this.loading = false)
    },

    detach(id) {
      this.loading = true
      axios
        .delete(`/api/admin/advisors/${id}/customers/${this.data.profile.id}`)
        .then(() => {
          bus.$emit("reloadCustomer");
          this.$buefy.snackbar.open("Adviseur verwijderd");
        })
        .catch(() => this.$buefy.snackbar.open("Kon adviseur niet verwijderen"))
        .finally(() => this.loading = false)
    },
  },
};
</script>
