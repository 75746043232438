<template>

    <div class="content">

        <section class="section light no-padding-bottom">
            <div class="container">
                <h3 class="title is-3"><strong>Inventarisatie</strong> <span class="is-primary">Zakelijke balansopstelling</span></h3>
                <form-errors :errors="form.errors"></form-errors>
            </div>
        </section>

        <section class="section no-background has-header">
            <div class="container">
                <div class="columns">
                    <div class="column is-6">
                        <div class="block full-height has-amount-bottom">
                            <h4 class="title is-4">Bezittingen</h4>

                            <label class="label">Beleggingen</label>
                            <div class="field has-addons">
                                <p class="control">
                                    <a class="button">€</a>
                                </p>
                                <p class="control is-expanded">
                                    <money name="assets.investments" class="input" type="text" placeholder="Beleggingen" v-model="form.assets.investments"></money>
                                    <form-field-errors :errors="form.errors" field="assets.investments"></form-field-errors>
                                </p>
                            </div>

                            <div class="field">
                                <label class="label">Onroerend goed</label>
                                <div class="field has-addons">
                                    <p class="control">
                                        <a class="button">€</a>
                                    </p>
                                    <p class="control is-expanded">
                                        <money name="assets.property" class="input" type="text" placeholder="Onroerend goed" v-model="form.assets.property"></money>
                                        <form-field-errors :errors="form.errors" field="assets.property"></form-field-errors>
                                    </p>
                                </div>
                            </div>

                            <div class="field">
                                <label class="label">Uitgesteld vermogen (lijfrentes, koopsompolissen etc.)</label>
                                <div class="field has-addons">
                                    <p class="control">
                                        <a class="button">€</a>
                                    </p>
                                    <p class="control is-expanded">
                                        <money name="assets.delayed_assets" class="input" type="text" placeholder="Uitgesteld vermogen" v-model="form.assets.delayed_assets"></money>
                                        <form-field-errors :errors="form.errors" field="assets.delayed_assets"></form-field-errors>
                                    </p>
                                </div>
                            </div>

                            <div class="field">
                                <label class="label">Liquiditeiten</label>
                                <div class="field has-addons">
                                    <p class="control">
                                        <a class="button">€</a>
                                    </p>
                                    <p class="control is-expanded">
                                        <money name="assets.liquidity" class="input" type="text" placeholder="Liquiditeiten" v-model="form.assets.liquidity"></money>
                                        <form-field-errors :errors="form.errors" field="assets.liquidity"></form-field-errors>
                                    </p>
                                </div>
                            </div>

                            <div class="field">
                                <label class="label">Overige bezittingen</label>
                                <div class="field has-addons">
                                    <p class="control">
                                        <a class="button">€</a>
                                    </p>
                                    <p class="control is-expanded">
                                        <money name="assets.other_assets" class="input is-expanded" type="text" placeholder="Overige bezittingen" v-model="form.assets.other_assets"></money>
                                        <form-field-errors :errors="form.errors" field="assets.other_assets"></form-field-errors>
                                    </p>
                                </div>
                            </div>

                            <div class="total-amount">
                                <hr />

                                <div class="columns">
                                    <div class="column">
                                        <strong class="title is-4">Totaal</strong>
                                    </div>
                                    <div class="column">
                                        &euro; {{ total_assets_number | currency }}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="column is-6">
                        <div class="block full-height has-amount-bottom">
                            <h4 class="title is-4">Schulden</h4>

                            <div class="field">
                                <label class="label">Hypothecaire schuld</label>
                                <div class="field has-addons">
                                    <p class="control">
                                        <a class="button">€</a>
                                    </p>
                                    <p class="control is-expanded">
                                        <money name="debts.mortgage" class="input" type="text" placeholder="Hypothecaire schuld" v-model="form.debts.mortgage"></money>
                                        <form-field-errors :errors="form.errors" field="debts.mortgage"></form-field-errors>
                                    </p>
                                </div>
                            </div>

                            <div class="field">
                                <label class="label">Pensioenverplichting (indien van toepassing bij een zakelijke rekening)</label>
                                <div class="field has-addons">
                                    <p class="control">
                                        <a class="button">€</a>
                                    </p>
                                    <p class="control is-expanded">
                                        <money name="debts.retirement" class="input" type="text" placeholder="Pensioenverplichting" v-model="form.debts.retirement"></money>
                                        <form-field-errors :errors="form.errors" field="debts.retirement"></form-field-errors>
                                    </p>
                                </div>

                            </div>

                            <div class="field">
                                <label class="label">Overige schulden</label>
                                <div class="field has-addons">
                                    <p class="control">
                                        <a class="button">€</a>
                                    </p>
                                    <p class="control is-expanded">
                                        <money name="debts.other_debts" class="input" type="text" placeholder="Overige schulden" v-model="form.debts.other_debts"></money>
                                        <form-field-errors :errors="form.errors" field="debts.other_debts"></form-field-errors>
                                    </p>
                                </div>
                            </div>

                            <div class="field">
                                <label class="label">Overige verplichtingen</label>
                                <div class="field has-addons">
                                    <p class="control">
                                        <a class="button">€</a>
                                    </p>
                                    <p class="control is-expanded">
                                        <money name="debts.other_obligations" class="input" type="text" placeholder="Overige verplichtingen" v-model="form.debts.other_obligations"></money>
                                        <form-field-errors :errors="form.errors" field="debts.other_obligations"></form-field-errors>
                                    </p>
                                </div>
                            </div>

                            <div class="total-amount">
                                <hr />

                                <div class="columns">
                                    <div class="column">
                                        <strong class="title is-4">Totaal</strong>
                                    </div>
                                    <div class="column">
                                        &euro; {{ total_debts_number | currency }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="columns">
                    <div class="column">
                        <button @click="save()" class="button is-primary" :class="{'is-loading': form.loading}">Tussentijds opslaan</button>
                        <button @click="next()" class="button is-primary is-pulled-right" :class="{'is-loading': form.loading}">Opslaan en verder</button>
                    </div>
                </div>

            </div>
        </section>

    </div>

</template>

<script>

    export default {

        data() {
            return {
                total_assets_number: 0,
                total_debts_number: 0,
                total_gainings_number: 0,
                total_expenses_number: 0,
                form: this.$parent.form.business_assets_and_liabilities,
                test: 0,
            }
        },

        beforeMount() {
            if (!this.form.assets) {
                this.form = {}
                this.form.next = false;
                this.form.assets = {
                    investments: 0,
                    property: 0,
                    delayed_assets: 0,
                    liquidity: 0,
                    other_assets: 0
                }
                this.form.debts = {
                    mortgage: 0,
                    retirement: 0,
                    other_debts: 0,
                    other_obligations: 0
                }
                this.form = new Laravel.Form(this.form)
            }

            this.total_assets();
            this.total_debts();
        },

        methods: {
            save() {
                this.$parent.save(this.form);
            },

            next() {
                this.$parent.save(this.form, true);
            },

            total_assets() {
                this.total_assets_number = 0

                for (var key of Object.keys(this.form.assets)) {
                    if(!isNaN(parseInt(this.form.assets[key]))) {
                        this.total_assets_number = this.total_assets_number + parseInt(this.form.assets[key])
                    }
                }

                return this.total_assets_number
            },

            total_debts() {
                this.total_debts_number = 0

                for (var key of Object.keys(this.form.debts)) {
                    if(!isNaN(parseInt(this.form.debts[key]))) {
                        this.total_debts_number = this.total_debts_number + parseInt(this.form.debts[key])
                    }
                }

                return this.total_debts_number
            },
        },

        watch: {
            'form.assets': {
                handler: function () {
                    this.total_assets()
                },
                deep: true,
            },
            'form.debts': {
                handler: function () {
                    this.total_debts()
                },
                deep: true,
            },
        }
    }
</script>
