<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <h4 class="title is-4">Financiële positie</h4>
        <div class="field">
          <label class="label">Wat is de omvang van uw vrij beschikbare vermogen?</label>
          <div v-if="data && data.financial_position">
            <p
              v-if="data.financial_position.available_captial == '100000 - 250000'"
            >€100.000 - €250.000</p>
            <p
              v-if="data.financial_position.available_captial == '250000 - 500000'"
            >€250.000 - €500.000</p>
            <p
              v-if="data.financial_position.available_captial == '500000 - 1000000'"
            >€500.000 - €1.000.000</p>
            <p
              v-if="data.financial_position.available_captial == '1000000'"
            >Meer dan €1.000.000</p>
          </div>
        </div>

        <div class="field">
          <label class="label">Startbedrag (beleggingsvoorstel)</label>
          <p
            v-if="data && data.financial_position"
          >{{ data.financial_position.initial_to_invest | currency }}</p>
        </div>

        <div class="field">
          <label class="label">Hoeveel van dit vermogen wilt u beleggen via BeSmart?</label>
          <p
            v-if="data && data.financial_position"
          >{{ data.financial_position.to_invest | currency }}</p>
        </div>
      </div>

      <div class="column is-6">
        <h4 class="title is-4">Herkomst belegbaar vermogen</h4>

        <div class="field">
          <label class="label">Wat is hoofdzakelijk de herkomst van het belegbare vermogen?</label>
          <div v-if="data && data.enterprise">
            <p class="control">
              <label class="checkbox">
                <input type="checkbox" v-model="data.enterprise.checked" disabled>
                Verkoop onderneming
              </label>
            </p>

            <div v-if="data.enterprise.checked" class="control">
              <div class="field">
                <label class="label">Naam onderneming</label>
                <p>{{ data.enterprise.name_enterprise }}</p>
              </div>

              <div class="field">
                <label class="label">Datum verkoop</label>
                <p>{{ data.enterprise.sold_date | formatDate }}</p>
              </div>

              <div class="field">
                <label class="label">Totale verkoopsom</label>
                <p>{{ data.enterprise.total_selling_price | currency }}</p>
              </div>
            </div>

            <p class="control" v-if="data.property">
              <label class="checkbox">
                <input type="checkbox" v-model="data.property.checked" disabled>
                Verkoop onroerend goed
              </label>
            </p>

            <div v-if="data.property.checked" class="control">
              <div class="field">
                <label class="label">Type woning</label>
                <p>{{ data.property.type }}</p>
              </div>

              <div class="field">
                <label class="label">Datum verkoop</label>
                <p>{{ data.property.sold_date | formatDate }}</p>
              </div>

              <div class="field">
                <label class="label">Totale verkoopsom</label>
                <p>{{ data.property.total_selling_price | currency }}</p>
              </div>
            </div>

            <p class="control" v-if="data.heritage">
              <label class="checkbox">
                <input type="checkbox" v-model="data.heritage.checked" disabled>
                Erfenis
              </label>
            </p>

            <div v-if="data.heritage.checked" class="control">
              <div class="field">
                <label class="label">Relatie tot erflater</label>
                <p>{{ data.heritage.relationship }}</p>
              </div>

              <div class="field">
                <label class="label">Jaar erfenis</label>
                <p>{{ data.heritage.year }}</p>
              </div>

              <div class="field">
                <label class="label">Bedrag</label>
                <p>{{ data.heritage.amount | currency }}</p>
              </div>
            </div>

            <p class="control" v-if="data.severance">
              <label class="checkbox">
                <input type="checkbox" v-model="data.severance.checked" disabled>
                Gouden handdruk
              </label>
            </p>

            <div
              v-if="data.severance && data.severance.checked"
              class="control"
            >
              <div class="field">
                <label class="label">Naam bedrijf</label>
                <p>{{ data.severance.company_name }}</p>
              </div>

              <div class="field">
                <label class="label">Datum ontvangst</label>
                <p>{{ data.severance.received_at | formatDate }}</p>
              </div>

              <div class="field">
                <label class="label">Bedrag</label>
                <p>{{ data.severance.amount | currency }}</p>
              </div>
            </div>

            <p class="control" v-if="data.savings">
              <label class="checkbox">
                <input type="checkbox" v-model="data.savings.checked" disabled>
                Gespaarde inkomsten uit arbeid en beleggen
              </label>
            </p>

            <p class="control" v-if="data.other">
              <label class="checkbox">
                <input type="checkbox" v-model="data.other.checked" disabled>
                Anders
              </label>
            </p>

            <div v-if="data.other && data.other.checked" class="control">
              <div class="field">
                <label class="label">Namelijk</label>
                <p>{{ data.other.namely }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"]
};
</script>
