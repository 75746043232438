<template>
  <div>
    <section class="section content no-header">
      <div class="container">
        <div class="columns">
          <div class="column is-4">
            <div class="block">
              <h4 class="title is-4">Uw contactpersonen</h4>
              <div v-if="data && data.contact_persons">
                <p
                  v-if="!data.contact_persons.manager && !data.contact_persons.advisor"
                >Er zijn nog geen contactpersonen voor u toegewezen.</p>
                <div v-for="(item, index) in data.contact_persons" v-bind:key="index">
                  <article class="media" v-if="item">
                    <figure class="media-left" style="margin-left: 0;">
                      <p class="image is-64x64">
                        <img
                          :src="item.profile_picture ? item.profile_picture : 'img/person_placeholder.png'"
                          class="is-rounded"
                        >
                      </p>
                    </figure>
                    <div class="media-content">
                      <div class="content">
                        <strong>{{ index === 'advisor' ? 'Relatiebeheerder' : 'Vermogensbeheerder' }}</strong>
                        <br>
                        <p>{{ item.fullName }}</p>
                        <router-link
                          :to="{name: 'notification/compose', params: {receiver: index} }"
                          class="button is-primary"
                        >Stuur bericht</router-link>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>

            <div class="block">
              <h4 class="title is-4">Samenstelling portefeuille</h4>
              <p
                v-if="!Object.keys(this.data.composition).length"
              >Er zijn nog niet genoeg gegevens om de samenstelling van de portefeuille weer te geven.</p>
              <highcharts :options="pie"></highcharts>
            </div>
          </div>

          <div class="column is-8">
            <div class="block full-height">
              <h4 class="title is-4">Rendementsontwikkeling</h4>

              <p
                v-if="!this.data.chart"
              >Er zijn nog niet genoeg gegevens om de rendementsontwikkeling weer te geven.</p>

              <DevelopmentChart
                :data="data ? data.chart : {}"
                :targetAmount="user.current_plan.target_amount"
                v-if="user.current_plan"
              ></DevelopmentChart>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-4">
            <div class="block">
              <h4 class="title is-4">Rendement per jaar</h4>
              <table class="table is-striped">
                <thead>
                  <th width="50%">Jaar</th>
                  <th>Rendement</th>
                </thead>
                <tbody>
                  <tr
                    v-for="(effieciency, year) in data.effieciency_per_year"
                    v-bind:key="year"
                    v-if="year != 'cumulative'"
                  >
                    <td>
                      {{ year }}
                      <span v-if="year == new Date().getFullYear()">(YTD)</span>
                    </td>
                    <td>{{ effieciency | currency }}%</td>
                  </tr>
                </tbody>
              </table>

              <div v-if="data && data.effieciency_per_year">
                <hr>

                <table class="table is-striped">
                  <tbody>
                    <tr>
                      <td width="50%">Cumulatief</td>
                      <td>{{ data.effieciency_per_year.cumulative | currency }}%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="column is-8">
            <div class="block full-height">
              <notification-widget :notifications="data.notifications"></notification-widget>
            </div>
          </div>
        </div>

        <div v-if="data && data.portfolio">
          <div class="block">
            <h4 class="title is-4">Uw portefeuille</h4>
            <portfolio :data="data.portfolio"></portfolio>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Highcharts from "highcharts/highcharts";
import DevelopmentChart from "@customer/dashboard/DevelopmentChart.vue";

export default {
  components: {
    DevelopmentChart: DevelopmentChart,
  },

  data() {
    return {
      datacollection: {},

      pie: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie"
        },
        title: {
          text: null
        },
        legend: {
          alignColumns: true,
          itemMarginBottom: 10
        },
        tooltip: {
          //pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>',
          backgroundColor: "#dee0d5",
          borderColor: "#c6c7c1",
          borderRadius: 0,
          borderWidth: 1,
          formatter: function() {
            return `<strong>${this.key}</strong><br />Maakt ${Math.round(
              this.point.percentage * 100
            ) / 100}% deel uit van uw portefeuille.`;
          }
        },
        colors: ["#3fb0ac", "#fbe697", "#e2b2b2", "#cdc586"],
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          }
        },

        credits: false,

        series: [
          {
            name: "Portefeuille",
            colorByPoint: true,
            data: [],
            size: "100%",
            innerSize: "75%"
          }
        ]
      }
    };
  },

  computed: {
    user() {
      return this.$root.user;
    },

    data() {
      return this.$parent.data;
    }
  },

  mounted() {
    this.fillPieChart();
  },

  methods: {
    fillPieChart() {
      if (this.data.composition) {
        // this.pie.series[0].data = [];
        for (let [key, value] of Object.entries(this.data.composition)) {
          this.pie.series[0].data.push({ name: key, y: value });
        }
      }
    }
  },

  watch: {
    data: function(value) {
      this.fillPieChart();
    }
  }
};
</script>
