<template>
  <div>
    <div class="section light" :class="{ 'no-padding-bottom': form.errors.any() }">
      <div class="container">
        <h3 class="title is-3">Inloggen</h3>

        <form-errors :errors="form.errors"></form-errors>
      </div>
    </div>

    <section class="section no-background has-header">
      <div class="container">
        <div class="block">
          <router-view></router-view>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: new Laravel.Form({}),
      accounts: [],
      id: '',
    };
  },

  methods: {
    login(data) {
      this.$root.setUser(data);

      if (
        localStorage.getItem("redirect") !== null &&
        localStorage.getItem("redirect").indexOf("/auth") === -1
      ) {
        let redirect = localStorage.getItem("redirect");
        localStorage.removeItem("redirect");
        this.$router.replace({ path: redirect });
      } else {
        if (data.user.role == "manager") {
          this.$router.replace({ name: "admin/dashboard" });
        } else {
          this.$router.replace({ name: "dashboard" });
        }
      }
    },
  }
};
</script>
