<template>
  <div>
    <div v-if="data">
      <h4 class="title is-4">Rendementsontwikkeling</h4>
      <DevelopmentChart
        :data="data.chart"
        :targetAmount="data.current_plan ? data.current_plan.target_amount : 0"
      ></DevelopmentChart>
    </div>
    <hr />
    <div class="columns">
      <div class="column is-5">
        <efficiency-per-year :data="data"></efficiency-per-year>
      </div>
    </div>
  </div>
</template>

<script>
import EfficiencyPerYear  from "@components/EfficiencyPerYear.vue";
import DevelopmentChart from "@customer/dashboard/DevelopmentChart.vue";

export default {
  components: {
    EfficiencyPerYear: EfficiencyPerYear,
    DevelopmentChart: DevelopmentChart,
  },

  computed: {
    data() {
      return this.$parent.data;
    },
  },
};
</script>
