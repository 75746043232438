<template>
  <div>
    <div class="field">
      <label class="label">Aanhef</label>
      <span>{{ data.salutation }}</span>
    </div>

    <div class="field">
      <label class="label">Voornaam</label>
      <span>{{ data.first_name }}</span>
    </div>

    <div class="field">
      <label class="label">Achternaam</label>
      <span>{{ data.last_name }}</span>
    </div>

    <div class="field">
      <label class="label">E-mailadres</label>
      <span>{{ data.email }}</span>
    </div>

    <div class="field">
      <label class="label">Adres</label>
      <span>{{ data.address }}</span>
    </div>

    <div class="field">
      <label class="label">Postcode</label>
      <span>{{ data.postal_code }}</span>
    </div>

    <div class="field">
      <label class="label">Woonplaats</label>
      <span>{{ data.place_of_residence }}</span>
    </div>

    <div class="field">
      <label class="label">Land</label>
      <span>{{ data.country }}</span>
    </div>

    <div class="field">
      <label class="label">Telefoonnummer</label>
      <span>{{ data.phone_number }}</span>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    data() {
      return this.$parent.customer;
    },
  },
};
</script>
