<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <h4 class="title is-4">Uw risicohouding</h4>
        <div class="field">
          <label class="label">Wat is de acceptatie van het risico van uw gewenste portefeuille?</label>
          <p v-if="data">{{ data.acceptance }}</p>
        </div>

        <div class="field">
          <label
            class="label"
          >Wat doet u wanneer de waarde van uw beleggingen in het eerste jaar daalt met 20%?</label>
          <p v-if="data">{{ data.risk_tolerance }}</p>
        </div>

        <div class="field">
          <label
            class="label"
          >Welke waardeontwikkeling van € 100.000,- in &eacute;&eacute;n jaar heeft uw voorkeur?</label>
          <p v-if="data && data.value_development === '95000 - 115000'">Tussen de € 95.000,- en € 115.000,-</p>
          <p v-if="data && data.value_development === '90000 - 125000'">Tussen de € 90.000,- en € 125.000,-</p>
          <p v-if="data && data.value_development === '80000 - 135000'">Tussen de € 80.000,- en € 135.000,-</p>
        </div>
      </div>
      <div class="column is-6">
        <h4 class="title is-4">&nbsp;</h4>
        <div class="field">
          <label class="label">Welke stelling past het beste bij u?</label>

          <div class="control" v-if="data">
            <label class="radio">
              <div class="columns">
                <div class="column is-narrow">
                  <input type="radio" value="1" v-model="data.endeavor" disabled="disabled">
                </div>
                <div class="column">
                  <p>
                    U streeft met uw beleggingen naar een rendement van
                    <strong>circa 3 á 4%</strong>. Met de beoogde portefeuille inrichting zal het rendement zich waarschijnlijk bewegen tussen een
                    <strong>negatief resultaat van -5% en een positief resultaat van 12%</strong>. Ook grotere uitslagen in positieve en negatieve zin zijn mogelijk.
                  </p>
                </div>
              </div>
            </label>
          </div>

          <div class="control" v-if="data">
            <label class="radio">
              <div class="columns">
                <div class="column is-narrow">
                  <input
                    name="endeavor"
                    type="radio"
                    value="2"
                    v-model="data.endeavor"
                    disabled="disabled"
                  >
                </div>
                <div class="column">
                  <p>
                    U streeft met uw beleggingen naar een rendement van
                    <strong>4 á 5%</strong>. Met de beoogde portefeuille inrichting zal het rendement zich waarschijnlijk bewegen tussen een
                    <strong>negatief resultaat van -6% en een positief resultaat van 15%</strong>. Ook grotere uitslagen in positieve en negatieve zin zijn mogelijk.
                  </p>
                </div>
              </div>
            </label>
          </div>

          <div class="control" v-if="data">
            <label class="radio">
              <div class="columns">
                <div class="column is-narrow">
                  <input
                    name="endeavor"
                    type="radio"
                    value="3"
                    v-model="data.endeavor"
                    disabled="disabled"
                  >
                </div>
                <div class="column">
                  <p>
                    U streeft met uw beleggingen naar een rendement van
                    <strong>5 á 6%</strong>. Met de beoogde portefeuille inrichting zal het rendement zich waarschijnlijk bewegen tussen een
                    <strong>negatief resultaat van -9% en een positief resultaat van 20%</strong>. Ook grotere uitslagen in positieve en negatieve zin zijn mogelijk.
                  </p>
                </div>
              </div>
            </label>
          </div>

          <div class="control" v-if="data">
            <label class="radio">
              <div class="columns">
                <div class="column is-narrow">
                  <input
                    name="endeavor"
                    type="radio"
                    value="4"
                    v-model="data.endeavor"
                    disabled="disabled"
                  >
                </div>
                <div class="column">
                  <p>
                    U streeft met uw beleggingen naar een rendement van
                    <strong>6 á 7%</strong>. Met de beoogde portefeuille inrichting zal het rendement zich waarschijnlijk bewegen tussen een
                    <strong>negatief resultaat van -12,5% en een positief resultaat van 25,5%</strong>. Ook grotere uitslagen in positieve en negatieve zin zijn mogelijk.
                  </p>
                </div>
              </div>
            </label>
          </div>

          <div class="control" v-if="data">
            <label class="radio">
              <div class="columns">
                <div class="column is-narrow">
                  <input
                    name="endeavor"
                    type="radio"
                    value="5"
                    v-model="data.endeavor"
                    disabled="disabled"
                  >
                </div>
                <div class="column">
                  <p>
                    U streeft met uw beleggingen naar een rendement van
                    <strong>7 á 8%</strong>. Met de beoogde portefeuille inrichting zal het rendement zich waarschijnlijk bewegen tussen een
                    <strong>negatief resultaat van -17,5% en een positief resultaat van 32,5%</strong>. Ook grotere uitslagen in positieve en negatieve zin zijn mogelijk.
                  </p>
                </div>
              </div>
            </label>
          </div>

          <div class="control" v-if="data">
            <label class="radio">
              <div class="columns">
                <div class="column is-narrow">
                  <input
                    name="endeavor"
                    type="radio"
                    value="6"
                    v-model="data.endeavor"
                    disabled="disabled"
                  >
                </div>
                <div class="column">
                  <p>
                    U streeft met uw beleggingen naar een rendement van
                    <strong>8 á 9%</strong>. Met de beoogde portefeuille inrichting zal het rendement zich waarschijnlijk bewegen tussen een
                    <strong>negatief resultaat van -19,5% en een positief resultaat van 36,5%</strong>. Ook grotere uitslagen in positieve en negatieve zin zijn mogelijk.
                  </p>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"]
};
</script>
