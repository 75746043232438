<template>

    <div>
        <div class="level">
            <div class="level-left">
                <div class="level-item">
                    <h4 class="title is-4">Kenmerken</h4>
                </div>
            </div>
            <div class="level-right">
                <div class="level-item">
                    <button class="button" @click="toggleEditRemarks()">Bewerken</button>
                </div>
            </div>
        </div>
        <form-errors :errors="form.errors"></form-errors>
        <form  @submit.prevent="saveRemarks()">
            <div class="field">
                <label class="label">Vermogensbeheerder</label>
                <span class="select" v-if="editRemarks">
                    <select v-model="form.manager">
                        <option :value="null">(niet gekoppeld)</option>
                        <option v-for="collegue in collegues" v-bind:key="collegue.id" :value="collegue.id">{{ collegue.fullName }}</option>
                    </select>
                </span>
                <p class="control" v-if="!editRemarks && data.profile.manager">{{ data.profile.manager_profile.fullName }}</p>
                <p class="control has-text-grey-light" v-if="!editRemarks && !data.profile.manager">Niet gekoppeld</p>
            </div>

            <div class="field">
                <label class="label">Relatiebeheerder</label>
                <span class="select" v-if="editRemarks">
                    <select v-model="form.advisor">
                        <option :value="null">(niet gekoppeld)</option>
                        <option v-for="collegue in collegues" v-bind:key="collegue.id" :value="collegue.id">{{ collegue.fullName }}</option>
                    </select>
                </span>
                <p class="control" v-if="!editRemarks && data.profile.advisor">{{ data.profile.advisor_profile.fullName }}</p>
                <p class="control has-text-grey-light" v-if="!editRemarks && !data.profile.advisor">Niet gekoppeld</p>
            </div>

            <div class="field">
                <label class="label">Kenmerk prospect</label>
                <span class="select" v-if="editRemarks">
                    <select v-model="form.prospect_tag">
                        <option value="1">Niet geinteresseerd</option>
                        <option value="2">Geinteresseerd</option>
                        <option value="3">Niet geschikt</option>
                        <option value="4">Geschikt</option>
                        <option value="5">Overleden</option>
                        <option value="6">Ex-client</option>
                    </select>
                </span>
                <p class="control" v-if="!editRemarks">
                    <span v-if="data.profile.prospect_tag == 1">Niet geinteresseerd</span>
                    <span v-if="data.profile.prospect_tag == 2">Geinteresseerd</span>
                    <span v-if="data.profile.prospect_tag == 3">Niet geschikt</span>
                    <span v-if="data.profile.prospect_tag == 4">Geschikt</span>
                    <span v-if="data.profile.prospect_tag == 5">Overleden</span>
                    <span v-if="data.profile.prospect_tag == 6">Ex-client</span>
                    <span class="control has-text-grey-light" v-if="!data.profile.prospect_tag">Onbekend</span>
                </p>
            </div>

            <div class="field">
                <label class="label">Herkomst lead</label>
                <div class="control" v-if="editRemarks">
                    <div v-if="!showLeadOriginInput">
                        <span class="select">
                            <select v-model="form.lead_origin">
                                <option v-for="item in remarks.lead_origin" v-bind:key="item" :value="item">{{ item }}</option>
                            </select>
                        </span>
                        <button type="button" @click="showLeadOriginInput = true" class="button">Nieuwe waarde toevoegen</button>
                    </div>
                    <input type="text" class="input" v-if="showLeadOriginInput" v-model="form.lead_origin" placeholder="Herkomst lead">
                </div>
                <p class="control" v-if="!editRemarks">{{ data.profile.lead_origin }}</p>
                <p class="control has-text-grey-light" v-if="!editRemarks && !data.profile.lead_origin">Onbekend</p>
            </div>

            <div class="field">
                <label class="label">Type lead</label>
                <div class="control" v-if="editRemarks">
                    <div v-if="!showLeadTypeInput">
                        <span class="select">
                            <select v-model="form.lead_type">
                                <option v-for="item in remarks.lead_type" v-bind:key="item" :value="item">{{ item }}</option>
                            </select>
                        </span>
                        <button type="button" @click="showLeadTypeInput = true" class="button">Nieuwe waarde toevoegen</button>
                    </div>
                    <input type="text" class="input" v-if="showLeadTypeInput" v-model="form.lead_type" placeholder="Type lead">
                </div>
                <p class="control" v-if="!editRemarks">{{ data.profile.lead_type }}</p>
                <p class="control has-text-grey-light" v-if="!editRemarks && !data.profile.lead_type">Onbekend</p>
            </div>

            <div class="field">
                <label class="label">Totaal vermogen</label>
                <div class="control" v-if="editRemarks">
                    <input type="text" class="input" v-model="form.total_capital" placeholder="Totaal vermogen">
                </div>
                <p class="control" v-if="!editRemarks && data.profile.total_capital">&euro; {{ data.profile.total_capital | currency }}</p>
                <p class="control has-text-grey-light" v-if="!editRemarks && !data.profile.total_capital">Onbekend</p>
            </div>

            <div class="field">
                <label class="label">Huidige beheerder</label>
                <div class="control" v-if="editRemarks">
                    <div v-if="!showCurrentFinancialManagerInput">
                        <span class="select">
                            <select v-model="form.current_financial_manager">
                                <option v-for="item in remarks.current_financial_manager" v-bind:key="item" :value="item">{{ item }}</option>
                            </select>
                        </span>
                        <button type="button" @click="showCurrentFinancialManagerInput = true" class="button">Nieuwe waarde toevoegen</button>
                    </div>
                    <input type="text" class="input" v-if="showCurrentFinancialManagerInput" v-model="form.current_financial_manager" placeholder="Huidige beheerder">
                </div>
                <p class="control" v-if="!editRemarks">{{ data.profile.current_financial_manager }}</p>
                <p class="control has-text-grey-light" v-if="!editRemarks && !data.profile.current_financial_manager">Onbekend</p>
            </div>

            <div class="field" v-if="editRemarks">
                <button type="submit" class="button is-primary" :class="{'is-loading': form.loading}">Opslaan</button>
            </div>
        </form>
    </div>
</div>

</template>

<script>
    export default {
        data() {
            return {
                editRemarks: false,
                showLeadTypeInput: false,
                showLeadOriginInput: false,
                showCurrentFinancialManagerInput: false,
                form: new Laravel.Form(this.$parent.data.profile),
                remarks: {},
            }
        },

        computed: {
            data() {
                return this.$parent.data
            }
        },

        mounted() {
            this.getRemarks();
            this.getCollegues();
        },

        methods: {
            toggleEditRemarks() {
                this.editRemarks = !this.editRemarks;

                if (this.editRemarks === false) {
                    this.showLeadTypeInput = false;
                    this.showLeadOriginInput = false;
                    this.showCurrentFinancialManagerInput = false;
                }
            },

            saveRemarks() {
                this.form.submit('put', `/api/admin/customers/${this.$parent.data.profile.id}/remarks`, false)
                    .then(data => {
                        this.$parent.data.profile = data;
                        this.toggleEditRemarks();
                        this.getRemarks();
                    }).catch(() => {this.$buefy.snackbar.open('Kon niet bijwerken.')});
            },

            getCollegues() {
                axios.get('/api/admin/collegues')
                    .then(({ data }) => {
                        this.collegues = data
                    })
                    .catch(() => {
                        this.$buefy.snackbar.open('Kon collega\'s niet laden.')
                    })
            },

            getRemarks() {
                axios.get(`/api/admin/remarks`)
                    .then(({data}) => {
                        this.remarks = data
                    })
                    .catch(() => {
                        this.$buefy.snackbar.open('Kon kenmerken niet laden.');
                    });
            }
        },

        watch: {
            data: function(val) {
                this.form = new Laravel.Form(val.profile)
            }
        }
    }
</script>
