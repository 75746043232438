<template>

    <div class="columns">
        <div class="column is-6">
            <div class="block">
                <div class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <h4 class="title is-4">Beleggersrekening</h4>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <button class="button" @click="toggleInvestorAccountForm()">Bewerken</button>
                        </div>
                    </div>
                </div>
                <form @submit.prevent="saveAccounts()">
                    <div class="columns">
                        <div class="column is-one-third">
                            <label class="label">Provider</label>
                            <p class="control" v-if="!showInvestorAccountForm">{{  data.profile.exchange_provider }}</p>
                            <div class="control full-width" v-if="showInvestorAccountForm">
                                <span class="select full-width">
                                    <select name="exchange_provider" class="full-width" v-model="form.exchange_provider">
                                        <option value=""></option>
                                        <option value="AIRS">AIRS</option>
                                        <option value="NNEK">NNEK</option>
                                    </select>
                                </span>
                            </div>
                        </div>
                        <div class="column">
                            <label class="label">Portefeuillenummer</label>
                            <p class="control" v-if="!showInvestorAccountForm">{{ data.profile.portfolio_number }}</p>
                            <div class="control is-expanded" v-if="showInvestorAccountForm">
                                <input name="portfolio_number" class="input" type="text" placeholder="Portefeuillenummer" v-model="form.portfolio_number">
                            </div>
                        </div>
                    </div>

                    <div class="field">
                        <label class="label">Instantie</label>
                        <p class="control" v-if="!showInvestorAccountForm">{{ data.profile.investor_account_instance }}</p>
                        <div class="control" v-if="showInvestorAccountForm">
                            <span class="select">
                                <select name="investor_account_instance" v-model="form.investor_account_instance">
                                    <option value=""></option>
                                    <option value="DeGiro">DeGiro</option>
                                    <option value="Binck Bank">Binck Bank</option>
                                </select>
                            </span>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Rekeningnummer</label>
                        <p class="control" v-if="!showInvestorAccountForm">{{ data.profile.investor_account_account_number }}</p>
                        <div class="control" v-if="showInvestorAccountForm">
                            <input name="investor_account_account_number" class="input" type="text" placeholder="Rekeningnummer" v-model="form.investor_account_account_number">
                        </div>
                    </div>
                    <div class="control" v-if="showInvestorAccountForm">
                        <button type="submit" class="button is-primary" :class="{ 'is-loading': form.loading }">Opslaan</button>
                    </div>
                </form>
            </div>
        </div>

        <div class="column is-6">
            <div class="block">
                <div class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <h4 class="title is-4">Tegenrekening</h4>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <button class="button" @click="toggleContraAccountForm()">Bewerken</button>
                        </div>
                    </div>
                </div>

                <form @submit.prevent="saveAccounts()">
                    <div class="field">
                        <label class="label">Instantie</label>
                        <p class="control" v-if="!showContraAccountForm">{{ data.profile.contra_account_instance }}</p>
                        <div class="control" v-if="showContraAccountForm">
                            <input name="contra_account_instance" class="input" type="text" placeholder="Instantie" v-model="form.contra_account_instance">
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Tenaamstelling</label>
                        <p class="control" v-if="!showContraAccountForm">{{ data.profile.contra_account_account_name }}</p>
                        <div class="control" v-if="showContraAccountForm">
                            <input name="contra_account_account_name" class="input" type="text" placeholder="Tenaamstelling" v-model="form.contra_account_account_name">
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Rekeningnummer</label>
                        <p class="control" v-if="!showContraAccountForm">{{ data.profile.contra_account_account_number }}</p>
                        <div class="control" v-if="showContraAccountForm">
                            <input name="contra_account_account_number" class="input" type="text" placeholder="Rekeningnummer" v-model="form.contra_account_account_number">
                        </div>
                    </div>
                    <div class="control" v-if="showContraAccountForm">
                        <button type="submit" class="button is-primary" :class="{ 'is-loading': form.loading }">Opslaan</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

</template>

<script>

    export default {

        data() {
            return {
                showInvestorAccountForm: false,
                showContraAccountForm: false,
                form: new Laravel.Form({
                    portfolio_number: '',
                    investor_account_instance: '',
                    investor_account_account_number: '',
                    contra_account_instance: '',
                    contra_account_account_number: '',
                    contra_account_account_name: '',
                })
            }
        },

        computed: {
            data() {
                return this.$parent.data;
            }
        },

        methods: {
            toggleInvestorAccountForm() {
                this.showInvestorAccountForm = !this.showInvestorAccountForm
                this.form = new Laravel.Form(this.data.profile)
            },

            toggleContraAccountForm() {
                this.showContraAccountForm = !this.showContraAccountForm
                this.form = new Laravel.Form(this.data.profile)
            },

            saveAccounts() {
                this.form.put(`/api/admin/customers/${this.$parent.data.profile.id}/bank-accounts`)
                    .then(data => {
                        this.showInvestorAccountForm = false;
                        this.showContraAccountForm = false;
                        this.$parent.data.profile = data
                    }).catch(() => {this.$buefy.snackbar.open('Kon niet bijwerken.')});
            }
        }
    }

</script>
