<template>
  <div class="content">
    <section class="section light no-padding-bottom">
      <div class="container">
        <h3 class="title is-3">
          <strong>Inventarisatie</strong>
          <span class="is-primary">Keuze depotbank</span>
        </h3>

        <form-errors :errors="form.errors"></form-errors>
      </div>
    </section>

    <section class="section no-background has-header">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-half-desktop">
            <div class="block">
              <h4 class="title is-4">Keuze depotbank</h4>
              <p>Maak een keuze in de twee onderstaande depotbanken.</p>
              <div class="control">
                <label class="radio">
                  <input type="radio" name="custodian" value="degiro" v-model="form.custodian">
                  DeGIRO
                </label>
              </div>
              <div class="control">
                <label class="radio">
                  <input type="radio" name="custodian" value="binck" v-model="form.custodian">
                  BINCK BANK
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-centered" v-if="form.custodian === 'degiro'">
          <div class="column is-half-desktop">
            <div class="block">
              <h4 class="title is-4">DeGIRO</h4>
              <p>Maak op de website van DeGIRO een account aan en voer de opgegeven accountgegevens in het onderstaande formulier in.</p>
              <ol>
                <li>Maak een account van het type '<strong><u>custody</u></strong>' of '<strong><u>pensioen</u></strong>' aan.
                  <a href="https://www.degiro.nl/registration/nl/registration/access/BeSmartIB2" target="_blank">Ga naar DeGIRO
                    <i class="fa fa-external-link"></i>
                  </a>.
                </li>
                <li>Geef uw gebruikersnaam in het onderstaande formulier op.</li>
              </ol>
              <form autocomplete="off" @submit.prevent="save">
                <label class="label">Uw gebruikersnaam bij DeGIRO</label>
                <p class="control">
                  <input
                    name="form.degiro_username"
                    class="input"
                    type="text"
                    placeholder="Uw gebruikersnaam"
                    v-model="form.degiro_username"
                    autocomplete="off"
                  >
                  <form-field-errors :errors="form.errors" field="degiro_username"></form-field-errors>
                </p>
              </form>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <button
              @click="save()"
              class="button is-primary"
              :class="{'is-loading': form.loading}"
            >Tussentijds opslaan</button>
            <button
              @click="next()"
              class="button is-primary is-pulled-right"
              :class="{'is-loading': form.loading}"
            >Opslaan en verder</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: this.$parent.form.custodian
    };
  },

  beforeMount() {
    if (!this.form.custodian) {
      this.form = {
        next: false,
        custodian: "",
        degiro_username: ""
      };

      this.form = new Laravel.Form(this.form);
    }
  },

  methods: {
    save() {
      this.$parent.save(this.form);
    },

    next() {
      this.$parent.save(this.form, true);
    }
  }
};
</script>
