<template>

    <div>

        <section class="section no-background no-header">
            <div class="container">

                <div class="columns">
                    <div class="column content">
                        <div class="block" v-if="!plan">
                            <p>Er is nog geen plan voor u aangemaakt. Kom op een later moment terug om uw beleggingsplan te bekijken.</p>
                        </div>
                        <div class="block" v-if="plan">
                            <b-tabs :animated="false">
                                <b-tab-item label="Plan">
                                    <div class="columns">
                                        <div class="column is-4">
                                            <h4 class="title is-4">Inhoudelijk</h4>

                                            <div class="field">
                                                <label class="label">Risicoprofiel / Plan</label>
                                                <p class="control">{{ plan.plan.name }}</p>
                                            </div>

                                            <div class="field">
                                                <label class="label">Bedrag</label>
                                                <p class="control" v-if="plan.amount">&euro; {{ plan.amount | currency }}</p>
                                            </div>

                                            <div class="field" v-if="plan.objective">
                                                <label class="label">Beleggingsdoel</label>
                                                <p class="control" v-if="plan.objective == 'retention'">Instandhouding vermogen</p>
                                                <p class="control" v-if="plan.objective == 'grow'">Vermogensgroei</p>
                                                <p class="control" v-if="plan.objective == 'not_necessary_addition'">Niet noodzakelijke aanvulling op het inkomen</p>
                                                <p class="control" v-if="plan.objective == 'necessary_addition'">Noodzakelijke aanvulling op het inkomen</p>
                                                <p class="control" v-if="plan.objective == 'repayment_mortgage'">Aflossing hypotheek</p>
                                                <p class="control" v-if="plan.objective == 'children_study'">Financiering studie</p>
                                                <p class="control" v-if="plan.objective == 'specific_spend_feature'">Specifieke uitgave in de toekomst</p>
                                                <p class="control" v-if="plan.objective == 'other'">Anders</p>

                                            </div>

                                            <div class="field">
                                                <label class="label">Horizon</label>
                                                <p class="control">{{ plan.calculated_horizon }} jaar</p>
                                            </div>

                                            <div class="field">
                                                <label class="label">Gewenst rendement over looptijd</label>
                                                <p class="control">{{ plan.rate_of_return }}%</p>
                                            </div>

                                            <div class="field">
                                                <label class="label">Doelbedrag</label>
                                                <p class="control">€{{ plan.target_amount | currency }}</p>
                                            </div>

                                            <div class="field">
                                                <label class="label">Beschrijving</label>
                                                <p class="control" v-if="plan.description" v-html="plan.description"></p>
                                                <p class="control" v-else><em>Geen beschrijving opgegeven</em></p>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab-item>
                                <b-tab-item label="Verwachte kosten">
                                    <ExpectedCosts :data="plan.expectedCosts"></ExpectedCosts>
                                </b-tab-item>
                            </b-tabs>
                        </div>
                    </div>
                </div>

            </div>
        </section>

    </div>

</template>

<script>
import ExpectedCosts from "@customer/plan/ExpectedCosts.vue";

    export default {

        components: {
            'ExpectedCosts': ExpectedCosts,
        },

        data() {
            return {
                plan: null,
                loading: false,
                showExpectedCosts: false,
            }
        },

        beforeMount() {
            // this.plan = this.user.current_plan

            axios.get('/api/plan')
                .then(({ data }) => {
                    this.plan = data
                })
                .catch(() => {
                    this.$buefy.snackbar.open('Kon overzicht niet laden.')
                })

            // if(this.proposals[this.$route.params.id]) {
            //     this.data = this.proposals[this.$route.params.id]
            // } else {
            //     this.getProposal(this.$route.params.id)
            //         .then(() => {
            //             this.data = this.proposals[this.$route.params.id]
            //         })
            //         .catch((error) => {
            //             alert("Kon voorstel niet ophalen van de server.")
            //         })
            // }
        },

        computed: {
            user() {
                return this.$root.user
            }
        },
    }

</script>
