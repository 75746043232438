<template>
  <div>
    <page-navigation :activePage="page" :isBusiness="isBusiness"></page-navigation>
    <section class="section no-background no-header" v-if="!page">
      <div class="container">
        <div class="columns has-content-centered">
          <div class="column is-8 content" v-if="!denied">
            <div class="block">
              <h3 class="title is-3">Inventarisatie</h3>

              <p>Wij verzoeken u vriendelijk de gevraagde gegevens zo volledig mogelijk in te vullen. Wij hebben deze informatie nodig om een goede beoordeling te kunnen maken en het stelt ons in staat uw belangen optimaal te behartigen.</p>

              <p>Indien u de relevante (financiële) gegevens beschikbaar heeft zal het invullen van de inventarisatie ongeveer 10 minuten in beslag nemen. Tussentijds is het mogelijk de ingevulde gegevens op te slaan door op de knop ‘opslaan ‘te klikken.</p>

              <p>Bedragen kunt u afronden op hele getallen. Bedragen die voor u niet van toepassing zijn kunt u op 0 laten staan.</p>
              <hr>

              <div class="has-text-centered">
                <router-link
                  tag="button"
                  :to="{ name: 'prospect', params: { page: 'bank_accounts' }}"
                  class="button is-primary"
                >Ga verder</router-link>
              </div>
            </div>
          </div>

          <div class="column is-8" v-if="denied">
            <div class="block">
              <h3 class="title is-3">Inventariseren niet mogelijk</h3>

              <p>Het is voor u niet mogelijk om het inventarisatieformulier in te vullen. Naar alle waarschijnlijkheid heeft u nog niet de status 'prospect'.</p>

              <p>Wanneer u er van overtuigt bent wel toegang te moeten hebben tot dit formulier, neemt u dan alstublieft contact op met ons.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div v-if="loaded">
      <bank-accounts v-if="page == 'bank_accounts'"></bank-accounts>
      <business-assets-and-liabilities v-if="page == 'business_assets_and_liabilities'"></business-assets-and-liabilities>
      <assets-and-liabilities v-if="page == 'assets_and_liabilities'"></assets-and-liabilities>
      <financial-position v-if="page == 'financial_position'"></financial-position>
      <horizon-and-objective v-if="page == 'horizon_and_objective'"></horizon-and-objective>
      <risk-attitude v-if="page == 'risk_attitude'"></risk-attitude>
      <knowledge-and-experience v-if="page == 'knowledge_and_experience'"></knowledge-and-experience>
      <identification v-if="page == 'identification'"></identification>
      <custodian v-if="page == 'custodian'"></custodian>
      <done v-if="page == 'done'"></done>
    </div>
  </div>
</template>

<script>
import PageNavigation from "@prospectForm/PageNavigation.vue";
import BankAccounts from "@prospectForm/BankAccounts.vue";
import BusinessAssetsAndLiabilities from "@prospectForm/BusinessAssetsAndLiabilities.vue";
import AssetsAndLiabilities from "@prospectForm/AssetsAndLiabilities.vue";
import FinancialPosition from "@prospectForm/FinancialPosition.vue";
import HorizonAndObjective from "@prospectForm/HorizonAndObjective.vue";
import RiskAttitude from "@prospectForm/RiskAttitude.vue";
import KnowledgeAndExperience from "@prospectForm/KnowledgeAndExperience.vue";
import Identification from "@prospectForm/Identification.vue";
import Custodian from "@prospectForm/Custodian.vue";
import Done from "@prospectForm/Done.vue";

export default {
  data() {
    return {
      denied: false,
      loaded: false,
      isBusiness: false,
      steps: [
        "bank_accounts",
        "assets_and_liabilities",
        "financial_position",
        "horizon_and_objective",
        "risk_attitude",
        "knowledge_and_experience",
        "identification",
        "custodian"
      ],
      form: {},
      prospect: {}
    };
  },

  components: {
    "page-navigation": PageNavigation,
    "bank-accounts": BankAccounts,
    "business-assets-and-liabilities": BusinessAssetsAndLiabilities,
    "assets-and-liabilities": AssetsAndLiabilities,
    "financial-position": FinancialPosition,
    "horizon-and-objective": HorizonAndObjective,
    "risk-attitude": RiskAttitude,
    "knowledge-and-experience": KnowledgeAndExperience,
    identification: Identification,
    custodian: Custodian,
    done: Done,
  },

  computed: {
    page: function() {
      return this.$route.params.page;
    }
  },

  beforeMount() {
    axios
      .get("/api/prospect")
      .then(({ data }) => {
        this.prospect = data.prospect;
        Object.entries(data).forEach(item => {
          this.form[item[0]] = new Laravel.Form(
            item[1] ? { next: false, ...item[1] } : { next: false }
          );
        });
        this.loaded = true;
        this.redirectToRightPage();
        this.changeAccountType(
          data.bank_accounts &&
            data.bank_accounts.hasOwnProperty("accountholder_1") &&
            data.bank_accounts.accountholder_1 &&
            data.bank_accounts.accountholder_1.account_type === "business"
            ? true
            : false
        );
      })
      .catch(error => {
        this.$buefy.snackbar.open("Kon formulier niet openen.");
      });
  },

  methods: {
    redirectToRightPage() {
      if (this.prospect.completed_custodian) {
        this.$router.push({ name: "prospect", params: { page: "done" } });
      } else if (this.prospect.completed_identification) {
        this.$router.push({ name: "prospect", params: { page: "custodian" } });
      } else if (this.prospect.completed_knowledge_and_experience) {
        this.$router.push({
          name: "prospect",
          params: { page: "identification" }
        });
      } else if (this.prospect.completed_risk_attitude) {
        this.$router.push({
          name: "prospect",
          params: { page: "knowledge_and_experience" }
        });
      } else if (this.prospect.completed_horizon_and_objective) {
        this.$router.push({
          name: "prospect",
          params: { page: "risk_attitude" }
        });
      } else if (this.prospect.completed_financial_position) {
        this.$router.push({
          name: "prospect",
          params: { page: "horizon_and_objective" }
        });
      } else if (this.prospect.completed_assets_and_liabilities) {
        this.$router.push({
          name: "prospect",
          params: { page: "financial_position" }
        });
      } else if (this.prospect.completed_business_assets_and_liabilities) {
        this.$router.push({
          name: "prospect",
          params: { page: "assets_and_liabilities" }
        });
      } else if (this.prospect.completed_bank_accounts) {
        this.$router.push({
          name: "prospect",
          params: { page: "business_assets_and_liabilities" }
        });
      } else {
        this.$router.push({ name: "prospect", params: {} }).catch(() => false);
      }
    },

    save(form, goNext = false, formData = false) {
      form.next = goNext;

      let url;

      if (this.page == "bank_accounts") {
        url = "api/prospect/bank_accounts";
      }

      if (this.page == "business_assets_and_liabilities") {
        url = "api/prospect/business_assets_and_liabilities";
      }

      if (this.page == "assets_and_liabilities") {
        url = "api/prospect/assets_and_liabilities";
      }

      if (this.page == "financial_position") {
        url = "api/prospect/financial_position";
      }

      if (this.page == "horizon_and_objective") {
        url = "api/prospect/horizon_and_objective";
      }

      if (this.page == "risk_attitude") {
        url = "api/prospect/risk_attitude";
      }

      if (this.page == "knowledge_and_experience") {
        url = "api/prospect/knowledge_and_experience";
      }

      if (this.page == "identification") {
        url = "api/prospect/identification";
      }

      if (this.page == "custodian") {
        url = "api/prospect/custodian";
      }

      form.post(url, false, formData).then(
        data => {
          this.$buefy.snackbar.open(`Gegevens succesvol opgeslagen.`);

          if (goNext) {
            this.prospect["completed_" + this.page] = true;
            this.redirectToRightPage();
          }

        },
        () => {
          console.log("error saving");
        }
      );
    },

    changeAccountType(isBusiness) {
      this.isBusiness = isBusiness;

      if (isBusiness) {
        this.steps.splice(1, 0, "business_assets_and_liabilities");
        // this.prospect.completed_business_assets_and_liabilities = null;
      } else {
        this.prospect.completed_business_assets_and_liabilities = true;
        if (this.steps.indexOf("business_assets_and_liabilities") > -1) {
          this.steps.splice(1, 1);
        }
      }
    }
  }
};
</script>
