<template>
  <div>
    <section class="section content no-header">
      <div class="container">
        <div class="columns">
          <div class="column is-6">
            <div class="block full-height">
              <h4 class="title is-4">Jouw openstaande taken</h4>

              <table class="table is-striped">
                <thead>
                  <tr>
                    <th>Onderwerp</th>
                    <th>Datum</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td v-if="!data || !data.tasks || !data.tasks.length" colspan="3">
                      <center>Geen openstaande taken</center>
                    </td>
                  </tr>
                  <tr class="has-pointer" v-for="task in data.tasks" v-bind:key="task.id">
                    <td @click="openCustomer(task.customer_id)">
                      <strong>{{ task.customer.fullName }}</strong>
                      : {{ task.description }}
                    </td>
                    <td @click="openCustomer(task.customer_id)" nowrap>{{ task.date | formatDate }}</td>
                    <td>
                      <button class="button is-success" @click="taskDone(task.id)">
                        <i class="fa fa-check"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="has-text-right">
                <router-link :to="{ name: 'admin/tasks' }" class="button is-primary">Meer taken</router-link>
              </div>
            </div>
          </div>

          <div class="column is-6">
            <div class="block full-height">
              <h4 class="title is-4">Hebben contact nodig (gekoppeld)</h4>
              <table class="table is-striped">
                <thead>
                  <th>Naam</th>
                  <th>Laatst gecontacteerd</th>
                </thead>
                <tbody>
                  <tr>
                    <td v-if="!data || !data.tasks || !data.tasks.length" colspan="2">
                      <center>Geen klanten</center>
                    </td>
                  </tr>
                  <tr
                    class="has-pointer"
                    v-for="row in data.contact"
                    v-bind:key="row.id"
                    @click="openCustomer(row.id)"
                  >
                    <td>{{ row.fullName }}</td>
                    <td>{{ timeAgo(row.latestContactNote.date_time) }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="has-text-right">
                <router-link
                  :to="{ name: 'admin/customers/contact' }"
                  class="button is-primary">
                  Meer klanten
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-6">
            <div class="block full-height">
              <notification-widget :notifications="data.notifications"></notification-widget>
            </div>
          </div>

          <div class="column is-6">
            <div class="block full-height">
              <h4 class="title is-4">Laatst binnengekomen prospects</h4>
              <table class="table is-striped">
                <thead>
                  <th>Naam</th>
                  <th>Datum</th>
                  <th>Type lead</th>
                </thead>
                <tbody>
                  <tr
                    class="has-pointer"
                    v-for="prospect in data.prospects"
                    @click="openCustomer(prospect.user.id)"
                    v-bind:key="prospect.id"
                  >
                    <td>{{ prospect.user.fullName }}</td>
                    <td>{{ prospect.created_at | formatDate('D-M-Y HH:mm:ss') }}</td>
                    <td>{{ prospect.user.lead_type }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="has-text-right">
                <router-link
                  :to="{ name: 'admin/customers/prospects' }"
                  class="button is-primary"
                >Meer prospects</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {}
    };
  },

  beforeMount() {
    this.getAdminDashboard();
  },

  computed: {},

  methods: {
    getAdminDashboard() {
      axios.get("/api/admin/dashboard").then(({ data }) => {
        this.data = data;
      }).catch(() => {this.$buefy.snackbar.open('Kon niet laden.')});
    },

    openCustomer(id) {
      this.$router.push({ name: "admin/customer", params: { id } });
    },

    taskDone(id) {
      this.$buefy.dialog.confirm({
        message: "Deze taak voltooid en verwijderen?",
        cancelText: "Nee",
        confirmText: "Ja",
        onConfirm: () => {
          axios
            .delete(`/api/admin/tasks/${id}`)
            .then(() => {
              this.$buefy.snackbar.open(`Taak succesvol verwijderd.`);
              this.getAdminDashboard();
            })
            .catch(() => {
              this.$buefy.snackbar.open(
                `Kon taak niet verwijderd, waarschijnlijk omdat het al verwijderd is.`
              );
            });
        }
      });
    },

    timeAgo(date) {
      return moment(date).locale('nl').fromNow()
    }
  }
};
</script>
