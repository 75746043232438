<template>
  <div>
    <div class="field" v-if="data">
      <label class="label">Keuze depotbank</label>
      <p v-if="data.custodian == 'degiro'">DeGIRO</p>
      <p v-if="data.custodian == 'binck'">BINCK BANK</p>
    </div>

    <div class="field" v-if="data">
       <label class="label">Uw gebruikersnaam bij DeGIRO</label>
      <p>{{ data.degiro_username }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],

  data() {
    return {
      password: null,
    }
  },
};
</script>
