<template>
  <div>
    <div class="block">
      <h4 class="title is-4">Beoogd rendement van {{ (data.name).toLowerCase() }} bewerken</h4>

      <form-errors :errors="plan.errors"></form-errors>

      <table class="table is-striped">
        <thead>
          <tr>
            <th width="40%">Veld</th>
            <th width="40%">Percentage</th>
            <th width="20%">Bewerken</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Beoogd renemdentspercentage per jaar</td>
            <td>
              <span v-if="currentPlanEdit !== 0">{{ plan.intended_yearly_return | currency }}%</span>
              <input
                v-if="currentPlanEdit === 0"
                type="text"
                class="input"
                v-model="plan.intended_yearly_return"
              >
            </td>
            <td>
              <button
                v-if="currentPlanEdit !== 0"
                type="button"
                class="button is-primary"
                @click="toggleEditPlan(0)"
              >Bewerken</button>
              <button
                v-if="currentPlanEdit === 0"
                type="button"
                class="button is-primary"
                :class="{'is-loading': plan.loading}"
                @click="saveEditPlan()"
              >Opslaan</button>
            </td>
          </tr>
          <tr>
            <td>Ondergrens rendementspercentage
              <br>
              <small>Bij welk resterend benodigd jaarlijks rendementspercentage moet een melding worden gegeven dat het plan afwijkt?</small>
            </td>
            <td>
              <span v-if="currentPlanEdit !== 1">{{ plan.intended_yearly_return_min | currency }}%</span>
              <input
                v-if="currentPlanEdit === 1"
                type="text"
                class="input"
                v-model="plan.intended_yearly_return_min"
              >
            </td>
            <td>
              <button
                v-if="currentPlanEdit !== 1"
                type="button"
                class="button is-primary"
                @click="toggleEditPlan(1)"
              >Bewerken</button>
              <button
                v-if="currentPlanEdit === 1"
                type="button"
                class="button is-primary"
                :class="{'is-loading': plan.loading}"
                @click="saveEditPlan()"
              >Opslaan</button>
            </td>
          </tr>
          <tr>
            <td>Bovengrens rendementspercentage
              <br>
              <small>Bij welk resterend benodigd jaarlijks rendementspercentage moet een melding worden gegeven dat het plan afwijkt?</small>
            </td>
            <td>
              <span v-if="currentPlanEdit !== 2">{{ plan.intended_yearly_return_max | currency }}%</span>
              <input
                v-if="currentPlanEdit === 2"
                type="text"
                class="input"
                v-model="plan.intended_yearly_return_max"
              >
            </td>
            <td>
              <button
                v-if="currentPlanEdit !== 2"
                type="button"
                class="button is-primary"
                @click="toggleEditPlan(2)"
              >Bewerken</button>
              <button
                v-if="currentPlanEdit === 2"
                type="button"
                class="button is-primary"
                :class="{'is-loading': plan.loading}"
                @click="saveEditPlan()"
              >Opslaan</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="block">
      <h4 class="title is-4">Modelportefeuille bewerken: {{ data.name }}</h4>

      <form-errors :errors="etfEditForm.errors"></form-errors>

      <table class="table is-striped">
        <thead>
          <tr>
            <th>Naam fonds / beleggingsinstrument</th>
            <th>Percentage van beleggingsportefeuille</th>
            <th>Toegestane stijging / daling</th>
            <th>Bewerken</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(etf, index) in data.etfs" v-bind:key="etf.id">
            <td>
              <span v-if="currentETFEdit != index">{{ etf.name }} ({{ etf.isin }})</span>
              <span class="select" v-if="currentETFEdit == index">
                <select name="etf_id" v-model="etfEditForm.etf_id">
                  <option value disabled hidden>Maak een keuze</option>
                  <option :value="etfEditForm.originalEtfId">{{ etf.name }} ({{ etf.isin }})</option>
                  <option
                    v-for="etf in available_etfs"
                    v-bind:key="etf.id"
                    :value="etf.id"
                  >{{ etf.name }} ({{ etf.isin }})</option>
                </select>
              </span>
            </td>
            <td>
              <span v-if="currentETFEdit != index">{{ etf.pivot.proportion | currency }}%</span>
              <input
                type="text"
                name="proportion"
                class="input"
                v-if="currentETFEdit == index"
                v-model="etfEditForm.proportion"
              >
            </td>
            <td>
              <span v-if="currentETFEdit != index">{{ etf.pivot.allowed_increse_decrese }}</span>
              <input
                type="text"
                name="allowed_increse_decrese"
                class="input"
                v-if="currentETFEdit == index"
                v-model="etfEditForm.allowed_increse_decrese"
              >
            </td>
            <td>
              <button
                class="button is-primary"
                @click="toggleEditETF(index)"
                v-if="currentETFEdit != index"
              >Bewerken</button>
              <button
                class="button is-danger"
                @click="removeETF(index, etf.id)"
                v-if="currentETFEdit != index"
              >Verwijderen van profiel</button>
              <button
                class="button is-primary"
                @click="saveEditETF()"
                v-if="currentETFEdit == index"
                :class="{'is-loading': etfEditForm.loading }"
              >Opslaan</button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td>{{ totalPercentage }}%</td>
            <td></td>
            <td></td>
          </tr>
          <tr v-if="!showAddETF">
            <td colspan="4">
              <button
                class="button is-primary"
                @click="toggleAddETF()"
              >Voeg nieuwe ETF toe aan {{ data.name }}</button>
            </td>
          </tr>
          <tr v-if="showAddETF">
            <td>
              <span class="select">
                <select name="etf_id" v-model="addEtfForm.etf_id">
                  <option value disabled hidden>Maak een keuze</option>
                  <option
                    v-for="etf in available_etfs"
                    :value="etf.id"
                  >{{ etf.name }} ({{ etf.isin }})</option>
                </select>
              </span>
            </td>
            <td>
              <input type="text" class="input" name="proportion" v-model="addEtfForm.proportion">
            </td>
            <td>
              <input
                type="text"
                class="input"
                name="allowed_increse_decrese"
                v-model="addEtfForm.allowed_increse_decrese"
              >
            </td>
            <td>
              <button
                class="button is-primary"
                @click="addETF()"
                :class="{ 'is-loading': addEtfForm.loading }"
              >ETF toevoegen</button>
            </td>
          </tr>
        </tfoot>
      </table>

      <form-errors :errors="addEtfForm.errors"></form-errors>

      <h4 class="title is-4">Asset verdeling</h4>

      <form-errors :errors="assetClassForm.errors"></form-errors>

      <table class="table is-striped">
        <thead>
          <tr>
            <th>Asset Class</th>
            <th>Default</th>
            <th>Bandbreedte min</th>
            <th>Bandbreedte max</th>
            <th>Bewerken</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(assetClass, index) in data.asset_classes" v-bind:key="assetClass.id">
            <td>{{ assetClass.name }}</td>
            <td>
              <span v-if="currentAssetClassEdit != index">{{ assetClass.pivot.partion }}</span>
              <input
                type="text"
                class="input"
                v-if="currentAssetClassEdit == index"
                v-model="assetClassForm.partion"
              >
            </td>
            <td>
              <span v-if="currentAssetClassEdit != index">{{ assetClass.pivot.bandwidth_min }}</span>
              <input
                type="text"
                class="input"
                v-if="currentAssetClassEdit == index"
                v-model="assetClassForm.bandwidth_min"
              >
            </td>
            <td>
              <span v-if="currentAssetClassEdit != index">{{ assetClass.pivot.bandwidth_max }}</span>
              <input
                type="text"
                class="input"
                v-if="currentAssetClassEdit == index"
                v-model="assetClassForm.bandwidth_max"
              >
            </td>
            <td>
              <button
                class="button is-primary"
                @click="toggleEditAssetClass(index)"
                v-if="currentAssetClassEdit != index"
              >Bewerken</button>
              <button
                class="button is-primary"
                @click="saveEditAssetClass()"
                v-if="currentAssetClassEdit == index"
                :class="{'is-loading': assetClassForm.loading }"
              >Opslaan</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["index"],

  data() {
    return {
      showAddETF: false,
      currentETFEdit: null,
      currentPlanEdit: null,
      currentAssetClassEdit: null,
      totalPercentage: 0,
      assetClassForm: new Laravel.Form(),
      etfEditForm: new Laravel.Form(),
      addEtfForm: new Laravel.Form({
        etf_id: "",
        proportion: null,
        allowed_increse_decrese: null
      }),
      plan: new Laravel.Form()
    };
  },

  beforeMount() {
    this.initData();
  },

  computed: {
    data() {
      return this.$parent.plans[this.index];
    },

    available_etfs() {
      return this.$parent.available_etfs;

      // return this.$parent.available_etfs.filter(etf => {
      //   return !!this.$parent.plans[this.index].etfs.find(({ id }) => {
      //     return etf.id === id;
      //   });
      // });
    }
  },

  methods: {
    initData() {
      this.calculateProportion();
      this.plan = new Laravel.Form(this.$parent.plans[this.index]);
    },

    toggleAddETF() {
      this.showAddETF = !this.showAddETF;
    },

    addETF() {
      this.addEtfForm
        .post(`/api/admin/plan/${this.$parent.plans[this.index].id}/etf`)
        .then(response => {
          Object.entries(response).forEach(([key, value]) => {
            this.$set(this.$parent.plans[this.index], key, value);
          });

          this.calculateProportion();
          this.toggleAddETF();
        })
        .catch(() => {});
    },

    toggleEditETF(index) {
      let form = this.$parent.plans[this.index].etfs[index].pivot;
      form.originalEtfId = this.$parent.plans[this.index].etfs[
        index
      ].pivot.etf_id;

      this.etfEditForm = new Laravel.Form(form);
      this.currentETFEdit = index;
    },

    saveEditETF() {
      this.etfEditForm
        .put(
          `/api/admin/plan/${this.etfEditForm.plan_id}/etf/${
            this.etfEditForm.originalEtfId
          }`
        )
        .then(response => {
          Object.entries(response).forEach(([key, value]) => {
            this.$set(this.$parent.plans[this.index], key, value);
          });

          this.calculateProportion();
          this.currentETFEdit = null;
        })
        .catch(err => {
          console.error(err);
        });
    },

    removeETF(index, etf) {
      axios
        .delete(
          `/api/admin/plan/${this.$parent.plans[this.index].id}/etf/${etf}`
        )
        .then(({ data }) => {
          Object.entries(response).forEach(([key, value]) => {
            this.$set(this.$parent.plans[this.index], key, value);
          });

          this.calculateProportion();
          this.currentETFEdit = null;
        });
    },

    toggleEditAssetClass(index) {
      this.assetClassForm = new Laravel.Form(
        this.$parent.plans[this.index].asset_classes[index].pivot
      );
      this.currentAssetClassEdit = index;
    },

    saveEditAssetClass() {
      this.assetClassForm
        .put(
          `/api/admin/plan/${this.assetClassForm.plan_id}/assetClass/${
            this.assetClassForm.asset_class_id
          }`
        )
        .then(response => {
          Object.entries(response).forEach(([key, value]) => {
            this.$set(this.$parent.plans[this.index], key, value);
          });

          this.calculateProportion();
          this.currentAssetClassEdit = null;
        })
        .catch((err) => {
            console.error(err)
        });
    },

    calculateProportion() {
      this.totalPercentage = 0;
      if (this.data) {
        for (let item of this.data.etfs) {
          this.totalPercentage += item.pivot.proportion;
        }
      }
    },

    toggleEditPlan(index = null) {
      this.currentPlanEdit = index;
    },

    saveEditPlan() {
      this.plan
        .put(`/api/admin/plan/${this.plan.id}`)
        .then(data => {
          this.$parent.plans[this.index] = data;
          this.$buefy.snackbar.open(`Plan succesvol opgeslagen.`);

          this.toggleEditPlan();
          this.plan = new Laravel.Form(data);
        })
        .catch(() => {
          this.$buefy.snackbar.open(`Kon plan niet opslaan.`);
        });
    }
  },

  watch: {
    index: function(val) {
      this.initData();
    }
  }
};
</script>
