<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <div class="block">
          <profile-form></profile-form>
        </div>
      </div>

      <div class="column is-6">
        <div class="block">
          <div class="columns">
            <div class="column">
              <h4 class="title is-4">Laatste notitie</h4>
            </div>
            <div class="column has-text-right">
              <router-link
                :to="{
                  name: 'admin/customer/notes',
                  params: { id: data.profile.id },
                }"
                class="button is-primary"
                v-if="Object.keys(data.profile).length"
                exact
              >
                Meer notities
                <span v-if="data.notes.length > 0"
                  >&nbsp;({{ data.notes.length }})</span
                >
              </router-link>
            </div>
          </div>
          <div class="card" v-if="data.notes.length">
            <header class="card-header">
              <p class="card-header-title">
                {{ data.notes[0].author
                }}<span
                  class="is-pulled-right"
                  v-if="data.notes[0].duration !== '0:00'"
                  >&nbsp;&bull; Tijdsduur:
                  {{ data.notes[0].duration }} uur</span
                >
              </p>
            </header>
            <div class="card-content">
              <p v-html="data.notes[0].note"></p>
              <small>{{
                data.notes[0].created_at | formatDate("D-M-Y HH:mm:ss")
              }}</small>
            </div>
          </div>
          <p v-if="!data.notes.length">
            <em>Er zijn nog geen notities geplaatst voor deze gebruiker.</em>
          </p>
        </div>

        <div class="block">
          <documents></documents>
        </div>

        <div class="block">
          <remarks></remarks>
        </div>
      </div>
    </div>

    <bank-accounts></bank-accounts>
  </div>
</template>

<script>

import Documents from "./components/Documents.vue";
import Remarks from "./components/Remarks.vue";
import BankAccounts from "./components/BankAccounts.vue";
import ProfileForm from "./components/ProfileForm.vue";

export default {
  data() {
    return {
      showSendMessage: false,
    };
  },

  components: {
    documents: Documents,
    "profile-form": ProfileForm,
    "bank-accounts": BankAccounts,
    remarks: Remarks,
  },

  computed: {
    data() {
      return this.$parent.data;
    },
  },

  methods: {
    toggleSendMessage() {
      this.showSendMessage = !this.showSendMessage;
    },
  },
};
</script>
