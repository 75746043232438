<template>
  <div>
    <create-plan v-if="showCreatePlan"></create-plan>

    <div class="block">
      <div class="columns">
        <div class="column">
          <h4 class="title is-4">(Beleggings)Plan</h4>
        </div>
        <div class="column has-text-right">
          <button class="button" @click="toggleCreatePlan">Nieuw plan aanmaken</button>
        </div>
      </div>

      <table class="table">
        <thead>
          <th>Plan aangemaakt</th>
          <th>Risicoprofiel</th>
          <th>Plan uitvouwen</th>
        </thead>
        <tbody>
          <tr v-for="(proposal, index) in data.proposals" v-bind:key="proposal.id">
            <td>
              {{ proposal.created_at | formatDate('D-M-Y') }} om {{ proposal.created_at | formatDate('HH:mm:ss') }}
              <span
                class="tag is-success is-medium"
                v-if="proposal.current_plan"
              >Actief</span>
            </td>
            <td>{{ proposal.plan.name }}</td>
            <td>
              <button
                class="button is-primary"
                v-if="openedForm != index"
                @click="openForm(index)"
              >Openen</button>
              <button class="button" v-if="openedForm == index">Geopend</button>
            </td>
          </tr>
          <tr v-if="data.proposals.length === 0">
            <td colspan="3">
              <center>Er is nog geen plan voor deze gebruiker aangemaakt.</center>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="block">
      <plan :index="openedForm"></plan>
    </div>
  </div>
</template>

<script>
import CreatePlan from "./components/CreatePlan.vue";
import Plan from "./components/Plan.vue";

export default {
  components: {
    Plan,
    CreatePlan
  },

  data() {
    return {
      openedForm: null,
      showCreatePlan: false
    };
  },

  computed: {
    data() {
      return this.$parent.data;
    }
  },

  methods: {
    openForm(index) {
      this.openedForm = index;
    },

    toggleCreatePlan() {
      this.showCreatePlan = !this.showCreatePlan;
    },

    openOnMount() {
      if (this.$parent.data && this.$parent.data.proposals) {
        this.$parent.data.proposals.forEach((proposal, index) => {
          if (proposal.current_plan) {
            this.openedForm = index;
          }
        });
      }
    }
  },

  mounted() {
    this.openOnMount();
  },

  watch: {
    "$parent.data.proposals": function(data) {
      this.openOnMount();
    }
  }
};
</script>
