<template>
  <div class="columns">
    <div class="column is-6">
      <div class="block">
        <h4 class="title is-4">Notitie toevoegen</h4>
        <form-errors :errors="note.errors"></form-errors>
        <form @submit.prevent="saveNote()">
          <label class="label">Tijdsduur van het gesprek</label>
          <div class="field">
            <span class="select">
              <select name="hours" v-model="note.duration_hours">
                <option value="0">0</option>
                <option v-for="n in 12" v-bind:key="n" :value="n">{{ n }}</option>
              </select>
            </span>
            <span class="control">uur</span>
            <span class="select">
              <select name="minutes" v-model="note.duration_minutes">
                <option value="00">00</option>
                <option
                  v-for="n in 59"
                  v-bind:key="n"
                  :value="n < 10 ? '0'+n : n"
                >{{ n < 10 ? '0'+n : n }}</option>
              </select>
            </span>
            <span class="control">minuten</span>
          </div>

          <div class="columns">
            <div class="column is-narrow">
              <label class="label">Soort</label>
              <div class="field">
                <p class="control">
                  <span class="select">
                    <select name="type" v-model="note.type">
                      <option value="contact">Contactmoment (180 dagen)</option>
                      <option value="review">KYC-review (360 dagen)</option>
                      <option value="note">Notitie</option>
                    </select>
                  </span>
                </p>
              </div>
            </div>
            <div class="column is-narrow" v-if="note.type == 'contact'">
              <label class="label">Type contactmoment</label>
              <div class="field">
                <span class="select">
                  <select v-model="note.contact_type">
                    <option value="telephone">Telefonisch</option>
                    <option value="person">In persoon</option>
                  </select>
                </span>
              </div>
            </div>
          </div>

          <div class="columns is-gapless" style="margin-bottom: 5px; align-items: flex-end">
            <div class="column">
              <label class="label">Notitie</label>
            </div>
            <div class="column is-narrow">
              <span class="select">
                <select v-model="template">
                  <option value>Selecteer template</option>
                  <option value="1">Orientatiegesprek</option>
                  <option value="2">Algemeen contact</option>
                  <option value="3">Servicegesprek portefeuille</option>
                  <option value="4">Prospectgesprek</option>
                </select>
              </span>
            </div>
          </div>

          <div class="field">
            <div class="control">
              <textarea name="note" class="textarea" v-model="note.note"></textarea>
            </div>
          </div>

          <label class="label">Datum</label>
          <div class="field has-addons">
            <div class="control">
              <b-datepicker name="date" v-model="note.date_time"></b-datepicker>
            </div>
            <div class="control">
              <b-timepicker name="time" placeholder="00:00" v-model="note.date_time"></b-timepicker>
            </div>
          </div>

          <div class="field">
            <button type="submit" class="button is-primary">Notitie toevoegen</button>
          </div>
        </form>
      </div>
    </div>

    <div class="column is-6">
      <div class="block">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h4 class="title is-4">Geplaatste notities</h4>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <button class="button" @click="openPrintableVersion()">Printbare versie</button>
            </div>
          </div>
        </div>

        <div v-for="note in data.notes" v-bind:key="note.id">
          <note :data="note" :customer="data.profile.id"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Note from "./components/Note.vue";

export default {
  components: {
    note: Note
  },

  data() {
    return {
      template: "",
      note: new Laravel.Form({
        note: "",
        type: "contact",
        contact_type: "telephone",
        date_time: new Date(),
        duration_hours: "0",
        duration_minutes: "00"
      })
    };
  },

  computed: {
    data() {
      return this.$parent.data;
    }
  },

  methods: {
    saveNote() {
      this.note
        .put(`api/admin/customers/${this.$parent.data.profile.id}/notes`)
        .then(data => {
          this.$parent.data.notes.unshift(data);
          this.$buefy.snackbar.open(`Notitie succesvol toegevoegd.`);
          this.note = new Laravel.Form({
            note: "",
            type: "note",
            contact: "telephone",
            date_time: new Date(),
            duration_hours: "0",
            duration_minutes: "00"
          });
        })
        .catch(() => {
          this.$buefy.snackbar.open(`Kon notitie niet toevoegen.`);
        });
    },

    openPrintableVersion() {
      this.$router.push({
        name: "admin/customer/notes/printable",
        params: { id: this.data.profile.id }
      });
    },

    setTemplate(template) {
      switch (template) {
        case "1":
          this.note.note = `Reden contact:

Vermogen:
Bij BeSmart:
Elders:
Risicoprofiel:
Overige vermogen:
Herkomst vermogen:

VVK Woning:
Hypotheek:

Inkomen & Uitgaven & Pensioen

Inkomen:    Waaruit:
Uitgaven:
Pensioen:   Waaruit:
- Vanaf wanneer:

Vermogensbeheer
Doelstelling:     Bij specifieke ds , wanneer:
Horizon:
Profielkeuze:

Leeftijd:
(voormalig) beroep:
Ervaring beleggen verleden:

Bijzonderheden:

Verslag:`;
          break;
        case "2":
          this.note.note = `Tijdstip:

Initiatief gesprek:

Plaats gesprek:

Actiepunten:

Samenvatting gesprek:
`;
          break;
        case "3":
          this.note.note = `Tijdstip:

Initiatief gesprek:

Plaats gesprek:

Rendement YTD:

Op basis van uw doelstellingen, kennis/ervaring en duurzaamheidsvoorkeuren blijft uw profiel:

Rendement sinds start:

Tevreden:

Actiepunten:

Opmerkingen:
`;
          break;
        case "4":
          this.note.note = `Verbeterpunten:

Voordelen van BeSmart:

Reden om over te stappen:

Volgende actie:

Details gesprek:
`;
          break;
      }
    }
  },

  watch: {
    template: function(val) {
      this.setTemplate(val);
    }
  }
};
</script>
