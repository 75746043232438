<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <div class="block">
          <profileForm></profileForm>
        </div>
      </div>

      <div class="column is-6">
        <div class="block">
          <documents></documents>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-6">
        <div class="block">
          <h4 class="title is-4">Beleggersrekening</h4>
          <div class="field">
            <label class="label">Instantie</label>
            <p
              class="control"
              v-if="profile.investor_account_instance"
            >{{ profile.investor_account_instance }}</p>
            <p class="control" v-else>
              <em>Nog niet opgegeven</em>
            </p>
          </div>
          <div class="field">
            <label class="label">Bankrekeningnummer</label>
            <p
              class="control"
              v-if="profile.investor_account_instance"
            >{{ profile.investor_account_account_number }}</p>
            <p class="control" v-else>
              <em>Nog niet opgegeven</em>
            </p>
          </div>
        </div>
      </div>

      <div class="column is-6">
        <div class="block">
          <h4 class="title is-4">Tegenrekening</h4>
          <div class="field">
            <label class="label">Instantie</label>
            <p
              class="control"
              v-if="profile.contra_account_instance"
            >{{ profile.contra_account_instance }}</p>
            <p class="control" v-else>
              <em>Nog niet opgegeven</em>
            </p>
          </div>
          <div class="field">
            <label class="label">Bankrekeningnummer</label>
            <p
              class="control"
              v-if="profile.contra_account_account_number"
            >{{ profile.contra_account_account_number }}</p>
            <p class="control" v-else>
              <em>Nog niet opgegeven</em>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileForm from "./ProfileForm.vue";
import Documents from "./Documents.vue";

export default {
  components: {
    profileForm: ProfileForm,
    documents: Documents,
  },

  computed: {
      profile() {
          return this.$root.user;
      }
  }
};
</script>
