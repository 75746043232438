<template>
  <div>
    <header class="modal-card-head">
      <p class="modal-card-title">Inventarisatieformulier bewerken</p>
      <button class="button" @click="close">Sluiten en niet opslaan</button>
    </header>
    <section class="modal-card-body beSmartBackground">
      <div class="container">
        <bank-accounts v-if="page == 'bank_accounts'"></bank-accounts>
        <business-assets-and-liabilities v-if="page == 'business_assets_and_liabilities'"></business-assets-and-liabilities>
        <assets-and-liabilities v-if="page == 'assets_and_liabilities'"></assets-and-liabilities>
        <financial-position v-if="page == 'financial_position'"></financial-position>
        <horizon-and-objective v-if="page == 'horizon_and_objective'"></horizon-and-objective>
        <risk-attitude v-if="page == 'risk_attitude'"></risk-attitude>
        <knowledge-and-experience v-if="page == 'knowledge_and_experience'"></knowledge-and-experience>
        <identification v-if="page == 'identification'"></identification>
        <custodian v-if="page == 'custodian'"></custodian>
        <done v-if="page == 'done'"></done>
      </div>
    </section>
  </div>
</template>

<script>
import PageNavigation from "@prospectForm/PageNavigation.vue";
import BankAccounts from "@prospectForm/BankAccounts.vue";
import BusinessAssetsAndLiabilities from "@prospectForm/BusinessAssetsAndLiabilities.vue";
import AssetsAndLiabilities from "@prospectForm/AssetsAndLiabilities.vue";
import FinancialPosition from "@prospectForm/FinancialPosition.vue";
import HorizonAndObjective from "@prospectForm/HorizonAndObjective.vue";
import RiskAttitude from "@prospectForm/RiskAttitude.vue";
import KnowledgeAndExperience from "@prospectForm/KnowledgeAndExperience.vue";
import Identification from "@prospectForm/Identification.vue";
import Custodian from "@prospectForm/Custodian.vue";
import Done from "@prospectForm/Done.vue";

export default {
  props: ["page", "id"],

  data() {
    return {
      form: {}
    };
  },

  components: {
    "bank-accounts": PageNavigation,
    "business-assets-and-liabilities": BankAccounts,
    "assets-and-liabilities": BusinessAssetsAndLiabilities,
    "financial-position": AssetsAndLiabilities,
    "horizon-and-objective": FinancialPosition,
    "risk-attitude": HorizonAndObjective,
    "knowledge-and-experience": RiskAttitude,
    identification: KnowledgeAndExperience,
    custodian: Identification,
    done: Custodian,
  },

  beforeMount() {
    let data = this.$parent.$parent.prospect;

    Object.entries(data).forEach(item => {
      this.form[item[0]] = new Laravel.Form(
        item[1] ? { next: false, ...item[1] } : { next: false }
      );
    });
  },

  methods: {
    redirectToRightPage() {
      return false;
    },

    close() {
      if(window.confirm('Weet je zeker dat je het venster wil sluiten zonder de wijzigingen op te slaan?')) {
        this.$parent.$parent.showEdit = false;
      }
    },

    save(form, goNext = false, formData = false) {
      let url;

      if (this.page == "bank_accounts") {
        url = "api/prospect/bank_accounts";
      }

      if (this.page == "business_assets_and_liabilities") {
        url = "api/prospect/business_assets_and_liabilities";
      }

      if (this.page == "assets_and_liabilities") {
        url = "api/prospect/assets_and_liabilities";
      }

      if (this.page == "financial_position") {
        url = "api/prospect/financial_position";
      }

      if (this.page == "horizon_and_objective") {
        url = "api/prospect/horizon_and_objective";
      }

      if (this.page == "risk_attitude") {
        url = "api/prospect/risk_attitude";
      }

      if (this.page == "knowledge_and_experience") {
        url = "api/prospect/knowledge_and_experience";
      }

      if (this.page == "identification") {
        url = "api/prospect/identification";
      }

      if (this.page == "custodian") {
        url = "api/prospect/custodian";
      }

      form.prospect_id = this.id;
      form.originalData.prospect_id = this.id;

      form
        .post(url, false, formData)
        .then(() => {
          this.$buefy.snackbar.open("Formulier succesvol opgeslagen");
          this.$parent.$parent.showEdit = false;
          location.reload()
        })
        .catch(() => {
          this.$buefy.snackbar.open("Kon formulier niet opslaan.");
        });
    },

    changeAccountType() {
      return false
    }
  }
};
</script>

<style>
.modal-card-body .container {
  width: 100% !important;
  max-width: 100% !important;
}

.modal-card-body.beSmartBackground {
  background-color: #f7f8f3 !important;
}
</style>
