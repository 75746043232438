<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <h4 class="title is-4">Beleggingshorizon</h4>
        <div class="field">
          <label class="label">Wat is de horizon voor uw beleggingen?</label>
          <div v-if="data.horizon">
            <p v-if="data.horizon.horizon < 31">{{ data.horizon.horizon }} jaar</p>
            <p v-if="data.horizon.horizon == '1 - 2'">1 - 2 jaar</p>
            <p v-if="data.horizon.horizon == '3 - 5'">3 - 5 jaar</p>
            <p v-if="data.horizon.horizon == '6 - 10'">6 - 10 jaar</p>
            <p v-if="data.horizon.horizon == '11 - 15'">11 - 15 jaar</p>
          </div>
        </div>

        <div class="field">
          <label
            class="label"
          >In hoeverre bent u, nu of in de toekomst, afhankelijk van de beleggingsresultaten uit uw vermogen?</label>
          <div v-if="data.horizon">
            <p v-if="data.horizon.dependence == 'niet'">niet afhankelijk</p>
            <p v-if="data.horizon.dependence == 'deels'">deels afhankelijk</p>
            <p v-if="data.horizon.dependence == 'geheel'">geheel afhankelijk</p>
          </div>
        </div>
      </div>

      <div class="column is-6">
        <h4 class="title is-4">Beleggingsdoelstelling</h4>

        <div class="field">
          <label class="label">Wat is het doel van uw beleggingen?</label>
          <p class="control" v-if="Object.keys(data.objective).length > 0">
            <label class="checkbox">
              <input type="checkbox" v-model="data.objective.retention.checked" disabled="disabled">
              Instandhouding vermogen (bijhouden inflatie + belasting)
            </label>
          </p>

          <p class="control" v-if="Object.keys(data.objective).length > 0">
            <label class="checkbox">
              <input type="checkbox" v-model="data.objective.grow.checked" disabled="disabled">
              Vermogensgroei
            </label>
          </p>

          <div v-if="Object.keys(data.objective).length > 0">
            <p class="control">
              <label class="checkbox">
                <input
                  type="checkbox"
                  v-model="data.objective.not_necessary_addition.checked"
                  disabled="disabled"
                >
                Niet noodzakelijke aanvulling op het inkomen
              </label>
            </p>

            <div v-if="data.objective.not_necessary_addition.checked" class="control">
              <div class="field">
                <label class="label">Bedrag</label>
                <p>&euro; {{ data.objective.not_necessary_addition.amount | currency }}</p>
              </div>

              <div class="field">
                <label class="label">Periodiciteit</label>
                <p>{{ data.objective.not_necessary_addition.periodicity }}</p>
              </div>

              <div class="field">
                <label class="label">Vanaf (jaartal)</label>
                <p>{{ data.objective.not_necessary_addition.starting_from }}</p>
              </div>
            </div>
          </div>

          <br>

          <div v-if="Object.keys(data.objective).length > 0">
            <p class="control">
              <label class="checkbox">
                <input
                  type="checkbox"
                  v-model="data.objective.necessary_addition.checked"
                  disabled="disabled"
                >
                Noodzakelijke aanvulling op het inkomen
              </label>
            </p>

            <div v-if="data.objective.necessary_addition.checked" class="control">
              <div class="field">
                <label class="label">Bedrag</label>
                <p>{{ data.objective.necessary_addition.amount | currency }}</p>
              </div>

              <div class="field">
                <label class="label">Periodiciteit</label>
                <p>{{ data.objective.necessary_addition.periodicity }}</p>
              </div>

              <div class="field">
                <label class="label">Vanaf (jaartal)</label>
                <p>{{ data.objective.necessary_addition.starting_from }}</p>
              </div>
            </div>
          </div>

          <br>

          <p class="control" v-if="Object.keys(data.objective).length > 0">
            <label class="checkbox">
              <input
                type="checkbox"
                v-model="data.objective.repayment_mortgage.checked"
                disabled="disabled"
              >
              Aflossing hypotheek
            </label>
          </p>

          <div v-if="Object.keys(data.objective).length > 0 && data.objective.repayment_mortgage.checked" class="control">
            <div class="field">
              <label class="label">Vanaf (jaartal)</label>
              <p>{{ data.objective.repayment_mortgage.starting_from }}</p>
            </div>
          </div>

          <p class="control" v-if="Object.keys(data.objective).length > 0">
            <label class="checkbox">
              <input
                type="checkbox"
                v-model="data.objective.children_study.checked"
                disabled="disabled"
              >
              Financiering studie kinderen
            </label>
          </p>

          <div v-if="Object.keys(data.objective).length > 0 && data.objective.children_study.checked" class="control">
            <div class="field">
              <label class="label">Vanaf (jaartal)</label>
              <p>{{ data.objective.children_study.starting_from }}</p>
            </div>
          </div>

          <p class="control" v-if="Object.keys(data.objective).length > 0">
            <label class="checkbox">
              <input
                type="checkbox"
                v-model="data.objective.specific_spend_feature.checked"
                disabled="disabled"
              >
              Specifieke uitgave in de toekomst
            </label>
          </p>

          <div v-if="Object.keys(data.objective).length > 0 && data.objective.specific_spend_feature.checked" class="control">
            <div class="field">
              <label class="label">Vanaf (jaartal)</label>
              <p>{{ data.objective.specific_spend_feature.starting_from }}</p>
            </div>
          </div>

          <div v-if="Object.keys(data.objective).length > 0">
            <p class="control">
              <label class="checkbox">
                <input type="checkbox" v-model="data.objective.other.checked" disabled="disabled">
                Anders
              </label>
            </p>

            <div v-if="Object.keys(data.objective).length > 0 && data.objective.other.checked" class="control">
              <div class="field">
                <label class="label">Namelijk</label>
                <p>{{ data.objective.other.namely }}</p>
              </div>
              <div class="field">
                <label class="label">Vanaf (jaartal)</label>
                <p>{{ data.objective.other.starting_from }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"]
};
</script>
