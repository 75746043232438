<template>
  <div class="modal-card" style="width: auto; min-width: 500px;">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ notification.data.subject }}</p>
    </header>
    <section class="modal-card-body">
      <div class="content" v-if="typeof notification.data.message === 'object'">
        <div class="mb-4">
          <pre class="content">{{
            notification.data.message.greeting
          }}</pre>
          <pre class="content">{{
            notification.data.message.introduction
          }}</pre>
        </div>
        <p
          v-if="
            notification.data.message.button_url &&
            notification.data.message.button_label
          "
        >
          <a
            :href="notification.data.message.button_url"
            class="button is-primary"
            target="_blank"
            noreferrer
            noopener
            >{{ notification.data.message.button_label }}</a
          >
        </p>

        <div class="mb-4" v-if="notification.data.message.closing">
          <pre class="content">{{ notification.data.message.closing }}</pre>
        </div>

        <div class="mb-4" v-if="notification.data.message.salutation">
          <pre class="content">{{ notification.data.message.salutation }}</pre>
        </div>
      </div>
      <pre class="content" v-else>{{ notification.data.message }}</pre>

      <div v-if="notification.data.attachment">
        <hr />
        <p>
          <a href="#" @click="downloadFile(notification.id)" target="_blank"
            ><i class="fa fa-download"></i> Download bijlage</a
          >
        </p>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="composeAndClose()">
        Sluiten en bericht opstellen
      </button>
      <button class="button is-primary" type="button" @click="$parent.close()">
        Sluiten
      </button>
    </footer>
  </div>
</template>

<script>
export default {
  props: ["notification"],

  computed: {
    token() {
      return localStorage.getItem("token");
    },
  },

  beforeMount() {
    axios
      .get(`/api/notifications/${this.notification.id}`)
      .then(({ data }) => {
        this.data = data;
      })
      .catch(() => {
        this.$buefy.snackbar.open("Kon bericht niet openen.");
      });
  },

  methods: {
    downloadFile(id) {
      window.open(`/api/notifications/${id}/download?token=${this.token}`);
    },

    composeAndClose() {
      this.$parent.close();
      this.$router.push({
        name: "admin/customer/notifications/compose",
      });
    }
  },
};
</script>
