<template>
  <div class="content">
    <section class="section light no-padding-bottom">
      <div class="container">
        <h3 class="title is-3">
          <strong>Inventarisatie</strong>
          <span class="is-primary">
            Vermogensopstelling
            <span v-if="$parent.isBusiness">privé</span>
          </span>
        </h3>
        <form-errors :errors="form.errors"></form-errors>
      </div>
    </section>

    <section class="section no-background has-header">
      <div class="container">
        <div class="columns">
          <div class="column is-6">
            <div class="block full-height has-amount-bottom">
              <h4 class="title is-4">Bezittingen</h4>

              <label class="label">Beleggingen</label>
              <div class="field has-addons">
                <p class="control">
                  <a class="button">€</a>
                </p>
                <p class="control is-expanded">
                  <money
                    name="assets.investments"
                    class="input"
                    type="text"
                    placeholder="Beleggingen"
                    v-model="form.assets.investments"
                  ></money>
                  <form-field-errors :errors="form.errors" field="assets.investments"></form-field-errors>
                </p>
              </div>

              <div class="field">
                <label class="label">Onroerend goed</label>
                <div class="field has-addons">
                  <p class="control">
                    <a class="button">€</a>
                  </p>
                  <p class="control is-expanded">
                    <money
                      name="assets.property"
                      class="input"
                      type="text"
                      placeholder="Onroerend goed"
                      v-model="form.assets.property"
                    ></money>
                    <form-field-errors :errors="form.errors" field="assets.property"></form-field-errors>
                  </p>
                </div>
              </div>

              <div class="field">
                <label class="label">Uitgesteld vermogen (lijfrentes, koopsompolissen etc.)</label>
                <div class="field has-addons">
                  <p class="control">
                    <a class="button">€</a>
                  </p>
                  <p class="control is-expanded">
                    <money
                      name="assets.delayed_assets"
                      class="input"
                      type="text"
                      placeholder="Uitgesteld vermogen"
                      v-model="form.assets.delayed_assets"
                    ></money>
                    <form-field-errors :errors="form.errors" field="assets.delayed_assets"></form-field-errors>
                  </p>
                </div>
              </div>

              <div class="field">
                <label class="label">Liquiditeiten</label>
                <div class="field has-addons">
                  <p class="control">
                    <a class="button">€</a>
                  </p>
                  <p class="control is-expanded">
                    <money
                      name="assets.liquidity"
                      class="input"
                      type="text"
                      placeholder="Liquiditeiten"
                      v-model="form.assets.liquidity"
                    ></money>
                    <form-field-errors :errors="form.errors" field="assets.liquidity"></form-field-errors>
                  </p>
                </div>
              </div>

              <div class="field">
                <label class="label">Overige bezittingen</label>
                <div class="field has-addons">
                  <p class="control">
                    <a class="button">€</a>
                  </p>
                  <p class="control is-expanded">
                    <money
                      name="assets.other_assets"
                      class="input is-expanded"
                      type="text"
                      placeholder="Overige bezittingen"
                      v-model="form.assets.other_assets"
                    ></money>
                    <form-field-errors :errors="form.errors" field="assets.other_assets"></form-field-errors>
                  </p>
                </div>
              </div>

              <div class="total-amount">
                <hr>

                <div class="columns">
                  <div class="column">
                    <strong class="title is-4">Totaal</strong>
                  </div>
                  <div class="column">&euro; {{ total_assets_number | currency }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="column is-6">
            <div class="block full-height has-amount-bottom">
              <h4 class="title is-4">Schulden</h4>

              <div class="field">
                <label class="label">Hypothecaire schuld</label>
                <div class="field has-addons">
                  <p class="control">
                    <a class="button">€</a>
                  </p>
                  <p class="control is-expanded">
                    <money
                      name="debts.mortgage"
                      class="input"
                      type="text"
                      placeholder="Hypothecaire schuld"
                      v-model="form.debts.mortgage"
                    ></money>
                    <form-field-errors :errors="form.errors" field="debts.mortgage"></form-field-errors>
                  </p>
                </div>
              </div>

              <div class="field">
                <label
                  class="label"
                >Pensioenverplichting (indien van toepassing bij een zakelijke rekening)</label>
                <div class="field has-addons">
                  <p class="control">
                    <a class="button">€</a>
                  </p>
                  <p class="control is-expanded">
                    <money
                      name="debts.retirement"
                      class="input"
                      type="text"
                      placeholder="Pensioenverplichting"
                      v-model="form.debts.retirement"
                    ></money>
                    <form-field-errors :errors="form.errors" field="debts.retirement"></form-field-errors>
                  </p>
                </div>
              </div>

              <div class="field">
                <label class="label">Overige schulden</label>
                <div class="field has-addons">
                  <p class="control">
                    <a class="button">€</a>
                  </p>
                  <p class="control is-expanded">
                    <money
                      name="debts.other_debts"
                      class="input"
                      type="text"
                      placeholder="Overige schulden"
                      v-model="form.debts.other_debts"
                    ></money>
                    <form-field-errors :errors="form.errors" field="debts.other_debts"></form-field-errors>
                  </p>
                </div>
              </div>

              <div class="field">
                <label class="label">Overige verplichtingen</label>
                <div class="field has-addons">
                  <p class="control">
                    <a class="button">€</a>
                  </p>
                  <p class="control is-expanded">
                    <money
                      name="debts.other_obligations"
                      class="input"
                      type="text"
                      placeholder="Overige verplichtingen"
                      v-model="form.debts.other_obligations"
                    ></money>
                    <form-field-errors :errors="form.errors" field="debts.other_obligations"></form-field-errors>
                  </p>
                </div>
              </div>

              <div class="total-amount">
                <hr>

                <div class="columns">
                  <div class="column">
                    <strong class="title is-4">Totaal</strong>
                  </div>
                  <div class="column">&euro; {{ total_debts_number | currency }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-6">
            <div class="block full-height has-amount-bottom">
              <h4 class="title is-4">Inkomen op jaarbasis</h4>

              <p>
                <strong>Bedragen netto per jaar</strong>
              </p>

              <br>

              <div class="field">
                <label class="label">Dienstbetrekking / zelfstandig ondernemen</label>
                <div class="field has-addons">
                  <p class="control">
                    <a class="button">€</a>
                  </p>
                  <p class="control is-expanded">
                    <money
                      name="gainings.employment"
                      class="input"
                      type="text"
                      placeholder="Dienstbetrekking / zelfstandig ondernemen"
                      v-model="form.gainings.employment"
                    ></money>
                    <form-field-errors :errors="form.errors" field="gainings.employment"></form-field-errors>
                  </p>
                </div>
              </div>

              <div class="field">
                <label class="label">Pensioen</label>
                <div class="field has-addons">
                  <p class="control">
                    <a class="button">€</a>
                  </p>
                  <p class="control is-expanded">
                    <money
                      name="gainings.retirement"
                      class="input"
                      type="text"
                      placeholder="Pensioen"
                      v-model="form.gainings.retirement"
                    ></money>
                    <form-field-errors :errors="form.errors" field="gainings.retirement"></form-field-errors>
                  </p>
                </div>
              </div>

              <div class="field">
                <label class="label">Inkomen uit vermogen</label>
                <div class="field has-addons">
                  <p class="control">
                    <a class="button">€</a>
                  </p>
                  <p class="control is-expanded">
                    <money
                      name="gainings.capital"
                      class="input"
                      type="text"
                      placeholder="Inkomen uit vermogen"
                      v-model="form.gainings.capital"
                    ></money>
                    <form-field-errors :errors="form.errors" field="gainings.capital"></form-field-errors>
                  </p>
                </div>
              </div>

              <div class="field">
                <label class="label">Huurinkomsten</label>
                <div class="field has-addons">
                  <p class="control">
                    <a class="button">€</a>
                  </p>
                  <p class="control is-expanded">
                    <money
                      name="gainings.rental"
                      class="input"
                      type="text"
                      placeholder="Huurinkomsten"
                      v-model="form.gainings.rental"
                    ></money>
                    <form-field-errors :errors="form.errors" field="gainings.rental"></form-field-errors>
                  </p>
                </div>
              </div>

              <div class="field">
                <label class="label">Overig inkomen</label>
                <div class="field has-addons">
                  <p class="control">
                    <a class="button">€</a>
                  </p>
                  <p class="control is-expanded">
                    <money
                      name="gainings.other_gainings"
                      class="input is-expanded"
                      type="text"
                      placeholder="Overig inkomen"
                      v-model="form.gainings.other_gainings"
                    ></money>
                    <form-field-errors :errors="form.errors" field="gainings.other_gainings"></form-field-errors>
                  </p>
                </div>
              </div>

              <div class="total-amount">
                <hr>

                <div class="columns">
                  <div class="column">
                    <strong class="title is-4">Totaal</strong>
                  </div>
                  <div class="column">&euro; {{ total_gainings_number | currency }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="column is-6">
            <div class="block full-height has-amount-bottom">
              <h4 class="title is-4">Lasten op jaarbasis</h4>

              <p>
                <strong>Bedragen netto per jaar</strong>
              </p>

              <br>

              <div class="field">
                <label class="label">Woonlasten</label>
                <div class="field has-addons">
                  <p class="control">
                    <a class="button">€</a>
                  </p>
                  <p class="control is-expanded">
                    <money
                      name="expenses.housing"
                      class="input"
                      type="text"
                      placeholder="Woonlasten"
                      v-model="form.expenses.housing"
                    ></money>
                    <form-field-errors :errors="form.errors" field="expenses.housing"></form-field-errors>
                  </p>
                </div>
              </div>

              <div class="field">
                <label class="label">Consumptie</label>
                <div class="field has-addons">
                  <p class="control">
                    <a class="button">€</a>
                  </p>
                  <p class="control is-expanded">
                    <money
                      name="expenses.consumption"
                      class="input"
                      type="text"
                      placeholder="Consumptie"
                      v-model="form.expenses.consumption"
                    ></money>
                    <form-field-errors :errors="form.errors" field="expenses.consumption"></form-field-errors>
                  </p>
                </div>
              </div>

              <div class="field">
                <label class="label">Overige lasten</label>
                <div class="field has-addons">
                  <p class="control">
                    <a class="button">€</a>
                  </p>
                  <p class="control is-expanded">
                    <money
                      name="expenses.other_expenses"
                      class="input is-expanded"
                      type="text"
                      placeholder="Overige lasten"
                      v-model="form.expenses.other_expenses"
                    ></money>
                    <form-field-errors :errors="form.errors" field="expenses.other_expenses"></form-field-errors>
                  </p>
                </div>
              </div>

              <div class="field">
                <label class="label">Aflossing schulden</label>
                <div class="field has-addons">
                  <p class="control">
                    <a class="button">€</a>
                  </p>
                  <p class="control is-expanded">
                    <money
                      name="expenses.debt_repayment"
                      class="input"
                      type="text"
                      placeholder="Aflossing schulden"
                      v-model="form.expenses.debt_repayment"
                    ></money>
                    <form-field-errors :errors="form.errors" field="expenses.debt_repayment"></form-field-errors>
                  </p>
                </div>
              </div>

              <div class="total-amount">
                <hr>

                <div class="columns">
                  <div class="column">
                    <strong class="title is-4">Totaal</strong>
                  </div>
                  <div class="column">&euro; {{ total_expenses_number | currency }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="block">
          <h4 class="title is-4">Huidig en/of toekomstig pensioen</h4>
          <table class="table">
            <thead>
              <tr>
                <th></th>
                <th>(geschat) bedrag netto per jaar</th>
                <th>Uitkering vanaf</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="v-align-middle">AOW</td>
                <td class="v-align-middle">
                  <div class="field has-addons">
                    <p class="control no-margin">
                      <a class="button">€</a>
                    </p>
                    <p class="control is-expanded">
                      <money
                        name="retirement.aow"
                        class="input"
                        type="text"
                        placeholder
                        v-model="form.retirement.aow_amount"
                      ></money>
                      <form-field-errors :errors="form.errors" field="retirement.aow_amount"></form-field-errors>
                    </p>
                  </div>
                </td>
                <td class="v-align-middle">
                  <input
                    type="text"
                    class="input"
                    v-model="form.retirement.aow_starting_at"
                    placeholder="Vanaf (jaartal)"
                  >
                  <form-field-errors :errors="form.errors" field="retirement.aow_starting_at"></form-field-errors>
                </td>
              </tr>
              <tr>
                <td class="v-align-middle">Werkgeverspensioen</td>
                <td>
                  <div class="field has-addons">
                    <p class="control no-margin">
                      <a class="button">€</a>
                    </p>
                    <p class="control is-expanded">
                      <money
                        name="retirement.aow"
                        class="input"
                        type="text"
                        placeholder
                        v-model="form.retirement.employer_amount"
                      ></money>
                      <form-field-errors :errors="form.errors" field="retirement.employer_amount"></form-field-errors>
                    </p>
                  </div>
                </td>
                <td>
                  <input
                    type="text"
                    class="input"
                    v-model="form.retirement.employer_starting_at"
                    placeholder="Vanaf (jaartal)"
                  >
                  <form-field-errors :errors="form.errors" field="retirement.employer_starting_at"></form-field-errors>
                </td>
              </tr>
              <tr>
                <td class="v-align-middle">Uitkering uit stamrecht BV</td>
                <td>
                  <div class="field has-addons">
                    <p class="control no-margin">
                      <a class="button">€</a>
                    </p>
                    <p class="control is-expanded">
                      <money
                        name="retirement.aow"
                        class="input"
                        type="text"
                        placeholder
                        v-model="form.retirement.stamrecht_amount"
                      ></money>
                      <form-field-errors :errors="form.errors" field="retirement.stamrecht_amount"></form-field-errors>
                    </p>
                  </div>
                </td>
                <td>
                  <input
                    type="text"
                    class="input"
                    v-model="form.retirement.stamrecht_starting_at"
                    placeholder="Vanaf (jaartal)"
                  >
                  <form-field-errors :errors="form.errors" field="retirement.stamrecht_starting_at"></form-field-errors>
                </td>
              </tr>
              <tr>
                <td class="v-align-middle">Lijfrentes e.d.</td>
                <td>
                  <div class="field has-addons">
                    <p class="control no-margin">
                      <a class="button">€</a>
                    </p>
                    <p class="control is-expanded">
                      <money
                        name="retirement.aow"
                        class="input"
                        type="text"
                        placeholder
                        v-model="form.retirement.annuity_amount"
                      ></money>
                      <form-field-errors :errors="form.errors" field="retirement.annuity_amount"></form-field-errors>
                    </p>
                  </div>
                </td>
                <td>
                  <input
                    type="text"
                    class="input"
                    v-model="form.retirement.annuity_starting_at"
                    placeholder="Vanaf (jaartal)"
                  >
                  <form-field-errors :errors="form.errors" field="retirement.annuity_starting_at"></form-field-errors>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="columns">
          <div class="column">
            <button
              @click="save()"
              class="button is-primary"
              :class="{'is-loading': form.loading}"
            >Tussentijds opslaan</button>
            <button
              @click="next()"
              class="button is-primary is-pulled-right"
              :class="{'is-loading': form.loading}"
            >Opslaan en verder</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total_assets_number: 0,
      total_debts_number: 0,
      total_gainings_number: 0,
      total_expenses_number: 0,
      form: this.$parent.form.assets_and_liabilities,
      test: 0
    };
  },

  beforeMount() {
    if (!this.form.assets) {
      this.form = {};
      this.form.next = false;
      this.form.assets = {
        investments: 0,
        property: 0,
        delayed_assets: 0,
        liquidity: 0,
        other_assets: 0
      };
      this.form.debts = {
        mortgage: 0,
        retirement: 0,
        other_debts: 0,
        other_obligations: 0
      };
      this.form.expenses = {
        housing: 0,
        consumption: 0,
        other_expenses: 0,
        debt_repayment: 0
      };
      this.form.gainings = {
        employment: 0,
        retirement: 0,
        capital: 0,
        rental: 0,
        other_gainings: 0
      };
      this.form.retirement = {
        aow_amount: 0,
        aow_starting_at: "",
        employer_amount: 0,
        employer_starting_at: "",
        stamrecht_amount: 0,
        stamrecht_starting_at: "",
        annuity_amount: 0,
        annuity_starting_at: ""
      };
      this.form = new Laravel.Form(this.form);
    }

    this.total_assets();
    this.total_debts();
    this.total_gainings();
    this.total_expenses();
  },

  methods: {
    save() {
      this.$parent.save(this.form);
    },

    next() {
      this.$parent.save(this.form, true);
    },

    total_assets() {
      this.total_assets_number = 0;

      for (var key of Object.keys(this.form.assets)) {
        if (!isNaN(parseInt(this.form.assets[key]))) {
          this.total_assets_number =
            this.total_assets_number + parseInt(this.form.assets[key]);
        }
      }

      return this.total_assets_number;
    },

    total_debts() {
      this.total_debts_number = 0;

      for (var key of Object.keys(this.form.debts)) {
        if (!isNaN(parseInt(this.form.debts[key]))) {
          this.total_debts_number =
            this.total_debts_number + parseInt(this.form.debts[key]);
        }
      }

      return this.total_debts_number;
    },

    total_gainings() {
      this.total_gainings_number = 0;

      for (var key of Object.keys(this.form.gainings)) {
        if (!isNaN(parseInt(this.form.gainings[key]))) {
          this.total_gainings_number =
            this.total_gainings_number + parseInt(this.form.gainings[key]);
        }
      }

      return this.total_gainings_number;
    },

    total_expenses() {
      this.total_expenses_number = 0;

      for (var key of Object.keys(this.form.expenses)) {
        if (!isNaN(parseInt(this.form.expenses[key]))) {
          this.total_expenses_number =
            this.total_expenses_number + parseInt(this.form.expenses[key]);
        }
      }

      return this.total_expenses_number;
    }
  },

  watch: {
    "form.assets": {
      handler: function() {
        this.total_assets();
      },
      deep: true
    },
    "form.debts": {
      handler: function() {
        this.total_debts();
      },
      deep: true
    },
    "form.gainings": {
      handler: function() {
        this.total_gainings();
      },
      deep: true
    },
    "form.expenses": {
      handler: function() {
        this.total_expenses();
      },
      deep: true
    }
  }
};
</script>
