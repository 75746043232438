<template>
    <div class="modal-card">
        <header class="modal-card-head">
                <p class="modal-card-title">Nieuwe klant aanmaken</p>
            </header>
            <section class="modal-card-body">
                <form-errors :errors="form.errors"></form-errors>
                <div class="field">
                    <label class="label">E-mailadres</label>
                    <div class="control">
                        <input name="email" type="text" class="input" v-model="form.email">
                    </div>
                </div>

                <div class="field">
                    <label class="label">Voornaam</label>
                    <div class="control">
                        <input name="first_name" type="text" class="input" v-model="form.first_name">
                    </div>
                </div>

                <div class="field">
                    <label class="label">Achternaam</label>
                    <div class="control">
                        <input name="last_name" type="text" class="input" v-model="form.last_name">
                    </div>
                </div>

                <div class="field">
                    <label class="label">Rol</label>
                    <div class="control">
                    <span class="select">
                        <select v-model="form.role_id">
                            <option value="4">Prospect</option>
                            <option value="3">Klant</option>
                        </select>
                    </span>
                    </div>
                </div>


            </section>

            <footer class="modal-card-foot">
                <button class="button" type="button" @click="$emit('close')">Annuleren</button>
                <button class="button is-primary" @click="createCustomer()">Klant aanmaken</button>
            </footer>
    </div>
</template>

<script>

    export default {

        props: ['router'],

        data() {
            return {
                form: new Laravel.Form({
                    email: '',
                    first_name: '',
                    last_name: '',
                    role_id: '4',
                })
            }
        },

        methods: {
            createCustomer() {
                this.form.post('/api/admin/customers')
                .then(data => {
                    this.$emit('close')
                    this.router.push({ name: 'admin/customer', params: { 'id': data.id} })
                })
                .catch((error) => {
                })
            }
        }
    }

</script>
