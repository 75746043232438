<template>

    <div v-if="errors.has(field)">
        <span class="help is-danger">{{ errors.get(field) }}</span>
    </div>

</template>

<script>

    export default {
        props: ['errors', 'field'],
    }

</script>
