<template>
    <div>
        <div class="level">
            <div class="level-left">
                <div class="level-item">
                    <button class="button" @click="zoomIn('current_duration')">Huidige looptijd</button>
                    <b-dropdown v-if="this.data && this.data.start_date && this.data.end_date">
                        <button class="button" slot="trigger">
                            <span>Aangepaste datum&nbsp;&nbsp;</span>
                            <i class="fa fa-caret-down"></i>
                        </button>

                        <b-dropdown-item custom paddingless>
                            <div class="modal-card" style="width:300px;">
                                <section class="modal-card-body">
                                    <label class="label">Begindatum</label>
                                    <p class="control">
                                        <b-datepicker
                                            name="start_date"
                                            placeholder="01-01-2000"
                                            v-model="start_date"
                                            :min-date="new Date(this.data.start_date)"
                                            :max-date="new Date(this.data.end_date)"
                                        ></b-datepicker>
                                    </p>

                                    <label class="label">Einddatum</label>
                                    <p class="control">
                                        <b-datepicker
                                            name="end_date"
                                            placeholder="01-01-2000"
                                            v-model="end_date"
                                            :min-date="new Date(this.data.start_date)"
                                            :max-date="new Date(this.data.end_date)"
                                        ></b-datepicker>
                                    </p>
                                </section>
                            </div>
                        </b-dropdown-item>
                    </b-dropdown>
                    <button class="button" @click="resetZoom()">Gehele looptijd</button>
                </div>
            </div>
        </div>

        <highcharts :options="line" ref="highcharts"></highcharts>

        <div class="legend columns">
            <div class="column is-6">
                <span :class="{disabled: !this.show_model}">
                    <span class="line intended"></span> Beoogd rendement
                </span> -
                <label class="checkbox">
                    <input type="checkbox" v-model="show_model">
                    Toon doellijn
                </label>
            </div>
            <div class="column is-6"><span class="line achieved"></span> Behaald rendement</div>
        </div>
        <div class="legend columns">
            <div class="column is-6"><span class="line attention"></span> Aandachtspunt (mouse over)</div>
        </div>
    </div>
</template>

<script>
    import Highcharts from 'highcharts/highcharts';

    export default {
        props: ['data', 'targetAmount'],

        data() {
            return {
                show_model: false,
                start_date: '',
                end_date: '',
                line: {
                    title: {
                        text: null
                    },

                    tooltip: {
                        xDateFormat: '%m-%Y',
                        backgroundColor: '#dee0d5',
                        borderColor: '#c6c7c1',
                        borderRadius: 0,
                        borderWidth: 1,
                        formatter: function() {
                            if (this.series.name === 'Start plan') {
                                return '<b>Plan gestart</b>';
                            } else if (this.series.name === 'Plan gewijzigd') {
                                if (this.point.description_short) {
                                    return `${this.point.description_short}`;
                                } else {
                                    return `<b>Plan gewijzigd</b><br />Nieuw plan: ${this.point.remark}`;
                                }
                            } else {
                                if (this.point.index === this.series.data[this.series.data.length - 1].index) {
                                    return `<small>${Highcharts.dateFormat('%d-%m-%Y', new Date(this.x))}</small><br />Rendement: ${Math.round(this.y * 100) / 100}%`;
                                }
                                return `<small>${Highcharts.dateFormat('%d-%m-%Y', new Date(this.x))}</small><br />Rendement: ${Math.round(this.y * 100) / 100}%`;
                            }
                        }
                    },

                    yAxis: {
                        title: {
                            text: 'Percentage'
                        },
                    },

                    xAxis: {
                        gridLineWidth: 1,
                        type: 'datetime',
                        dateTimeLabelFormats: {
                            year: '%Y'
                        },
                        tickInterval: 31536000000
                    },

                    plotOptions: {
                          series: {
                            fillOpacity: 0.1
                          }
                    },

                    legend: {
                        enabled: false
                    },

                    credits: false,

                    series: [
                    // Plan
                    {
                        name: 'Beoogd Rendement',
                        zIndex: 1,
                        data: [],
                        color: '#0D8A84',
                        lineWidth: 3,
                        visible: false,
                        marker: {
                            enabled: false,
                        },
                        dashStyle: 'Dash',
                        enableMouseTracking: false
                    },
                    // Positive
                    {
                        name: 'Positief rendement',
                        linkedTo: '0',
                        color: '#0D8A84',
                        fillColor: 'rgba(110,205,201,0.5)',
                        type: 'arearange',
                        data: [],
                        zIndex: 0,
                        visible: false,
                        // lineWidth: 0,
                        enableMouseTracking: false,
                        marker: {
                            enabled: false,
                        },
                    },
                    // Negative
                    {
                        name: 'Negatief rendement',
                        linkedTo: '0',
                        color: '#E0161C',
                        fillColor: 'rgba(252,100,104,0.5)',
                        type: 'arearange',
                        data: [],
                        zIndex: 0,
                        visible: false,
                        // lineWidth: 0,
                        enableMouseTracking: false,
                        marker: {
                            enabled: false,
                        },
                    },
                    // Line
                    {
                        name: 'Rendement',
                        color: '#333333',
                        data: [],
                        marker: {
                            enabled: false,
                        },
                        zoneAxis: 'x',
                        dashStyle: 'Line',
                        lineWidth: 1.5,
                        zIndex: 999,
                    },
                    {
                        name: 'Start plan',
                        color: '#fbe697',
                        data: [],
                        marker: {
                            enabled: true,
                            radius: 8,
                            symbol: 'circle'
                        },
                        zoneAxis: 'x',
                        dashStyle: 'Dash',
                        lineWidth: 3,
                        zIndex: 999,
                    },
                    {
                        name: 'Plan gewijzigd',
                        color: '#fbe697',
                        data: [],
                        marker: {
                            enabled: true,
                            radius: 8,
                            symbol: 'circle'
                        },
                        states: {
                            hover: {
                                lineWidthPlus: 0,
                            }
                        },
                        zoneAxis: 'x',
                        dashStyle: 'Dash',
                        lineWidth: 0,
                        zIndex: 999,
                    },
                    // Line
                    {
                        name: 'Rendement',
                        color: '#333333',
                        data: [],
                        marker: {
                            enabled: false,
                        },
                        zoneAxis: 'x',
                        dashStyle: 'Dash',
                        visible: false,
                        lineWidth: 1,
                        zIndex: 999,
                    },
                    {
                        name: 'End point',
                        color: '#ffffff',
                        data: [],
                        marker: {
                            enabled: false,
                        },
                        enableMouseTracking: false,
                        zoneAxis: 'x',
                        dashStyle: 'Dash',
                        // visible: false,
                        lineWidth: 0,
                        zIndex: 999,
                    }
                    ]
                }
            }
        },

        mounted() {
            this.fillLineChart();
        },

        methods: {
            fillLineChart() {
                if (this.data && this.data.rate_of_return) {

                    this.line.series[3].data = []
                    this.line.series[4].data = []
                    this.line.series[5].data = []

                    this.buildModelLine()
                    this.line.series[3].data = [...this.data.rate_of_return]
                    this.line.series[4].data = [this.data.start_plan]
                    this.line.series[5].data = this.data.switched

                    this.start_date = moment(this.data.start_date).format('YYYY-MM-DD');
                    this.end_date = moment(this.data.end_date).format('YYYY-MM-DD');

                    this.line.series[7].data = [[this.data.end_date, this.data.end_point]]

                }
            },

            buildModelLine() {

                let plan = this.data.plans[0];
                let date = plan.start_date;
                let daysSinceStart = 0;
                let value;
                let item;
                let intendedYearlyReturn = this.data.plans[0].intended_yearly_return;
                let moreAmount = 0;

                // for (let i = 0; i <= this.data.plans.length - 1; i++) {
                    plan = this.data.plans.sort((a, b) => a.switched_plan_at < b.switched_plan_at ? +1 : -1)[0];

                    while (date < plan.end_date) {
                        value = this.FV(plan.intended_yearly_return / 100, daysSinceStart / 365, 0, (plan.amount + moreAmount) * -1, 0);

                        item = (((value - plan.amount) / plan.amount) * 100);

                        this.line.series[0].data.push([date, item]);
                        daysSinceStart = daysSinceStart + 1;
                        date = date + 86400000;

                        // if (this.data.plans[i + 1] && this.data.plans[i + 1].switched_plan_at < date) {
                        //     daysSinceStart = 0;
                        //     moreAmount = plan.amount * ((item / 100));
                        //     console.log(moreAmount)
                        //     break;
                        // }
                    }
                // }

            },

            /*lineIntersection() {

                var chart = this.$refs.highcharts.chart;

                var s0 = chart.series[3].points;
                var s1 = chart.series[0].points;
                var greenSeries = chart.series[1];
                var redSeries = chart.series[2];
                var n0 = s0.length;
                var n1 = s1.length;
                var i,j,isect;

                this.line.series[1].data = []
                this.line.series[2].data = []

                for (i = 1; i < n0; i++){
                    var ratePoint = s0[i]
                    var modelPoint = this.closest(ratePoint.x, this.line.series[0].data)

                    for (j = 1; j < n1; j++) {

                        // Intersection points
                        if (isect = this.get_line_intersection(s0[i-1],s0[i], s1[j-1],s1[j])){

                            this.line.series[1].data.push([
                                isect[0],
                                isect[1],
                                isect[1]
                            ])

                            this.line.series[2].data.push([
                                isect[0],
                                isect[1],
                                isect[1]
                            ])

                        }
                    }

                    // Green section
                    if (ratePoint.y > (modelPoint[1])) {
                        // Add to green section
                        this.line.series[1].data.push([
                            ratePoint.x,
                            ratePoint.y,
                            modelPoint[1]
                        ])

                        // Add to red section
                        this.line.series[2].data.push([
                            ratePoint.x,
                            null,
                            null
                        ])
                    }

                    // Red section
                    else if (ratePoint.y < (modelPoint[1])) {
                        // Add to red section
                        this.line.series[2].data.push([
                            ratePoint.x,
                            ratePoint.y,
                            modelPoint[1]
                        ])

                        // Add to green section
                        this.line.series[1].data.push([
                            ratePoint.x,
                            null,
                            null
                        ])
                    }
                }

                chart.redraw();
            },*/

            zoomIn(type) {
                var chart = this.$refs.highcharts.chart;

                let addSeconds;
                if (type == 'current_duration') {
                    addSeconds = this.data.last_certain_date - this.data.start_plan[0];
                }

                var yChartMin = chart.yAxis[0].min;
                var yChartMax = chart.yAxis[0].max;

                chart.xAxis[0].update({
                    min: this.data.start_plan[0],
                    max: this.data.start_plan[0] + addSeconds,
                });
            },

            resetZoom() {
                var chart = this.$refs.highcharts.chart;
                this.show_model = false

                chart.xAxis[0].update({
                    min: null,
                    max: this.data.plans[0]['end_date'],
                });

                chart.yAxis[0].update({
                    min: undefined,
                    max: undefined,
                });
            },

            zoomGraph(min, max) {
                var chart = this.$refs.highcharts.chart;

                chart.xAxis[0].update({
                    min: min,
                    max: max,
                });
            },

            get_line_intersection(p0,p1,p2,p3) {
                var p0_x = p0.x;
                var p0_y = p0.y;
                var p1_x = p1.x;
                var p1_y = p1.y;
                var p2_x = p2.x;
                var p2_y = p2.y;
                var p3_x = p3.x;
                var p3_y = p3.y;

                var s1_x, s1_y, s2_x, s2_y;
                s1_x = p1_x - p0_x;     s1_y = p1_y - p0_y;
                s2_x = p3_x - p2_x;     s2_y = p3_y - p2_y;

                var s = (-s1_y * (p0_x - p2_x) + s1_x * (p0_y - p2_y)) / (-s2_x * s1_y + s1_x * s2_y);
                var t = ( s2_x * (p0_y - p2_y) - s2_y * (p0_x - p2_x)) / (-s2_x * s1_y + s1_x * s2_y);

                if (s >= 0 && s <= 1 && t >= 0 && t <= 1)
                {
                    return [p0_x + (t * s1_x),p0_y + (t * s1_y)];
                }

                return false;
            },

            closest (search, arr) {
                var curr = arr[0];
                var diff = Math.abs (search - curr[0]);
                for (var val = 0; val < arr.length; val++) {
                    var newdiff = Math.abs (search - arr[val][0]);
                    if (newdiff < diff) {
                        diff = newdiff;
                        curr = arr[val];
                    }
                }
                return curr;
            },

            FV(rate, nper, pmt, pv, type) {
              var pow = Math.pow(1 + rate, nper),
                 fv;
              if (rate) {
               fv = (pmt*(1+rate*type)*(1-pow)/rate)-pv*pow;
              } else {
               fv = -1 * (pv + pmt * nper);
              }
              return fv.toFixed(2);
            }
        },

        watch: {
            data: function(value) {
                this.fillLineChart();
            },
            show_model: function(value) {
                if (value) {
                    this.line.series[0].visible = true;
                    this.line.series[1].visible = true;
                    this.line.series[2].visible = true;
                    this.line.series[6].visible = true;
                    // this.lineIntersection();
                } else {
                    this.line.series[0].visible = false;
                    this.line.series[1].visible = false;
                    this.line.series[2].visible = false;
                    this.line.series[6].visible = false;
                }
            },
            start_date: function (value) {
                let min = parseInt(moment(value).format('x'));
                let max = parseInt(moment(this.end_date).format('x'));
                this.zoomGraph(min, max)
            },
            end_date: function (value) {
                let min = parseInt(moment(this.start_date).format('x'));
                let max = parseInt(moment(value).format('x'));
                this.zoomGraph(min, max)
            }
        }

    }
</script>

<style>
    .highcharts-plot-background {
        fill: #FDFDFD;
    }
</style>
