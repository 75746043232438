<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <h4 class="title is-4">Bezittingen</h4>

        <div class="field">
          <label class="label">Beleggingen</label>
          <p v-if="Object.keys(data).length > 0">&euro; {{ data.assets.investments }}</p>
        </div>

        <div class="field">
          <label class="label">Onroerend goed</label>
          <p v-if="Object.keys(data).length > 0">&euro; {{ data.assets.property }}</p>
        </div>

        <div class="field">
          <label class="label">Uitgesteld vermogen (lijfrentes, koopsompolissen etc.)</label>
          <p v-if="Object.keys(data).length > 0">&euro; {{ data.assets.delayed_assets }}</p>
        </div>

        <div class="field">
          <label class="label">Liquiditeiten</label>
          <p v-if="Object.keys(data).length > 0">&euro; {{ data.assets.liquidity }}</p>
        </div>

        <div class="field">
          <label class="label">Overige bezittingen</label>
          <p v-if="Object.keys(data).length > 0">&euro; {{ data.assets.other_assets }}</p>
        </div>
      </div>

      <div class="column is-6">
        <h4 class="title is-4">Schulden</h4>

        <div class="field">
          <label class="label">Hypothecaire schuld</label>
          <p v-if="Object.keys(data).length > 0">&euro; {{ data.debts.mortgage }}</p>
        </div>

        <div class="field">
          <label
            class="label"
          >Pensioenverplichting (indien van toepassing bij een zakelijke rekening)'</label>
          <p v-if="Object.keys(data).length > 0">&euro; {{ data.debts.retirement }}</p>
        </div>

        <div class="field">
          <label class="label">Overige schulden</label>
          <p v-if="Object.keys(data).length > 0">&euro; {{ data.debts.other_debts }}</p>
        </div>

        <div class="field">
          <label class="label">Overige verplichtingen</label>
          <p v-if="Object.keys(data).length > 0">&euro; {{ data.debts.other_obligations }}</p>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-6">
        <h4 class="title is-4">Inkomen op jaarbasis</h4>

        <div class="field">
          <label class="label">Dienstbetrekking / zelfstandig ondernemen</label>
          <p v-if="Object.keys(data).length > 0">&euro; {{ data.gainings.employment }}</p>
        </div>

        <div class="field">
          <label class="label">Pensioen</label>
          <p v-if="Object.keys(data).length > 0">&euro; {{ data.gainings.retirement }}</p>
        </div>

        <div class="field">
          <label class="label">Inkomen uit vermogen</label>
          <p v-if="Object.keys(data).length > 0">&euro; {{ data.gainings.capital }}</p>
        </div>

        <div class="field">
          <label class="label">Huurinkomsten</label>
          <p v-if="Object.keys(data).length > 0">&euro; {{ data.gainings.rental }}</p>
        </div>

        <div class="field">
          <label class="label">Overig inkomen</label>
          <p v-if="Object.keys(data).length > 0">&euro; {{ data.gainings.other_gainings }}</p>
        </div>
      </div>

      <div class="column is-6">
        <h4 class="title is-4">Lasten op jaarbasis</h4>

        <div class="field">
          <label class="label">Woonlasten</label>
          <p v-if="Object.keys(data).length > 0">&euro; {{ data.expenses.housing }}</p>
        </div>

        <div class="field">
          <label class="label">Consumptie</label>
          <p v-if="Object.keys(data).length > 0">&euro; {{ data.expenses.consumption }}</p>
        </div>

        <div class="field">
          <label class="label">Overige lasten</label>
          <p v-if="Object.keys(data).length > 0">&euro; {{ data.expenses.other_expenses }}</p>
        </div>

        <div class="field">
          <label class="label">Aflossing schulden</label>
          <p v-if="Object.keys(data).length > 0">&euro; {{ data.expenses.debt_repayment }}</p>
        </div>
      </div>
    </div>
    <h4 class="title is-4">Huidig en/of toekomstig pensioen</h4>
    <table class="table">
      <thead>
        <tr>
          <th></th>
          <th>(geschat) bedrag netto per jaar</th>
          <th>Uitkering vanaf</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>AOW</td>
          <td>
            <span v-if="data.retirement">&euro; {{ data.retirement.aow_amount }}</span>
          </td>
          <td>
            <span v-if="data.retirement">{{ data.retirement.aow_starting_at }}</span>
          </td>
        </tr>
        <tr>
          <td>Werkgeverspensioen</td>
          <td>
            <span v-if="data.retirement">&euro; {{ data.retirement.employer_amount }}</span>
          </td>
          <td>
            <span v-if="data.retirement">{{ data.retirement.employer_starting_at }}</span>
          </td>
        </tr>
        <tr>
          <td>Uitkering uit stamrecht BV</td>
          <td>
            <span v-if="data.retirement">&euro; {{ data.retirement.stamrecht_amount }}</span>
          </td>
          <td>
            <span v-if="data.retirement">{{ data.retirement.stamrecht_starting_at }}</span>
          </td>
        </tr>
        <tr>
          <td>Lijfrentes e.d.</td>
          <td>
            <span v-if="data.retirement">&euro; {{ data.retirement.annuity_amount }}</span>
          </td>
          <td>
            <span v-if="data.retirement">{{ data.retirement.annuity_starting_at }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ["data"]
};
</script>
