<template>

    <div>

        <div class="submenu">
            <div class="container">
                <div class="tabs is-top">
                    <ul>
                        <li :class="{ 'is-active': true }">
                            <router-link :to="{ name: 'admin/plans' }">
                                Risicoprofielen
                            </router-link>
                        </li>
                        <li :class="{ 'is-active': false }">
                            <router-link :to="{ name: 'admin/plans/etfs' }">
                                ETF's
                            </router-link>
                        </li>

                    </ul>
                </div>
            </div>
        </div>

        <section class="section no-header no-background">
            <div class="container">
                <div class="block">

                    <table class="table is-striped">
                        <thead>
                            <tr>
                                <th>Plan</th>
                                <th>Openen</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(plan, index) in plans">
                                <td>{{ plan.name }}</td>
                                <td>
                                    <button @click="openPlan(index)" class="button is-primary">Openen</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-if="plan !== null">
                    <plan v-if="plan !== null" :index="plan"></plan>
                </div>

            </div>
        </section>

    </div>
</template>

<script>
import Plan from './Plan.vue'

    export default {

        components: {
            'plan': Plan
        },

        data() {
            return {
                page: '',
                plans: {},
                plan: null
            }
        },

        beforeMount() {
            axios.get(`api/admin/plan`)
                .then(({data}) => {
                    this.plans = data.plans
                    this.available_etfs = data.available_etfs
                })
        },

        methods: {
            openPlan(index) {
                this.plan = index
            }
        }
    }

</script>
