<template>
  <div>
    <div class="submenu">
      <div class="container">
        <div class="tabs is-top">
          <ul>
            <li :class="{ 'is-active': false }">
              <router-link :to="{ name: 'admin/plans' }">Risicoprofielen</router-link>
            </li>
            <li :class="{ 'is-active': true }">
              <router-link :to="{ name: 'admin/plans/etfs' }">ETF's</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <section class="section no-background no-header">
      <div class="container">
        <div class="block">
          <table class="table">
            <thead>
              <tr>
                <th>ISIN-nummer</th>
                <th>Naam</th>
                <th>Publieke naam</th>
                <th>Asset class</th>
                <th>Bewerken</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(etf, index) in etfs" v-bind:key="etf.id">
                <td class="v-align-middle">{{ etf.isin }}</td>
                <td class="v-align-middle">
                  <span v-if="editRow !== index">{{ etf.name }}</span>
                  <input
                    type="input"
                    class="input"
                    v-model="editForm.name"
                    v-if="editRow === index"
                  >
                </td>
                <td class="v-align-middle">
                  <span v-if="editRow !== index">{{ etf.public_name }}</span>
                  <input
                    type="input"
                    class="input"
                    v-model="editForm.public_name"
                    v-if="editRow === index"
                  >
                </td>
                <td class="v-align-middle">
                  <span v-if="editRow !== index">{{ etf.asset_class.name }}</span>
                  <span class="select" v-if="editRow === index">
                    <select v-model="editForm.asset_class_id">
                      <option value="1">Aandelen</option>
                      <option value="2">Obligaties</option>
                      <option value="3">Grondstoffen</option>
                      <option value="4">Liquiditeiten</option>
                    </select>
                  </span>
                </td>
                <td>
                  <button class="button" @click="edit(index)" v-if="editRow !== index">Bewerken</button>
                  <button
                    class="button is-primary"
                    @click="saveEdit()"
                    v-if="editRow === index"
                  >Opslaan</button>
                </td>
              </tr>
            </tbody>
          </table>

          <button class="button is-primary" @click="toggleAddETF()">ETF toevoegen</button>
        </div>

        <div class="block" v-if="showAddETFForm">
          <form @submit.prevent="addETF()">
            <form-errors :errors="form.errors"></form-errors>

            <div class="columns">
              <div class="column is-6">
                <div class="field">
                  <label class="label">Naam asset manager</label>
                  <input
                    type="text"
                    class="input"
                    placeholder="Naam asset manager"
                    v-model="form.asset_manager"
                  >
                </div>

                <div class="field">
                  <label class="label">Naam beleggingsinstrument</label>
                  <input
                    type="text"
                    class="input"
                    placeholder="Naam beleggingsinstrument"
                    v-model="form.name"
                  >
                </div>

                <div class="field">
                  <label class="label">ISIN</label>
                  <input type="text" class="input" placeholder="ISIN" v-model="form.isin">
                </div>

                <div class="field">
                  <label class="label">Vermogenscategorie</label>
                  <span class="select">
                    <select v-model="form.asset_class_id">
                      <option value disabled hidden>Maak een keuze</option>
                      <option
                        v-for="asset_class in available_asset_classes"
                        v-bind:key="asset_class.id"
                        :value="asset_class.id"
                      >{{ asset_class.name }}</option>
                    </select>
                  </span>
                </div>

                <div class="field">
                  <label class="label">Regio</label>
                  <input type="text" class="input" placeholder="Regio" v-model="form.region">
                </div>

                <div class="field">
                  <label class="label">Sector</label>
                  <input type="text" class="input" placeholder="Sector" v-model="form.sector">
                </div>

                <div class="field">
                  <button
                    type="submit"
                    class="button is-primary"
                    :class="{ 'is-loading': form.loading }"
                  >ETF opslaan</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      etfs: [],
      available_asset_classes: [],
      showAddETFForm: false,
      editRow: null,
      editForm: null,
      form: new Laravel.Form({
        asset_manager: null,
        name: null,
        isin: null,
        asset_class_id: "",
        region: null,
        sector: null
      })
    };
  },

  beforeMount() {
    this.load();
  },

  methods: {
    load() {
        axios.get(`api/admin/etf`).then(({ data }) => {
            this.etfs = data.etf;
            this.available_asset_classes = data.available_asset_classes;
            });
    },

    toggleAddETF() {
      this.showAddETFForm = !this.showAddETFForm;
    },

    edit(index) {
      this.editRow = index;
      this.editForm = new Laravel.Form(this.etfs[index]);
    },

    saveEdit() {
        this.editForm.put(`/api/admin/etf/${this.editForm.id}`)
            .then(data => {
                this.$buefy.snackbar.open('ETF succesvol bewerkt.')
                this.editForm = null;
                this.editRow = null;
                this.etfs = data.etf;
                this.available_asset_classes = data.available_asset_classes;
            })
            .catch(() => {
                this.$buefy.snackbar.open('Kon ETF niet bewerken.')
            })
    },

    addETF() {
      this.form.post('/api/admin/etf').then(data => {
        this.toggleAddETF();
        this.etfs = data.etf;
      });
    }
  }
};
</script>
