<template>
  <div class="content">
    <section class="section light no-padding-bottom">
      <div class="container">
        <h3 class="title is-3">
          <strong>Inventarisatie</strong>
          <span class="is-primary">Kennis en ervaring</span>
        </h3>

        <form-errors :errors="form.errors"></form-errors>
      </div>
    </section>

    <section class="section no-background has-header">
      <div class="container">
        <div class="columns">
          <div class="column is-6">
            <div class="block">
              <h4 class="title is-4">Opleiding en beroep rekeninghouder 1</h4>

              <div class="field">
                <label class="label">Hoogstgenoten opleiding</label>
                <input
                  name="accountholder_1.highest_education"
                  class="input"
                  type="text"
                  placeholder="Hoogstgenoten opleiding"
                  v-model="form.accountholder_1.highest_education"
                >
                <form-field-errors :errors="form.errors" field="accountholder_1.highest_education"></form-field-errors>
              </div>

              <div class="field">
                <label class="label">Beroep</label>
                <input
                  name="accountholder_1.profession"
                  class="input"
                  type="text"
                  placeholder="Beroep"
                  v-model="form.accountholder_1.profession"
                >
                <form-field-errors :errors="form.errors" field="accountholder_1.profession"></form-field-errors>
              </div>

              <div class="field">
                <label class="label">Naam (voormalig) werkgever</label>
                <input
                  name="accountholder_1.name_employer"
                  class="input"
                  type="text"
                  placeholder="Naam (voormalig) werkgever"
                  v-model="form.accountholder_1.name_employer"
                >
                <form-field-errors :errors="form.errors" field="accountholder_1.name_employer"></form-field-errors>
              </div>
            </div>
          </div>

          <div class="column is-6" v-if="form.accountholder_2 && form.accountholder_2.last_name">
            <div class="block">
              <h4 class="title is-4">Opleiding en beroep rekeninghouder 2</h4>

              <div class="field">
                <label class="label">Hoogstgenoten opleiding</label>
                <input
                  name="accountholder_2.highest_education"
                  class="input"
                  type="text"
                  placeholder="Hoogstgenoten opleiding"
                  v-model="form.accountholder_2.highest_education"
                >
                <form-field-errors :errors="form.errors" field="accountholder_2.highest_education"></form-field-errors>
              </div>

              <div class="field">
                <label class="label">Beroep</label>
                <input
                  name="accountholder_2.profession"
                  class="input"
                  type="text"
                  placeholder="Beroep"
                  v-model="form.accountholder_2.profession"
                >
                <form-field-errors :errors="form.errors" field="accountholder_2.profession"></form-field-errors>
              </div>

              <div class="field">
                <label class="label">Naam (voormalig) werkgever</label>
                <input
                  name="accountholder_2.name_employer"
                  class="input"
                  type="text"
                  placeholder="Naam (voormalig) werkgever"
                  v-model="form.accountholder_2.name_employer"
                >
                <form-field-errors :errors="form.errors" field="accountholder_2.name_employer"></form-field-errors>
              </div>
            </div>
          </div>
        </div>

        <div class="block">
          <h4 class="title is-4">Ervaring dienstverlening</h4>
          <p class="control">Hoeveel ervaring heeft u met onderstaande effectendienstverlening?</p>
          <div class="columns">
            <div class="column">
              <label class="label">Vermogensbeheer</label>

              <div class="field">
                <label class="radio">
                  <input
                    name="form.concept.wealth_management"
                    type="radio"
                    value="1"
                    v-model="form.concept.wealth_management"
                  >
                  Minder dan 2 jaar
                </label>
              </div>

              <div class="field">
                <label class="radio">
                  <input
                    name="form.concept.wealth_management"
                    type="radio"
                    value="2"
                    v-model="form.concept.wealth_management"
                  >
                  2 tot 10 jaar
                </label>
              </div>

              <div class="field">
                <label class="radio">
                  <input
                    name="form.concept.wealth_management"
                    type="radio"
                    value="3"
                    v-model="form.concept.wealth_management"
                  >
                  Meer dan 10 jaar
                </label>
              </div>

              <form-field-errors :errors="form.errors" field="concept.wealth_management"></form-field-errors>
            </div>

            <div class="column">
              <label class="label">Advies</label>

              <div class="field">
                <label class="radio">
                  <input
                    name="form.concept.advice"
                    type="radio"
                    value="1"
                    v-model="form.concept.advice"
                  >
                  Minder dan 2 jaar
                </label>
              </div>

              <div class="field">
                <label class="radio">
                  <input
                    name="form.concept.advice"
                    type="radio"
                    value="2"
                    v-model="form.concept.advice"
                  >
                  2 tot 10 jaar
                </label>
              </div>

              <div class="field">
                <label class="radio">
                  <input
                    name="form.concept.advice"
                    type="radio"
                    value="3"
                    v-model="form.concept.advice"
                  >
                  Meer dan 10 jaar
                </label>
              </div>

              <form-field-errors :errors="form.errors" field="concept.advice"></form-field-errors>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Zelf beleggen</label>

                <div class="field">
                  <label class="radio">
                    <input
                      name="form.concept.self_invest"
                      type="radio"
                      value="1"
                      v-model="form.concept.self_invest"
                    >
                    Minder dan 2 jaar
                  </label>
                </div>

                <div class="field">
                  <label class="radio">
                    <input
                      name="form.concept.self_invest"
                      type="radio"
                      value="2"
                      v-model="form.concept.self_invest"
                    >
                    2 tot 10 jaar
                  </label>
                </div>

                <div class="field">
                  <label class="radio">
                    <input
                      name="form.concept.self_invest"
                      type="radio"
                      value="3"
                      v-model="form.concept.self_invest"
                    >
                    Meer dan 10 jaar
                  </label>
                </div>

                <form-field-errors :errors="form.errors" field="concept.self_invest"></form-field-errors>
              </div>
            </div>
          </div>
        </div>

        <div class="block">
          <h4 class="title is-4">Kennis & ervaring financiële instrumenten</h4>
          <p>Hoeveel ervaring heeft u in de onderstaande financiële instrumenten, hoeveel transacties deed u of uw adviseur/vermogensbeheerder gemiddeld per kwartaal en wat was de gemiddelde omvang van deze transacties?</p>
          <table class="table" width="100%">
            <thead>
              <tr>
                <th></th>
                <th>Ervaring</th>
                <th>Kennis</th>
                <th>Aantal transacties per kwartaal</th>
                <th>Gemiddelde omvang</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in rows" v-bind:key="row.key">
                <th>{{ row.name }}</th>
                <td>
                  <span class="select">
                    <select :name="`form.concept.${row.key}`" v-model="form.concept[row.key]">
                      <option value="1">Minder dan 2 jaar</option>
                      <option value="2">2 - 10 jaar</option>
                      <option value="3">Meer dan 10 jaar</option>
                    </select>
                  </span>
                </td>
                <td>
                  <span class="select">
                    <select :name="`form.knowledge.${row.key}`" v-model="form.knowledge[row.key]">
                      <option value="1">Minder dan 2 jaar</option>
                      <option value="2">2 - 10 jaar</option>
                      <option value="3">Meer dan 10 jaar</option>
                    </select>
                  </span>
                </td>
                <td>
                  <span class="select">
                    <select
                      :name="`form.transactions.${row.key}`"
                      v-model="form.transactions[row.key]"
                    >
                      <option value="1">0-3 transacties</option>
                      <option value="2">3-5 transacties</option>
                      <option value="3">Meer dan 5 transacties</option>
                    </select>
                  </span>
                </td>
                <td>
                  <span class="select">
                    <select :name="`form.amount.${row.key}`" v-model="form.amount[row.key]">
                      <option value="1">0 - €2500,-</option>
                      <option value="2">€2500,- - &lt; €7500,-</option>
                      <option value="3">> €7500,-</option>
                    </select>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="columns">
          <div class="column">
            <button
              @click="save()"
              class="button is-primary"
              :class="{'is-loading': form.loading}"
            >Tussentijds opslaan</button>
            <button
              @click="next()"
              class="button is-primary is-pulled-right"
              :class="{'is-loading': form.loading}"
            >Opslaan en verder</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rows: [
        {
          key: "shares",
          name: "Aandelen"
        },
        {
          key: "investment_funds",
          name: "Beleggingsfondsen"
        },
        {
          key: "exchange_funds",
          name: "Indexfondsen en ETF's"
        },
        {
          key: "debentures",
          name: "Obligaties"
        },
        {
          key: "derivatives",
          name: "Derivaten"
        },
        {
          key: "alternative",
          name: "Alternatieve beleggingen"
        }
      ],
      bank_accounts: this.$parent.form.bank_accounts,
      form: this.$parent.form.knowledge_and_experience
    };
  },

  beforeMount() {
    if (!this.form.concept) {
      this.form = {};
      this.form.next = false;

      let defaultValues = {};
      this.rows.forEach(x => (defaultValues[x.key] = 1));

      this.form.concept = { ...defaultValues };
      this.form.knowledge = { ...defaultValues };
      this.form.transactions = { ...defaultValues };
      this.form.amount = { ...defaultValues };

      this.form = new Laravel.Form(this.form);
    }

    this.form.accountholder_1 = this.bank_accounts.accountholder_1;
    this.form.accountholder_2 = this.bank_accounts.accountholder_2;
    this.form.originalData.accountholder_1 = {};
    this.form.originalData.accountholder_2 = {};
  },

  methods: {
    save() {
      this.$parent.save(this.form);
    },

    next() {
      this.$parent.save(this.form, true);
    }
  }
};
</script>
