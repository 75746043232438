<template>
  <div>
    <div class="submenu" v-if="user.role === 'manager'">
      <div class="container">
        <div class="tabs is-top">
          <ul>
            <li :class="{ 'is-active': false }">
              <router-link :to="{ name: 'notifications' }"
                >Berichten</router-link
              >
            </li>
            <li :class="{ 'is-active': false }">
              <router-link :to="{ name: 'admin/notifications/all' }"
                >Alle berichten</router-link
              >
            </li>
            <li :class="{ 'is-active': true }">
              <router-link :to="{ name: 'admin/notifications/compose' }"
                >Bericht opstellen</router-link
              >
            </li>
            <li :class="{ 'is-active': false }">
              <router-link :to="{ name: 'admin/notifications/templates' }"
                >Templates</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>

    <section class="section content no-header">
      <div class="container">
        <div class="columns">
          <div class="column is-7">
            <div class="block">
              <form @submit.prevent="submitForm">
                <form-errors :errors="form.errors"></form-errors>

                <div class="field">
                  <label class="label">Aan</label>
                  <span class="select">
                    <select v-model="form.role_id">
                      <option value="3-4">Klanten en prospecten</option>
                      <option value="3">Alleen klanten</option>
                      <option value="4">Alleen prospecten</option>
                      <option value="nnek">NNEK-klanten</option>
                      <option value="bjv">Alleen bertjan@classdesign.nl (test)</option>
                    </select>
                  </span>
                </div>

                <div class="field">
                  <label class="label">Selecteer template</label>
                  <div class="is-flex">
                    <p class="control mr-2">
                      <span class="select">
                        <select v-model="selected_template">
                          <option :value="undefined">
                            Selecteer een template
                          </option>
                          <option
                            v-for="template in templates"
                            :key="template.id"
                            :value="template.id"
                          >
                            {{ template.name }}
                          </option>
                        </select>
                      </span>
                    </p>
                    <button
                      type="button"
                      @click="editTemplate()"
                      class="button"
                      v-if="selected_template && !edit_mode"
                    >
                      Bewerk tekst
                    </button>
                  </div>
                </div>

                <div class="field" v-if="!selected_template">
                  <div class="field">
                    <label class="label">Onderwerp</label>
                    <input
                      type="text"
                      class="input"
                      v-model="form.subject"
                      placeholder="Onderwerp"
                    />
                  </div>

                  <div class="field">
                    <label class="label">Aanhef</label>
                    <input
                      type="text"
                      class="input"
                      v-model="form.greeting"
                      placeholder="Aanhef"
                    />
                    <em
                      >Let op dat deze aanhef voor iedereen wordt gebruikt. Jan
                      Janssen is een voorbeeld. Bij een template wordt dit
                      vervangen door de naam van de gebruiker, maar op deze
                      manier niet.</em
                    >
                  </div>

                  <div class="field">
                    <label class="label">{{
                      edit_mode ? "Introductie" : "Bericht"
                    }}</label>
                    <textarea
                      class="textarea"
                      placeholder="Typ hier het bericht"
                      v-model="form.introduction"
                    ></textarea>
                  </div>

                  <div class="field" v-if="form.button_label !== undefined">
                    <label class="label">Knoptekst</label>
                    <input
                      type="text"
                      class="input"
                      placeholder="Knoptekst"
                      v-model="form.button_label"
                    />
                  </div>

                  <div class="field" v-if="form.button_url !== undefined">
                    <label class="label">Knoplink (URL)</label>
                    <input
                      type="text"
                      class="input"
                      placeholder="Knoplink (URL)"
                      v-model="form.button_url"
                    />
                  </div>

                  <div class="field" v-if="form.closing !== undefined">
                    <label class="label">Afsluiting</label>
                    <textarea
                      class="textarea"
                      placeholder="Typ hier de afsluiting"
                      v-model="form.closing"
                    ></textarea>
                  </div>

                  <div class="field">
                    <label class="label">Groet</label>
                    <textarea
                      class="textarea"
                      placeholder="Typ hier de groet"
                      v-model="form.salutation"
                      rows="2"
                    ></textarea>
                  </div>
                </div>

                <div
                  class="field"
                  v-if="selected_template && variableFields.length > 0"
                >
                  <div
                    class="field"
                    v-for="field in variableFields"
                    :key="field.label"
                  >
                    <label class="label">{{ field.label }}</label>
                    <input
                      type="text"
                      class="input"
                      :placeholder="field.label"
                      v-model="field.value"
                    />
                  </div>
                </div>

                <div class="field">
                  <label class="label">Bijlage</label>

                  <b-field class="file" :class="{ 'has-name': !!form.file }">
                    <b-upload v-model="form.file" class="file-label">
                      <span class="file-cta">
                        <b-icon class="file-icon" icon="upload"></b-icon>
                        <span class="file-label">Kies bestand (bijlage)</span>
                      </span>
                      <span class="file-name" v-if="form.file">
                        {{ form.file.name }}
                      </span>
                    </b-upload>
                  </b-field>
                  <p class="content is-small">
                    Toegestaande bestandstypes: jpeg, png, jpg, doc, docx, pdf.
                    Maximale grootte: 10MB
                  </p>
                </div>

                <div class="field">
                  <label class="label">Controlecode</label>
                  <input
                    type="text"
                    class="input"
                    placeholder="Controlecode"
                    v-model="form.two_factor"
                  />
                  <p class="content is-small">
                    Omdat dit bericht aan alle klanten zal worden verstuurd,
                    dient u uw code voor tweetrapsverificatie op te geven.
                  </p>
                </div>

                <div class="field">
                  <button
                    type="submit"
                    class="button is-primary"
                    :class="{ 'is-loading': form.loading }"
                  >
                    Verzenden
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="column is-5">
            <div class="block">
              <div class="field">
                <label class="label">Onderwerp</label>
                <p>{{ replacedSubject }}</p>
              </div>
              <hr />
              <div class="field">
                <label class="label">Bericht</label>
                <div>
                  <h4>{{ form.greeting }}</h4>
                  <div>
                    <p>{{ replacedIntroduction }}</p>
                    <p v-if="form.button_label">
                      <button class="button is-primary">
                        {{ form.button_label }}
                      </button>
                    </p>
                    <p>{{ replacedClosing }}</p>
                  </div>
                  <p
                    style="margin-top: 1em"
                    v-html="form.salutation?.replace('\n', '<br />')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        fullSalutation: "Geachte heer/mevrouw Janssen,",
        informalSalutation: "Beste Jan,",
      },
      templates: [],
      variableFields: [],
      edit_mode: false,
      selected_template: undefined,
      form: new Laravel.Form({
        selected_template: undefined,
        role_id: "3-4",
        subject: "",
        greeting: "",
        introduction: "",
        button_label: undefined,
        button_url: undefined,
        closing: undefined,
        salutation: "",
        file: null,
        two_factor: "",
      }),
    };
  },

  mounted() {
    if (this.data) {
      this.form = new Laravel.Form({ ...this.form, ...this.defaultValues });
    }
  },

  computed: {
    user() {
      return this.$root.user;
    },

    defaultValues() {
      return {
        selected_template: undefined,
        role_id: this.form.role_id,
        greeting: this.data.fullSalutation,
        salutation: `Met vriendelijke groet,\n${this.user.fullName}`,
      };
    },

    replacedSubject() {
      return this.form.subject.replace(
        /\[(.*?)\]/g,
        (match) =>
          this.variableFields.find(
            (field) => field.location === "subject" && field.match == match
          ).value || match
      );
    },
    replacedIntroduction() {
      return this.form.introduction?.replace(
        /\[(.*?)\]/g,
        (match) =>
          this.variableFields.find(
            (field) => field.location === "introduction" && field.match == match
          ).value || match
      );
    },
    replacedClosing() {
      return this.form.closing?.replace(
        /\[(.*?)\]/g,
        (match) =>
          this.variableFields.find(
            (field) => field.location === "closing" && field.match == match
          ).value || match
      );
    },
  },

  beforeMount() {
    axios
      .get("/api/admin/notification-templates")
      .then(({ data }) => {
        this.templates = data;
      })
      .catch(() => {
        this.$buefy.snackbar.open("Kon templates niet laden.");
      });
  },

  methods: {
    onFileChange(event) {
      this.form.file = event.target.files[0];
    },

    submitForm() {
      this.form.loading = true;

      let data = new FormData();
      for (let [key, value] of Object.entries(this.form.data())) {
        if (value) {
          data.append(key, value);
        }
      }
      data.append("subject", this.replacedSubject);
      data.append("introduction", this.replacedIntroduction);

      if (this.form.closing) {
        data.append("closing", this.replacedClosing);
      }

      if (this.form.file) {
        data.append("file", this.form.file);
      }

      axios
        .post(`/api/admin/notifications`, data)
        .then(({ data }) => {
          this.form.loading = false;
          this.$buefy.snackbar.open("Bericht succesvol verzonden.");
          this.form.reset();
          this.selected_template = undefined;
        })
        .catch((error) => {
          this.form.loading = false;
          this.form.onFail(error.response.data);
          this.$buefy.snackbar.open("Kon bericht niet verzenden.");
        });
    },

    editTemplate() {
      this.edit_mode = true;
      this.form.subject = this.replacedSubject;
      this.form.introduction = this.replacedIntroduction;
      this.form.closing = this.replacedClosing;
      this.selected_template = 0;
    },
  },

  watch: {
    data() {
      this.form = new Laravel.Form({ ...this.form, ...this.defaultValues });
    },

    selected_template(val) {
      if (val === 0) {
        return;
      }

      const template = this.templates.find((template) => template.id == val);
      this.edit_mode = false;

      if (!template) {
        this.form = new Laravel.Form({
          ...this.defaultValues,
          subject: "",
          introduction: "",
          button_label: undefined,
          button_url: undefined,
          closing: undefined,
          email: false,
          file: null,
        });

        return;
      }

      this.form = new Laravel.Form({
        ...this.defaultValues,
        selected_template: val,
        greeting:
          template.greeting === "formal"
            ? this.data.fullSalutation
            : this.data.informalSalutation,
        subject: template.subject,
        introduction: template.introduction,
        button_label: template.button_label,
        button_url: template.button_url,
        closing: template.closing,
        email: false,
      });

      this.variableFields = [];
      const regex = /\[(.*?)\]/g;
      const fieldsToMatch = ["subject", "introduction", "closing"];

      fieldsToMatch.forEach((field) => {
        template[field].replace(regex, (match) => {
          this.variableFields.push({
            match,
            label: match.replace("[", "").replace("]", ""),
            location: field,
            value: "",
          });
        });
      });
    },
  },
};
</script>
