<template>
  <div>
    <div class="section light">
      <div class="container">
        <h3 class="title is-3">Nieuw wachtwoord instellen</h3>

        <form-errors :errors="passwordResetForm.errors"></form-errors>
      </div>
    </div>

    <section class="section content has-header">
      <div class="container">
        <div class="block">
          <div v-if="!success">
            <p>Vul hieronder uw e-mailadres en tweemaal een door u gekozen wachtwoord in. Het wachtwoord moet minimaal 8 karakters bevatten.</p>

            <form method="GET" @submit.prevent="submitForm()">
              <div class="columns">
                <div class="column">
                  <label>E-mailadres</label>
                </div>
                <div class="column">
                  <input
                    name="email"
                    type="email"
                    class="input"
                    placeholder="E-mailadres"
                    v-model="passwordResetForm.email"
                  >
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <label>Nieuw wachtwoord</label>
                </div>
                <div class="column">
                  <input
                    name="password"
                    type="password"
                    class="input"
                    placeholder="Nieuw wachtwoord"
                    v-model="passwordResetForm.password"
                  >
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <label>Nieuw wachtwoord (herhalen)</label>
                </div>
                <div class="column">
                  <input
                    name="password_confirmation"
                    type="password"
                    class="input"
                    placeholder="Nieuw wachtwoord (herhalen)"
                    v-model="passwordResetForm.password_confirmation"
                  >
                </div>
              </div>

              <div class="columns">
                <div class="column"></div>
                <div class="column">
                  <button
                    type="submit"
                    class="button is-primary"
                    :class="{'is-loading': passwordResetForm.loading}"
                  >Bevestigen</button>
                </div>
              </div>
            </form>
          </div>

          <div v-if="success">
            <p>Uw wachtwoord is succesvol gewijzigd. U bent automatisch ingelogd en kunt terugkeren naar het dashboard door op de onderstaande knop te klikken.</p>

            <router-link
              tag="button"
              :to="{ name: 'dashboard' }"
              class="button is-primary"
              exact
            >Ga naar het dashboard</router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      passwordResetForm: new Laravel.Form({
        email: "",
        password: "",
        password_confirmation: "",
        token: this.$route.params.token
      }),
      success: false
    };
  },

  methods: {
    submitForm() {
      this.passwordResetForm
        .post("/api/auth/password/reset")
        .then(data => {
          this.$root.setUser(data);
          this.success = true;
        })
        .catch(error => {});
    }
  }
};
</script>
