<template>
  <section class="section content no-header">
    <div class="container">
      <div class="block">
        <table class="table">
          <thead>
            <tr>
              <th>Jaar</th>
              <th>Kwartaal</th>
              <th>Kwartaalrapportage</th>
              <th>Bericht</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(row, index) in data" v-bind:key="row.id">
              <td>{{ row.year }}</td>
              <td>
                <span v-if="row.quarter === 0">
                  Jaarrapportage
                </span>
                <span v-else>
                    {{ row.quarter }}<sup>e</sup>
                    kwartaal
                </span>
              </td>
              <td width="40%">
                <button
                  class="button"
                  @click="download(row.quarterReportDownloadURL)"
                  v-if="row.quarterReportDownloadURL"
                >
                  <i class="fa fa-download"></i>&nbsp;Download
                </button>
                <a v-if="row.link_url && row.link_title" :href="row.link_url" class="button is-primary is-outlined" target="_blank" rel="noopener noreferrer">{{row.link_title}}</a>
                <span class="is-italic" v-if="!row.quarterReportDownloadURL">Niet beschikbaar</span>
              </td>
              <!-- <td width="20%">
                <button
                  class="button"
                  @click="download(row.invoiceDownloadURL)"
                  v-if="row.invoiceDownloadURL"
                >
                  <i class="fa fa-download"></i>&nbsp;Download
                </button>
                <span class="is-italic" v-if="!row.invoiceDownloadURL">Niet beschikbaar</span>
              </td> -->
              <td width="20%">
                <button
                  class="button"
                  v-if="row.description"
                  @click="showMessage(index)"
                >Bericht weergeven</button>
                <span class="is-italic" v-if="!row.description">Niet beschikbaar</span>
              </td>
            </tr>
            <tr v-if="data.length === 0">
              <td
                colspan="3"
                class="has-text-centered"
              >Er staan nog geen kwartaalrapportages voor u klaar.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <b-modal :active.sync="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="media">
            <div class="media-content"  v-if="openedModal !== null" style="white-space: break-spaces;">{{ data[openedModal].description }}</div>
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
  data() {
    return {
      data: [],
      isCardModalActive: false,
      openedModal: null
    };
  },

  beforeMount() {
    axios
      .get("/api/quarter-reports")
      .then(({ data }) => {
        this.data = data;
      })
      .catch(() => {
        this.$buefy.snackbar.open("Kon overzicht niet laden.");
      });
  },

  computed: {
    token() {
      return localStorage.getItem("token");
    }
  },

  methods: {
    download(url) {
      window.open(`${url}?token=${this.token}`);
    },

    showMessage(index) {
      this.isCardModalActive = true;
      this.openedModal = index;
    }
  }
};
</script>
