<style>
html.printable button {
  display: none;
}
</style>

<template>
  <div v-if="show">
    <h3 class="title is-4 has-pointer">
      <div class="level">
        <div class="level-left" @click="toggleSection('bank_accounts')">
          <div class="level-item">
            <i class="fa fa-chevron-down" v-if="showSection != 'bank_accounts'"></i>
            <i class="fa fa-chevron-up" v-if="showSection == 'bank_accounts'"></i>
            Stap 1: Rekeninghouders
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <button class="button" @click="openEdit('bank_accounts')">Rekeninghouders bewerken</button>
          </div>
        </div>
      </div>
    </h3>

    <bank-accounts :data="prospect.bank_accounts" v-if="showSection === 'bank_accounts'"></bank-accounts>

    <h3 class="title is-4 has-pointer">
      <div class="level">
        <div class="level-left" @click="toggleSection('business_assets_and_liabilities')">
          <div class="level-item">
            <i class="fa fa-chevron-down" v-if="showSection != 'business_assets_and_liabilities'"></i>
            <i class="fa fa-chevron-up" v-if="showSection == 'business_assets_and_liabilities'"></i>
            Stap 2: Zakelijke vermogensopstelling
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <button
              class="button"
              @click="openEdit('business_assets_and_liabilities')"
            >Zakelijke vermogensopstelling bewerken</button>
          </div>
        </div>
      </div>
    </h3>

    <business-assets-and-liabilities
      :data="prospect.business_assets_and_liabilities"
      v-if="showSection === 'business_assets_and_liabilities'"
    ></business-assets-and-liabilities>

    <h3 class="title is-4 has-pointer">
      <div class="level">
        <div class="level-left" @click="toggleSection('assets_and_liabilities')">
          <div class="level-item">
            <i class="fa fa-chevron-down" v-if="showSection != 'assets_and_liabilities'"></i>
            <i class="fa fa-chevron-up" v-if="showSection == 'assets_and_liabilities'"></i>
            Stap 3: Vermogensopstelling
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <button
              class="button"
              @click="openEdit('assets_and_liabilities')"
            >Vermogensopstelling bewerken</button>
          </div>
        </div>
      </div>
    </h3>

    <assets-and-liabilities
      :data="prospect.assets_and_liabilities"
      v-if="showSection === 'assets_and_liabilities'"
    ></assets-and-liabilities>

    <h3 class="title is-4 has-pointer">
      <div class="level">
        <div class="level-left" @click="toggleSection('financial_position')">
          <div class="level-item">
            <i class="fa fa-chevron-down" v-if="showSection != 'financial_position'"></i>
            <i class="fa fa-chevron-up" v-if="showSection == 'financial_position'"></i>
            Stap 4: Clientprofiel: Financiële positie
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <button
              class="button"
              @click="openEdit('financial_position')"
            >Clientprofiel: Financiële positie bewerken</button>
          </div>
        </div>
      </div>
    </h3>

    <financial-position
      :data="prospect.financial_position"
      v-if="showSection === 'financial_position'"
    ></financial-position>

    <h3 class="title is-4 has-pointer">
      <div class="level">
        <div class="level-left" @click="toggleSection('horizon_and_objective')">
          <div class="level-item">
            <i class="fa fa-chevron-down" v-if="showSection != 'horizon_and_objective'"></i>
            <i class="fa fa-chevron-up" v-if="showSection == 'horizon_and_objective'"></i>
            Stap 5: Clientprofiel: Horizon en beleggingsdoel
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <button
              class="button"
              @click="openEdit('horizon_and_objective')"
            >Clientprofiel: Horizon en beleggingsdoel bewerken</button>
          </div>
        </div>
      </div>
    </h3>

    <horizon-and-objective
      :data="prospect.horizon_and_objective"
      v-if="showSection === 'horizon_and_objective'"
    ></horizon-and-objective>

    <h3 class="title is-4 has-pointer">
      <div class="level">
        <div class="level-left" @click="toggleSection('risk_attitude')">
          <div class="level-item">
            <i class="fa fa-chevron-down" v-if="showSection != 'risk_attitude'"></i>
            <i class="fa fa-chevron-up" v-if="showSection == 'risk_attitude'"></i>
            Stap 6: Risicohouding
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <button class="button" @click="openEdit('risk_attitude')">Risicohouding bewerken</button>
          </div>
        </div>
      </div>
    </h3>

    <risk-attitude :data="prospect.risk_attitude" v-if="showSection === 'risk_attitude'"></risk-attitude>

    <h3 class="title is-4 has-pointer">
      <div class="level">
        <div class="level-left" @click="toggleSection('knowledge_and_experience')">
          <div class="level-item">
            <i class="fa fa-chevron-down" v-if="showSection != 'knowledge_and_experience'"></i>
            <i class="fa fa-chevron-up" v-if="showSection == 'knowledge_and_experience'"></i>
            Stap 7: Kennis en ervaring
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <button
              class="button"
              @click="openEdit('knowledge_and_experience')"
            >Kennis en ervaring bewerken</button>
          </div>
        </div>
      </div>
    </h3>

    <knowledge-and-experience
      :data="prospect.knowledge_and_experience"
      :bank_accounts="prospect.bank_accounts"
      v-if="showSection === 'knowledge_and_experience'"
    ></knowledge-and-experience>

    <h3 class="title is-4 has-pointer">
      <div class="level">
        <div class="level-left" @click="toggleSection('identification')">
          <div class="level-item">
            <i class="fa fa-chevron-down" v-if="showSection != 'identification'"></i>
            <i class="fa fa-chevron-up" v-if="showSection == 'identification'"></i>
            Stap 8: Identificatie
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <button
              class="button"
              @click="openEdit('identification')"
            >Identificatie bewerken</button>
          </div>
        </div>
      </div>
    </h3>

    <identification
      :data="prospect.identification"
      v-if="showSection === 'identification'"
    ></identification>

    <h3 class="title is-4 has-pointer">
      <div class="level">
        <div class="level-left" @click="toggleSection('custodian')">
          <div class="level-item">
            <i class="fa fa-chevron-down" v-if="showSection != 'custodian'"></i>
            <i class="fa fa-chevron-up" v-if="showSection == 'custodian'"></i>
            Stap 9: Keuze depotbank
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <button
              class="button"
              @click="openEdit('custodian')"
            >Keuze depotbank bewerken</button>
          </div>
        </div>
      </div>
    </h3>

    <custodian
      :data="prospect.custodian"
      v-if="showSection === 'custodian'"
    ></custodian>

    <b-modal class="prospectEditModal" :active.sync="showEdit" :width="960">
      <ProspectEdit :page="editPage" :id="id" ></ProspectEdit>
    </b-modal>
  </div>
</template>

<script>
import ProspectEdit from "./ProspectEdit.vue";
import BankAccounts from "./prospect/BankAccounts.vue";
import BusinessAssetsAndLiabilities from "./prospect/BusinessAssetsAndLiabilities.vue";
import AssetsAndLiabilities from "./prospect/AssetsAndLiabilities.vue";
import FinancialPosition from "./prospect/FinancialPosition.vue";
import HorizonAndObjective from "./prospect/HorizonAndObjective.vue";
import RiskAttitude from "./prospect/RiskAttitude.vue";
import KnowledgeAndExperience from "./prospect/KnowledgeAndExperience.vue";
import Identification from "./prospect/Identification.vue";
import Custodian from "./prospect/Custodian.vue";

export default {
  props: ["index"],

  components: {
    ProspectEdit,
    BankAccounts,
    BusinessAssetsAndLiabilities,
    AssetsAndLiabilities,
    FinancialPosition,
    HorizonAndObjective,
    RiskAttitude,
    KnowledgeAndExperience,
    Identification,
    Custodian
  },

  data() {
    return {
      show: false,
      prospect: {},
      showSection: "",
      showEdit: false,
      editPage: null,
      id: null
    };
  },

  mounted() {
    window.bus.$on('reloadCustomer', () => {
      setTimeout(() => {
        this.prospect = this.$parent.data.prospects[this.index];
      }, 500)
    });

    if (this.$route.params.section) {
      this.toggleSection(this.$route.params.section)
    }
  },

  computed: {
    data() {
      return this.$parent.data;
    }
  },

  methods: {
    toggleSection(section) {
      if (this.showSection == section) {
        return (this.showSection = "");
      }

      if (this.$route.params.section !== section) {
        this.$router.push({ name: 'admin/customer/prospect', params: {
          id: this.$parent.data.profile.id,
          index: this.index,
          section: section
        }});
      }

      return (this.showSection = section);
    },

    openEdit(page) {
      this.editPage = page;
      this.showEdit = true;
    }
  },

  watch: {
    index: function(index) {
      this.show = true;
      this.prospect = this.$parent.data.prospects[index];
      this.id = this.prospect.prospect.id;
    },

    showEdit: function(newValue) {
      // this.prospect = this.allProspectData;
    }
  }
};
</script>

<style>
.prospectEditModal .modal-content {
  width: 90% !important;
  max-width: 90% !important;
}
</style>
