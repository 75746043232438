<template>

    <div>
        <div class="block">
            <h4 class="title is-4">Wachtwoord wijzigen</h4>

            <div v-if="success">
                <p>Uw wachtwoord is succesvol gewijzigd. Voortaan kunt u met uw e-mailadres in combinatie met uw zojuist gekozen wachtwoord inloggen.</p>
            </div>

            <div v-if="!success">
                <p>Vul hieronder uw huidige wachtwoord in, gevolgd door tweemaal een door u gekozen nieuw wachtwoord. Het wachtwoord moet minimaal 8 tekens bevatten.</p>

                <form-errors :errors="form.errors"></form-errors>

                <form @submit.prevent="changePassword()">
                    <div class="field">
                        <label class="label">Huidig wachtwoord</label>
                        <input name="form.current_password" class="input" type="password" placeholder="Huidig wachtwoord" v-model="form.current_password">
                        <form-field-errors :errors="form.errors" field="current_password"></form-field-errors>
                    </div>

                    <div class="field">
                        <label class="label">Nieuw wachtwoord</label>
                        <input name="form.password" class="input" type="password" placeholder="Nieuw wachtwoord" v-model="form.password">
                        <form-field-errors :errors="form.errors" field="password"></form-field-errors>
                    </div>

                    <div class="field">
                        <label class="label">Nieuw wachtwoord (controle)</label>
                        <input name="form.password_confirmation" class="input" type="password" placeholder="Nieuw wachtwoord" v-model="form.password_confirmation">
                        <form-field-errors :errors="form.errors" field="password_confirmation"></form-field-errors>
                    </div>

                    <div class="field">
                        <button type="submit" class="button is-primary" :class="{ 'is-loading': form.loading }">Wachtwoord wijzigen</button>
                    </div>

                </form>
            </div>
        </div>
    </div>

</template>

<script>

    export default {

        data() {
            return {
                form: new Laravel.Form({
                    current_password: '',
                    password: '',
                    password_confirmation: ''
                }),
                success: false
            }
        },

        methods: {
            changePassword() {
                this.form.post('/api/profile/password')
                    .then((data) => {
                        this.success = true
                    })
            }
        }
    }

</script>
